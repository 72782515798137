import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import configs from '../utils/configs'
import UserStore from '../stores/UserStore'
import AuthStore from '../stores/AuthStore'
import OrderStore from '../stores/OrderStore'
import BasicHeader from '../components/header/BasicHeader'
import RoundCheckbox from '../components/checkbox/RoundCheckbox'
import ImageManager from '../images/ImageManager'
import SquareCheckbox from '../components/checkbox/SquareCheckbox'
import './ItemScreen.css'

const ShippingAddressScreen = observer(() => {
  const kakao = window.Kakao
  const [setToDefault, setSetToDefault] = useState(true)
  const [authObj, setAuthObj] = useState({})
  const [error, setError] = useState({})
  const history = useHistory()
  const checkInitialKakaoState = async () => {
    if (kakao) {
      if (!kakao.isInitialized()) {
        await kakao.init(configs.kakaoJsKey)
      }
    }
  }
  // const getUserInfoAndRegister = async (dynamicLinkParams) => {
  //   await kakao.API.request({
  //     url: '/v2/user/me',
  //     success: async (response) => {
  //       if (response?.properties?.alwayzToken) {
  //         console.log(`token saved in kakao properties!`)
  //         AuthStore.setToken(response.properties.alwayzToken)
  //         UserStore.setIsLoading(true)
  //         const [userSuccess, orders] = await Promise.all([
  //           await UserStore.loadUserInfo(),
  //           await OrderStore.loadUserOrdersList(),
  //         ])
  //         UserStore.setIsLoading(false)
  //         console.log(`user success: ${userSuccess}`)
  //         if (!userSuccess) {
  //           localStorage.removeItem('@alwayz@token')
  //           kakao.API.request({
  //             url: '/v1/user/unlink',
  //             success(response) {
  //               console.log(response)
  //             },
  //             fail(error) {
  //               console.log(error)
  //             },
  //           })
  //         }

  //         return
  //       }
  //       const kakaoAccountId = response.id
  //       const kakaoAccount = response.kakao_account
  //       if (!kakaoAccount?.has_phone_number) {
  //         alert(
  //           '해당 계정은 전화번호 등록이 되어있지 않아서 회원가입이 불가합니다. 다른 카카오 계정을 사용해주세요.',
  //         )
  //         kakao.API.request({
  //           url: '/v1/user/unlink',
  //           success(response) {
  //             console.log(response)
  //           },
  //           fail(error) {
  //             console.log(error)
  //           },
  //         })
  //         return
  //       }
  //       const {
  //         profile,
  //         // phone_number: phoneNumber,
  //         birthday: birthMonthAndDay,
  //         birthday_type: birthdayType,
  //         birthyear: birthYear,
  //         gender,
  //       } = kakaoAccount
  //       if (!profile) {
  //         alert(
  //           '카카오 계정 정보가 없습니다. 잠시 후 다시 시도해주시거나 다른 카카오 계정을 사용해주세요.',
  //         )
  //         return
  //       }
  //       const {
  //         nickname: userName,
  //         thumbnail_image_url: thumbnailUserImageUri,
  //         profile_image_url: userImageUri,
  //       } = profile

  //       let { phone_number: phoneNumber } = kakaoAccount
  //       console.log(`phone number: ${phoneNumber}`)
  //       const countryCode = phoneNumber.split(' ')[0].replace(/\+/g, '')
  //       phoneNumber = `0${phoneNumber.split(' ')[1].replace(/-/g, '')}`
  //       console.log(
  //         `success response: ${JSON.stringify(response)}, ${JSON.stringify({
  //           kakaoAccountId,
  //           userName,
  //           thumbnailUserImageUri,
  //           userImageUri,
  //           birthMonthAndDay,
  //           birthYear,
  //           birthdayType,
  //           countryCode,
  //           phoneNumber,
  //           gender,
  //           dynamicLinkParams,
  //         })}`,
  //       )
  //       const kakaoRegisterResult = await backendApis.registerKakaoUser(
  //         kakaoAccountId,
  //         userName,
  //         thumbnailUserImageUri,
  //         userImageUri,
  //         birthMonthAndDay,
  //         birthYear,
  //         birthdayType,
  //         countryCode,
  //         phoneNumber,
  //         gender,
  //         dynamicLinkParams,
  //       )
  //       console.log(
  //         `register kakao user result: ${JSON.stringify(kakaoRegisterResult)}`,
  //       )
  //       if ([200, 2001].includes(kakaoRegisterResult.status)) {
  //         AuthStore.setToken(kakaoRegisterResult.data.token)
  //         UserStore.setIsLoading(true)
  //         const [userSuccess, orders] = await Promise.all([
  //           await UserStore.loadUserInfo(),
  //           await OrderStore.loadUserOrdersList(),
  //         ])
  //         UserStore.setIsLoading(false)
  //         kakao.API.request({
  //           url: '/v1/user/update_profile',
  //           data: {
  //             properties: {
  //               alwayzUserId: kakaoRegisterResult.data.userId,
  //               alwayzToken: kakaoRegisterResult.data.token,
  //             },
  //           },
  //           success: (updateResponse) => {
  //             console.log(
  //               `user properties update response: ${JSON.stringify(
  //                 updateResponse,
  //               )}`,
  //             )
  //           },
  //           fail: (updateError) => {
  //             console.log(
  //               `user properties update error: ${JSON.stringify(updateError)}`,
  //             )
  //           },
  //         })
  //       }
  //     },
  //     fail: async (response) => {
  //       console.log(`fail response: ${JSON.stringify(response)}`)
  //     },
  //   })
  // }
  useEffect(() => {
    UserStore.setScreenName('ShippingAddressScreen_Web')
    checkInitialKakaoState()
  }, [])
  const ShippingAddressComponent = ({ addressInfo }) => {
    let receivingMethodText = ''
    if (addressInfo.receivingMethod === 'door') {
      receivingMethodText = '문 앞'
    } else if (addressInfo.receivingMethod === 'etc') {
      receivingMethodText = '기타'
    }
    const subText = { paddingTop: 2, paddingBottom: 2, color: '#BDBDBD' }
    return (
      <div
        style={{
          // backgroundColor: 'red',
          paddingTop: 16,
        }}
        role='button'
        tabIndex='0'
        onClick={() => {
          UserStore.setUserDefaultAddressInfo(addressInfo)
        }}
        onKeyDown={() => {
          UserStore.setUserDefaultAddressInfo(addressInfo)
        }}
      >
        <div
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <SquareCheckbox
            size={20}
            checked={
              UserStore.userInfo.defaultAddressInfo?.addressId ===
              addressInfo.addressId
            }
          />
          <div style={{ paddingLeft: 8 }}>{addressInfo.recipient}</div>
          <div style={{ paddingLeft: 8 }}>
            {`${addressInfo.recipientPhoneNumber.slice(
              0,
              3,
            )}-${addressInfo.recipientPhoneNumber.slice(
              3,
              7,
            )}-${addressInfo.recipientPhoneNumber.slice(7)}`}
          </div>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <button
              type='button'
              style={{
                display: 'flex',
                width: 24,
                height: 24,
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
                backgroundColor: '#FFF',
                border: 'none',
              }}
              onClick={() => {
                UserStore.deleteUserAddress(addressInfo.addressId)
              }}
            >
              <ImageManager source='Delete' size={20} />
            </button>
          </div>
        </div>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <div
            style={subText}
          >{`(${addressInfo.postcode}) ${addressInfo.postcodeAddress} `}</div>
          <div style={subText}>{addressInfo.detailAddress}</div>
          <div style={subText}>{`공동현관 비밀번호 : ${
            addressInfo.frontDoorPassword === ''
              ? '없음'
              : addressInfo.frontDoorPassword
          }`}</div>
          <div style={subText}>{`배송 수령 방법 : ${receivingMethodText}`}</div>
        </div>
        <div style={{ height: 8, backgroundColor: '#FFF' }} />
        <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
      </div>
    )
  }
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <BasicHeader title='배송지' />
      <div>
        {(UserStore.userInfo.addressesInfo || []).map((address) => (
          <ShippingAddressComponent
            key={address.addressId}
            addressInfo={address}
          />
        ))}
      </div>
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        <button
          style={{
            border: 'solid',
            outline: 'none',
            fontSize: 16,
            color: '#333333',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            width: '100%',
            borderColor: '#BDBDBD',
            borderWidth: 0.5,
            backgroundColor: '#FFF',
            height: 48,
          }}
          type='button'
          onClick={() => {
            history.push('/address/add')
          }}
        >
          <ImageManager source='PlusSmall' size={20} />
          <div style={{ paddingLeft: 8 }}>새 배송지 추가하기</div>
        </button>
      </div>
      {/* {!UserStore.userInfo._id && (
        <div>
          <button
            type='button'
            id='custom-login-btn'
            onClick={async () => {
              //   const kakao = window.Kakao
              kakao.Auth.login({
                // redirectUri: 'http://localhost:4002',
                // scope: 'profile,phone_number,gender,birthday',
                async success(authObjTemp) {
                  // alert(JSON.stringify(authObj))
                  setAuthObj(authObjTemp)
                  await kakao.Auth.setAccessToken(authObjTemp.access_token)
                  await getUserInfoAndRegister(UserStore.dynamicLinkParams)
                  //   await checkKakaoLogin()
                },
                fail(err) {
                  setError(err)
                  // alert(JSON.stringify(err))
                },
              })
              //   checkKakaoLogin()
              // kakao.Auth.authorize({
              //   redirectUri: 'http://192.168.45.230:4002',
              //   scope: 'profile,phone_number,gender,birthday',
              // })
            }}
          >
            카카오톡으로 주문자 인증하기
          </button>
        </div>
      )} */}
      <div style={{ backgroundColor: '#F5F5F5', flexGrow: 1 }}>
        {UserStore.userInfo.defaultAddressInfo.addressId && (
          <div
            role='button'
            tabIndex='0'
            onClick={() => {
              if (setToDefault) {
                setSetToDefault(false)
              } else {
                setSetToDefault(true)
              }
            }}
            onKeyDown={() => {
              if (setToDefault) {
                setSetToDefault(false)
              } else {
                setSetToDefault(true)
              }
            }}
            style={{
              paddingLeft: 16,
              paddingTop: 8,
              color: '#999999',
              fontSize: 14,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              outline: 'none',
            }}
          >
            <RoundCheckbox checked={setToDefault} size={16} />
            <div style={{ paddingLeft: 8 }}>
              현재 배송지를 기본 배송지로 설정
            </div>
          </div>
        )}
        {/* {JSON.stringify(authObj)}
      {JSON.stringify(error)} */}
        <div
          style={{
            paddingRight: 16,
            paddingLeft: 16,
          }}
          className='special-gray'
        >
          <button
            style={{
              width: '100%',
              marginBottom: 16,
              height: 48,
              backgroundColor: '#EA3f49',
              border: 'none',
              borderRadius: 4,
              fontSize: 18,
              color: '#FFF',
            }}
            type='button'
            onClick={async () => {
              if (!UserStore.userInfo._id) {
                alert(
                  '상품 배송상태를 전달받기 위해 주문자 인증하기 버튼을 눌러주세요!',
                )
                return
              }
              if (
                UserStore.userInfo.defaultAddressInfo?.addressId &&
                UserStore.userInfo.addressesInfo?.length > 0 &&
                UserStore.userInfo.addressesInfo.find(
                  (address) =>
                    address.addressId ===
                    UserStore.userInfo.defaultAddressInfo.addressId,
                )
              ) {
                if (setToDefault) {
                  UserStore.uploadUserDefaultAddressInfo()
                }
                // navigation.navigate('OrderMain')
                history.goBack()
                // UserStore.setUserDefaultAddressInfo()
              } else if (UserStore.userInfo.addressesInfo?.length > 0) {
                alert(
                  '선택한 배송지가 없습니다. 상품을 받으실 배송지를 선택해주세요',
                )
              } else {
                alert(
                  '등록한 배송지가 없습니다. 상품을 받으실 배송지를 등록해주세요',
                )
              }
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  )
})

export default ShippingAddressScreen
