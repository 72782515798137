import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import UserStore from '../stores/UserStore'
import BasicHeader from '../components/header/BasicHeader'
import RoundCheckbox from '../components/checkbox/RoundCheckbox'

const AddShippingAddressSecondScreen = observer(() => {
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    UserStore.setScreenName('AddShippingAddressSecondScreen_Web')
  }, [])
  if (!location.state) {
    history.replace('/')
    return <></>
  }
  const [existFrontDoorPassword, setExistFrontDoorPassword] = useState(true)
  const [frontDoorPassword, onChangefrontDoorPassword] = useState('')
  const [receivingMethod, setReceivingMethod] = useState('door')
  const [detailForReceivingMethod, onChangeDetailForReceivingMethod] =
    useState('')

  const refFrontDoorPassword = useRef()
  const refDetailForShippingMethod = useRef()

  const onChangeTextfrontDoorPassword = (text) => {
    setExistFrontDoorPassword(true)
    onChangefrontDoorPassword(text)
  }
  const onChangeTextDetailForReceivingMethod = (text) => {
    setReceivingMethod('etc')
    onChangeDetailForReceivingMethod(text)
  }
  const { addressInfo } = location.state
  const styles = {
    textInput: {
      height: 40,
      backgroundColor: '#F5F5F5',
      fontSize: 14,
      // alignSelf: 'center',
      // textAlign: 'center',
      borderRadius: 4,
      padding: 0,
      border: 'none',
      outline: 'none',
      paddingLeft: 8,
      flexGrow: 1,
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
      height: 32,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      outline: 'none',
      height: 40,
      alignItems: 'center',
    },
    subText: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  }
  return (
    <div>
      <BasicHeader title='새 배송지 추가하기(1/2)' />
      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
        <div style={styles.text}>공동현관 비밀번호</div>
        <div
          role='button'
          tabIndex='0'
          style={styles.container}
          onClick={() => {
            setExistFrontDoorPassword(true)
          }}
          onKeyDown={() => {
            setExistFrontDoorPassword(true)
          }}
        >
          <RoundCheckbox checked={existFrontDoorPassword} />
          <div style={styles.subText}>비밀번호</div>
          <input
            type='text'
            style={styles.textInput}
            placeholder='#0000'
            onChange={(e) => {
              onChangeTextfrontDoorPassword(e.target.value)
            }}
            value={frontDoorPassword}
            ref={refFrontDoorPassword}
          />
        </div>
        <div
          style={styles.container}
          role='button'
          tabIndex='0'
          onClick={() => {
            setExistFrontDoorPassword(false)
            onChangefrontDoorPassword('')
          }}
          onKeyDown={() => {
            setExistFrontDoorPassword(false)
            onChangefrontDoorPassword('')
          }}
        >
          <RoundCheckbox checked={!existFrontDoorPassword} />
          <div style={styles.subText}>없음</div>
        </div>
      </div>
      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
        <div style={styles.text}>배송 수령 방식</div>
        <div
          style={styles.container}
          role='button'
          tabIndex='0'
          onClick={() => {
            onChangeDetailForReceivingMethod('')
            setReceivingMethod('door')
          }}
          onKeyDown={() => {
            onChangeDetailForReceivingMethod('')
            setReceivingMethod('door')
          }}
        >
          <RoundCheckbox checked={receivingMethod === 'door'} />
          <div style={styles.subText}>문 앞</div>
        </div>
        <div
          style={styles.container}
          role='button'
          tabIndex='0'
          onClick={() => {
            setReceivingMethod('etc')
          }}
          onKeyDown={() => {
            setReceivingMethod('etc')
          }}
        >
          <RoundCheckbox checked={receivingMethod === 'etc'} />
          <div style={styles.subText}>지금 입력</div>
          <input
            style={styles.textInput}
            type='text'
            placeholder='직접 입력하기'
            onChange={(e) => {
              onChangeTextDetailForReceivingMethod(e.target.value)
            }}
            value={detailForReceivingMethod}
            ref={refDetailForShippingMethod}
          />
        </div>
      </div>
      <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 12 }}>
        <button
          style={{
            border: 'none',
            outline: 'none',
            fontSize: 16,
            color: '#FFF',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#EA3F49',
            height: 40,
          }}
          type='button'
          onClick={async () => {
            if (existFrontDoorPassword && frontDoorPassword === '') {
              alert('공동현관 비밀번호를 기입해주세요.')
              return
            }
            if (receivingMethod === 'etc' && detailForReceivingMethod === '') {
              alert('배송 수령 방식을 기입해주세요.')
              return
            }
            UserStore.setIsLoading(true)
            const {
              recipient,
              postcode,
              postcodeAddress,
              detailAddress,
              recipientPhoneNumber,
            } = addressInfo
            const result = await UserStore.registerAddress(
              recipient,
              postcode,
              postcodeAddress,
              detailAddress,
              recipientPhoneNumber,
              frontDoorPassword,
              receivingMethod,
              detailForReceivingMethod,
            )
            UserStore.setIsLoading(false)
            if (result?.status === 200) {
              console.log(`going back 2 steps!`)
              history.go(-2)
              // history.push('/address')
              // navigation.navigate('ShippingAddress')
            }
          }}
        >
          배송지 추가하기
        </button>
      </div>
    </div>
  )
})

export default AddShippingAddressSecondScreen
