import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import RenderRating from '../rating/RenderRating'
import moment from 'moment'

const ReviewComponent = observer(({ itemReviewInfo, source }) => {
  return (
    <div style={{ paddingLeft: 16 }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <RenderRating rate={itemReviewInfo.ratingScore} />
        <div
          style={{
            marginLeft: 6,
            paddingTop: 2,
            fontSize: 14,
            color: '#9E9E9E',
          }}
        >{`${moment(itemReviewInfo?.updatedAt).format('YYYY-MM-DD')} `}</div>
      </div>
      {itemReviewInfo?.nthPurchase > 1 && (
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: '#FFF',
          }}
        >
          <div style={{ fontSize: 14 }}>
            {`${itemReviewInfo?.nthPurchase}번 재구매`}
          </div>
        </div>
      )}
      {itemReviewInfo?.optionsText && (
        <>
          <div style={{ paddingTop: 4, fontSize: 12, color: '#9E9E9E' }}>
            {`${itemReviewInfo?.optionsText.join(', ')}`}
          </div>
        </>
      )}
      <div style={{ height: 12 }} />
      {itemReviewInfo.reviewImageUris?.length > 0 &&
        itemReviewInfo.reviewImageUris[0]?.toLowerCase().slice(-4) !==
          'heic' && (
          <div
            style={{
              width: 'auto',
              overflowX: 'scroll',
              overflowY: 'hidden',
              whiteSpace: 'nowrap',
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: 12,
            }}
          >
            {itemReviewInfo.reviewImageUris?.map((reviewImageUri) => (
              // <>
              <img
                key={reviewImageUri}
                alt={reviewImageUri}
                src={reviewImageUri}
                style={{
                  height: source === 'ReviewSummary' ? 96 : 160,
                  width: source === 'ReviewSummary' ? 96 : 160,
                  paddingRight: 16,
                  objectFit: 'cover',
                }}
              />
              // </>
            ))}
          </div>
        )}
      {itemReviewInfo.text.length > 0 && (
        <div
          style={{
            color: '#333333',
            fontSize: 14,
            paddingBottom: 12,
            paddingRight: 16,
          }}
        >
          {itemReviewInfo.text}
        </div>
      )}
      <div
        style={{
          height: 1,
          backgroundColor: '#E0E0E0',
          marginRight: 16,
        }}
      />
    </div>
  )
})

export default ReviewComponent
