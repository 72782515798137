import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../stores/ItemStore'
import UserStore from '../stores/UserStore'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'
import BasicHeader from './header/BasicHeader'
import ImageManager from '../images/ImageManager'
import ItemList from './items/ItemList'
import { useHistory } from 'react-router-dom'

const EmptyScreenComponent = observer(() => {
  const history = useHistory()
  useEffect(() => {
    if (
      !UserStore.isLoadingEmptyScreen &&
      !ItemStore.initialLoadingDataAssetsDone &&
      UserStore.screenName === 'EmptyScreen_Web'
    ) {
      const itemId = localStorage.getItem('@alwayz@itemId')
      if (!itemId) {
        ItemStore.initialLoadDataAssets()
      } else {
        history.replace(`/items/${itemId}`)
      }
    }
  }, [UserStore.isLoadingEmptyScreen, UserStore.screenName])

  return UserStore.isLoadingEmptyScreen ? (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0',
        bottom: '0',
      }}
    >
      <Loader type='Oval' color='#EA3F49' height='40' width='40' />
      <div style={{ paddingTop: 20, color: '#333333' }}>
        잠시만 기다려주세요
      </div>
    </div>
  ) : (
    <div
      style={{
        maxWidth: '480px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
      }}
    >
      <BasicHeader title='올웨이즈' />
      <div
        style={{
          paddingTop: 56,
          paddingBottom: 24,
          fontSize: 18,
          textAlign: 'center',
          lineHeight: 1.5,
        }}
      >
        <br />
        다시 상품 화면으로 가려면 뒤로가기 <br />
        혹은 들어온 링크로 다시 들어와주세요!
        <br />
      </div>
      <div
        style={{
          display: 'flex',
          height: 40,
          width: '100%',
          backgroundColor: '#FFF',
          paddingBottom: 48,
        }}
      >
        <button
          type='button'
          style={{
            height: 40,
            width: '100%',
            marginLeft: 16,
            marginRight: 16,
            border: 'solid',
            borderRadius: 4,
            borderWidth: 1,
            backgroundColor: '#EA3F49',
            color: '#FFF',
            borderColor: '#EA3F49',
            fontSize: 16,
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={async () => {
            const { itemId, teamDealId, invitorId, linkType } =
              UserStore.dynamicLinkParams
            UserStore.setIsLoading(true)
            const link = await dynamicLinkUtils.createAppDownloadLink(
              itemId || '',
              teamDealId || '',
              invitorId || '',
              linkType || '',
              '',
              '',
              '',
              'EmptyScreenWeb',
              UserStore.dynamicLinkParams.isLinkFromApp,
              UserStore.dynamicLinkParams.bottomSheetTimeOut,
              UserStore.isLateComer,
              UserStore.testGroup?.endedTeamDealNotify,
              UserStore.testGroup?.ableToPurchaseViralItem,
              UserStore.testGroup?.reviewPosition,
              UserStore.testGroup?.teamMemberShowing,
              UserStore.testGroup?.appDownloadFullScreen,
            )
            UserStore.setIsLoading(false)
            if (link) {
              console.log(`link: ${link}`)
              window.location.href = link
            }
          }}
        >
          <div style={{ paddingRight: 4 }}>앱으로 구매하여 추가 할인 받기</div>
          <ImageManager source='NextWhite' size={18} />
        </button>
      </div>
      {ItemStore.initialLoadingDataAssetsDone && (
        <ItemList
          categoryType='large'
          categoryId='total'
          enteringComponent='EmptyScreenAlwayzSuggestionComponent'
        />
      )}
      <div
        style={{
          width: '100%',
          height: 170,
          maxWidth: '456px',
          paddingTop: 24,
          paddingLeft: 24,
          paddingBottom: 24,
          color: '#bdbdbd',
          backgroundColor: '#F5F5F5',
          lineHeight: 1.4,
        }}
      >
        <div>대표자 : 강재윤 </div>
        <div>법인명 : 주식회사 레브잇</div>
        <div>사업자등록번호 : 179-81-02225</div>
        <div>주소 : 서울특별시 관악구 남부순환로 220길 39</div>
        <div>통신판매번호: 2022-서울관악-0341</div>
      </div>
    </div>
  )
})

export default EmptyScreenComponent
