import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import commaNumber from 'comma-number'

const ItemSummaryComponent = observer(
  ({
    imageUri,
    mainText = '',
    text1 = '',
    text2 = '',
    text3 = '',
    Price = '',
    itemPrice = '',
    shippingFee = '',
    totalPrice = '',
    discountPrice = '',
    couponsInfo = [],
    border = true,
    imageSize = 64,
    disabled = false,
    itemId,
    itemInfo,
    index,
    isCouponSelected = false,
    linkShareDiscountAmount = 0,
  }) => {
    const history = useHistory()
    const styles = {
      subText: {
        color: '#BDBDBD',
        fontSize: 14,
        paddingTop: 4,
      },
      priceText: {
        display: 'flex',
        flexDirection: 'row',
        color: '#BDBDBD',
        fontSize: 14,
        justifyContent: 'space-between',
        paddingTop: 4,
      },
      discountText: {
        display: 'flex',
        flexDirection: 'row',
        color: '#EA3F49',
        fontSize: 14,
        justifyContent: 'space-between',
        paddingTop: 4,
      },
      mainPriceText: {
        display: 'flex',
        flexDirection: 'row',
        color: '#333333',
        fontSize: 14,
        justifyContent: 'space-between',
        fontWeight: 'bold',
        paddingTop: 4,
      },
    }
    return (
      <div
        role='button'
        tabIndex='0'
        onClick={() => {
          if (disabled) {
            return
          }
          history.push(`/items/${itemId}`)
        }}
        onKeyDown={() => {
          if (disabled) {
            return
          }
          history.push(`/items/${itemId}`)
        }}
      >
        {/* {couponsInfo.length > 0 && <div>쿠폰 모달</div>} */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 12,
            paddingTop: 12,
          }}
        >
          <img
            alt=''
            src={imageUri}
            style={{ width: imageSize, height: imageSize, marginRight: 16 }}
          />
          <div style={{ width: '100%' }}>
            {mainText !== '' && <div style={{ fontSize: 16 }}>{mainText}</div>}
            {text1 !== '' && <div style={styles.subText}>{text1}</div>}
            {text2 !== '' && <div style={styles.subText}>{text2}</div>}
            {text3 !== '' && <div style={styles.subText}>{text3}</div>}
            {/* {couponsInfo.length > 0 &&
              (isCouponSelected ? <div>쿠폰 수정</div> : <div>쿠폰 선택</div>)} */}
            {itemPrice !== '' && (
              <div style={styles.priceText}>
                총 상품 가격 :
                <div /> {commaNumber(itemPrice)}원
              </div>
            )}
            {shippingFee !== '' && (
              <div style={styles.priceText}>
                배송비 : <div />
                {commaNumber(shippingFee)}원
              </div>
            )}
            {/* {linkShareDiscountAmount > 0 && (
              <div style={styles.discountText}>
                첫 구매 2000원 할인 : <div />
                {commaNumber(-linkShareDiscountAmount)}원
              </div>
            )} */}
            {totalPrice !== '' && (
              <div style={styles.mainPriceText}>
                상품 결제 금액 : <div />
                {commaNumber(totalPrice - (linkShareDiscountAmount || 0))}원
              </div>
            )}
            {Price !== '' && <div>{Price}원</div>}
          </div>
        </div>
      </div>
    )
  },
)

export default ItemSummaryComponent
