import React, { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'

function GganbuChickenScreen() {
  const [data, setData] = useState()
  const params = dynamicLinkUtils.parseLink(window.location.href)

  useEffect(async () => {
    if (params?.itemId) {
      const result = await backendApis.getGganbuChickenReceivedItemInfo(
        params?.itemId,
      )
      if (result?.status === 200) {
        setData(result?.data)
      }
    }
  }, [params?.itemId])

  return (
    <>
      <Layout>
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#F7F3E8',
            marginBottom: 10,
            fontFamily: 'GmarketSans',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: '100%',
              background:
                'linear-gradient(180deg, #FF9500 20%, #FFD01D 50%, #FF9500 70%, #FF9500 100%)',
              marginBottom: 10,
              fontFamily: 'GmarketSans',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // overflowY: 'scroll',
            }}
          >
            <div
              style={{
                fontSize: 32,
                marginTop: 20,
                color: '#704410',
                fontWeight: 'bold',
              }}
            >
              친구도 나도 받는 <span style={{ color: '#D00000' }}>1+1</span>
            </div>
            <div
              style={{
                fontSize: 16,
                marginTop: 4,
                marginBottom: 16,
                fontWeight: 'bold',
                color: '#905F02',
              }}
            >
              맞팜을 하고 1+1 치킨을 받아가세요
            </div>
            <div
              style={{
                //   backgroundColor: 'blue',
                width: '88vw',
                height: '36vh',
                borderRadius: 20,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'GmarketSans',
                // overflowY: 'scroll',
                //   paddingTop: 8,
                //   paddingBottom: 16,
              }}
            >
              <img
                src={`${data?.mainImageUri}`}
                alt=''
                style={{ height: 180, marginBottom: -12 }}
              />
              <div>
                <span
                  style={{
                    color: 'white',
                    backgroundColor: '#905F02',
                    padding: 4,
                    borderRadius: 4,
                    fontWeight: 'bold',
                  }}
                >
                  남은시간
                </span>{' '}
                23:50:00
              </div>
            </div>

            <div
              style={{
                backgroundColor: '#fff',
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
                marginTop: 48,
                height: 148,
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: 260,
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '80vw' }}
              >
                <button
                  type='button'
                  style={{
                    border: 'none',
                    background: 'none',
                    backgroundColor: '#FFCA28',
                    // width: '80vw',
                    height: 56,
                    marginTop: 12,
                    borderRadius: 12,
                    color: '#392500',
                    fontWeight: 'bold',
                    fontSize: 16,
                    flex: 1,
                  }}
                  //   className={`${'scaleUp'}`}
                  onClick={async () => {
                    window.location.href = `https://alwayzshop.ilevit.com/app`
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'MapleStory',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      fontSize: 18,
                    }}
                  >
                    <img
                      style={{
                        height: 60,
                        marginBottom: 72,
                        top: -60,
                        position: 'absolute',
                      }}
                      alt=''
                      src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/e50c7eee-bd8e-4e81-8535-d83713e5d00a.png'
                    />
                    치킨 받으러 가기
                  </div>
                </button>
              </div>

              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  margin: 16,
                  paddingBottom: 8,
                  width: '80vw',
                  textAlign: 'center',
                  borderBottom: '1px solid rgba(64, 55, 58, 0.1)',
                }}
              >
                참여방법
              </div>
              <div
                style={{
                  fontSize: 14,
                  width: '80vw',
                  height: 80,
                  color: '#63565B',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginBottom: 40,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#F7F3E8',
                      color: '#63565B',
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 8,
                    }}
                  >
                    1
                  </div>{' '}
                  <div>올웨이즈에 가입하여 올팜 맞팜을 해요</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#F7F3E8',
                      color: '#63565B',
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 8,
                    }}
                  >
                    2
                  </div>{' '}
                  <div>
                    24시간 내 {data?.missionGganbuNum}명과 맞팜하기 미션을
                    완료해요
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#F7F3E8',
                      color: '#63565B',
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 8,
                    }}
                  >
                    3
                  </div>{' '}
                  <div>친구와 나 모두 치킨 이벤트에 참여가 돼요</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default GganbuChickenScreen
