import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import UseWindowDimension from '../common/UseWindowDimension'
import ImageManager from '../../images/ImageManager'

// import { useHistory, useLocation, useParams } from 'react-router-dom'

const MainImages = observer(() => {
  const { width } = UseWindowDimension()
  const [currentIndex, setCurrentIndex] = useState(0)
  return (
    <div
      style={{
        // display: 'flex',
        // flexDirection: 'row',
        position: 'relative',
        width: '100%',
        height: 0,
        paddingBottom: '100%',
        // overflow: 'hidden',
        // height: window.innerWidth,
        // overflowX: 'scroll',
        // scrollSnapType: 'x mandatory',
      }}
    >
      <div
        onScroll={(e) => {
          setCurrentIndex(Math.round(e.target.scrollLeft / width))
        }}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'row',
          overflowY: 'hidden',
          overflowX: 'scroll',
          scrollSnapType: 'x mandatory',
          // whiteSpace: 'nowrap',
        }}
      >
        {ItemStore.targetItemInfo.mainImageUris?.map((mainImageUri, index) => (
          <div
            key={mainImageUri}
            style={{
              scrollSnapAlign: 'start',
              position: 'relative',
              width: '100%',
              height: '100%',
              // height: 0,
              // paddingBottom: '100%',
            }}
          >
            <img
              alt={mainImageUri}
              // key={mainImageUri}
              src={mainImageUri}
              style={{
                // position: 'absolute',
                // top: 0,
                // right: 0,
                // bottom: 0,
                // left: 0,
                objectFit: 'cover',
                // width: window.innerWidth,
                // width: '100%',
                paddingRight: `${
                  index === ItemStore.targetItemInfo.mainImageUris.length - 1
                    ? 0
                    : 100
                }%`,
                height: '100%',
              }}
              // width={window.innerWidth}
              // height='100%'
              // height={window.innerWidth}
            />
          </div>
        ))}
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 12,
          right: 16,
          paddingLeft: 12,
          paddingRight: 12,
          color: '#FFF',
          fontSize: 12,
          height: 24,
          backgroundColor: '#00000088',
          borderRadius: 16,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        {currentIndex + 1}/{ItemStore.targetItemInfo.mainImageUris?.length}
        {/* {index + 1}/{ItemStore.targetItemInfo.mainImageUris?.length} */}
      </div>
      {ItemStore.targetItemInfo?.isCheapest && (
        <ImageManager
          source='IsCheapestImage'
          style={{
            position: 'absolute',
            left: 12,
            top: 12,
            width: 64,
            height: 64,
          }}
        />
      )}
    </div>
  )
})

export default MainImages
