import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import StarFull from '../../images/StarFull.png'
import StarHalf from '../../images/StarHalf.png'
import StarNone from '../../images/StarNone.png'
import ImageManager from '../../images/ImageManager'

const RenderRating = observer(({ rate, size = 20 }) => {
  const ratingArray = []
  for (let i = 0; i < 5; i += 1) {
    if (i < Math.floor(rate + 0.25)) {
      ratingArray.push('StarFull')
    } else if (Math.floor(rate) < i) {
      ratingArray.push('StarNone')
    } else if (Math.floor(rate) === i) {
      if (rate - Math.floor(rate) > 0.25) {
        ratingArray.push('StarHalf')
      } else {
        ratingArray.push('StarNone')
      }
    }
  }
  return (
    <span>
      {ratingArray.map((value, index) => {
        // return <span key={value + index.toString()}>{value} </span>
        return (
          <ImageManager
            key={value.toString() + index.toString()}
            source={value}
            size={size}
          />
        )
      })}
    </span>
  )
})

export default RenderRating
