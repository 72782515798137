import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import OrderStore from '../../stores/OrderStore'
import UserStore from '../../stores/UserStore'
import commaNumber from 'comma-number'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import '../../screens/ItemScreen.css'

const ItemQuestionButton = observer(() => {
  const history = useHistory()
  return (
    <div
      style={{
        maxWidth: '448px',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingRight: 16,
        paddingLeft: 16,
      }}
      className='special-gray'
    >
      <button
        style={{
          width: '100%',
          marginBottom: 16,
          marginTop: 8,
          height: 48,
          backgroundColor: '#EA3f49',
          border: 'none',
          borderRadius: 4,
          fontSize: 18,
          color: '#FFF',
        }}
        type='button'
        onClick={() => {
          UserStore.setShowAppDownloadModal(true)
        }}
      >
        상품 문의하기
      </button>
    </div>
  )
})

export default ItemQuestionButton
