import React from 'react'
import ImageManager from '../../images/ImageManager'

const SquareCheckbox = ({ checked = false, size = 16 }) => {
  if (checked) {
    return (
      <div
        style={{
          display: 'flex',
          width: size,
          height: size,
          backgroundColor: '#EA3F49',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 4,
        }}
      >
        <ImageManager source='CheckSmall' size={size - 4} />
      </div>
    )
  }
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: 4,
        border: 'solid',
        borderColor: '#BDBDBD',
        borderWidth: 0.5,
      }}
    />
  )
}

export default SquareCheckbox
