import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ImageManager from '../images/ImageManager'
import Modal from 'react-modal'

// 사진
import PocketMoneyEnvelope from '../images/PocketMoneyEnvelope.png'
import duoduoStyleEnvelope from '../images/duoduoStyleEnvelope.png'
import LotteryIcon from '../images/LotteryIcon.png'
import FriendIcon from '../images/FriendIcon.png'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'
import commaNumber from 'comma-number'
import ItemList from '../components/items/ItemList'
import ItemStore from '../stores/ItemStore'
import WhiteTextTimer from '../components/texts/WhiteTextTimer'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '88%',
    borderRadius: 25.5,
    borderColor: '#BDBDBD',
    padding: 0,
    height: 240,
    paddingTop: 18,
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // paddingLeft: 20,
    // paddingRight: 20,
    transform: 'translate(-50%, -50%)',
  },
}

const pocketMoneyScreen = observer(() => {
  const history = useHistory()

  const [price, setPrice] = useState(0)
  // const [phoneNumberText, setPhoneNumberText] = useState('')
  // const [phoneNumber, setPhoneNumber] = useState('')
  // const [showModal, setShowModal] = useState(false)

  // const onChange = (e: any) => {
  //   setPhoneNumberText(e.target.value)
  // }

  useEffect(() => {
    const params = dynamicLinkUtils.parseLink(window.location.href)

    if (params?.receiverPrice) {
      setPrice(params.receiverPrice)
    }
  }, [])
  useEffect(() => {
    ItemStore.loadSpecificItemsInfo()
  }, [])

  useEffect(() => {
    if (!ItemStore.initialLoadingDataAssetsDone) {
      ItemStore.initialLoadDataAssets()
    }
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: 1,
        backgroundColor: '#FFF3E1',
      }}
    >
      {/* <Modal
        isOpen={showModal}
        style={customStyles}
        portalClassName='modal'
        aria-labelledby='contained-modal-title-vcenter'
        fullScreen='mg-down'
      >
        <div>
          <div
            className='box-sizing'
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 12,
              alignItems: 'center',
              marginBottom: 24,
            }}
          >
            <div
              className='box-sizing'
              style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}
            >
              전화번호 입력하고
            </div>
            <div
              className='box-sizing'
              style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}
            >
              {`${price}원 받기`}
            </div>
          </div>
          <input
            value={phoneNumberText}
            onChange={onChange}
            placeholder='01012345678'
            style={{
              width: '100%',
              fontWeight: 'bold',
              fontSize: 24,
              textAlign: 'center',
              marginBottom: 24,
            }}
          />
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              width: '100%',
              display: 'flex',
              marginBottom: 8,
            }}
          >
            <button
              className='box-sizing sale-button'
              style={{
                height: 51,
                backgroundColor: '#FF3E3E',
                borderRadius: 25.5,
                fontSize: 18,
                fontWeight: 'bold',
                color: '#fff',
                width: window.innerWidth - 96,
                borderColor: '#E9E6E6',
              }}
              onClick={() => {
                if (phoneNumber !== phoneNumberText) {
                  alert(
                    '전화번호가 일치하지 않아요. 다시 한번 전화번호를 확인해주세요!',
                  )
                  return
                }

                if (price > 0) {
                  if (phoneNumber === phoneNumberText) {
                    alert('전화번호가 일치합니다! 인출하러 가볼까요?')
                    window.location.href = `https://alwayzshop.ilevit.com/app`
                  }
                  return
                }

                alert('알 수 없는 오류 발생! 새로고침 후 다시 시작해주세요!')
              }}
            >
              {`${commaNumber(price)}원 받기`}
            </button>
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 12,
            }}
          >
            <button
              className='box-sizing'
              type='button'
              style={{
                display: 'flex',
                width: 24,
                height: 24,
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
                backgroundColor: '#FFF',
                border: 'none',
              }}
              onClick={() => {
                setShowModal(false)
              }}
            >
              <ImageManager source='Delete' size={20} />
            </button>
          </div>
        </div>
      </Modal> */}
      <div
        style={{
          height: 48,
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '25%', marginTop: 4 }}>
          <ImageManager source='AlwayzFullLogoRed' />
        </div>
      </div>
      <div
        className='box-sizing'
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          alignItems: 'center',
          marginBottom: 24,
        }}
      >
        <div style={{ height: 40 }} />
        <div
          className='box-sizing'
          style={{
            fontSize: 24,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          5만원 채우면 현금으로 출금!
        </div>
        <div style={{ height: 16 }} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          marginTop: -20,
        }}
      >
        <img src={duoduoStyleEnvelope} alt='' width={window.innerWidth - 128} />
        <div
          className='box-sizing'
          style={{
            position: 'absolute',
            top: (window.innerWidth - 128) * 0.08,
            fontSize: 21,
          }}
        >
          현재 금액
        </div>
        <div
          className='box-sizing'
          style={{
            position: 'absolute',
            top: (window.innerWidth - 128) * 0.18,
            fontSize: 36,
            fontWeight: 'bold',
          }}
        >
          {`${commaNumber(47810)} `}
          <span
            style={{
              fontSize: 30,
              fontWeight: 'bold',
            }}
          >
            원
          </span>
        </div>
        <div
          className='box-sizing'
          style={{
            position: 'absolute',
            bottom: (window.innerWidth - 128) * 0.04,
            fontSize: 15,
            color: '#fff',
          }}
        >
          남은 시간{' '}
          <WhiteTextTimer
            onTimeEnd={() => {}}
            timeStamp={new Date(new Date().setHours(new Date().getHours() + 4))}
            timerMinutes={0}
          />
        </div>
      </div>
      <div style={{ height: 20 }} />
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          width: '100%',
          display: 'flex',
          marginBottom: 24,
        }}
      >
        <button
          className='box-sizing sale-button'
          style={{
            height: 46,
            backgroundColor: '#FF3E3E',
            borderRadius: 10,
            fontSize: 18,
            fontWeight: 'bold',
            color: '#fff',
            width: window.innerWidth - 96,
            borderColor: 'yellow',
          }}
          onClick={() => {
            // alert('현금 받으러 가볼까요?')
            window.location.href = `https://alwayzshop.ilevit.com/app`
          }}
        >
          친구 초대하고 5만원 모으기
        </button>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 20,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            width: window.innerWidth / 2,
          }}
        >
          <div
            className='box-sizing'
            style={{
              marginLeft: 36,
              marginRight: 6,
              backgroundColor: '#fff',
              borderRadius: 25.5,
              paddingTop: 12,
              paddingBottom: 12,
            }}
          >
            <img
              style={{ marginBottom: 12 }}
              src={LotteryIcon}
              alt=''
              width={40}
            />
            <div>한시간마다 랜덤금액을</div>
            <div>뽑을 수 있어요.</div>
            <div style={{ height: 12 }} />
            <button
              className='box-sizing sale-button'
              style={{
                backgroundColor: '#FF3E3E',
                borderRadius: 10,
                fontSize: 15,
                fontWeight: 'bold',
                color: '#fff',
                width: '84%',
                height: 32,
              }}
              onClick={() => {
                window.location.href = `https://alwayzshop.ilevit.com/app`
              }}
            >
              랜덤 금액 뽑기
            </button>
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
            width: window.innerWidth / 2,
          }}
        >
          <div
            className='box-sizing'
            style={{
              marginLeft: 6,
              marginRight: 36,
              backgroundColor: '#fff',
              borderRadius: 25.5,
              paddingTop: 12,
              paddingBottom: 12,
            }}
          >
            <img
              style={{ marginBottom: 12 }}
              src={FriendIcon}
              alt=''
              width={40}
            />
            <div>친구에게 적립금을</div>
            <div>줄 수 있어요.</div>
            <div style={{ height: 12 }} />
            <button
              className='box-sizing sale-button'
              style={{
                backgroundColor: '#FF3E3E',
                borderRadius: 10,
                fontSize: 15,
                fontWeight: 'bold',
                color: '#fff',
                height: 32,
                width: '84%',
              }}
              onClick={() => {
                // alert('현금 받으러 가볼까요?')
                window.location.href = `https://alwayzshop.ilevit.com/app`
              }}
            >
              0명 / 3명
            </button>
          </div>
        </div>
      </div>
      {ItemStore.initialLoadingDataAssetsDone && (
        <div style={{ backgroundColor: 'white' }}>
          <ItemList
            categoryType='large'
            categoryId='total'
            enteringComponent='OrderEndScreenAlwayzSuggestionComponent'
          />
        </div>
      )}
    </div>
  )
})

export default pocketMoneyScreen
