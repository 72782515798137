import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import ItemStore from '../stores/ItemStore'
import BasicHeader from '../components/header/BasicHeader'
import UserStore from '../stores/UserStore'
import ItemQuestionButton from '../components/tab/ItemQuestionButton'

const ItemQuestionScreen = observer(() => {
  const location = useLocation()
  const itemId = location.state?.itemId
  const history = useHistory()
  useEffect(() => {
    UserStore.setScreenName('ItemQuestionScreen_Web')
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div style={{ backgroundColor: '#e2e2e8' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '480px',
          marginLeft: 'auto',
          marginRight: 'auto',
          backgroundColor: '#ffffff',
        }}
      >
        <BasicHeader title='상품 문의' />
        {(ItemStore.targetItemInfo.itemQuestions || []).map((item) => (
          <div key={item._id}>
            <div
              style={{
                fontSize: 16,
                paddingTop: 12,
                paddingBottom: 12,
                marginLeft: 16,
                marginRight: 16,
                color: '#333333',
              }}
            >
              Q. {item.questionInfo.text}
            </div>
            <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
            <div
              style={{
                fontSize: 16,
                paddingTop: 12,
                paddingBottom: 12,
                marginLeft: 16,
                marginRight: 16,
                color: '#999999',
              }}
            >
              {item.answerInfo
                ? `A. ${item.answerInfo.text}`
                : '답변을 기다리고 있습니다.'}
            </div>
            <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
          </div>
        ))}
        {(ItemStore.targetItemInfo.itemQuestions || []).length === 0 && (
          <div
            style={{
              height: 128,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 14,
              color: '#999999',
            }}
          >
            아직 상품 문의가 없어요.
          </div>
        )}
        <div style={{ backgroundColor: '#F5F5F5', flexGrow: 1 }} />
        <ItemQuestionButton />
      </div>
    </div>
  )
})

export default ItemQuestionScreen
