import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ItemStore from '../../stores/ItemStore'
import UserStore from '../../stores/UserStore'
import OrderStore from '../../stores/OrderStore'
import PastTeamOrderComponent from './PastTeamOrderComponent'
import DoubleProfileImage from '../social/DoubleProfileImage'
import GrayTextTimer from '../texts/GrayTextTimer'
import SoldOutModal from '../modal/SoldOutModal'
import parseViralItemOptions from '../../utils/parseViralItemOptions'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'

const TeamOrderComponent = observer(
  ({
    team,
    setValidTeamDeals,
    validTeamDeals,
    needsPrivacyProtection,
    soldOut,
    isViralItem,
    onClickFunc,
  }) => {
    const [showSoldOutModal, setShowSoldOutModal] = useState(false)
    if (team.teamCompleted) {
      return <PastTeamOrderComponent team={team} />
    }
    if (isViralItem) {
      return (
        <div
          style={{
            height: 48,
            paddingLeft: 16,
            paddingRight: 16,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <SoldOutModal
            showModal={showSoldOutModal}
            setShowModal={setShowSoldOutModal}
          />
          <DoubleProfileImage
            names
            membersInfo={team.membersInfo}
            needsPrivacyProtection={needsPrivacyProtection}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                color: '#999999',
                fontSize: 14,
              }}
            >
              <div>
                남은 인원{' '}
                <span style={{ color: '#EA3F49' }}>
                  {(team.teamDealThreshold || 2) - team.membersInfo.length}명
                </span>
              </div>
              <div>
                <GrayTextTimer
                  timeStamp={new Date(team.restartedAt || team.createdAt)}
                  onTimeEnd={() => {
                    setValidTeamDeals(
                      validTeamDeals.filter(
                        (teamDeal) => teamDeal._id !== team._id,
                      ),
                    )
                  }}
                />
              </div>
            </div>
            <div>
              <button
                style={{
                  border: 'solid',
                  borderColor: '#E0E0E0',
                  borderWidth: 1,
                  display: 'flex',
                  color: '#BDBDBD',
                  flexDirection: 'row',
                  backgroundColor: '#FFF',
                  borderRadius: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 14,
                  height: 32,
                  paddingLeft: 12,
                  paddingRight: 12,
                  marginLeft: 12,
                }}
                type='button'
                onClick={() => {}}
              >
                팀 모집 중
              </button>
            </div>
          </div>
        </div>
      )
    }
    const onPressJoinTeamDeal = () => {
      if (soldOut) {
        setShowSoldOutModal(true)
        // alert(
        //   '해당 상품은 품절되어서 주문이 불가능합니다. 더 많은 상품들을 보시려면 앱에서 확인해주세요!',
        // )
        return
      }
      const isParticipatedTeamDeal = team.membersInfo.find(
        (member) => member.userId === UserStore.userInfo._id,
      )
      if (isParticipatedTeamDeal) {
        alert('이미 참여한 팀에는 다시 참여할 수 없어요.')
        return
      }
      // const existingOrder = OrderStore.loadedUserOrdersList
      //   .filter((order) =>
      //     ['team-gathering', 'pre-shipping', 'shipping'].includes(order.status),
      //   )
      //   .find((order) => order.itemInfo.itemId === ItemStore.targetItemInfo._id)
      // if (existingOrder) {
      //   alert(
      //     '해당 상품은 동시에 여러 번 주문할 수 없어요. 배송 완료 후 주문 가능합니다.',
      //   )
      //   return
      // }
      let sellerId = ItemStore.targetItemInfo.sellerInfo.sellerId
      let optionsInfo = ItemStore.targetItemInfo.optionsInfo
      if (ItemStore.targetItemInfo.sellerInfo.sharedSellerIds?.length > 0) {
        const randomNumber = Math.random()
        if (randomNumber > ItemStore.targetItemInfo.sellerInfo.orderRatio) {
          const randomInt = Math.floor(
            Math.random() *
              ItemStore.targetItemInfo.sellerInfo.sharedSellerIds?.length,
          )
          sellerId =
            ItemStore.targetItemInfo.sellerInfo.sharedSellerIds[randomInt]
          optionsInfo = parseViralItemOptions(optionsInfo, randomInt)
        }
      }
      OrderStore.setItemInfoByIndex(
        {
          itemId: ItemStore.targetItemInfo._id,
          itemTitle: ItemStore.targetItemInfo.itemTitle,
          optionsInfo,
          itemImageUri:
            ItemStore.targetItemInfo.mainImageUris &&
            ItemStore.targetItemInfo.mainImageUris[0],
          isTaxFree: !!ItemStore.targetItemInfo.isTaxFree,
          shippingInfo: ItemStore.targetItemInfo.shippingInfo,
          teamPurchasePrice: ItemStore.targetItemInfo.teamPurchasePrice,
          individualPurchasePrice:
            ItemStore.targetItemInfo.individualPurchasePrice,
          purchaseLimits: ItemStore.targetItemInfo.purchaseLimits,
          teamDealThreshold: ItemStore.targetItemInfo.teamDealThreshold,
          sellerId,
          dynamicLinkParams: {
            ...UserStore.dynamicLinkParams,
            ...UserStore.testGroup,
          },
          isViralItem: ItemStore.targetItemInfo.isViralItem,
          isUnrestartableItem: ItemStore.targetItemInfo.isUnrestartableItem,
          isLotteryItem: ItemStore.targetItemInfo?.isLotteryItem,
          isCouponExchangeItem: ItemStore.targetItemInfo?.isCouponExchangeItem,
          isMartItem: ItemStore.targetItemInfo?.isMartItem,
          characteristicItem: ItemStore.targetItemInfo?.characteristicItem,
          source: 'Web',
        },
        OrderStore.currentItemIndex,
      )
      OrderStore.setItemIsTeamDealByIndex(true, OrderStore.currentItemIndex)
      OrderStore.setItemTeamDealIdByIndex(team._id, OrderStore.currentItemIndex)
      OrderStore.setItemQuantityByIndex(
        OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
          .quantity || 1,
        OrderStore.currentItemIndex,
      )
      OrderStore.setCloseOrderBottomSheet(false)
    }
    return (
      <div
        style={{
          height: 48,
          paddingLeft: 16,
          paddingRight: 16,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <SoldOutModal
          showModal={showSoldOutModal}
          setShowModal={setShowSoldOutModal}
        />
        <DoubleProfileImage
          names
          membersInfo={team.membersInfo}
          needsPrivacyProtection={needsPrivacyProtection}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              color: '#999999',
              fontSize: 14,
            }}
          >
            <div>
              남은 인원{' '}
              <span style={{ color: '#EA3F49' }}>
                {(team.teamDealThreshold || 2) - team.membersInfo.length}명
              </span>
            </div>
            <div>
              <GrayTextTimer
                timeStamp={new Date(team.restartedAt || team.createdAt)}
                onTimeEnd={() => {
                  setValidTeamDeals(
                    validTeamDeals.filter(
                      (teamDeal) => teamDeal._id !== team._id,
                    ),
                  )
                }}
              />
            </div>
          </div>
          <div>
            <button
              style={{
                border: 'none',
                display: 'flex',
                color: '#FFF',
                flexDirection: 'row',
                backgroundColor: '#ea3f49',
                borderRadius: 4,
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
                height: 32,
                paddingLeft: 12,
                paddingRight: 12,
                marginLeft: 12,
              }}
              type='button'
              onClick={onClickFunc}
            >
              참여하기
            </button>
          </div>
        </div>
      </div>
    )
  },
)

export default TeamOrderComponent
