import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'
import OrderStore from '../../stores/OrderStore'
import ItemStore from '../../stores/ItemStore'
import UserStore from '../../stores/UserStore'
import ImageManager from '../../images/ImageManager'

// import copyToClipboard from '../../utils/clipboardUtils'

// function copyToClipboard(textToCopy) {
//   // navigator clipboard api needs a secure context (https)
//   if (navigator.clipboard && window.isSecureContext) {
//     // navigator clipboard api method'
//     return navigator.clipboard.writeText(textToCopy)
//   }
//   // text area method
//   const textArea = document.createElement('textarea')
//   textArea.value = textToCopy
//   // make the textarea out of viewport
//   textArea.style.position = 'fixed'
//   textArea.style.left = '-999999px'
//   textArea.style.top = '-999999px'
//   document.body.appendChild(textArea)
//   textArea.focus()
//   textArea.select()
//   try {
//     const successful = document.execCommand('copy')
//     const msg = successful ? 'successful' : 'unsuccessful'
//     console.log(`msg: ${msg}`)
//   } catch (err) {
//     console.log('Was not possible to copy te text: ', err)
//   }
// }

const ItemScreenHeader = observer(({ onClickFunc }) => {
  // const [appDownloadLink, setAppDownloadLink] = useState('')
  // const createAppDownloadLink = async () => {
  //   const { itemId, teamDealId, invitorId, linkType } =
  //     UserStore.dynamicLinkParams
  //   UserStore.setIsLoading(true)
  //   const link = await dynamicLinkUtils.createAppDownloadLink(
  //     itemId || '',
  //     teamDealId || '',
  //     invitorId || '',
  //     linkType || '',
  //     '',
  //     '',
  //     '',
  //     'ItemScreen',
  //   )
  //   UserStore.setIsLoading(false)
  //   setAppDownloadLink(link)
  // }
  // useEffect(() => {
  //   createAppDownloadLink()
  // }, [])
  // async function copyToClipboard(textToCopy) {
  //   // navigator clipboard api needs a secure context (https)
  //   if (navigator.clipboard) {
  //     // navigator clipboard api method'
  //     await navigator.clipboard.writeText(textToCopy)
  //     return
  //   }
  //   // text area method
  //   const textArea = document.createElement('textarea')
  //   textArea.value = textToCopy
  //   // make the textarea out of viewport
  //   textArea.style.position = 'fixed'
  //   textArea.style.left = '-999999px'
  //   textArea.style.top = '-999999px'
  //   document.body.appendChild(textArea)
  //   textArea.select()
  //   try {
  //     const successful = document.execCommand('copy')
  //     const msg = successful ? 'successful' : 'unsuccessful'
  //     console.log(`msg: ${msg}`)
  //   } catch (err) {
  //     console.log('Was not possible to copy te text: ', err)
  //   }
  //   document.body.removeChild(textArea)
  // }
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        zIndex: 100,
        width: '100%',
        maxWidth: '480px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <button
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          backgroundColor: '#000000d4',
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          fontSize: 16,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        type='button'
        onClick={onClickFunc}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: 56,
            alignItems: 'center',
          }}
        >
          <ImageManager source='AppIcon' size={44} />
          <div
            style={{
              marginLeft: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              color: '#ffffff',
            }}
          >
            <div style={{ fontSize: 14, fontWeight: '500', paddingBottom: 4 }}>
              100원만 사도 무료배송!
            </div>
            <div style={{ fontSize: 18, fontWeight: 'bold' }}>
              앱에서 3만원 쿠폰팩 받기
            </div>
          </div>
        </div>

        <div
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 12,
            borderRadius: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ffffff',
            fontSize: 14,
            fontWeight: 'bold',
            backgroundColor: '#EA3F49',
          }}
        >
          <span style={{ marginRight: 2 }}>앱 열기</span>
          <ImageManager source='NextWhite' size={14} />
        </div>
      </button>
      {/* </CopyToClipboard> */}
      {/* ItemScreenHeader <button type='button'>SocialFeed</button>
      <button type='button'> KakaoShare</button>
      <button
        type='button'
        onClick={async () => {
          const existingTeamDeal = OrderStore.loadedUserOrdersList.find(
            (order) =>
              order.status === 'team-gathering' &&
              order.itemInfo.itemId === ItemStore.targetItemInfo._id &&
              order.teamDealId,
          )
          const result = await dynamicLinkUtils.createDynamicItemLink(
            ItemStore.targetItemInfo._id,
            existingTeamDeal ? existingTeamDeal.teamDealId : '',
            UserStore.userInfo._id,
            'dynamicLink',
            `${ItemStore.targetItemInfo.itemTitle} ${ItemStore.targetItemInfo.teamPurchasePrice}원!`,
            `${UserStore.userInfo.userName}님과 팀으로 구매하면 무료배송?! 배송지도 따로, 옵션도 따로!`,
            ItemStore.targetItemInfo.mainImageUris[0],
          )
        }}
      >
        {' '}
        OtherShare
      </button> */}
    </div>
  )
})

export default ItemScreenHeader
