import React from 'react'
import ImageManager from '../../images/ImageManager'

const AppDownloadMessage = ({ small = false }) => {
  if (small) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: 56,
        }}
      >
        <ImageManager source='AppIcon' size={56} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 16,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ fontSize: 15, fontWeight: 'bold', color: '#333333' }}>
            올웨이즈
          </div>
          <div style={{ fontSize: 15, fontWeight: 'bold', color: '#333333' }}>
            100원만 사도 무료배송!
          </div>
          <div style={{ display: 'flex', flex: 1 }} />
          <div style={{ fontSize: 12, color: '#BDBDBD', fontWeight: 'bold' }}>
            앱에서 더 많은 혜택을 받으세요
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 24 }}>
      <ImageManager source='AppIcon' size={80} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 16,
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <div style={{ fontSize: 20, fontWeight: 'bold' }}>
          조건없이 모든 상품
        </div>
        <div style={{ fontSize: 20, fontWeight: 'bold' }}>무료배송!</div>
        <div style={{ display: 'flex', flex: 1 }} />
        <div style={{ fontSize: 16, color: '#BDBDBD', fontWeight: 'bold' }}>
          앱으로 더 많은 상품과 혜택받기
        </div>
      </div>
    </div>
  )
}

export default AppDownloadMessage
