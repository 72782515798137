import { makeObservable, observable, action, runInAction } from 'mobx'
import backendApis from '../utils/backendApis'
import matrixUtils from '../utils/matrixUtils'
import AuthStore from './AuthStore'
import OrderStore from './OrderStore'
import UserStore from './UserStore'

const ITEM_LOADING_BATCH_SIZE = 32
const ITEM_LOADING_INITIAL_BATCH_SIZE = 32

class ItemStore {
  initialLoadingDataAssetsDone = false
  targetItemInfo = {}
  categoryTree = []
  totalItemVectors = []
  totalItemIds = []
  itemIdsByMediumCategories = {}
  itemVectorsByMediumCategories = {}
  itemCategoryMappingsByMediumCategories = {}
  stateVectors = {}
  categoryWeights = {}
  specificItemsInfo = []

  remainingItemIdsByMediumCategories = {
    large: {},
    small: {},
  }

  remainingItemVectorsByMediumCategories = {
    large: {},
    small: {},
  }

  remainingItemCategoryMappingsByMediumCategories = {
    large: {},
    small: {},
  }

  remainingItemScoresByMediumCategories = {
    large: {},
    small: {},
  }

  remainingItemOrderings = {
    large: {},
    small: {},
  }

  remainingItemIds = {
    large: {},
    small: {},
  }

  remainingItemCategoryMappings = {
    large: {},
    small: {},
  }

  loadedItemIds = {
    large: {},
    small: {},
  }

  constructor() {
    makeObservable(this, {
      initialLoadingDataAssetsDone: observable.ref,
      targetItemInfo: observable,
      categoryTree: observable.ref,
      itemIdsByMediumCategories: observable.ref,
      itemVectorsByMediumCategories: observable.ref,
      itemCategoryMappingsByMediumCategories: observable.ref,
      stateVectors: observable,
      categoryWeights: observable,
      specificItemsInfo: observable,

      setInitialLoadingDataAssetsDone: action,
      setTargetItemInfo: action,
      setCategoryTree: action,
      setItemIdsByMediumCategories: action,
      setItemVectorsByMediumCategories: action,
      setItemCategoryMappingsByMediumCategories: action,
      setStateVectors: action,
      setCategoryWeights: action,
      setSpecificItemsInfo: action,
    })
  }

  setStateVectors(stateVectors) {
    console.log(`setting state vector`)
    this.stateVectors = stateVectors
  }

  setCategoryWeights(categoryWeights) {
    const newCategoryWeights = categoryWeights
    Object.keys(newCategoryWeights).forEach((largeCategoryId) => {
      const weightsObject = newCategoryWeights[largeCategoryId]
      const totalMediumCategoryNumber = Object.keys(weightsObject).length
      const meanWeight = 1 / totalMediumCategoryNumber
      Object.keys(weightsObject).forEach((mediumCategoryId) => {
        newCategoryWeights[largeCategoryId][mediumCategoryId] *= 0.5
        newCategoryWeights[largeCategoryId][mediumCategoryId] +=
          0.5 * meanWeight
      })
    })
    this.categoryWeights = newCategoryWeights
  }

  setSpecificItemsInfo(specificItemsInfo) {
    this.specificItemsInfo = specificItemsInfo
  }

  setInitialLoadingDataAssetsDone(initialLoadingDataAssetsDone) {
    this.initialLoadingDataAssetsDone = initialLoadingDataAssetsDone
  }

  setRemainingItemIdsByMediumCategories(categoryType, categoryId, itemIds) {
    this.remainingItemIdsByMediumCategories[categoryType][categoryId] = itemIds
  }

  setRemainingItemVectorsByMediumCategories(
    categoryType,
    categoryId,
    itemVectors,
  ) {
    this.remainingItemVectorsByMediumCategories[categoryType][categoryId] =
      itemVectors
  }

  setRemainingItemCategoryMappingsByMediumCategories(
    categoryType,
    categoryId,
    categoryMappings,
  ) {
    this.remainingItemCategoryMappingsByMediumCategories[categoryType][
      categoryId
    ] = categoryMappings
  }

  setRemainingItemScoresByMediuimCategories(
    categoryType,
    categoryId,
    itemScores,
  ) {
    this.remainingItemScoresByMediumCategories[categoryType][categoryId] =
      itemScores
  }

  setRemainingItemIds(categoryType, categoryId, itemIds) {
    this.remainingItemIds[categoryType][categoryId] = itemIds
  }

  setRemainingItemOrdering(categoryType, categoryId, itemOrdering) {
    this.remainingItemOrderings[categoryType][categoryId] = itemOrdering
  }

  setRemainingItemCategoryMappings(
    categoryType,
    categoryId,
    categoryAndIndexMappings,
  ) {
    this.remainingItemCategoryMappings[categoryType][categoryId] =
      categoryAndIndexMappings
  }

  initializeItemOrdering(categoryType, categoryId, higherCategoryId = null) {
    if (categoryType === 'large') {
      if (categoryId === 'total') {
        this.setRemainingItemIdsByMediumCategories(
          categoryType,
          categoryId,
          this.itemIdsByMediumCategories,
        )
        this.setRemainingItemVectorsByMediumCategories(
          categoryType,
          categoryId,
          this.itemVectorsByMediumCategories,
        )
        this.setRemainingItemCategoryMappingsByMediumCategories(
          categoryType,
          categoryId,
          this.itemCategoryMappingsByMediumCategories,
        )
        const [
          scoresByMediumCategories,
          remainingItemIds,
          remainingItemCategoryMappings,
          remainingItemOrdering,
        ] = matrixUtils.calculateItemMatchResultsByMediumCategories(
          this.itemVectorsByMediumCategories,
          this.stateVectors,
          this.categoryWeights.total,
          this.itemIdsByMediumCategories,
          this.itemCategoryMappingsByMediumCategories,
        )
        this.setRemainingItemScoresByMediuimCategories(
          categoryType,
          categoryId,
          scoresByMediumCategories,
        )
        this.setRemainingItemIds(categoryType, categoryId, remainingItemIds)
        this.setRemainingItemCategoryMappings(
          categoryType,
          categoryId,
          remainingItemCategoryMappings,
        )
        this.setRemainingItemOrdering(
          categoryType,
          categoryId,
          remainingItemOrdering,
        )
      }
    }
  }

  recalculateItemOrdering(categoryType, categoryId, mediumCategoryId = null) {
    const remainingItemIdsByMediumCategories = {}
    const remainingItemVectorsByMediumCategories = {}
    const remainingItemScoresByMediumCategories = {}
    const remainingItemCategoryMappingsByMediumCategories = {}
    if (categoryType === 'large') {
      this.remainingItemCategoryMappings[categoryType][categoryId].forEach(
        (categoryMapping) => {
          const { medium, idx } = categoryMapping
          if (remainingItemIdsByMediumCategories[medium]) {
            remainingItemIdsByMediumCategories[medium].push(
              this.remainingItemIdsByMediumCategories[categoryType][categoryId][
                medium
              ][idx],
            )
            remainingItemVectorsByMediumCategories[medium].push(
              this.remainingItemVectorsByMediumCategories[categoryType][
                categoryId
              ][medium][idx],
            )
            remainingItemScoresByMediumCategories[medium].push(
              this.remainingItemScoresByMediumCategories[categoryType][
                categoryId
              ][medium][idx],
            )
            remainingItemCategoryMappingsByMediumCategories[medium].push(
              this.remainingItemCategoryMappingsByMediumCategories[
                categoryType
              ][categoryId][medium][idx],
            )
          } else {
            remainingItemIdsByMediumCategories[medium] = [
              this.remainingItemIdsByMediumCategories[categoryType][categoryId][
                medium
              ][idx],
            ]

            remainingItemVectorsByMediumCategories[medium] = [
              this.remainingItemVectorsByMediumCategories[categoryType][
                categoryId
              ][medium][idx],
            ]

            remainingItemScoresByMediumCategories[medium] = [
              this.remainingItemScoresByMediumCategories[categoryType][
                categoryId
              ][medium][idx],
            ]

            remainingItemCategoryMappingsByMediumCategories[medium] = [
              this.remainingItemCategoryMappingsByMediumCategories[
                categoryType
              ][categoryId][medium][idx],
            ]
          }
        },
      )
      this.setRemainingItemIdsByMediumCategories(
        categoryType,
        categoryId,
        remainingItemIdsByMediumCategories,
      )
      this.setRemainingItemVectorsByMediumCategories(
        categoryType,
        categoryId,
        remainingItemVectorsByMediumCategories,
      )
      this.setRemainingItemScoresByMediuimCategories(
        categoryType,
        categoryId,
        remainingItemScoresByMediumCategories,
      )
      this.setRemainingItemCategoryMappingsByMediumCategories(
        categoryType,
        categoryId,
        remainingItemCategoryMappingsByMediumCategories,
      )
      // const [
      //   scoresByMediumCategories,
      //   remainingItemIds,
      //   remainingItemCategoryMappings,
      //   remainingItemOrdering,
      // ] = matrixUtils.calculateItemMatchResultsByMediumCategories(
      //   this.remainingItemVectorsByMediumCategories[categoryType][categoryId],
      //   this.stateVectors,
      //   this.categoryWeights[categoryId],
      //   this.remainingItemIdsByMediumCategories[categoryType][categoryId],
      //   this.remainingItemCategoryMappingsByMediumCategories[categoryType][
      //     categoryId
      //   ],
      // )
      const [
        scoresByMediumCategories,
        remainingItemIds,
        remainingItemCategoryMappings,
        remainingItemOrdering,
      ] = matrixUtils.recalculateItemMatchResultsByMediumCategories(
        this.remainingItemVectorsByMediumCategories[categoryType][categoryId],
        this.stateVectors,
        this.categoryWeights[categoryId],
        this.remainingItemIdsByMediumCategories[categoryType][categoryId],
        this.remainingItemCategoryMappingsByMediumCategories[categoryType][
          categoryId
        ],
        this.remainingItemScoresByMediumCategories[categoryType][categoryId],
        mediumCategoryId,
      )
      this.setRemainingItemScoresByMediuimCategories(
        categoryType,
        categoryId,
        scoresByMediumCategories,
      )
      this.setRemainingItemIds(categoryType, categoryId, remainingItemIds)
      this.setRemainingItemCategoryMappings(
        categoryType,
        categoryId,
        remainingItemCategoryMappings,
      )
      this.setRemainingItemOrdering(
        categoryType,
        categoryId,
        remainingItemOrdering,
      )
    }
  }

  async loadTopkRemainingItem(categoryType, categoryId, loadedLength) {
    const batchSize =
      loadedLength === 0
        ? ITEM_LOADING_INITIAL_BATCH_SIZE
        : ITEM_LOADING_BATCH_SIZE
    const loadingItemIndices = this.remainingItemOrderings[categoryType][
      categoryId
    ].slice(-batchSize)
    const loadingItemIds = loadingItemIndices.map(
      (index) => this.remainingItemIds[categoryType][categoryId][index],
    )
    this.setRemainingItemOrdering(
      categoryType,
      categoryId,
      this.remainingItemOrderings[categoryType][categoryId].slice(
        0,
        -batchSize,
      ),
    )
    if (categoryType === 'large') {
      this.setRemainingItemCategoryMappings(
        categoryType,
        categoryId,
        this.remainingItemCategoryMappings[categoryType][categoryId].slice(
          0,
          -batchSize,
        ),
      )
    }
    const result = await backendApis.getItemsInfo(loadingItemIds)
    if (result) {
      matrixUtils.shuffleArray(result)
    }
    return result || []
  }

  async loadSpecificItemsInfo() {
    // const itemsInfo = []
    // const chunkSize = 3
    // for (
    //   let loadedItemsChunkIdx = 0;
    //   loadedItemsChunkIdx < 2;
    //   loadedItemsChunkIdx += chunkSize
    // ) {
    //   const loadedItemsChunk = await backendApis.getSpecificItems(
    //     loadedItemsChunkIdx,
    //     loadedItemsChunkIdx + chunkSize,
    //   )
    //   console.log('~~~~~~~~~~~~~~~~~~~~`', loadedItemsChunk.data)
    //   itemsInfo.push(loadedItemsChunk)
    // }
    // if (itemsInfo.length > 0) {
    //   this.setSpecificItemsInfo(itemsInfo)
    //   console.log('hello~~~~~~~~~~~~~~~~~', this.specificItemsInfo)
    // } else console.log('nothing returned!')

    const iteminfo = await backendApis.getSpecificItems(0, 0)
    if (iteminfo?.data?.length > 0) {
      this.setSpecificItemsInfo(iteminfo.data)
    }
  }

  setTargetItemInfo(targetItemInfo) {
    this.targetItemInfo = targetItemInfo
  }

  async loadTargetItemInfo(itemId) {
    const token = AuthStore.loadToken()
    let result
    if (token) {
      console.log(`token present`)
      result = await backendApis.getItemInfo(itemId)
      if (result?.status === 200) {
        this.setTargetItemInfo(result.data)
        UserStore.loadUserInfo()
        OrderStore.loadUserOrdersList()
      }
    } else {
      result = await backendApis.getItemInfoWithoutLogin(itemId)
      if (result?.status === 200) {
        this.setTargetItemInfo(result.data)
      }
    }

    if (!result) {
      return
    }

    if (
      this.categoryWeights[
        result.data.categoryInfo.largeCategoryId.toString()
      ] &&
      this.categoryWeights[result.data.categoryInfo.largeCategoryId.toString()][
        result.data.categoryInfo.mediumCategoryId.toString()
      ]
    ) {
      runInAction(() => {
        this.categoryWeights[
          result.data.categoryInfo.largeCategoryId.toString()
        ] = this.adjustWeights(
          this.categoryWeights[
            result.data.categoryInfo.largeCategoryId.toString()
          ],
          result.data.categoryInfo.mediumCategoryId.toString(),
        )
        this.categoryWeights.total = this.adjustWeights(
          this.categoryWeights.total,
          result.data.categoryInfo.mediumCategoryId.toString(),
        )
        const itemIdx = this.totalItemIds.indexOf(result.data._id)
        if (itemIdx === -1) {
          return result
        }
        const itemVector = this.totalItemVectors[itemIdx]
        this.stateVectors[
          result.data.categoryInfo.mediumCategoryId.toString()
        ] = matrixUtils.calculateNewStateVector(
          this.stateVectors[
            result.data.categoryInfo.mediumCategoryId.toString()
          ],
          itemVector,
        )
      })
      if (token) {
        backendApis.uploadUserVectorsAndWeights(
          this.stateVectors,
          this.categoryWeights,
        )
      }

      this.recalculateItemOrdering(
        'large',
        'total',
        result.data.categoryInfo.mediumCategoryId.toString(),
      )
      // if (
      //   this.remainingItemCategoryMappings.large[
      //     result.data.categoryInfo.largeCategoryId.toString()
      //   ]
      // ) {
      //   this.recalculateItemOrdering(
      //     'large',
      //     result.data.categoryInfo.largeCategoryId.toString(),
      //     result.data.categoryInfo.mediumCategoryId.toString(),
      //   )
      // }
      // if (
      //   // this.currentCategories.mediumCategoryId !== undefined &&
      //   this.currentCategories.mediumCategoryId?.toString() ===
      //   result.data.categoryInfo.mediumCategoryId.toString()
      // ) {
      //   this.recalculateItemOrdering(
      //     'small',
      //     'total',
      //     result.data.categoryInfo.mediumCategoryId.toString(),
      //   )
      //   // console.log(
      //   //   `remaining item ids: ${JSON.stringify(
      //   //     this.remainingItemIds.small,
      //   //   )}`,
      //   // )
      //   if (
      //     this.remainingItemIds.small[
      //       result.data.categoryInfo.smallCategoryId.toString()
      //     ]
      //   ) {
      //     this.recalculateItemOrdering(
      //       'small',
      //       result.data.categoryInfo.smallCategoryId.toString(),
      //       result.data.categoryInfo.mediumCategoryId.toString(),
      //     )
      //   }
      // }
    }

    return result
  }

  async initialLoadDataAssets() {
    const results = await Promise.all([
      this.loadCategoryTree(),
      this.loadItemIdsByMediumCategories(),
      this.loadItemVectorsByMediumCategories(),
      this.loadItemCategoryMappingsByMediumCategories(),
      this.initializeVectorsAndWeights(),
    ])

    if (results.every((result) => result)) {
      this.setInitialLoadingDataAssetsDone(true)
      Object.keys(this.itemIdsByMediumCategories).forEach((mediumCategory) => {
        this.totalItemIds.push(
          ...this.itemIdsByMediumCategories[mediumCategory],
        )
        this.totalItemVectors.push(
          ...this.itemVectorsByMediumCategories[mediumCategory],
        )
      })
      return true
    }
    return false
    //   this.setInitialLoadingDataAssetsDone(true)
    // Object.keys(this.itemIdsByMediumCategories).forEach((mediumCategory) => {
    //   this.totalItemIds.push(...this.itemIdsByMediumCategories[mediumCategory])
    //   this.totalItemVectors.push(
    //     ...this.itemVectorsByMediumCategories[mediumCategory],
    //   )
    // })
  }

  setCategoryTree(categoryTree) {
    this.categoryTree = categoryTree
  }

  async loadCategoryTree() {
    console.log(`load category tree started at: ${Date.now() % 10000}`)
    // if (this.categoryTree.length > 0) {
    //   return true
    // }

    const categoryTreeResult = await backendApis.getTotalCategoryTree()
    if (categoryTreeResult?.status === 200 && categoryTreeResult.data) {
      this.setCategoryTree(categoryTreeResult.data)
      // console.log(`category tree: ${JSON.stringify(categoryTreeResult.data)}`)
      console.log(`load category tree finished at: ${Date.now() % 10000}`)
      return true
    }
    return false
  }

  setItemIdsByMediumCategories(itemIdsByMediumCategories) {
    this.itemIdsByMediumCategories = itemIdsByMediumCategories
  }

  async loadItemIdsByMediumCategories() {
    // if (Object.keys(this.itemIdsByMediumCategories).length > 0) {
    //   return true
    // }
    const itemIdsByMediumCategoriesResult =
      await backendApis.getItemIdsByMediumCategories()
    if (
      itemIdsByMediumCategoriesResult?.status === 200 &&
      itemIdsByMediumCategoriesResult.data
    ) {
      this.setItemIdsByMediumCategories(itemIdsByMediumCategoriesResult.data)
      // console.log(
      //   `item ids: ${JSON.stringify(itemIdsByMediumCategoriesResult.data)}`,
      // )
      return true
    }
    return false
  }

  setItemVectorsByMediumCategories(itemVectorsByMediumCategories) {
    this.itemVectorsByMediumCategories = itemVectorsByMediumCategories
  }

  async loadItemVectorsByMediumCategories() {
    const itemVectorsByMediumCategoriesResult =
      await backendApis.getItemVectorsByMediumCategories()
    if (
      itemVectorsByMediumCategoriesResult?.status === 200 &&
      itemVectorsByMediumCategoriesResult.data
    ) {
      this.setItemVectorsByMediumCategories(
        itemVectorsByMediumCategoriesResult.data,
      )
      return true
    }
    return false
  }

  setItemCategoryMappingsByMediumCategories(
    itemCategoryMappingsByMediumCategories,
  ) {
    this.itemCategoryMappingsByMediumCategories =
      itemCategoryMappingsByMediumCategories
  }

  adjustWeights(weightsObject, targetMediumCategoryId) {
    const newWeightsObject = { ...weightsObject }
    const totalMediumCategoryNumber = Object.keys(weightsObject).length
    // const meanWeight =
    //   Object.values(weightsObject).reduce((a, b) => a + b, 0) /
    //   totalMediumCategoryNumber
    const meanWeight = 1 / totalMediumCategoryNumber
    const adjustRatio = Math.min(
      (0.006 * Object.keys(this.stateVectors).length) /
        totalMediumCategoryNumber,
      (0.06 * Object.keys(this.stateVectors).length * meanWeight) /
        totalMediumCategoryNumber /
        weightsObject[targetMediumCategoryId] /
        10,
    )
    console.log(`adjust ratio: ${adjustRatio}`)
    for (const mediumCategory in newWeightsObject) {
      if (mediumCategory !== targetMediumCategoryId) {
        newWeightsObject[mediumCategory] *= 1 - adjustRatio
      } else {
        newWeightsObject[mediumCategory] +=
          ((totalMediumCategoryNumber - 1) / totalMediumCategoryNumber) *
          adjustRatio *
          (1 - newWeightsObject[mediumCategory])
      }
    }
    return newWeightsObject
  }

  async loadItemCategoryMappingsByMediumCategories() {
    const itemCategoryMappingsByMediumCategoriesResult =
      await backendApis.getItemCategoryMappingsByMediuimCategories()
    if (
      itemCategoryMappingsByMediumCategoriesResult?.status === 200 &&
      itemCategoryMappingsByMediumCategoriesResult.data
    ) {
      this.setItemCategoryMappingsByMediumCategories(
        itemCategoryMappingsByMediumCategoriesResult.data,
      )
      return true
    }
    return false
  }

  async initializeVectorsAndWeights() {
    console.log(
      `load initial vectors and weights started at: ${Date.now() % 10000}`,
    )
    AuthStore.loadToken()
    let result = {}
    if (AuthStore.token === '') {
      result = await backendApis.getInitialVectorsAndWeightsByMediumCategories()
      if (result?.status !== 200) {
        return false
      }
    } else {
      console.log(`initialize vectors and weights here`)
      const vectorsAndWeightsList = await Promise.all([
        await backendApis.getInitialVectorsAndWeightsByMediumCategories(),
        await backendApis.getUserVectorsAndWeights(),
      ])
      if (
        vectorsAndWeightsList[0]?.status !== 200 ||
        vectorsAndWeightsList[1]?.status !== 200
      ) {
        return false
      }
      console.log(`initialize vectors and weights passed`)
      console.log(
        `initial vectors: ${JSON.stringify(vectorsAndWeightsList[0].data)}`,
      )
      console.log(`loaded vectors: ${JSON.stringify(vectorsAndWeightsList[1])}`)
      result.status = 200
      const weightsPerMediumCategories = {}
      const vectorsPerMediumCategories = {}
      const weightsNormalizerPerLargeCategories = {}
      Object.keys(
        vectorsAndWeightsList[0].data.weightsPerMediumCategories,
      ).forEach((largeCategory) => {
        Object.keys(
          vectorsAndWeightsList[0].data.weightsPerMediumCategories[
            largeCategory
          ],
        ).forEach((mediumCategory) => {
          if (
            vectorsAndWeightsList[1].data.weightsPerMediumCategories[
              largeCategory
            ] &&
            vectorsAndWeightsList[1].data.weightsPerMediumCategories[
              largeCategory
            ][mediumCategory]
          ) {
            weightsPerMediumCategories[largeCategory] = {
              ...weightsPerMediumCategories[largeCategory],
            }
            weightsPerMediumCategories[largeCategory][mediumCategory] =
              vectorsAndWeightsList[1].data.weightsPerMediumCategories[
                largeCategory
              ][mediumCategory]
            weightsNormalizerPerLargeCategories[largeCategory] =
              (weightsNormalizerPerLargeCategories[largeCategory] || 0) +
              vectorsAndWeightsList[1].data.weightsPerMediumCategories[
                largeCategory
              ][mediumCategory]
          } else {
            weightsPerMediumCategories[largeCategory] = {
              ...weightsPerMediumCategories[largeCategory],
            }
            weightsPerMediumCategories[largeCategory][mediumCategory] =
              vectorsAndWeightsList[0].data.weightsPerMediumCategories[
                largeCategory
              ][mediumCategory]
            weightsNormalizerPerLargeCategories[largeCategory] =
              (weightsNormalizerPerLargeCategories[largeCategory] || 0) +
              vectorsAndWeightsList[0].data.weightsPerMediumCategories[
                largeCategory
              ][mediumCategory]
          }
        })
      })
      Object.keys(
        vectorsAndWeightsList[0].data.vectorsPerMediumCategories,
      ).forEach((mediumCategory) => {
        // console.log(
        //   `vector lengths: ${vectorsAndWeightsList[1].data.vectorsPerMediumCategories[mediumCategory]?.length}, ${vectorsAndWeightsList[0].data.vectorsPerMediumCategories[mediumCategory].length}`,
        // )
        // console.log(`medium category: ${mediumCategory}`)
        if (
          vectorsAndWeightsList[1].data.vectorsPerMediumCategories[
            mediumCategory
          ] &&
          vectorsAndWeightsList[1].data.vectorsPerMediumCategories[
            mediumCategory
          ].length ===
            vectorsAndWeightsList[0].data.vectorsPerMediumCategories[
              mediumCategory
            ].length
        ) {
          vectorsPerMediumCategories[mediumCategory] =
            vectorsAndWeightsList[1].data.vectorsPerMediumCategories[
              mediumCategory
            ]
        } else {
          vectorsPerMediumCategories[mediumCategory] =
            vectorsAndWeightsList[0].data.vectorsPerMediumCategories[
              mediumCategory
            ]
        }
      })
      Object.keys(weightsNormalizerPerLargeCategories).forEach(
        (largeCategory) => {
          // console.log(
          //   `weight sum for ${largeCategory}: ${weightsNormalizerPerLargeCategories[largeCategory]}`,
          // )
          Object.keys(weightsPerMediumCategories[largeCategory]).forEach(
            (mediumCategory) => {
              weightsPerMediumCategories[largeCategory][mediumCategory] /=
                weightsNormalizerPerLargeCategories[largeCategory]
            },
          )
        },
      )
      Object.keys(vectorsPerMediumCategories).forEach((mediumCategory) => {
        // Object.keys(vectorsPerMediumCategories[mediumCategory]).forEach(
        //   (vector) => {
        const vector = vectorsPerMediumCategories[mediumCategory]

        const vectorSum = vector.reduce((a, b) => a + b, 0)
        if (!vectorSum) {
          vectorsPerMediumCategories[mediumCategory] = Array(
            vector.length,
          ).fill(1 / Math.sqrt(vector.length))
          console.log(`${vectorsPerMediumCategories[mediumCategory]}`)
        }
        //   },
        // )
      })
      // console.log(
      //   `fixed vectors: ${JSON.stringify(vectorsPerMediumCategories)}`,
      // )
      result.data = {
        // weightsPerMediumCategories: {
        //   ...vectorsAndWeightsList[0].data.weightsPerMediumCategories,
        //   ...vectorsAndWeightsList[1].data.weightsPerMediumCategories,
        // },
        weightsPerMediumCategories,
        // vectorsPerMediumCategories: {
        //   ...vectorsAndWeightsList[0].data.vectorsPerMediumCategories,
        //   ...vectorsAndWeightsList[1].data.vectorsPerMediumCategories,
        // },
        vectorsPerMediumCategories,
      }
      // await backendApis.getUserVectorsAndWeights()
    }
    // const result =
    //   await backendApis.getInitialVectorsAndWeightsByMediumCategories()
    if (result?.status === 200 && result.data) {
      this.setCategoryWeights(result.data.weightsPerMediumCategories)
      this.setStateVectors(result.data.vectorsPerMediumCategories)
      console.log(
        `load initial vectors and weights finished at: ${Date.now() % 10000}`,
      )
      return true
    }
    return true
  }
}

export default new ItemStore()
