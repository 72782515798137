import React, { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'

function OnePlusOneScreen() {
  const [data, setData] = useState()
  const params = dynamicLinkUtils.parseLink(window.location.href)

  useEffect(async () => {
    if (params?.itemId) {
      const result = await backendApis.getOnePlusOneReceivedItemInfo(
        params?.itemId,
      )
      if (result?.status === 200) {
        setData(result?.data)
      }
    }
  }, [params?.itemId])

  return (
    <>
      <Layout>
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#F7F3E8',
            marginBottom: 10,
            fontFamily: 'GmarketSans',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontSize: 40,
              marginTop: 32,
              color: '#704410',
              fontWeight: 'bold',
            }}
          >
            친구도 나도 <span style={{ color: '#FF8A00' }}>1+1</span>
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 8,
              marginBottom: 20,
              fontWeight: 'bold',
              color: '#978143',
            }}
          >
            출석체크를 하고 1+1 상품을 받아가세요
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              width: '88vw',
              height: '380px',
              borderRadius: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontFamily: 'GmarketSans',
            }}
          >
            <div
              style={{
                height: 20,
                marginTop: 20,
                paddingBottom: 12,
                borderBottom: '1px solid rgba(64, 55, 58, 0.1)',
                fontWeight: 'bold',
              }}
            >
              {data?.mainTitle} {data?.subTitle}{' '}
              <span style={{ color: '#FF8A00' }}>1+1</span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: -12,
              }}
            >
              <img
                src={`${data?.mainImageUri}`}
                alt=''
                style={{ height: 148, marginBottom: -16 }}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                fontSize: 12,
                color: '#9d9699',
                marginTop: 16,
              }}
            >
              *상품은 한정수량으로 진행되어 조기 품절될 수 있어요
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '80vw' }}
            >
              <button
                type='button'
                style={{
                  border: 'none',
                  background: 'none',
                  backgroundColor: '#FF4D00',
                  width: '80vw',
                  height: 48,
                  marginTop: 12,
                  borderRadius: 12,
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: 16,
                  fontFamily: 'GmarketSans',
                }}
                onClick={async () => {
                  window.location.href = `https://alwayzshop.ilevit.com/app`
                }}
              >
                출석체크 하기
              </button>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '80vw' }}
            >
              <button
                type='button'
                style={{
                  border: 'none',
                  background: 'none',
                  backgroundColor: '#40373A',
                  height: 48,
                  marginTop: 8,
                  borderRadius: 12,
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: 16,
                  flex: 1,
                }}
                onClick={async () => {
                  window.location.href = `https://alwayzshop.ilevit.com/app`
                }}
              >
                <div style={{ fontFamily: 'GmarketSans' }}>
                  공유하고 1+1 상품 받으러 가기
                </div>
              </button>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
              marginTop: 20,
              height: 220,
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: 20,
                margin: 16,
                paddingBottom: 8,
                width: '68vw',
                textAlign: 'center',
                borderBottom: '1px solid rgba(64, 55, 58, 0.1)',
              }}
            >
              참여방법
            </div>
            <div
              style={{
                fontSize: 12,
                width: '68vw',
                height: 80,
                color: '#63565B',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F7F3E8',
                    color: '#63565B',
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  1
                </div>{' '}
                <div>올웨이즈에 가입하여 출석체크를 해요</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F7F3E8',
                    color: '#63565B',
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  2
                </div>{' '}
                <div>
                  {data?.missionDaysNum}일 연속 출석체크 미션을 완료해요
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F7F3E8',
                    color: '#63565B',
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  3
                </div>{' '}
                <div>친구와 나 모두에게 선물을 드려요</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default OnePlusOneScreen
