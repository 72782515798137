import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import backendApis from '../utils/backendApis'
import Postcode from '@actbase/react-daum-postcode'
import BasicHeader from '../components/header/BasicHeader'
import TitleBodyWithDivider from '../components/items/TitleBodyWithDivider'
import UserStore from '../stores/UserStore'

const ShippingMethodDetailScreen = observer(() => {
  useEffect(() => {
    UserStore.setScreenName('ShippingMethodDetailScreen_Web')
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const history = useHistory()
  const location = useLocation()
  const shippingIndividualInfo = location.state?.shippingInfo || {}
  const { shippingMethod, shippingCompany, returnFeeInfo, shippingFeeInfo } =
    shippingIndividualInfo

  const deliverInfo = [
    { title: '배송방법', body: shippingMethod || '순차배송' },
    { title: '배송사', body: shippingCompany || '변동가능' },
    {
      title: '배송비',
      body:
        shippingFeeInfo?.split('-').join('\n-').split('·').join('\n·') ||
        `무료배송.
도서산간 추가배송비
- 제주 지역: 2,500원
- 도서산간 지역: 5,000원`,
    },
    // { title: '묶음배송 여부', body: '가능' },
    {
      title: '배송기간',
      body: `- 도서 산간 지역 등은 배송에 3-5일이 더 소요될 수 있습니다. 
- 천재지변, 물량 수급 변동 등 예외적인 사유 발생시, 다소 지연될 수 있는 점 양해 부탁드립니다.`,
    },
  ]
  const refundInfo = [
    {
      title: `교환/반품 비용\n(왕복)`,
      body:
        returnFeeInfo?.split('-').join('\n-') ||
        `5,000원
- 단, 고객 변심의 경우에만 발생
- 도서산간 및 일부 지역 추가비용 발생`,
    },
    {
      title: '교환/반품 신청\n기준일',
      body: `- 단순변심에 의한 교환/반품은 제품 수령 후 7일 이내까지, 교환/반품 제한사항에 해당하지 않는 경우에만 가능(배송비용과 교환/반품 비용 왕복배송비 고객부담)
- 상품의 내용이 광고의 내용과 다른 경우에는 상품을 수령한 날부터 3개월 이내, 그 사실을 안 날 또는 알 수가 있었던 날부터 30일 이내에 청약철회 가능`,
    },
  ]

  const categoryRefundInfo = [
    {
      title: '의류/잡화/수입명품',
      body: `- 상품의 택(TAG) 제거, 라벨 및 상품 훼손, 구성품 누락으로 상품의 가치가 현저히 감소된 경우`,
    },
    {
      title: '계절상품/식품/화장품',
      body: `- 신선냉동 식품의 단순변심의 경우
- 뷰티 상품 이용 시 트러블(알러지, 붉은 반점, 가려움, 따가움)이 발생하는 경우, 진료확인서 및 소견서 등을 증빙하면 환불 가능(제반비용 고객부담)`,
    },
    {
      title: '전자/가전/설치상품',
      body: `- 설치 또는 사용하여 재판매가 어려운 경우, 액정이 있는 상품의 전원을 켠 경우
- 상품의 시리얼 넘버 유출로 내장된 소프트웨어의 가치가 감소한 경우 (내비게이션, OS시리얼이 적힌 PMP)
- 홀로그램 등을 분리, 분실, 훼손하여 상품의 가치가 현저히 감소하여 재판매가 불가할 경우 (노트북, 데스크탑 PC 등)`,
    },
    {
      title: '자동차용품',
      body: `- 상품을 개봉하여 장착한 이후 단순변심인경우`,
    },
    {
      title: '게임/책',
      body: `- 복제가 가능한 상품의 포장 등을 훼손한 경우`,
    },
  ]
  const styles = {
    title: {
      fontSize: 18,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
    },
  }
  return (
    <div style={{ backgroundColor: '#e2e2e8' }}>
      <div
        style={{
          maxWidth: '480px',
          marginLeft: 'auto',
          marginRight: 'auto',
          backgroundColor: '#ffffff',
        }}
      >
        <BasicHeader title='배송/교환/반품 정책' />
        <div style={styles.title}>배송정보</div>
        {deliverInfo.map((value) => (
          <TitleBodyWithDivider
            key={value.title}
            title={value.title}
            body={value.body}
          />
        ))}
        <div style={{ height: 12, backgroundColor: '#F5F5F5' }} />
        <div style={styles.title}>교환/반품 안내</div>
        {refundInfo.map((value) => (
          <TitleBodyWithDivider
            key={value.title}
            title={value.title}
            body={value.body}
          />
        ))}
        <div style={{ height: 12, backgroundColor: '#F5F5F5' }} />

        <div style={styles.title}>교환/반품 제한사항</div>
        <div
          style={{
            fontSize: 14,
            paddingTop: 8,
            paddingBottom: 8,
            marginLeft: 16,
            marginRight: 16,
            border: 'solid',
            borderTopWidth: 0.5,
            borderColor: '#E0E0E0',
            borderWidth: 0,
            borderBlockColor: '#E0E0E0',
            whiteSpace: 'pre-line',
            color: '#999999',
          }}
        >{`- 주문/제작 상품의 경우, 상품의 제작이 이미 진행된 경우

- 상품 포장을 개봉하여 사용 또는 설치 완료되어 상품의 가치가 훼손된 경우 (단, 내용 확인을 위한 포장 개봉의 경우는 예외)

- 고객의 사용, 시간경과, 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우

- 세트상품 일부 사용, 구성품을 분실하였거나 취급 부주의로 인한 파손/고장/오염으로 재판매 불가한 경우

- 모니터 해상도의 차이로 인해 색상이나 이미지가 실제와 달라, 고객이 단순 변심으로 교환/반품을 무료로 요청하는 경우

- 제조사의 사정 (신모델 출시 등) 및 부품 가격 변동 등에 의해 무료 교환/반품으로 요청하는 경우`}</div>
        <div style={{ height: 12, backgroundColor: '#F5F5F5' }} />

        <div style={styles.title}>카테고리별 교환/반품 제한사항</div>
        {categoryRefundInfo.map((value) => (
          <TitleBodyWithDivider
            key={value.title}
            title={value.title}
            body={value.body}
          />
        ))}
      </div>
    </div>
  )
})

export default ShippingMethodDetailScreen
