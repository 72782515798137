import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import ImageManager from '../../images/ImageManager'
// import { useHistory, useLocation, useParams } from 'react-router-dom'

const DisplayOrderMainPayment = observer(
  ({ paymentMethodType, representativeCardInfo }) => {
    return (
      <div
        style={{
          paddingLeft: 16,
          paddingBottom: 8,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <ImageManager source='KakaoPay' />
        <div style={{ paddingLeft: 8, fontSize: 14, color: '#BDBDBD' }}>
          카카오페이
        </div>
      </div>
    )
  },
)

export default DisplayOrderMainPayment
