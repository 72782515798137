import { makeObservable, observable, action } from 'mobx'
import backendApis from '../utils/backendApis'
import socket from '../utils/socket'
import uuid from 'uuid-random'

const compareCreatedAt = (obj1, obj2) => {
  const createdAt1 = new Date(obj1.createdAt)
  const createdAt2 = new Date(obj2.createdAt)
  let comparison = 0
  if (createdAt1 < createdAt2) {
    comparison = 1
  } else if (createdAt1 > createdAt2) {
    comparison = -1
  }
  return comparison
}

class UserStore {
  dynamicLinkParams = {}
  testCount = 0
  isMobile = false
  ableToPurchaseViralItem = false
  testGroup = {}
  isLateComer = false
  userInfo = {
    _id: '',
    userName: '',
    friendIds: [],
    friendRequesterIds: [],
    friendRequesteeIds: [],
    defaultAddressInfo: {},
    defaultPaymentInfo: {},
    phoneNumber: '',
    userImageUri: '',
    thumbnailUserImageUri: '',
    addressesInfo: [],
    cardsInfo: [],
    representativeCardInfo: {},
    kakaoPayInfo: {},
  }

  userUsageId = ''
  //   routeNameRef = null
  //   isBackgroundForNecessaryUtils = false
  couponsInfo = []
  isLoading = false
  isLoadingEmptyScreen = false
  isLoadingChallengeScreen = false
  screenName = ''
  linkBenefitsInfo = {
    linkSharerDiscountRate: 0,
    linkReceiverFirstPurchaseDiscountRate: 0,
  }

  uploadingUserLinkUsage = false
  showAppDownloadModal = false
  showAppDownloadItemModal = false

  constructor() {
    makeObservable(this, {
      dynamicLinkParams: observable,
      testCount: observable,
      userInfo: observable,
      isMobile: observable,
      isLoading: observable,
      isLoadingEmptyScreen: observable,
      isLoadingChallengeScreen: observable,
      screenName: observable,
      linkBenefitsInfo: observable,
      uploadingUserLinkUsage: observable,
      showAppDownloadModal: observable,
      showAppDownloadItemModal: observable,
      ableToPurchaseViralItem: observable,
      isLateComer: observable,
      testGroup: observable,
      //   routeNameRef: observable.ref,
      //   isBackgroundForNecessaryUtils: observable.ref,
      setDynamicLinkParams: action,
      setTestCount: action,
      setUserInfo: action,
      setIsMobile: action,
      setIsLoading: action,
      setIsLoadingEmptyScreen: action,
      setIsLoadingChallengeScreen: action,
      setScreenName: action,
      setLinkBenefitsInfo: action,
      setUploadingUserLinkUsage: action,
      setShowAppDownloadModal: action,
      setShowAppDownloadItemModal: action,
      setAbleToPurchaseViralItem: action,
      setIsLateComer: action,
      setTestGroup: action,
      //   setRouteNameRef: action,
    })
  }

  setTestGroup(testGroup) {
    this.testGroup = testGroup
  }

  setIsLateComer(isLateComer) {
    this.isLateComer = isLateComer
  }

  setAbleToPurchaseViralItem(ableToPurchaseViralItem) {
    this.ableToPurchaseViralItem = ableToPurchaseViralItem
  }

  setUploadingUserLinkUsage(uploadingUserLinkUsage) {
    this.uploadingUserLinkUsage = uploadingUserLinkUsage
  }

  setLinkBenefitsInfo(linkBenefitsInfo) {
    this.linkBenefitsInfo = linkBenefitsInfo
  }

  setScreenName(screenName) {
    this.screenName = screenName
  }

  setIsLoadingEmptyScreen(isLoadingEmptyScreen) {
    this.isLoadingEmptyScreen = isLoadingEmptyScreen
  }

  setIsLoadingChallengeScreen(isLoadingChallengeScreen) {
    this.isLoadingChallengeScreen = isLoadingChallengeScreen
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading
  }

  setIsMobile(isMobile) {
    this.isMobile = isMobile
  }

  setDynamicLinkParams(dynamicLinkParams) {
    this.dynamicLinkParams = dynamicLinkParams
  }

  setTestCount(testCount) {
    this.testCount = testCount
  }

  setUserInfo(userInfo) {
    this.userInfo = userInfo
  }

  setUserKakaoPayInfo(kakaoPayInfo) {
    // console.log(`kakao pay info: ${JSON.stringify(kakaoPayInfo)}`)
    this.setUserInfo({ ...this.userInfo, kakaoPayInfo })
  }

  setUserDefaultAddressInfo(addressInfo) {
    this.setUserInfo({
      ...this.userInfo,
      defaultAddressInfo: addressInfo,
    })
  }

  setShowAppDownloadModal(showAppDownloadModal) {
    this.showAppDownloadModal = showAppDownloadModal
  }

  setShowAppDownloadItemModal(showAppDownloadItemModal) {
    this.showAppDownloadItemModal = showAppDownloadItemModal
  }

  async registerAddress(
    recipient,
    postcode,
    postcodeAddress,
    detailAddress,
    recipientPhoneNumber,
    frontDoorPassword,
    receivingMethod,
    detailForReceivingMethod,
  ) {
    const addressId = uuid()
    this.setUserInfo({
      ...this.userInfo,
      addressesInfo: this.userInfo.addressesInfo
        ? [
            ...this.userInfo.addressesInfo.filter(
              (address) =>
                address.recipient !== recipient ||
                address.postcode !== postcode ||
                address.postcodeAddress !== postcodeAddress ||
                address.detailAddress !== detailAddress,
            ),
            {
              addressId,
              recipient,
              postcode,
              postcodeAddress,
              detailAddress,
              recipientPhoneNumber,
              frontDoorPassword,
              receivingMethod,
              detailForReceivingMethod,
            },
          ]
        : [
            {
              addressId,
              recipient,
              postcode,
              postcodeAddress,
              detailAddress,
              recipientPhoneNumber,
              frontDoorPassword,
              receivingMethod,
              detailForReceivingMethod,
            },
          ],
      defaultAddressInfo: {
        addressId,
        recipient,
        postcode,
        postcodeAddress,
        detailAddress,
        recipientPhoneNumber,
        frontDoorPassword,
        receivingMethod,
        detailForReceivingMethod,
      },
    })
    if (this.userInfo._id) {
      const result = await backendApis.registerUserAddress({
        addressId,
        recipient,
        postcode,
        postcodeAddress,
        detailAddress,
        recipientPhoneNumber,
        frontDoorPassword,
        receivingMethod,
        detailForReceivingMethod,
      })
      return result
    }
    return { status: 200 }
  }

  //   setCouponsInfo(couponsInfo) {
  //     // console.log(`coupons info: ${JSON.stringify(couponsInfo)}`)
  //     this.couponsInfo = couponsInfo
  //   }

  async uploadUserDefaultAddressInfo() {
    const result = await backendApis.registerUserDefaultAddress(
      this.userInfo.defaultAddressInfo,
    )
    return result
  }

  async loadUserInfo() {
    // console.log(`load user info`)
    // await backendApis.registerLogs('load user info')
    const result = await backendApis.loadUserInfo()
    // await backendApis.registerLogs(`load user info: ${JSON.stringify(result)}`)
    if (result?.status === 200) {
      // console.log(
      //   `load user info address result: ${JSON.stringify(
      //     result.data.addressesInfo,
      //   )}`,
      // )
      const addressesInfo = result.data.addressesInfo || []
      const { addressesInfo: addressesInfoTemp } = this.userInfo
      let { defaultAddressInfo } = this.userInfo
      if (!defaultAddressInfo.addressId) {
        defaultAddressInfo = result.data.defaultAddressInfo || {}
      }
      await Promise.all(
        addressesInfoTemp.map(async (addressTemp) => {
          const existingAddress = addressesInfoTemp.find(
            (address) =>
              address.recipient === addressTemp.recipient &&
              address.postcode === addressTemp.postcode &&
              address.postcodeAddress === addressTemp.postcodeAddress &&
              address.detailAddress === addressTemp.detailAddress,
          )
          if (!existingAddress) {
            addressesInfo.push(addressTemp)
            const {
              addressId,
              recipient,
              postcode,
              postcodeAddress,
              detailAddress,
              recipientPhoneNumber,
              frontDoorPassword,
              receivingMethod,
              detailForReceivingMethod,
            } = addressTemp
            await backendApis.registerUserAddress({
              addressId,
              recipient,
              postcode,
              postcodeAddress,
              detailAddress,
              recipientPhoneNumber,
              frontDoorPassword,
              receivingMethod,
              detailForReceivingMethod,
            })
          }
        }),
      )
      this.setUserInfo({ ...result.data, addressesInfo, defaultAddressInfo })
      return true
    }
    return false
  }

  async deleteUserAddress(addressId) {
    // console.log(`deleting user address id: ${addressId}`)
    // console.log(`existing addresses info`)
    this.setUserInfo({
      ...this.userInfo,
      addressesInfo: this.userInfo.addressesInfo.filter(
        (addressInfo) => addressInfo.addressId !== addressId,
      ),
    })
    if (this.userInfo.defaultAddressInfo.addressId === addressId) {
      this.setUserDefaultAddressInfo({})
    }
    if (this.userInfo._id) {
      await backendApis.deleteUserAddress(addressId)
    }
  }

  async uploadUserLinkUsage(
    link,
    paramsInfo,
    action,
    screen = 'Web Item Screen',
  ) {
    this.setUploadingUserLinkUsage(true)
    // console.log(`user link usage: ${screen}`)
    if (paramsInfo.itemId && paramsInfo.itemId !== 'undefined') {
      paramsInfo.alwayzItemId = paramsInfo.itemId
      delete paramsInfo.itemId
    }
    if (paramsInfo.invitorId && paramsInfo.invitorId !== 'undefined') {
      paramsInfo.alwayzInvitorId = paramsInfo.invitorId
    }
    if (paramsInfo.teamDealId && paramsInfo.teamDealId !== 'undefined') {
      paramsInfo.alwayzTeamDealId = paramsInfo.teamDealId
    }
    await backendApis.uploadUserLinkUsage(link, paramsInfo, screen, action)
    this.setUploadingUserLinkUsage(false)
  }

  async loadLinkBenefitsInfo() {
    const result = await backendApis.getLinkBenefitsInfo()

    if (result?.status === 200 && result.data) {
      this.setLinkBenefitsInfo(result.data)
    }
  }

  initializeSocket() {
    socket.emit('initialize', this.userInfo._id)
  }
}

const container = {}
container.instance = new UserStore()

export default container.instance
