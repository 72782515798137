import { logEvent, getAnalytics } from 'firebase/analytics'
import UserStore from '../stores/UserStore'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'

const goAppDownload = async (buttonType = false) => {
  if (UserStore.isLoading) return
  UserStore.setIsLoading(true)

  const analytics = getAnalytics()
  const { itemId = undefined, invitorId = undefined } =
    UserStore?.dynamicLinkParams
  let shareType = 'alwayzWeb'

  if (itemId) {
    shareType += '_itemShare'
  } else if (buttonType != false) {
    shareType += `_${buttonType}`
  }
  logEvent(analytics, 'click_app_install', { button_type: buttonType })

  const link = await dynamicLinkUtils.createAppDownloadDynamicLink({
    itemId,
    shareType,
    invitorId,
  })

  UserStore.setIsLoading(false)
  if (link) {
    window.location.href = link
  }
}

export default goAppDownload
