import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ToastContainer, Zoom } from 'react-toastify'
import ItemList from '../components/items/ItemList'
import ImageManager from '../images/ImageManager'
import ItemStore from '../stores/ItemStore'
import UserStore from '../stores/UserStore'

const MyGiftCardScreen = () => {
  return (
    <div style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
      <div
        style={{
          height: 48,
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '25%', marginTop: 4 }}>
          <ImageManager source='AlwayzFullLogoRed' />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 20,
          flex: 1,
        }}
      >
        <div style={{ height: 12 }} />
        <img
          src='https://always-bucket-dev.s3.us-west-1.amazonaws.com/f5dfd395-f5a2-4265-82b8-9f87a89a4bc4.png'
          alt=''
          width='80%'
        />
        {/* <img
          src='https://always-bucket-dev.s3.us-west-1.amazonaws.com/9f1e351f-eaa2-47a4-abd4-35204d0d249e.png'
          alt=''
          width='70%'
        /> */}
        <div style={{ height: 24 }} />

        <img
          src='https://always-bucket-dev.s3.us-west-1.amazonaws.com/72745629-c443-474f-a0cc-427ee6ddab93.png'
          alt=''
          width='80%'
        />
        <div style={{ height: 48 }} />

        <button
          type='button'
          style={{
            width: '80%',
            height: 54,
            backgroundColor: '#0077FF',
            borderRadius: 12,
            fontSize: 16,
            fontWeight: 'bold',
            color: '#fff',
            border: 'none',
          }}
          onClick={() => {
            window.location.href = `https://alwayzshop.ilevit.com/app`
          }}
        >
          신세계 상품권 5천원 계속 받으러 가기
        </button>
      </div>
      <div style={{ height: 20 }} />
    </div>
  )
}
export default MyGiftCardScreen
