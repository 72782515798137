import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import configs from '../utils/configs'
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'
import OrderStore from '../stores/OrderStore'
import socket from '../utils/socket'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'
import EmptyScreenComponent from '../components/EmptyScreenComponent'
import SupportFundEvent from './SupportFundEventScreen'
import AlfarmCoffeeGiftScreen from './AlfarmCoffeeGiftScreen'
import AlfarmChocoGiftScreen from './AlfarmChocoGiftScreen'
import OnePlusOneScreen from './OnePlusOneScreen'
import NewYearsMoneyScreen from './NewYearsMoneyScreen'
import GganbuChickenScreen from './GganbuChickenScreen'

// const parseLink = (url) => {
//   const regex = /[?&]([^=#]+)=([^&#]*)/g
//   const params = {}
//   let match
//   while ((match = regex.exec(url)) !== null) {
//     params[match[1]] = match[2]
//     // console.log(match[1], match[2])
//   }
//   if (params.cohort && params.limit) {
//     params.limit = parseInt(params.limit, 10)
//   }
//   return params
// }

const KakaoTestScreen = observer(() => {
  const history = useHistory()
  const kakao = window.Kakao
  //   const [params, setParams] = useState({})
  const [authObj, setAuthObj] = useState({})
  const [error, setError] = useState({})
  const [orderIndicator, setOrderIndicator] = useState('')
  const [kakaoPayPgToken, setKakaoPayPgToken] = useState('')
  const [canceledKakaoPay, setCanceledKakaoPay] = useState(false)
  // const [impSuccessState, setImpSuccessState] = useState(false)

  const getUserInfoAndRegister = async (dynamicLinkParams) => {
    await kakao.API.request({
      url: '/v2/user/me',
      success: async (response) => {
        if (response?.properties?.alwayzToken) {
          console.log(`token saved in kakao properties!`)
          AuthStore.setToken(response.properties.alwayzToken)
          const [userSuccess, orders] = await Promise.all([
            await UserStore.loadUserInfo(),
            await OrderStore.loadUserOrdersList(),
            await UserStore.uploadUserLinkUsage(
              window.location.href,
              UserStore.dynamicLinkParams,
              'received',
              UserStore.screenName,
            ),
          ])
          console.log(`user success: ${userSuccess}`)
          if (!userSuccess) {
            localStorage.removeItem('@alwayz@token')
            kakao.API.request({
              url: '/v1/user/unlink',
              success(response) {
                console.log(response)
              },
              fail(error) {
                console.log(error)
              },
            })
          }

          return
        }
        const kakaoAccountId = response.id
        const kakaoAccount = response.kakao_account
        if (!kakaoAccount?.has_phone_number) {
          alert(
            '해당 계정은 전화번호 등록이 되어있지 않아서 회원가입이 불가합니다. 다른 카카오 계정을 사용해주세요.',
          )
          kakao.API.request({
            url: '/v1/user/unlink',
            success(response) {
              console.log(response)
            },
            fail(error) {
              console.log(error)
            },
          })
          return
        }
        const {
          profile,
          // phone_number: phoneNumber,
          birthday: birthMonthAndDay,
          birthday_type: birthdayType,
          birthyear: birthYear,
          gender,
        } = kakaoAccount
        if (!profile) {
          alert(
            '카카오 계정 정보가 없습니다. 잠시 후 다시 시도해주시거나 다른 카카오 계정을 사용해주세요.',
          )
          return
        }
        const {
          nickname: userName,
          thumbnail_image_url: thumbnailUserImageUri,
          profile_image_url: userImageUri,
        } = profile

        let { phone_number: phoneNumber } = kakaoAccount
        console.log(`phone number: ${phoneNumber}`)
        const countryCode = phoneNumber.split(' ')[0].replace(/\+/g, '')
        phoneNumber = `0${phoneNumber.split(' ')[1].replace(/-/g, '')}`
        console.log(
          `success response: ${JSON.stringify(response)}, ${JSON.stringify({
            kakaoAccountId,
            userName,
            thumbnailUserImageUri,
            userImageUri,
            birthMonthAndDay,
            birthYear,
            birthdayType,
            countryCode,
            phoneNumber,
            gender,
            dynamicLinkParams,
          })}`,
        )
        const kakaoRegisterResult = await backendApis.registerKakaoUser(
          kakaoAccountId,
          userName,
          thumbnailUserImageUri,
          userImageUri,
          birthMonthAndDay,
          birthYear,
          birthdayType,
          countryCode,
          phoneNumber,
          gender,
          dynamicLinkParams,
        )
        console.log(
          `register kakao user result: ${JSON.stringify(kakaoRegisterResult)}`,
        )
        if ([200, 2001].includes(kakaoRegisterResult?.status)) {
          AuthStore.setToken(kakaoRegisterResult.data.token)
          const [userSuccessTemp, ordersTemp] = await Promise.all([
            await UserStore.loadUserInfo(),
            await OrderStore.loadUserOrdersList(),
            await UserStore.uploadUserLinkUsage(
              window.location.href,
              UserStore.dynamicLinkParams,
              'received',
              UserStore.screenName,
            ),
          ])
          // UserStore.loadUserInfo()
          // OrderStore.loadUserOrdersList()
          kakao.API.request({
            url: '/v1/user/update_profile',
            data: {
              properties: {
                alwayzUserId: kakaoRegisterResult.data.userId,
                alwayzToken: kakaoRegisterResult.data.token,
              },
            },
            success: (updateResponse) => {
              console.log(
                `user properties update response: ${JSON.stringify(
                  updateResponse,
                )}`,
              )
            },
            fail: (updateError) => {
              console.log(
                `user properties update error: ${JSON.stringify(updateError)}`,
              )
            },
          })
        }
      },
      fail: async (response) => {
        console.log(`fail response: ${JSON.stringify(response)}`)
      },
    })
  }
  const getKakaoAccessToken = async (kakaoAuthCode) => {
    const accessTokenResult = await backendApis.getKakaoAccessToken(
      kakaoAuthCode,
    )
    if (
      accessTokenResult?.status === 200 &&
      accessTokenResult.data.access_token
    ) {
      await kakao.Auth.setAccessToken(accessTokenResult.data.access_token)
    }
    return accessTokenResult
  }

  const redirect = ({ params, prefix }) => {
    let url = `/${prefix}?`
    Object.keys(params).forEach((key) => {
      url += `${key}=${params[key]}&`
    })
    history.replace(url.slice(0, -1))
    UserStore.setIsLoadingEmptyScreen(false)
  }

  const checkInitialKakaoState = async () => {
    if (kakao) {
      //   const kakao = window.Kakao

      console.log('kakao', kakao)
      UserStore.setIsLoadingEmptyScreen(true)

      if (!kakao.isInitialized()) {
        await kakao.init(configs.kakaoJsKey)
      }

      const params = dynamicLinkUtils.parseLink(window.location.href)
      console.log('params', params)

      // if (!params.code && !params.error) {
      //   UserStore.setScreenName('EmptyScreen_Web')
      //   return
      // }

      // WEB에서 분기 칠 때 아래의 로직 사용

      // shareType이 challengeShare일때 분기
      if (params.shareType === 'challengeShare') {
        console.log('this is the place')
        // ### Now Working

        const dynamicLinkParams = { ...params, source: 'web' }
        console.log(`dynamic link params: ${JSON.stringify(dynamicLinkParams)}`)

        UserStore.setDynamicLinkParams(dynamicLinkParams)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/challenge/${dynamicLinkParams.alwayzChallengeItemId}?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            UserStore.setScreenName('EmptyScreen_Web')
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/challenge/${dynamicLinkParams.alwayzChallengeItemId}?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      } else if (params.shareType === 'sendGiftShare') {
        const dynamicLinkParams = { ...params, source: 'web' }
        console.log(`dynamic link params: ${JSON.stringify(dynamicLinkParams)}`)

        UserStore.setDynamicLinkParams(dynamicLinkParams)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/send-gift/${dynamicLinkParams.alwayzSendGiftId}?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(url.slice(0, -1))
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            UserStore.setScreenName('EmptyScreen_Web')
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/send-gift/${dynamicLinkParams.alwayzSendGiftId}?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.shareType === 'sendMoney') {
        const dynamicLinkParams = { ...params, source: 'web' }
        // console.log(dynamicLinkParams)
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/sendMoney/${dynamicLinkParams.sendMoneyId}?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            UserStore.setScreenName('EmptyScreen_Web')
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/sendMoney/${dynamicLinkParams.sendMoneyId}?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.shareType === 'pocketMoneyShare') {
        const dynamicLinkParams = { ...params, source: 'web' }
        // console.log(dynamicLinkParams)
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/pocketMoney/?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            UserStore.setScreenName('EmptyScreen_Web')
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/pocketMoney/?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.shareType === 'CheckInInviteFriends') {
        const dynamicLinkParams = { ...params, source: 'web' }
        // console.log(dynamicLinkParams)
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/checkInInvite/?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            UserStore.setScreenName('EmptyScreen_Web')
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/checkInInvite/?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.shareType === 'MyGiftCardScreen') {
        const dynamicLinkParams = { ...params, source: 'web' }
        // console.log(dynamicLinkParams)
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/giftcard/${dynamicLinkParams.invitorId}?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            UserStore.setScreenName('EmptyScreen_Web')
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/giftcard/${dynamicLinkParams.invitorId}?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.shareType === 'alfarmCoffeeGift') {
        const dynamicLinkParams = { ...params, source: 'web' }
        // console.log(dynamicLinkParams)
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        console.log(params)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/alfarm-coffee-gift?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            // console.log(dynamicLinkParams.documentId)
            let url = `/alfarm-coffee-gift?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/alfarm-coffee-gift?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.shareType === 'alfarmChocoGift') {
        const dynamicLinkParams = { ...params, source: 'web' }
        // console.log(dynamicLinkParams)
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        console.log(params)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/alfarm-choco-gift?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            // console.log(dynamicLinkParams.documentId)
            let url = `/alfarm-choco-gift?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/alfarm-choco-gift?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.shareType === 'onePlusOne') {
        const dynamicLinkParams = { ...params, source: 'web' }
        // console.log(dynamicLinkParams)
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        console.log(params)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/one-plus-one?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            // console.log(dynamicLinkParams.documentId)
            let url = `/one-plus-one?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/one-plus-one?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }
      if (params.shareType === 'gganbuChicken') {
        const dynamicLinkParams = { ...params, source: 'web' }
        // console.log(dynamicLinkParams)
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        console.log(params)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/gganbu-chicken-event?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            // console.log(dynamicLinkParams.documentId)
            let url = `/gganbu-chicken-event?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/gganbu-chicken-event?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.shareType === 'newYearsMoney') {
        const dynamicLinkParams = { ...params, source: 'web' }
        // console.log(dynamicLinkParams)
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        console.log(params)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/new-years-money?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            // console.log(dynamicLinkParams.documentId)
            let url = `/new-years-money?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/new-years-money?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.shareType === 'supportFundEvent') {
        const dynamicLinkParams = { ...params, source: 'web' }
        // console.log(dynamicLinkParams)
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        console.log(params)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/support-fund-event?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            // console.log(dynamicLinkParams.documentId)
            let url = `/support-fund-event?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/support-fund-event?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.shareType === 'luckyBoxEvent') {
        const dynamicLinkParams = { ...params, source: 'web' }
        UserStore.setDynamicLinkParams(dynamicLinkParams)

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            let url = `/luckyBox/${dynamicLinkParams.documentId}?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            await getUserInfoAndRegister(dynamicLinkParams)
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          } else {
            console.log(dynamicLinkParams.documentId)
            let url = `/luckyBox/${dynamicLinkParams.documentId}?`
            Object.keys(dynamicLinkParams).forEach((key) => {
              url += `${key}=${dynamicLinkParams[key]}&`
            })
            history.replace(
              //   `/challenge/${params.itemId}?teamDealId=${dynamicLinkParams.teamDealId}`,
              url.slice(0, -1),
            )
            UserStore.setIsLoadingEmptyScreen(false)
          }
        } else if (params.error) {
          let url = `/luckyBox/${dynamicLinkParams.documentId}?`
          Object.keys(dynamicLinkParams).forEach((key) => {
            url += `${key}=${dynamicLinkParams[key]}&`
          })
          history.replace(url.slice(0, -1))
          UserStore.setIsLoadingEmptyScreen(false)
        }
      }

      if (params.itemId || params.alwayzItemId) {
        localStorage.setItem(
          '@alwayz@itemId',
          params.itemId || params.alwayzItemId,
        )
        const dynamicLinkParams = { ...params, source: 'web' }
        dynamicLinkParams.itemId = params.itemId || params.alwayzItemId
        dynamicLinkParams.teamDealId =
          params.teamDealId || params.alwayzTeamDealId
        dynamicLinkParams.invitorId = params.invitorId || params.alwayzInvitorId
        if (
          !dynamicLinkParams.teamDealId ||
          dynamicLinkParams.teamDealId === 'undefined'
        ) {
          delete dynamicLinkParams.teamDealId
        }
        if (
          !dynamicLinkParams.invitorId ||
          dynamicLinkParams.invitorId === 'undefined'
        ) {
          delete dynamicLinkParams.invitorId
        }
        delete dynamicLinkParams.alwayzItemId
        delete dynamicLinkParams.alwayzTeamDealId
        delete dynamicLinkParams.alwayzInvitorId
        delete dynamicLinkParams.code
        console.log(`dynamic link params: ${JSON.stringify(dynamicLinkParams)}`)
        UserStore.setDynamicLinkParams(dynamicLinkParams)
        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            await getUserInfoAndRegister(dynamicLinkParams)
          }
          redirect({
            prefix: `items/${dynamicLinkParams.itemId}`,
            params: dynamicLinkParams,
          })
        } else if (params.error) {
          redirect({
            prefix: `items/${dynamicLinkParams.itemId}`,
            params: dynamicLinkParams,
          })
        }
        //  else {
        //   window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=25f474ae1c52a3059473823b0a65aaec&redirect_uri=http://192.168.45.230:3000/users/login/kakao/auth-code/auto&response_type=code&prompt=none&state=${JSON.stringify(
        //     params,
        //   )}`
        // }
      } else if (params.orderIndicator) {
        const dynamicLinkParams = { ...params }

        console.log(
          `order indicator params: ${JSON.stringify(dynamicLinkParams)}`,
        )

        if (params.code) {
          const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
          delete dynamicLinkParams.code
          if (
            kakaoAccessTokenResult?.status === 200 &&
            kakaoAccessTokenResult.data.access_token
          ) {
            await getUserInfoAndRegister(dynamicLinkParams)
            console.log(`params: ${JSON.stringify(params)}`)
            const {
              orderIndicator: orderIndicatorTemp,
              pg_token: kakaoPayPgTokenTemp,
              imp_uid: impUid,
              imp_success: impSuccess,
              merchant_uid: merchantUid,
              amount,
            } = params
            if (kakaoPayPgTokenTemp) {
              setOrderIndicator(orderIndicatorTemp)
              setKakaoPayPgToken(kakaoPayPgTokenTemp)
            } else if (impSuccess === 'true') {
              const kakaoPayResult = {
                impUid,
                impSuccess,
                merchantUid,
                amount: parseInt(amount, 10),
              }
              const result = await OrderStore.recordKakaoSinglePayResultFromImp(
                orderIndicatorTemp,
                kakaoPayResult,
              )
              if (result?.status === 200 && result.data?.ordersListProcessed) {
                const teamGatheringOrders =
                  result.data.ordersListProcessed.filter(
                    (order) => order.status === 'team-gathering',
                  )
                const isTeamJoined = result.data.ordersListProcessed.filter(
                  (order) =>
                    order.isTeamDeal && order.status === 'pre-shipping',
                )
                if (teamGatheringOrders.length > 0) {
                  history.replace(
                    `/items/${teamGatheringOrders[0].itemInfo.itemId}`,
                  )
                  history.push('/order-end', { teamGatheringOrders })
                } else if (
                  isTeamJoined.length > 0 &&
                  result.data.ordersListProcessed.length > 0
                ) {
                  OrderStore.setSharingOrdersInfo(
                    result.data.ordersListProcessed,
                  )
                  OrderStore.setOrderIdsToShareToAlwayz(
                    result.data.ordersListProcessed.map((order) => order._id),
                  )
                  OrderStore.setShowTeamJoinedPurchaseModal(true)
                  history.replace(`/items/${isTeamJoined[0].itemInfo.itemId}`)
                  history.push('/order-end', { teamJoinedOrders: isTeamJoined })
                } else if (
                  teamGatheringOrders.length === 0 &&
                  result.data.ordersListProcessed.length > 0
                ) {
                  OrderStore.setSharingOrdersInfo(
                    result.data.ordersListProcessed,
                  )
                  OrderStore.setOrderIdsToShareToAlwayz(
                    result.data.ordersListProcessed.map((order) => order._id),
                  )
                  OrderStore.setShowIndividualPurchaseModal(true)
                  history.replace(
                    `/items/${result.data.ordersListProcessed[0].itemInfo.itemId}`,
                  )
                  history.push('/order-end', {
                    individualOrders: result.data.ordersListProcessed,
                  })
                }

                OrderStore.resetItemInfo()
                UserStore.setIsLoadingEmptyScreen(false)
              }
              // await backendApis.recordKakaoSinglePayResultFromImp()
              // setImpSuccessState(true)
            } else {
              // console.log(`set canceled kakao pay to true!`)
              // setOrderIndicator(orderIndicatorTemp)
              // setCanceledKakaoPay(true)
              UserStore.setScreenName('EmptyScreen_Web')
              UserStore.setIsLoadingEmptyScreen(false)
            }

            //   await OrderStore.setKakaoPayPgToken(kakaoPayPgToken, orderIndicator)
          }
        } else {
          const token = AuthStore.loadToken()
          console.log(`loaded token: ${token}`)
          if (token) {
            const {
              orderIndicator: orderIndicatorTemp,
              pg_token: kakaoPayPgTokenTemp,
              imp_uid: impUid,
              imp_success: impSuccess,
              merchant_uid: merchantUid,
              amount,
            } = params
            if (kakaoPayPgTokenTemp) {
              setOrderIndicator(orderIndicatorTemp)
              setKakaoPayPgToken(kakaoPayPgTokenTemp)
            } else if (impSuccess === 'true') {
              const kakaoPayResult = {
                impUid,
                impSuccess,
                merchantUid,
                amount: parseInt(amount, 10),
              }
              const result = await OrderStore.recordKakaoSinglePayResultFromImp(
                orderIndicatorTemp,
                kakaoPayResult,
              )
              if (result?.status === 200 && result.data?.ordersListProcessed) {
                const teamGatheringOrders =
                  result.data.ordersListProcessed.filter(
                    (order) => order.status === 'team-gathering',
                  )
                const isTeamJoined = result.data.ordersListProcessed.filter(
                  (order) =>
                    order.isTeamDeal && order.status === 'pre-shipping',
                )
                if (teamGatheringOrders.length > 0) {
                  history.replace(
                    `/items/${teamGatheringOrders[0].itemInfo.itemId}`,
                  )
                  history.push('/order-end', { teamGatheringOrders })
                } else if (
                  isTeamJoined.length > 0 &&
                  result.data.ordersListProcessed.length > 0
                ) {
                  OrderStore.setSharingOrdersInfo(
                    result.data.ordersListProcessed,
                  )
                  OrderStore.setOrderIdsToShareToAlwayz(
                    result.data.ordersListProcessed.map((order) => order._id),
                  )
                  OrderStore.setShowTeamJoinedPurchaseModal(true)
                  history.replace(`/items/${isTeamJoined[0].itemInfo.itemId}`)
                  history.push('/order-end', { teamJoinedOrders: isTeamJoined })
                } else if (
                  teamGatheringOrders.length === 0 &&
                  result.data.ordersListProcessed.length > 0
                ) {
                  OrderStore.setSharingOrdersInfo(
                    result.data.ordersListProcessed,
                  )
                  OrderStore.setOrderIdsToShareToAlwayz(
                    result.data.ordersListProcessed.map((order) => order._id),
                  )
                  OrderStore.setShowIndividualPurchaseModal(true)
                  history.replace(
                    `/items/${result.data.ordersListProcessed[0].itemInfo.itemId}`,
                  )
                  history.push('/order-end', {
                    individualOrders: result.data.ordersListProcessed,
                  })
                }

                OrderStore.resetItemInfo()
                UserStore.setIsLoadingEmptyScreen(false)
              } else {
                const currentUserOrdersList =
                  OrderStore.loadedUserOrdersList.find(
                    (order) => order.orderIndicator === orderIndicator,
                  )
                if (currentUserOrdersList) {
                  history.replace(
                    `/items/${currentUserOrdersList.itemInfo.itemId}`,
                  )
                } else {
                  history.replace('/')
                }
              }
            } else {
              // const [userSuccess, orders] = await Promise.all([
              //   await UserStore.loadUserInfo(),
              //   await OrderStore.loadUserOrdersList(),
              // ])
              // console.log(`set canceled kakao pay to true!`)
              // setOrderIndicator(orderIndicatorTemp)
              // setCanceledKakaoPay(true)
              UserStore.setScreenName('EmptyScreen_Web')
              UserStore.setIsLoadingEmptyScreen(false)
            }
          } else {
            UserStore.setScreenName('EmptyScreen_Web')
            UserStore.setIsLoadingEmptyScreen(false)
          }
        }
        //  else {
        //   console.log(`params before login: ${JSON.stringify(params)}`)
        //   window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=25f474ae1c52a3059473823b0a65aaec&redirect_uri=http://192.168.45.230:3000/users/login/kakao/auth-code/auto&response_type=code&prompt=none&state=${JSON.stringify(
        //     params,
        //   )}`
        // }
      } else {
        UserStore.setScreenName('EmptyScreen_Web')
        UserStore.setIsLoadingEmptyScreen(false)
      }

      // checkKakaoLogin()
    }
  }
  useEffect(() => {
    if (
      canceledKakaoPay &&
      OrderStore.loadedUserOrdersList.length > 0 &&
      orderIndicator
    ) {
      const currentUserOrdersList = OrderStore.loadedUserOrdersList.find(
        (order) => order.orderIndicator === orderIndicator,
      )
      if (currentUserOrdersList) {
        history.replace(`/items/${currentUserOrdersList.itemInfo.itemId}`)
      }
    }
  }, [canceledKakaoPay, OrderStore.loadedUserOrdersList.length, orderIndicator])

  useEffect(() => {
    checkInitialKakaoState()
  }, [])
  // useEffect(() => {
  //   backendApis.registerLogs(window.location.pathname)
  // }, [window.location.pathname])
  const finishKakaoPay = async () => {
    const result = await OrderStore.setKakaoPayPgToken(
      kakaoPayPgToken,
      orderIndicator,
    )
    setKakaoPayPgToken('')
    setOrderIndicator('')
    if (result?.status === 200 && result.data.approved_at) {
      const teamGatheringOrders = result.data.ordersListProcessed.filter(
        (order) => order.status === 'team-gathering',
      )
      const isTeamJoined = result.data.ordersListProcessed.filter(
        (order) => order.isTeamDeal && order.status === 'pre-shipping',
      )
      if (teamGatheringOrders.length > 0) {
        history.replace(`/items/${teamGatheringOrders[0].itemInfo.itemId}`)
        history.push('/order-end', { teamGatheringOrders })
      } else if (
        isTeamJoined.length > 0 &&
        result.data.ordersListProcessed.length > 0
      ) {
        OrderStore.setSharingOrdersInfo(result.data.ordersListProcessed)
        OrderStore.setOrderIdsToShareToAlwayz(
          result.data.ordersListProcessed.map((order) => order._id),
        )
        OrderStore.setShowTeamJoinedPurchaseModal(true)
        history.replace(`/items/${isTeamJoined[0].itemInfo.itemId}`)
        history.push('/order-end', { teamJoinedOrders: isTeamJoined })
      } else if (
        teamGatheringOrders.length === 0 &&
        result.data.ordersListProcessed.length > 0
      ) {
        OrderStore.setSharingOrdersInfo(result.data.ordersListProcessed)
        OrderStore.setOrderIdsToShareToAlwayz(
          result.data.ordersListProcessed.map((order) => order._id),
        )
        OrderStore.setShowIndividualPurchaseModal(true)
        history.replace(
          `/items/${result.data.ordersListProcessed[0].itemInfo.itemId}`,
        )
        history.push('/order-end', {
          individualOrders: result.data.ordersListProcessed,
        })
      }

      OrderStore.resetItemInfo()
    }
    UserStore.setIsLoadingEmptyScreen(false)
  }

  useEffect(() => {
    if (AuthStore.token && orderIndicator && kakaoPayPgToken) {
      finishKakaoPay()
    }
  }, [AuthStore.token, orderIndicator, kakaoPayPgToken])

  // useEffect(() => {
  //   if (AuthStore.token && orderIndicator && impSuccessState) {
  //   }
  // }, [AuthStore.token, orderIndicator, impSuccessState])

  return (
    <div style={{ flex: 1, backgroundColor: '#e2e2e8' }}>
      <EmptyScreenComponent />
      {/* <button
        style={{ height: 24 }}
        type='button'
        id='custom-login-btn'
        onClick={() => {
          const kakao = window.Kakao
          kakao.API.request({
            url: '/v1/user/unlink',
            async success(response) {
              console.log(response)
              AuthStore.setToken('')
              localStorage.removeItem('@alwayz@token')
            },
            fail(error) {
              console.log(error)
            },
          })
        }}
      >
        연결 끊기
      </button> */}
    </div>
  )
})

export default KakaoTestScreen
