import { makeObservable, observable, action } from 'mobx'

class AuthStore {
  token = ''
  needToConnectSocket = false
  constructor() {
    makeObservable(this, {
      token: observable.ref,
      needToConnectSocket: observable,
      setToken: action,
      setNeedToConnectSocket: action,
    })
  }

  setToken(token) {
    this.token = token
    localStorage.setItem('@alwayz@token', token)
  }

  setNeedToConnectSocket(needToConnectSocket) {
    this.needToConnectSocket = needToConnectSocket
  }

  loadToken() {
    if (!this.token) {
      const token = localStorage.getItem('@alwayz@token')
      if (token) {
        this.setToken(token)
        return token
      }
    }
    return this.token
  }
}

export default new AuthStore()
