import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'

const DetailImage = ({ detailImageUri }) => {
  return (
    <div>
      <img
        alt={detailImageUri}
        src={detailImageUri}
        //   style={{ objectFit: 'cover' }}
        width='100%'
        //   height='200'
      />
    </div>
  )
}

export default DetailImage
