import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import MainImages from './MainImages'
import ItemStore from '../../stores/ItemStore'
import RenderRating from '../rating/RenderRating'
import ImageManager from '../../images/ImageManager'
import UserStore from '../../stores/UserStore'
import TeamDealThresholdButton from '../buttons/TeamDealThresholdButton'
import IsCheapestButton from '../buttons/IsCheapestButton'
import FreeDeliverButton from '../buttons/FreeDeliverButton'
import ItemScreenTitleText from '../texts/ItemScreenTitleText'
import EventTextBox from '../texts/EventTextBox'
import GetRatingCount from '../texts/GetRatingCount'
import moment from 'moment'
import goAppDownload from '../../utils/appDownload'

const commaNumber = require('comma-number')

const ItemScreenTeamDealHeader = observer(
  ({ isLinkRewardTarget = false, validTeamDeals, reviews, itemId }) => {
    const history = useHistory()
    const numberOfReview = commaNumber(
      (ItemStore.targetItemInfo?.totalRatingCount || 0) +
        (ItemStore.targetItemInfo?.totalRatingCountRandom || 0),
    )
    
    const teamPurchasePrice = ItemStore.targetItemInfo?.teamPurchasePrice
    const newUserPrice = Math.max(
      100,
      parseInt(teamPurchasePrice - 2000, 10),
    )
    const newUserDiscountRate = Math.min(
      100,
      Math.floor(
        (1 - newUserPrice / ItemStore.targetItemInfo?.individualPurchasePrice) * 100,
      ),
    )

    const styles = {
      priceContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 12,
        paddingLeft: 16,
        paddingRight: 16,
        position: 'relative',
      },
      discountRate: {
        fontSize: 16,
        color: '#666666',
        fontWeight: 'bold',
        marginTop: 0,
        paddingVertical: 0,
        marginBottom: 0,
      },
      subPrice: {
        textDecorationLine: 'line-through',
        color: '#999999',
        fontWeight: 'normal',
      },
      reviewCount: {
        fontSize: 14,
        color: '#666666',
        fontWeight: 'bold',
        paddingTop: 2,
        // backgroundColor: 'red',
      },
      mainPrice: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#EA3F49',
      },
      linkShareDiscountPrice: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#EA3F49',
      },
      linkShareDiscountText: {
        fontSize: 14,
        color: '#999999',
        // fontWeight: 'normal',
        marginLeft: 8,
      },
      itemTitle: {
        fontSize: 18,
        marginTop: 8,
        marginBottom: 12,
        paddingLeft: 16,
        paddingRight: 16,
      },
      divider: {
        height: 1,
        backgroundColor: '#E0E0E0',
        marginLeft: 16,
        marginRight: 16,
      },
      sectionDivider: {
        height: 8,
        backgroundColor: '#F5F5F5',
      },
      iconText: {
        marginTop: 12,
        marginBottom: 8,
        marginLeft: 16,
        display: 'flex',
        fontSize: 14,
        fontWeight: 'bold',
        flexDirection: 'row',
        alignItems: 'center',
      },
      subText: {
        marginLeft: 16,
        color: '#333333',
        fontSize: 16,
      },
    }
    const LinkTargetBenefits = () => {
      return (
        <div
          style={{
            border: 'solid',
            marginTop: 12,
            marginLeft: 16,
            marginRight: 16,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: '#E0E0E0',
          }}
        >
          <div
            style={{
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            <div style={{ color: '#333333', fontWeight: 'bold', fontSize: 14 }}>
              {/* 지금 첫 구매 시 2000원 추가 할인 */}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 6,
              }}
            >
              <div
                style={{
                  width: 4,
                  height: 4,
                  borderRadius: 3,
                  backgroundColor: '#BDBDBD',
                  marginRight: 6,
                }}
              />
              <div style={{ color: '#999999', fontSize: 14 }}>
                하단의 붉은색 버튼으로 구매 시 즉시 적용
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 4,
              }}
            >
              <div
                style={{
                  width: 4,
                  height: 4,
                  borderRadius: 3,
                  backgroundColor: '#BDBDBD',
                  marginRight: 6,
                }}
              />
              <div style={{ color: '#999999', fontSize: 14 }}>
                앱으로 첫 구매하면 총 10%(5+5) 추가 할인
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 4,
              }}
            >
              <div
                style={{
                  width: 4,
                  height: 4,
                  borderRadius: 3,
                  backgroundColor: '#BDBDBD',
                  marginRight: 6,
                }}
              />
              <div style={{ color: '#999999', fontSize: 14 }}>
                팀구매 공유 링크로 들어오신 고객님께 드리는 혜택
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <MainImages />
        <div>
          {isLinkRewardTarget &&
            UserStore.linkBenefitsInfo.linkReceiverFirstPurchaseDiscountRate >
              0 && <LinkTargetBenefits />}
          <div style={styles.priceContainer}>
            {ItemStore?.targetItemInfo?.discountRate !== undefined && (
              <div style={styles.discountRate}>
                {Math.floor(ItemStore?.targetItemInfo?.discountRate * 100)}%{' '}
                <span style={styles.subPrice}>
                  {commaNumber(
                    ItemStore.targetItemInfo.individualPurchasePrice,
                  )}
                  원
                </span>
                <br />
                <div style={styles.mainPrice}>
                  {commaNumber(ItemStore.targetItemInfo.teamPurchasePrice)}원
                </div>
              </div>
            )}
            {ItemStore?.targetItemInfo?.totalRatingCount !== undefined && (
              <button
                type='button'
                style={{
                  position: 'absolute',
                  right: 16,
                  top: 0,
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  backgroundColor: '#FFF',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
                // onClick={() => {
                //   UserStore.setShowAppDownloadModal(true)
                // }}
                onClick={() => {
                  history.push('/items/review', {
                    itemId,
                    reviews,
                    numberOfReview,
                  })
                }}
              >
                <div>
                  <RenderRating
                    rate={
                      ItemStore.targetItemInfo.totalRatingCount +
                      (ItemStore.targetItemInfo.totalOrderQuantityRandom || 0)
                        ? (ItemStore.targetItemInfo.totalRatingScore +
                            (ItemStore.targetItemInfo.totalRatingScoreRandom ||
                              0)) /
                          (ItemStore.targetItemInfo.totalRatingCount +
                            (ItemStore.targetItemInfo.totalRatingCountRandom ||
                              0))
                        : 0
                    }
                  />
                </div>
                <div style={styles.reviewCount}>({numberOfReview})</div>
              </button>
            )}
          </div>
        </div>
        <div style={{
          display:'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: 12,
          marginRight: 12,
          marginTop: 6,
          marginBottom: 6,
          padding: 8,
          backgroundColor: '#eeeeee',
          borderRadius: 6
          }}>
        <div style={{ display:'flex', flexDirection:'row', alignItems:'center'}}>
          <div style={{ fontWeight:'bold', color:'#424242',fontSize:16, marginRight: 4 }}>{`ㄴ ${commaNumber(newUserPrice)}원`}</div>
          <div style={{ fontSize:13 }}>{` (지금 가입하면 ${newUserDiscountRate}% 할인)`}</div>
        </div>
        <button type='button'
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: 8,
                  marginRight: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 6,
                  paddingBottom: 6,
                  border: 0,
                  borderRadius: 6,
                  backgroundColor: '#FF3E3E',
                  fontWeight:'bold', 
                  color:'#ffffff',
                  fontSize:14,
                }}
                onClick={() => {
                  UserStore.setShowAppDownloadModal(true)
                  goAppDownload('coupon')
              }}
              >
                쿠폰 받기 →
              </button>
        </div>
        <div style={styles.itemTitle}>{ItemStore.targetItemInfo.itemTitle}</div>
        <div style={styles.divider} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 16,
            // alignItems: 'flex-start',
            // marginTop: 2,
            // position: 'relative',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 12,
            }}
          >
            <div
              style={{
                fontSize: 16,
                marginRight: 16,
                width: 72,
                color: '#9E9E9E',
              }}
            >
              배송비
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              <div
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                모든 상품 무료배송
              </div>
            </div>
          </div>
          <div style={{ height: 8 }} />
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              marginRight: 16,
            }}
          >
            <div
              style={{
                fontSize: 16,
                width: 72,
                color: '#9E9E9E',
              }}
            >
              배송정보
            </div>
            <div style={styles.subText}>
              평균 {ItemStore.targetItemInfo.shippingInfo?.shippingDays}일 소요
              {' · '} {ItemStore.targetItemInfo.shippingInfo?.shippingCompany}
            </div>
          </div>
        </div>

        {ItemStore.targetItemInfo?.isEventDeal && (
          <div style={styles.subText}>
            일정 인원이 충족되거나 이벤트 기간이 종료될 경우 3일 내로 배송이
            시작됩니다.
          </div>
        )}
        <div style={{ height: 12 }} />
        <EventTextBox
          itemId={ItemStore.targetItemInfo?._id}
          isEventDeal={ItemStore.targetItemInfo.isEventDeal}
          isViralItem={ItemStore.targetItemInfo?.isViralItem}
          isLotteryItem={ItemStore.targetItemInfo?.isLotteryItem}
          isUnrestartableItem={ItemStore.targetItemInfo?.isUnrestartableItem}
          isNewUserOnlyItem={ItemStore.targetItemInfo?.isNewUserOnlyItem}
          isCouponExchangeItem={ItemStore.targetItemInfo?.isCouponExchangeItem}
          itemText={
            ItemStore.targetItemInfo?.itemText ||
            ItemStore.targetItemInfo?.itemTitle
          }
          numberOfTeamText={
            ItemStore.targetItemInfo?.numberOfTeams
              ? `${ItemStore.targetItemInfo?.numberOfTeams}팀(${
                  parseInt(ItemStore.targetItemInfo?.numberOfTeams, 10) *
                  ItemStore.targetItemInfo?.teamDealThreshold
                }명)`
              : ''
          }
          deadlineText={moment(ItemStore.targetItemInfo?.lotteryEndsAt).format(
            'MM월 DD일 HH시',
          )}
          announceText={moment(
            ItemStore.targetItemInfo?.lotteryAnnounceAt,
          ).format('DD일 HH시 발표')}
          isPushNotified={ItemStore.targetItemInfo?.isPushNotified}
          teamPurchasePrice={ItemStore.targetItemInfo?.teamPurchasePrice}
        />
        {/* <div style={styles.subText}>배송/교환/반품 정책</div> */}
        <div style={styles.sectionDivider} />
        {ItemStore.targetItemInfo?.isViralItem ||
        ItemStore.targetItemInfo?.isLotteryItem ||
        ItemStore.targetItemInfo?.isUnrestartableItem ? (
          <div>
            <ItemScreenTitleText
              text='열린 팀구매'
              // moreTextTeamDeal={`현재 ${validTeamDeals?.length}팀 모집 중`}
            />
            {/* <EventTextBox
              isViralItem={ItemStore.targetItemInfo?.isViralItem}
              isLotteryItem={ItemStore.targetItemInfo?.isLotteryItem}
              isUnrestartableItem={
                ItemStore.targetItemInfo?.isUnrestartableItem
              }
            /> */}
          </div>
        ) : (
          <ItemScreenTitleText text='열린 팀구매 참여하기' />
        )}
        <div style={styles.divider} />
      </div>
    )
  },
)

export default ItemScreenTeamDealHeader
