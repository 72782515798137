const {
  REACT_APP_NODE_ENV_USER,
  REACT_APP_BACKEND_URL_DEVELOPMENT,
  REACT_APP_BACKEND_URL_PRODUCTION,
  REACT_APP_WEB_URL_PRODUCTION,
  REACT_APP_WEB_URL_DEVELOPMENT,
  REACT_APP_KAKAO_JS_KEY_DEVELOPMENT,
  REACT_APP_KAKAO_JS_KEY_PRODUCTION,
  REACT_APP_FIREBASE_API_KEY_DEVELOPMENT,
  REACT_APP_FIREBASE_API_KEY_PRODUCTION,
  REACT_APP_IMP_CODE_DEVELOPMENT,
  REACT_APP_IMP_CODE_PRODUCTION,
} = process.env

const configs = {
  nodeEnv: null,
  backendUrl: null,
  kakaoJsKey: null,
  webUrl: null,
  firebaseApiKey: null,
  impCode: null,
}
//
if (REACT_APP_NODE_ENV_USER === 'development') {
  configs.nodeEnv = 'development'
  configs.backendUrl = REACT_APP_BACKEND_URL_DEVELOPMENT
  configs.kakaoJsKey = REACT_APP_KAKAO_JS_KEY_DEVELOPMENT
  configs.webUrl = REACT_APP_WEB_URL_DEVELOPMENT
  configs.firebaseApiKey = REACT_APP_FIREBASE_API_KEY_DEVELOPMENT
  configs.impCode = REACT_APP_IMP_CODE_DEVELOPMENT
  console.log(`web url: ${configs.webUrl}`)
} else if (REACT_APP_NODE_ENV_USER === 'production') {
  configs.nodeEnv = 'production'
  configs.backendUrl = REACT_APP_BACKEND_URL_PRODUCTION
  configs.kakaoJsKey = REACT_APP_KAKAO_JS_KEY_PRODUCTION
  configs.webUrl = REACT_APP_WEB_URL_PRODUCTION
  configs.firebaseApiKey = REACT_APP_FIREBASE_API_KEY_PRODUCTION
  configs.impCode = REACT_APP_IMP_CODE_PRODUCTION
}

export default configs
