import React, { useState } from 'react'
import Modal from 'react-modal'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import OrderStore from '../../stores/OrderStore'
import UserStore from '../../stores/UserStore'
import parseViralItemOptions from '../../utils/parseViralItemOptions'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '80%',
    borderRadius: 4,
    border: 'solid',
    borderColor: '#BDBDBD',
    borderWidth: 0.5,
    padding: 0,
    height: 240,
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // paddingLeft: 20,
    // paddingRight: 20,
    transform: 'translate(-50%, -50%)',
  },
}

const TeamDealFailedModal = observer(
  ({
    showModal = false,
    setShowModal,
    soldOut,
    invitorId,
    invitedOrderId,
    isInitialTeamDealLinkInvited,
    showSoldOutModal,
    setShowSoldOutModal,
  }) => {
    // const [modalIsOpen, setIsOpen] = useState(true)

    // function openModal() {
    //   setIsOpen(true)
    // }

    const closeModal = () => {
      setShowModal(false)
    }
    return (
      <Modal
        isOpen={showModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='App Download Modal'
      >
        <div
          style={{
            marginLeft: 16,
            marginRight: 16,
            marginTop: 32,
            marginBottom: 12,
            // backgroundColor: 'red',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 18,
              fontWeight: 'bold',
              marginBottom: 32,
            }}
          >
            팀구매 참여 불가해요. 😭
          </div>
          <div
            style={{
              color: '#999999',
              marginRight: 16,
              marginLeft: 16,
              marginBottom: 32,
              textAlign: 'center',
            }}
          >
            들어오신 링크의 팀구매는 <br />
            이미 성사되었거나 만료되었어요 <br />새 팀구매를 시작해보세요.
          </div>
          <button
            style={{
              width: '100%',
              marginBottom: 0,
              height: 36,
              backgroundColor: '#EA3f49',
              border: 'none',
              borderRadius: 4,
              fontSize: 16,
              color: '#FFF',
              fontWeight: 'bold',
            }}
            type='button'
            onClick={async () => {
              // 새 팀구매 시작하기
              if (soldOut) {
                // alert(
                //   '해당 상품은 현재 품절되어서 주문이 불가능합니다. 더 많은 상품들을 보시려면 앱에서 확인해주세요!',
                // )
                setShowSoldOutModal(true)
                return
              }
              if (!ItemStore.targetItemInfo.detailImageUris) {
                return
              }
              if (UserStore.uploadingUserLinkUsage) {
                return
              }
              let sellerId = ItemStore.targetItemInfo.sellerInfo.sellerId
              let optionsInfo = ItemStore.targetItemInfo.optionsInfo
              if (
                ItemStore.targetItemInfo.sellerInfo.sharedSellerIds?.length > 0
              ) {
                const randomNumber = Math.random()
                if (
                  randomNumber > ItemStore.targetItemInfo.sellerInfo.orderRatio
                ) {
                  const randomInt = Math.floor(
                    Math.random() *
                      ItemStore.targetItemInfo.sellerInfo.sharedSellerIds
                        ?.length,
                  )
                  sellerId =
                    ItemStore.targetItemInfo.sellerInfo.sharedSellerIds[
                      randomInt
                    ]
                  optionsInfo = parseViralItemOptions(optionsInfo, randomInt)
                }
              }
              OrderStore.resetItemInfo()
              OrderStore.setItemInfoByIndex(
                {
                  itemId: ItemStore.targetItemInfo._id,
                  itemTitle: ItemStore.targetItemInfo.itemTitle,
                  optionsInfo,
                  itemImageUri:
                    ItemStore.targetItemInfo.mainImageUris &&
                    ItemStore.targetItemInfo.mainImageUris[0],
                  isTaxFree: !!ItemStore.targetItemInfo.isTaxFree,
                  shippingInfo: ItemStore.targetItemInfo.shippingInfo,
                  teamPurchasePrice: ItemStore.targetItemInfo.teamPurchasePrice,
                  individualPurchasePrice:
                    ItemStore.targetItemInfo.individualPurchasePrice,
                  purchaseLimits: ItemStore.targetItemInfo.purchaseLimits,
                  teamDealThreshold: ItemStore.targetItemInfo.teamDealThreshold,
                  sellerId,
                  dynamicLinkParams: {
                    ...UserStore.dynamicLinkParams,
                    ...UserStore.testGroup,
                  },
                  invitorId,
                  invitedOrderId,
                  isViralItem: ItemStore.targetItemInfo.isViralItem,
                  isUnrestartableItem:
                    ItemStore.targetItemInfo.isUnrestartableItem,
                  isLotteryItem: ItemStore.targetItemInfo?.isLotteryItem,
                  isCouponExchangeItem:
                    ItemStore.targetItemInfo?.isCouponExchangeItem,
                  isMartItem: ItemStore.targetItemInfo?.isMartItem,
                  characteristicItem:
                    ItemStore.targetItemInfo?.characteristicItem,

                  source: 'Web',
                },
                OrderStore.currentItemIndex,
              )
              OrderStore.setItemIsTeamDealByIndex(
                true,
                OrderStore.currentItemIndex,
              )
              OrderStore.setItemQuantityByIndex(
                OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                  .quantity || 1,
                OrderStore.currentItemIndex,
              )
              if (isInitialTeamDealLinkInvited) {
                OrderStore.setItemIsInitialTeamDealLinkInvited(
                  isInitialTeamDealLinkInvited,
                  OrderStore.currentItemIndex,
                )
              }
              setShowModal(false)
              OrderStore.setCloseOrderBottomSheet(false)
            }}
          >
            새 팀구매 열기
          </button>
        </div>
      </Modal>
    )
  },
)

export default TeamDealFailedModal
