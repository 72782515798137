import React from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import UseWindowDimension from '../common/UseWindowDimension'
import RenderRating from '../rating/RenderRating'
import GetRatingCount from '../texts/GetRatingCount'
import LinesEllipsis from 'react-lines-ellipsis'
import commaNumber from 'comma-number'
import TeamDealThresholdButton from '../buttons/TeamDealThresholdButton'
import FreeDeliverButton from '../buttons/FreeDeliverButton'
import IsCheapestButton from '../buttons/IsCheapestButton'
import ImageManager from '../../images/ImageManager'
import UserStore from '../../stores/UserStore'
import { isWindows } from 'react-device-detect'

// import { useHistory, useLocation, useParams } from 'react-router-dom'

const ItemListComponent = observer(({ itemInfo, index, enteringComponent }) => {
  const { width } = UseWindowDimension()
  const limitedWidth = Math.min(width, 480)
  const history = useHistory()
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        paddingBottom: 24,
        outline: 'none',
        cursor: 'pointer',
      }}
      role='button'
      tabIndex={0}
      onClick={() => {
        UserStore.setDynamicLinkParams({
          ...UserStore.dynamicLinkParams,
          enteringComponent,
        })
        history.replace(`/items/${itemInfo?._id}`)
      }}
      onKeyDown={() => {
        UserStore.setDynamicLinkParams({
          ...UserStore.dynamicLinkParams,
          enteringComponent,
        })
        // console.log('itemInfo?._id', itemInfo?._id)
        // history.replace(`/items/${itemInfo?._id}`)
        UserStore.setShowAppDownloadItemModal(true)
      }}
    >
      <div>
        <img
          alt={itemInfo?.mainImageUris[0]}
          // key={mainImageUri}
          src={itemInfo?.mainImageUris[0]}
          style={{
            objectFit: 'cover',
            width: (limitedWidth - 36) / 2,
            height: (limitedWidth - 36) / 2,
            borderRadius: 8,
          }}
        />
        <div style={{ height: 168 }}>
          <LinesEllipsis
            style={{ fontSize: 16 }}
            text={itemInfo?.itemTitle}
            maxLine='2'
            ellipsis='..'
            basedOn='letters'
          />
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
            <div style={{ color: '#666666', fontSize: 14, fontWeight: 'bold' }}>
              {Math.ceil(itemInfo?.discountRate * 100)}%
            </div>
            <div
              style={{
                fontSize: 14,
                marginLeft: 2,
                color: '#999999',
                textDecorationLine: 'line-through',
              }}
            >
              {commaNumber(itemInfo?.individualPurchasePrice)}원
            </div>
          </div>
          <div
            style={{
              color: '#EA3F49',
              fontSize: 16,
              fontWeight: 'bold',
              marginTop: 0,
            }}
          >
            {commaNumber(itemInfo?.teamPurchasePrice)}원
          </div>

          <div
            style={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              // backgroundColor: 'red',
            }}
          >
            <RenderRating
              size={16}
              rate={GetRatingCount(itemInfo)?.getRating}
            />
            <div
              style={{
                fontSize: 10,
                includeFontPadding: false,
                paddingBottom: 4,
                paddingLeft: 2,
                color: '#999999',
                fontWeight: 'bold',
              }}
            >
              ({commaNumber(GetRatingCount(itemInfo)?.getRatingCount)})
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default ItemListComponent
