import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ImageManager from '../images/ImageManager'
import Modal from 'react-modal'

// 사진

import dailyCheckInWithCoupon from '../images/DailyCheckInWithCoupon.png'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'
import ItemList from '../components/items/ItemList'
import ItemStore from '../stores/ItemStore'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '88%',
    borderRadius: 25.5,
    borderColor: '#BDBDBD',
    padding: 0,
    height: 240,
    paddingTop: 18,
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // paddingLeft: 20,
    // paddingRight: 20,
    transform: 'translate(-50%, -50%)',
  },
}

const CheckInInviteScreen = observer(() => {
  const history = useHistory()

  const [userName, setUserName] = useState('')
  // const [phoneNumberText, setPhoneNumberText] = useState('')
  // const [phoneNumber, setPhoneNumber] = useState('')
  // const [showModal, setShowModal] = useState(false)

  // const onChange = (e: any) => {
  //   setPhoneNumberText(e.target.value)
  // }

  useEffect(() => {
    const params = dynamicLinkUtils.parseLink(window.location.href)
    setUserName(params?.userName || '')
  }, [])

  useEffect(() => {
    ItemStore.loadSpecificItemsInfo()
  }, [])

  useEffect(() => {
    if (!ItemStore.initialLoadingDataAssetsDone) {
      ItemStore.initialLoadDataAssets()
    }
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: 1,
        backgroundColor: '#FFF3E1',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '48%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ height: 42 }} />
          <div
            style={{
              marginRight: '2%',
              marginLeft: '2%',
            }}
          >
            <ImageManager source='Welcome' />
          </div>
          <div style={{ height: 8 }} />
          <ImageManager source='AlwayzFullLogoRed' />
          <div style={{ height: 12 }} />
        </div>
      </div>
      <div
        className='box-sizing'
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          alignItems: 'center',
        }}
      >
        <div style={{ height: 16 }} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          marginTop: -20,
        }}
      >
        <img
          src={dailyCheckInWithCoupon}
          alt=''
          width={window.innerWidth - 32}
        />
      </div>
      <div style={{ height: 20 }} />
      <div
        className='box-sizing'
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          alignItems: 'center',
          marginBottom: 24,
        }}
      >
        <div
          className='box-sizing'
          style={{
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 8,
            textAlign: 'center',
          }}
        >
          {decodeURIComponent(userName)}님이 초대장을 보냈어요
        </div>
        <div
          className='box-sizing'
          style={{
            fontSize: 18,
            marginTop: 2,
            marginBottom: 2,
            textAlign: 'center',
          }}
        >
          함께 올웨이즈 출석체크 하고
        </div>
        <div
          className='box-sizing'
          style={{
            fontSize: 18,
            marginTop: 2,
            marginBottom: 2,
            textAlign: 'center',
          }}
        >
          최대 10,000원의 혜택을 받으세요!
        </div>
      </div>
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          width: '100%',
          display: 'flex',
          marginBottom: 24,
        }}
      >
        <button
          className='box-sizing sale-button'
          style={{
            height: 46,
            backgroundColor: '#FF3E3E',
            borderRadius: 10,
            fontSize: 18,
            fontWeight: 'bold',
            color: '#fff',
            width: window.innerWidth - 96,
            borderColor: 'yellow',
          }}
          onClick={() => {
            window.location.href = `https://alwayzshop.ilevit.com/app`
          }}
        >
          다운 받으러 가기
        </button>
      </div>

      {ItemStore.initialLoadingDataAssetsDone && (
        <div style={{ backgroundColor: 'white' }}>
          <ItemList
            categoryType='large'
            categoryId='total'
            enteringComponent='OrderEndScreenAlwayzSuggestionComponent'
          />
        </div>
      )}
    </div>
  )
})

export default CheckInInviteScreen
