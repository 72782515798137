import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
// import { useHistory, useLocation, useParams } from 'react-router-dom'

const DisplayOrderMainAddress = observer(({ item }) => {
  if (!item?.receivingMethod) {
    return <></>
  }
  let receivingMethodText = ''
  if (item.receivingMethod === 'door') {
    receivingMethodText = '문 앞'
  } else if (item.receivingMethod === 'etc') {
    receivingMethodText = '기타'
  }
  const subText = {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 14,
    color: '#BDBDBD',
  }
  return (
    <div style={{ paddingTop: 0, paddingBottom: 12 }}>
      <div style={subText}>
        {item.recipient}{' '}
        {`${item.recipientPhoneNumber.slice(
          0,
          3,
        )}-${item.recipientPhoneNumber.slice(
          3,
          7,
        )}-${item.recipientPhoneNumber.slice(7)}`}
      </div>
      <div style={subText}>{`(${item.postcode}) ${item.postcodeAddress} `}</div>
      <div style={subText}>{item.detailAddress}</div>
      <div style={subText}>{`공동현관 비밀번호 : ${
        item.frontDoorPassword === '' ? '없음' : item.frontDoorPassword
      }`}</div>
      <div style={subText}>{`배송 수령 방법 : ${receivingMethodText}`}</div>
    </div>
  )
})

export default DisplayOrderMainAddress
