import { io } from 'socket.io-client'
import configs from './configs'
import AuthStore from '../stores/AuthStore'

console.log(`socket backendurl: ${configs.backendUrl}`)
const socket = io(configs.backendUrl, {
  autoConnect: false,
  transports: ['websocket'],
})
// const socket = io('https://alwayzshop.ilevit.com', {
//   autoConnect: false,
//   transports: ['websocket'],
// })

socket.on('connect', () => {
  console.log(`socket connected: ${socket.id}`)
  //   AuthStore.setNeedToConnectSocket(false)
  AuthStore.setNeedToConnectSocket(true)
})

// socket.on('loadMembersInfo', () => {
//   roundTableStore.loadMembersInfo()
// })

// socket.on('setUser', () => {
//   userStore.setUser()
// })

export default socket
