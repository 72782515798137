import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import OrderStore from '../../stores/OrderStore'
import ImageManager from '../../images/ImageManager'
import commaNumber from 'comma-number'
import UserStore from '../../stores/UserStore'
import SoldOutModal from '../modal/SoldOutModal'
import parseViralItemOptions from '../../utils/parseViralItemOptions'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'

const BottomOrderBar = observer(
  ({
    itemInfo,
    soldOut,
    invitorId,
    isInitialTeamDealLinkInvited,
    invitedOrderId,
    showSoldOutModal,
    setShowSoldOutModal,
    onClickFunc,
  }) => {
    const [ableToPressHeart, setAbleToPressHeart] = useState(true)
    // const [showSoldOutModal, setShowSoldOutModal] = useState(false)
    return (
      <div
        style={{
          height: 64,
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
        }}
      >
        <SoldOutModal
          showModal={showSoldOutModal}
          setShowModal={setShowSoldOutModal}
        />
        <button
          style={{
            borderColor: '#F8C454',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'column',
            color: '#FFF',
            flex: 0.9,
            alignItems: 'center',
            backgroundColor: '#F8C454',
            justifyContent: 'center',
            marginTop: 8,
            marginBottom: 8,
            marginLeft: 8,
            borderRadius: 4,
          }}
          type='button'
          onClick={onClickFunc}
          // onClick={async () => {
          //   // 스토어 링크로 타고 넘어가는 로직
          //   const { itemId, teamDealId, invitorId, linkType } =
          //     UserStore.dynamicLinkParams
          //   UserStore.setIsLoading(true)
          //   const link = await dynamicLinkUtils.createAppDownloadLink(
          //     itemId || '',
          //     teamDealId || '',
          //     invitorId || '',
          //     linkType || '',
          //     '',
          //     '',
          //     '',
          //     'ItemScreenWeb',
          //     UserStore.dynamicLinkParams.isLinkFromApp,
          //     UserStore.dynamicLinkParams.bottomSheetTimeOut,
          //     UserStore.isLateComer,
          //     UserStore.testGroup?.endedTeamDealNotify,
          //     UserStore.testGroup?.ableToPurchaseViralItem,
          //     UserStore.testGroup?.reviewPosition,
          //     UserStore.testGroup?.teamMemberShowing,
          //     UserStore.testGroup?.appDownloadFullScreen,
          //   )
          //   UserStore.setIsLoading(false)
          //   if (link) {
          //     window.location.href = link
          //     // await copyToClipboard(link)
          //   }
          //   // onPressJoinTeamDeal()
          //   // 스토어로 넘어가는 로직 끝

          //   // 기존 OrderMain으로 넘어가는 결제로직
          //   // if (soldOut) {
          //   //   // alert(
          //   //   //   '해당 상품은 현재 품절되어서 주문이 불가능합니다. 더 많은 상품들을 보시려면 앱에서 확인해주세요!',
          //   //   // )
          //   //   setShowSoldOutModal(true)
          //   //   return
          //   // }
          //   // if (!ItemStore.targetItemInfo.detailImageUris) {
          //   //   return
          //   // }
          //   // if (UserStore.uploadingUserLinkUsage) {
          //   //   return
          //   // }
          //   // // if (
          //   // //   ItemStore.targetItemInfo.isViralItem &&
          //   // //   new Date() > new Date('Sat, 18 Dec 2021 06:00:00 GMT')
          //   // // ) {
          //   // //   alert('해당 상품은 현재 새 팀구매 여는 것이 불가합니다.')
          //   // //   return
          //   // // }
          //   // // const existingOrder = OrderStore.loadedUserOrdersList
          //   // //   .filter((order) =>
          //   // //     ['team-gathering', 'pre-shipping', 'shipping'].includes(
          //   // //       order.status,
          //   // //     ),
          //   // //   )
          //   // //   .find(
          //   // //     (order) =>
          //   // //       order.itemInfo.itemId === ItemStore.targetItemInfo._id,
          //   // //   )
          //   // // if (existingOrder) {
          //   // //   // checked
          //   // //   alert(
          //   // //     '해당 상품은 동시에 여러 번 주문할 수 없어요. 배송 완료 후 주문 가능합니다.',
          //   // //   )
          //   // //   return
          //   // // }
          //   // let sellerId = ItemStore.targetItemInfo.sellerInfo.sellerId
          //   // let optionsInfo = ItemStore.targetItemInfo.optionsInfo
          //   // if (
          //   //   ItemStore.targetItemInfo.sellerInfo.sharedSellerIds?.length > 0
          //   // ) {
          //   //   const randomNumber = Math.random()
          //   //   if (
          //   //     randomNumber > ItemStore.targetItemInfo.sellerInfo.orderRatio
          //   //   ) {
          //   //     const randomInt = Math.floor(
          //   //       Math.random() *
          //   //         ItemStore.targetItemInfo.sellerInfo.sharedSellerIds?.length,
          //   //     )
          //   //     sellerId =
          //   //       ItemStore.targetItemInfo.sellerInfo.sharedSellerIds[randomInt]
          //   //     optionsInfo = parseViralItemOptions(optionsInfo, randomInt)
          //   //   }
          //   // }
          //   // OrderStore.resetItemInfo()
          //   // OrderStore.setItemInfoByIndex(
          //   //   {
          //   //     itemId: ItemStore.targetItemInfo._id,
          //   //     itemTitle: ItemStore.targetItemInfo.itemTitle,
          //   //     optionsInfo,
          //   //     itemImageUri:
          //   //       ItemStore.targetItemInfo.mainImageUris &&
          //   //       ItemStore.targetItemInfo.mainImageUris[0],
          //   //     isTaxFree: !!ItemStore.targetItemInfo.isTaxFree,
          //   //     shippingInfo: ItemStore.targetItemInfo.shippingInfo,
          //   //     teamPurchasePrice: ItemStore.targetItemInfo.teamPurchasePrice,
          //   //     individualPurchasePrice:
          //   //       ItemStore.targetItemInfo.individualPurchasePrice,
          //   //     purchaseLimits: ItemStore.targetItemInfo.purchaseLimits,
          //   //     sellerId,
          //   //     dynamicLinkParams: {
          //   //       ...UserStore.dynamicLinkParams,
          //   //       ...UserStore.testGroup,
          //   //     },
          //   //     invitorId,
          //   //     invitedOrderId,
          //   //     isViralItem: ItemStore.targetItemInfo.isViralItem,
          //   //     isUnrestartableItem:
          //   //       ItemStore.targetItemInfo.isUnrestartableItem,
          //   //     isLotteryItem: ItemStore.targetItemInfo?.isLotteryItem,
          //   //     isCouponExchangeItem:
          //   //       ItemStore.targetItemInfo?.isCouponExchangeItem,
          //   //     isMartItem: ItemStore.targetItemInfo?.isMartItem,
          //   //     characteristicItem:
          //   //       ItemStore.targetItemInfo?.characteristicItem,

          //   //     source: 'Web',
          //   //   },
          //   //   OrderStore.currentItemIndex,
          //   // )
          //   // OrderStore.setItemIsTeamDealByIndex(
          //   //   false,
          //   //   OrderStore.currentItemIndex,
          //   // )
          //   // OrderStore.setItemQuantityByIndex(
          //   //   OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
          //   //     .quantity || 1,
          //   //   OrderStore.currentItemIndex,
          //   // )
          //   // if (isInitialTeamDealLinkInvited) {
          //   //   OrderStore.setItemIsInitialTeamDealLinkInvited(
          //   //     isInitialTeamDealLinkInvited,
          //   //     OrderStore.currentItemIndex,
          //   //   )
          //   // }
          //   // OrderStore.setCloseOrderBottomSheet(false)
          //   // 기존 OrderMain으로 넘어가는 결제로직 끝
          // }}
        >
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>
            {commaNumber(
              Math.floor(
                ItemStore.targetItemInfo.individualPurchasePrice *
                  (isInitialTeamDealLinkInvited
                    ? 1 -
                      (UserStore.linkBenefitsInfo
                        .linkReceiverFirstPurchaseDiscountRate || 0)
                    : 1),
              ),
            )}
            원
          </div>
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>혼자 구매하기</div>
        </button>
        <button
          style={{
            borderColor: '#EA3F49',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'column',
            color: '#FFF',
            flex: 1,
            alignItems: 'center',
            backgroundColor: '#EA3F49',
            justifyContent: 'center',
            marginTop: 8,
            marginBottom: 8,
            margin: 8,
            borderRadius: 4,
          }}
          type='button'
          onClick={onClickFunc}
          // onClick={async () => {
          //   // 스토어 링크로 타고 넘어가는 로직
          //   const { itemId, teamDealId, invitorId, linkType } =
          //     UserStore.dynamicLinkParams
          //   UserStore.setIsLoading(true)
          //   const link = await dynamicLinkUtils.createAppDownloadLink(
          //     itemId || '',
          //     teamDealId || '',
          //     invitorId || '',
          //     linkType || '',
          //     '',
          //     '',
          //     '',
          //     'ItemScreenWeb',
          //     UserStore.dynamicLinkParams.isLinkFromApp,
          //     UserStore.dynamicLinkParams.bottomSheetTimeOut,
          //     UserStore.isLateComer,
          //     UserStore.testGroup?.endedTeamDealNotify,
          //     UserStore.testGroup?.ableToPurchaseViralItem,
          //     UserStore.testGroup?.reviewPosition,
          //     UserStore.testGroup?.teamMemberShowing,
          //     UserStore.testGroup?.appDownloadFullScreen,
          //   )
          //   UserStore.setIsLoading(false)
          //   if (link) {
          //     window.location.href = link
          //     // await copyToClipboard(link)
          //   }
          //   // onPressJoinTeamDeal()
          //   // 스토어로 넘어가는 로직 끝

          //   // 기존 OrderMain 넘어가기 위한 로직
          //   // if (soldOut) {
          //   //   // alert(
          //   //   //   '해당 상품은 현재 품절되어서 주문이 불가능합니다. 더 많은 상품들을 보시려면 앱에서 확인해주세요!',
          //   //   // )
          //   //   setShowSoldOutModal(true)
          //   //   return
          //   // }
          //   // if (!ItemStore.targetItemInfo.detailImageUris) {
          //   //   return
          //   // }
          //   // if (UserStore.uploadingUserLinkUsage) {
          //   //   return
          //   // }
          //   // // if (
          //   // //   ItemStore.targetItemInfo.isViralItem &&
          //   // //   new Date() > new Date('Sat, 18 Dec 2021 06:00:00 GMT')
          //   // // ) {
          //   // //   alert('해당 상품은 현재 팀구매 여는 것이 불가합니다.')
          //   // //   return
          //   // // }

          //   // // const existingOrder = OrderStore.loadedUserOrdersList
          //   // //   .filter((order) =>
          //   // //     ['team-gathering', 'pre-shipping', 'shipping'].includes(
          //   // //       order.status,
          //   // //     ),
          //   // //   )
          //   // //   .find(
          //   // //     (order) =>
          //   // //       order.itemInfo.itemId === ItemStore.targetItemInfo._id,
          //   // //   )
          //   // // if (existingOrder) {
          //   // //   // checked
          //   // //   alert(
          //   // //     '해당 상품은 동시에 여러 번 주문할 수 없어요. 배송 완료 후 주문 가능합니다.',
          //   // //   )
          //   // //   return
          //   // // }
          //   // let sellerId = ItemStore.targetItemInfo.sellerInfo.sellerId
          //   // let optionsInfo = ItemStore.targetItemInfo.optionsInfo
          //   // if (
          //   //   ItemStore.targetItemInfo.sellerInfo.sharedSellerIds?.length > 0
          //   // ) {
          //   //   const randomNumber = Math.random()
          //   //   // console.log(
          //   //   //   `random number for bottom order bar: ${randomNumber}, ${ItemStore.targetItemInfo.sellerInfo.orderRatio}`,
          //   //   // )
          //   //   if (
          //   //     randomNumber > ItemStore.targetItemInfo.sellerInfo.orderRatio
          //   //   ) {
          //   //     const randomInt = Math.floor(
          //   //       Math.random() *
          //   //         ItemStore.targetItemInfo.sellerInfo.sharedSellerIds?.length,
          //   //     )
          //   //     sellerId =
          //   //       ItemStore.targetItemInfo.sellerInfo.sharedSellerIds[randomInt]
          //   //     optionsInfo = parseViralItemOptions(optionsInfo, randomInt)
          //   //   }
          //   // }
          //   // OrderStore.resetItemInfo()
          //   // OrderStore.setItemInfoByIndex(
          //   //   {
          //   //     itemId: ItemStore.targetItemInfo._id,
          //   //     itemTitle: ItemStore.targetItemInfo.itemTitle,
          //   //     optionsInfo,
          //   //     itemImageUri:
          //   //       ItemStore.targetItemInfo.mainImageUris &&
          //   //       ItemStore.targetItemInfo.mainImageUris[0],
          //   //     isTaxFree: !!ItemStore.targetItemInfo.isTaxFree,
          //   //     shippingInfo: ItemStore.targetItemInfo.shippingInfo,
          //   //     teamPurchasePrice: ItemStore.targetItemInfo.teamPurchasePrice,
          //   //     individualPurchasePrice:
          //   //       ItemStore.targetItemInfo.individualPurchasePrice,
          //   //     purchaseLimits: ItemStore.targetItemInfo.purchaseLimits,
          //   //     teamDealThreshold: ItemStore.targetItemInfo.teamDealThreshold,
          //   //     sellerId,
          //   //     dynamicLinkParams: {
          //   //       ...UserStore.dynamicLinkParams,
          //   //       ...UserStore.testGroup,
          //   //     },
          //   //     invitorId,
          //   //     invitedOrderId,
          //   //     isViralItem: ItemStore.targetItemInfo.isViralItem,
          //   //     isUnrestartableItem:
          //   //       ItemStore.targetItemInfo.isUnrestartableItem,
          //   //     isLotteryItem: ItemStore.targetItemInfo?.isLotteryItem,
          //   //     isCouponExchangeItem:
          //   //       ItemStore.targetItemInfo?.isCouponExchangeItem,
          //   //     characteristicItem:
          //   //       ItemStore.targetItemInfo?.characteristicItem,

          //   //     source: 'Web',
          //   //   },
          //   //   OrderStore.currentItemIndex,
          //   // )
          //   // OrderStore.setItemIsTeamDealByIndex(
          //   //   true,
          //   //   OrderStore.currentItemIndex,
          //   // )
          //   // OrderStore.setItemQuantityByIndex(
          //   //   OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
          //   //     .quantity || 1,
          //   //   OrderStore.currentItemIndex,
          //   // )
          //   // if (isInitialTeamDealLinkInvited) {
          //   //   OrderStore.setItemIsInitialTeamDealLinkInvited(
          //   //     isInitialTeamDealLinkInvited,
          //   //     OrderStore.currentItemIndex,
          //   //   )
          //   // }
          //   // OrderStore.setCloseOrderBottomSheet(false)
          //   // 기존 OrderMain 넘어가기 위한 로직 끝
          // }}
        >
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>
            {commaNumber(
              Math.floor(
                ItemStore.targetItemInfo.teamPurchasePrice *
                  (isInitialTeamDealLinkInvited
                    ? 1 -
                      (UserStore.linkBenefitsInfo
                        .linkReceiverFirstPurchaseDiscountRate || 0)
                    : 1),
              ),
            )}
            원
          </div>
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>
            {ItemStore.targetItemInfo.teamDealThreshold || 2}인 팀구매 열기
          </div>
        </button>
      </div>
    )
  },
)

export default BottomOrderBar
