import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'

const OrdersScreen = observer(() => {
  const history = useHistory()
  return <div>OrdersScreen</div>
})

export default OrdersScreen
