import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ImageManager from '../images/ImageManager'
import Modal from 'react-modal'

// 사진
import PocketMoneyEnvelope from '../images/PocketMoneyEnvelope.png'
import duoduoStyleEnvelope from '../images/duoduoStyleEnvelope.png'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'
import commaNumber from 'comma-number'
import ItemList from '../components/items/ItemList'
import ItemStore from '../stores/ItemStore'
import ItemScreenTitleText from '../components/texts/ItemScreenTitleText'
import ItemListComponent from '../components/items/ItemListComponent'
import OneDollarItemListComponent from '../components/items/OneDollarItemListComponent'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '88%',
    borderRadius: 25.5,
    borderColor: '#BDBDBD',
    padding: 0,
    height: 240,
    paddingTop: 18,
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // paddingLeft: 20,
    // paddingRight: 20,
    transform: 'translate(-50%, -50%)',
  },
}

const SendMoneyScreen = observer(() => {
  const history = useHistory()

  const [price, setPrice] = useState(0)
  // const [phoneNumberText, setPhoneNumberText] = useState('')
  // const [phoneNumber, setPhoneNumber] = useState('')
  // const [showModal, setShowModal] = useState(false)

  // const onChange = (e: any) => {
  //   setPhoneNumberText(e.target.value)
  // }

  useEffect(() => {
    const params = dynamicLinkUtils.parseLink(window.location.href)
    if (params?.receiverPrice) {
      setPrice(params.receiverPrice)
    }
  }, [])

  useEffect(() => {
    if (!ItemStore.initialLoadingDataAssetsDone) {
      ItemStore.initialLoadDataAssets()
    }
  }, [])

  useEffect(() => {
    ItemStore.loadSpecificItemsInfo()
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: 1,
        backgroundColor: '#FFF3E1',
      }}
    >
      <div
        style={{
          height: 48,
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '25%', marginTop: 4 }}>
          <ImageManager source='AlwayzFullLogoRed' />
        </div>
      </div>
      <div
        className='box-sizing'
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          alignItems: 'center',
          marginBottom: 24,
        }}
      >
        <div style={{ height: 40 }} />
        <div
          className='box-sizing'
          style={{
            fontSize: 24,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          올웨이즈 쇼핑 지원금 이벤트
        </div>
        <div style={{ height: 30 }} />
        <div
          className='box-sizing'
          style={{ fontSize: 20, textAlign: 'center' }}
        >
          친구가 나에게 보낸 지원금은?
        </div>
        <div style={{ height: 16 }} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          marginTop: -20,
        }}
      >
        <img src={duoduoStyleEnvelope} alt='' width={window.innerWidth - 128} />
        <div
          className='box-sizing'
          style={{
            position: 'absolute',
            top: 30,
            fontSize: 36,
            fontWeight: 'bold',
          }}
        >
          {`${commaNumber(price)} 원`}
        </div>
      </div>
      <div style={{ height: 20 }} />
      <div className='box-sizing' style={{ fontSize: 16, textAlign: 'center' }}>
        앱설치 및 로그인 하면
      </div>
      <div className='box-sizing' style={{ fontSize: 16, textAlign: 'center' }}>
        지원금을 현금으로 받을 수 있어요.
      </div>
      <div style={{ height: 20 }} />
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          width: '100%',
          display: 'flex',
          marginBottom: 40,
        }}
      >
        <button
          className='box-sizing sale-button'
          style={{
            height: 46,
            backgroundColor: '#FF3E3E',
            borderRadius: 10,
            fontSize: 18,
            fontWeight: 'bold',
            color: '#fff',
            width: window.innerWidth - 96,
            borderColor: 'yellow',
          }}
          onClick={() => {
            if (price > 0) {
              // alert('현금 받으러 가볼까요?')
              window.location.href = `https://alwayzshop.ilevit.com/app`
              return
            }
            alert('알 수 없는 오류 발생! 새로고침 후 다시 시작해주세요!')
          }}
        >
          {`${commaNumber(price)}원 현금 받으러 가기`}
        </button>
      </div>

      {ItemStore.initialLoadingDataAssetsDone && price <= 400 && (
        <div style={{ backgroundColor: 'white' }}>
          <ItemList
            categoryType='large'
            categoryId='total'
            enteringComponent='OrderEndScreenAlwayzSuggestionComponent'
          />
        </div>
        // <div style={{ height: 400 }} />
      )}
      {ItemStore.initialLoadingDataAssetsDone && price >= 400 && (
        <div>
          <div style={{ backgroundColor: '#FFE5E5' }}>
            <div>
              <div style={{ height: 40 }} />
              <div
                className='box-sizing'
                style={{
                  fontSize: 60,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                잠깐!
              </div>
              <div style={{ height: 24 }} />
              <div
                className='box-sizing'
                style={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                아래 상품들은 다 900원이에요.
              </div>
              <div style={{ height: 30 }} />
            </div>
          </div>
          <ItemScreenTitleText text='3시간만 진행되는 900원 상점!' />
          <div
            style={{
              // display: 'flex',
              // flex: 1,
              // flexDirection: 'column',
              backgroundColor: 'white',
              columnCount: 2,
              columnGap: 12,
              paddingLeft: 12,
              paddingRight: 12,
              // columnWidth: Math.floor((width - 36) / 2),
            }}
          >
            {ItemStore.specificItemsInfo.map((item) => (
              <OneDollarItemListComponent
                key={item._id}
                itemInfo={item}
                enteringComponent='SendMoneyWebScreen'
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
})

export default SendMoneyScreen
