import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ItemStore from '../../stores/ItemStore'
import UserStore from '../../stores/UserStore'
import OrderStore from '../../stores/OrderStore'
import ItemScreenHeader from '../items/ItemScreenHeader'
import heic2any from 'heic2any'
import ImageManager from '../../images/ImageManager'
import EmptyProfile from '../../images/EmptyProfile.png'
import DoubleProfileImageFilter from './DoubleProfileImageFilter'
import LinesEllipsis from 'react-lines-ellipsis'

const DoubleProfileImage = ({
  membersInfo,
  size,
  names = false,
  needsPrivacyProtection,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {membersInfo
          .slice(0, 2)
          .reverse()
          .map((member, index) => (
            <DoubleProfileImageFilter
              imageUri={member.userImageUri}
              needsPrivacyProtection={needsPrivacyProtection}
              key={member.userId}
            />
          ))}
      </div>
      {names && (
        <div
          style={{
            marginLeft: 20,
            fontSize: 14,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <LinesEllipsis
            style={{ maxWidth: 52 }}
            text={
              membersInfo[0].userName.slice(
                0,
                Math.floor((2 * membersInfo[0].userName.length) / 3),
              ) +
              membersInfo[0].userName
                .slice(Math.floor((2 * membersInfo[0].userName.length) / 3))
                .replace(/./g, '*')
            }
            maxLine='1'
            ellipsis='..'
            basedOn='letters'
          />
          {/* <div
            style={{
              display: 'block',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              maxLines: 1,
              maxWidth: 32,
              overflow: 'hidden',
            }}
          >
            {membersInfo[0].userName.slice(
              0,
              Math.floor((2 * membersInfo[0].userName.length) / 3),
            ) +
              membersInfo[0].userName
                .slice(Math.floor((2 * membersInfo[0].userName.length) / 3))
                .replace(/./g, '*')}
          </div> */}
          {membersInfo.length > 1 && (
            <span>{` 외 ${membersInfo.length - 1}명`}</span>
          )}
        </div>
      )}
    </div>
  )
}

export default DoubleProfileImage
