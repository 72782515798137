import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import configs from '../utils/configs'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'
import UserStore from '../stores/UserStore'
import OrderStore from '../stores/OrderStore'
import ItemSummaryComponent from '../components/items/ItemSummaryComponent'
import TeamGatherComponent from '../components/social/TeamGatherComponent'
import BasicHeader from '../components/header/BasicHeader'
import ImageManager from '../images/ImageManager'
import TeamMembersComponent from '../components/social/TeamMembersComponent'
import copyToClipboard from '../utils/clipboardUtils'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import TeamPurchaseComponent from '../components/social/TeamPurchaseComponent'
import AppDownloadButton from '../components/buttons/AppDownloadButton'
import ItemStore from '../stores/ItemStore'
import ItemList from '../components/items/ItemList'

const OrderEndScreen = observer(() => {
  const kakao = window.Kakao
  const [authObj, setAuthObj] = useState({})
  const [error, setError] = useState({})
  const [showModal, setShowModal] = useState(true)
  // const [itemShareLink, setItemShareLink] = useState('')

  const history = useHistory()
  const location = useLocation()
  const notify = (dynamicLinkParams) => {
    toast('링크가 클립보드에 복사되었어요.', {
      autoClose: 1500,
      closeButton: false,
    })
    UserStore.uploadUserLinkUsage(
      window.location.href,
      dynamicLinkParams,
      'generated',
      'Web Order End Screen',
    )
  }
  useEffect(() => {
    const unblock = history.block((location, action) => {
      console.log('#### history block', location, action)
      if (action === 'POP') {
        return false
      }
      return true
      // if (isBlock && action === 'POP') {
      //   console.log('#### blocked ####');
      //   return false;
      // }
    })
    return () => {
      unblock()
    }
  }, [])
  if (!location.state) {
    history.push('/')
    return <></>
  }
  const { teamGatheringOrders, teamJoinedOrders, individualOrders } =
    location.state

  useEffect(() => {
    const firstOrder =
      (teamGatheringOrders && teamGatheringOrders[0]) ||
      (teamJoinedOrders && teamJoinedOrders[0]) ||
      (individualOrders && individualOrders[0])
    if (firstOrder) {
      UserStore.setDynamicLinkParams({
        itemId: firstOrder?.itemInfo.itemId,
        teamDealId: firstOrder?.teamDealId || '',
        invitorId: firstOrder?.itemInfo.dynamicLinkParams?.invitorId || '',
      })
    }
  }, [])
  useEffect(() => {
    if (teamGatheringOrders) {
      OrderStore.setSharingOrdersInfo(teamGatheringOrders)
      OrderStore.setOrderIdsToShareToAlwayz(
        teamGatheringOrders.map((order) => order._id),
      )
    }
    UserStore.setScreenName('OrderEndScreen_Web')
  }, [])

  useEffect(() => {
    if (!ItemStore.initialLoadingDataAssetsDone) {
      ItemStore.initialLoadDataAssets()
    }
  }, [])

  const styles = {
    buttonContainer: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
    },
    redButton: {
      width: '100%',
      height: 40,
      border: 'solid',
      borderRadius: 4,
      borderWidth: 1,
      backgroundColor: '#EA3F49',
      color: '#FFF',
      borderColor: '#EA3F49',
      fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    whiteButton: {
      width: '100%',
      height: 40,
      border: 'solid',
      borderRadius: 4,
      borderWidth: 1,
      backgroundColor: '#FFF',
      color: '#EA3F49',
      borderColor: '#EA3F49',
      fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    totalContainer: {
      // width: '100wh',
      // height: '100vh',
      // display: 'flex',
      // flexDirection: 'column',
    },
  }
  //   const AppDownloadButton = () => {
  //     return (
  //       <button
  //         style={styles.whiteButton}
  //         type='button'
  //         onClick={async () => {
  //           const { itemId, teamDealId, invitorId, linkType } =
  //             UserStore.dynamicLinkParams
  //           UserStore.setIsLoading(true)
  //           const link = await dynamicLinkUtils.createAppDownloadLink(
  //             itemId || '',
  //             teamDealId || '',
  //             invitorId || '',
  //             linkType || '',
  //             '',
  //             '',
  //             '',
  //             'OrderEndScreen',
  //           )
  //           UserStore.setIsLoading(false)
  //           if (link) {
  //             console.log(`link: ${link}`)
  //             window.location.href = link
  //           }
  //         }}
  //       >
  //         <div style={{ paddingRight: 4 }}>앱으로 더 많은 상품 보기</div>
  //         <ImageManager source='NextRed' size={16} />
  //       </button>
  //     )
  //   }

  //   const TeamPurchaseComponent = ({ item }) => {
  //     if (
  //       item.teamDealInfo.teamDealThreshold ===
  //       item.teamDealInfo.membersInfo.length
  //     ) {
  //       return (
  //         <div>
  //           <ItemSummaryComponent
  //             imageUri={item.itemInfo.itemImageUri}
  //             mainText={item.itemInfo.itemTitle}
  //             text1={item.itemInfo.optionsInfo.optionNames
  //               .map((value, index) => {
  //                 const optionDetail =
  //                   item.itemInfo.optionsInfo.totalOptions[index][
  //                     item.selectedOption[index]
  //                   ].name
  //                 return `${value} : ${optionDetail}`
  //               })
  //               .join(', ')}
  //             text2={`주문 수량 : ${item.quantity}개`}
  //             Price={item.totalPrice + item.shippingFee}
  //             itemId={item.itemInfo.itemId}
  //             itemInfo={{
  //               ...item.itemInfo,
  //               _id: item.itemInfo.itemId,
  //               mainImageUris: [item.itemInfo.itemImageUri],
  //             }}
  //           />
  //           <div
  //             style={{
  //               height: 0.5,
  //               backgroundColor: '#E0E0E0',
  //               marginLeft: 16,
  //               marginRight: 16,
  //             }}
  //           />
  //           <div
  //             style={{
  //               display: 'flex',
  //               flexDirection: 'column',
  //               alignItems: 'center',
  //               paddingTop: 16,
  //               paddingBottom: 16,
  //             }}
  //           >
  //             <div
  //               style={{
  //                 fontSize: 18,
  //                 fontWeight: 'bold',
  //                 paddingBottom: 12,
  //                 display: 'flex',
  //                 flexDirection: 'row',
  //                 alignItems: 'center',
  //               }}
  //             >
  //               <ImageManager source='WelcomeOne' size={32} />
  //               <div style={{ marginLeft: 24, marginRight: 24 }}>
  //                 팀구매 성공!
  //               </div>
  //               <ImageManager source='WelcomeTwo' size={32} />
  //             </div>
  //             <TeamMembersComponent correspondingOrder={item} />
  //             <div
  //               style={{ color: '#333333', fontSize: 16, textAlign: 'center' }}
  //             >
  //               상품 배송이 시작되었어요! <br />
  //               배송 현황은 앱에서 확인 가능해요.
  //             </div>
  //           </div>
  //           <div
  //             style={{
  //               height: 0.5,
  //               backgroundColor: '#E0E0E0',
  //               marginLeft: 16,
  //               marginRight: 16,
  //             }}
  //           />
  //           <div style={styles.buttonContainer}>
  //             <AppDownloadButton />
  //           </div>
  //         </div>
  //       )
  //     }
  //     return (
  //       <div>
  //         <ItemSummaryComponent
  //           imageUri={item.itemInfo.itemImageUri}
  //           mainText={item.itemInfo.itemTitle}
  //           text1={item.itemInfo.optionsInfo.optionNames
  //             .map((value, index) => {
  //               const optionDetail =
  //                 item.itemInfo.optionsInfo.totalOptions[index][
  //                   item.selectedOption[index]
  //                 ].name
  //               return `${value} : ${optionDetail}`
  //             })
  //             .join(', ')}
  //           text2={`주문 수량 : ${item.quantity}개`}
  //           Price={item.totalPrice + item.shippingFee}
  //           itemId={item.itemInfo.itemId}
  //           itemInfo={{
  //             ...item.itemInfo,
  //             _id: item.itemInfo.itemId,
  //             mainImageUris: [item.itemInfo.itemImageUri],
  //           }}
  //         />
  //         <div
  //           style={{
  //             height: 0.5,
  //             backgroundColor: '#E0E0E0',
  //             marginLeft: 16,
  //             marginRight: 16,
  //           }}
  //         />
  //         <TeamGatherComponent
  //           timeStamp={
  //             new Date(
  //               item.teamDealInfo.restartedAt || item.teamDealInfo.createdAt,
  //             )
  //           }
  //           orderId={item._id}
  //           isAutoMatching={item.isAutoMatching}
  //           isFinishedGathering={item.isFinishedGathering}
  //         />
  //         <div
  //           style={{
  //             height: 0.5,
  //             backgroundColor: '#E0E0E0',
  //             marginLeft: 16,
  //             marginRight: 16,
  //           }}
  //         />

  //         {item.isFinishedGathering ? (
  //           <div style={styles.buttonContainer}>
  //             <button
  //               style={styles.redButton}
  //               type='button'
  //               onClick={async () => {
  //                 const soldOutResult = await backendApis.checkItemSoldOut(
  //                   item.itemInfo.itemId,
  //                 )
  //                 if (
  //                   soldOutResult.status === 2000 &&
  //                   soldOutResult.data.soldOut
  //                 ) {
  //                   alert(
  //                     '안녕하세요 고객님, 해당 상품 주문량이 많아 품절되었습니다. 결제를 취소하시거나, 재입고까지 기다리실 수 있습니다.',
  //                   )
  //                 }
  //                 const result = await backendApis.restartEndedTeamDeal(
  //                   item.teamDealId,
  //                   item._id,
  //                 )

  //                 if (result.status === 200) {
  //                   OrderStore.setLoadedUserOrdersList(
  //                     OrderStore.loadedUserOrdersList.map((order) => {
  //                       if (order._id === item._id) {
  //                         console.log(`matched order! ${order._id}`)
  //                         return {
  //                           ...order,
  //                           isFinishedGathering: false,
  //                           teamDealInfo: {
  //                             ...order.teamDealInfo,
  //                             restartedAt: new Date(),
  //                           },
  //                           // teamGatheringStartedAt: new Date(),
  //                         }
  //                       }
  //                       return order
  //                     }),
  //                   )
  //                   OrderStore.setSharingOrdersInfo([item])
  //                   OrderStore.setOrderIdsToShareToAlwayz([item._id])
  //                   OrderStore.setCreatedTeamDealIdsToShare([item.teamDealId])
  //                   setShowModal(true)
  //                 }
  //               }}
  //             >
  //               팀 모집 재도전하기
  //             </button>
  //             <AppDownloadButton />
  //           </div>
  //         ) : (
  //           <div style={styles.buttonContainer}>
  //             <button
  //               style={styles.redButton}
  //               type='button'
  //               onClick={async () => {
  //                 notify()
  //                 OrderStore.setSharingOrdersInfo([item])
  //                 setShowModal(true)
  //                 const { itemInfo, teamDealId } = item
  //                 const { itemId, itemTitle, itemImageUri, teamPurchasePrice } =
  //                   itemInfo
  //                 const link = await dynamicLinkUtils.createDynamicItemLink(
  //                   itemId,
  //                   teamDealId,
  //                   'dynamicLink',
  //                   `${itemTitle} ${teamPurchasePrice}원!`,
  //                   `${UserStore.userInfo.userName}님과 팀으로 구매하면 무료배송?! 배송지도 따로, 옵션도 따로!`,
  //                   itemImageUri,
  //                 )
  //                 const message = `${UserStore.userInfo.userName}님과 함께 구매하면 ${itemTitle} ${teamPurchasePrice}원!
  // ${link}`
  //                 copyToClipboard(message)
  //               }}
  //             >
  //               팀구매 친구 모집하기
  //             </button>
  //             <div style={{ height: 12 }} />
  //             <AppDownloadButton />
  //           </div>
  //         )}
  //       </div>
  //     )
  //   }
  if (teamGatheringOrders) {
    return (
      <div style={styles.totalContainer}>
        <ToastContainer
          style={{ color: '#333333' }}
          position='top-center'
          autoClose={1500}
          hideProgressBar
          transition={Zoom}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          closeButton={false}
        />
        <div style={{ height: 56 }} />
        {teamGatheringOrders.map((item) => (
          <TeamPurchaseComponent item={item} key={item._id} notify={notify} />
        ))}
        {ItemStore.initialLoadingDataAssetsDone && (
          <ItemList
            categoryType='large'
            categoryId='total'
            enteringComponent='OrderEndScreenAlwayzSuggestionComponent'
          />
        )}
        <BasicHeader title='팀구매' />
      </div>
    )
  }
  if (teamJoinedOrders) {
    return (
      <div style={styles.totalContainer}>
        <ToastContainer
          style={{ color: '#333333' }}
          position='top-center'
          autoClose={1500}
          hideProgressBar
          transition={Zoom}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          closeButton={false}
        />
        <div style={{ height: 56 }} />
        {teamJoinedOrders.map((item) => (
          <TeamPurchaseComponent item={item} key={item._id} notify={notify} />
        ))}
        {ItemStore.initialLoadingDataAssetsDone && (
          <ItemList
            categoryType='large'
            categoryId='total'
            enteringComponent='OrderEndScreenAlwayzSuggestionComponent'
          />
        )}
        <BasicHeader title='팀구매' />
      </div>
    )
  }
  if (individualOrders) {
    return (
      <div style={styles.totalContainer}>
        <div style={{ height: 56 }} />
        {individualOrders.map((item) => (
          <div key={item._id}>
            <ItemSummaryComponent
              imageUri={item.itemInfo.itemImageUri}
              mainText={item.itemInfo.itemTitle}
              text1={item.itemInfo.optionsInfo.optionNames
                .map((value, index) => {
                  const optionDetail =
                    item.itemInfo.optionsInfo.totalOptions[index][
                      item.selectedOption[index]
                    ].name
                  return `${value} : ${optionDetail}`
                })
                .join(', ')}
              text2={`주문 수량 : ${item.quantity}개`}
              Price={item.totalPrice + item.shippingFee}
              itemId={item.itemInfo.itemId}
              itemInfo={{
                ...item.itemInfo,
                _id: item.itemInfo.itemId,
                mainImageUris: [item.itemInfo.itemImageUri],
              }}
            />
          </div>
        ))}
        <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
        <div style={styles.buttonContainer}>
          <AppDownloadButton itemId={individualOrders[0]?.itemInfo.itemId} />
        </div>
        {ItemStore.initialLoadingDataAssetsDone && (
          <ItemList
            categoryType='large'
            categoryId='total'
            enteringComponent='OrderEndScreenAlwayzSuggestionComponent'
          />
        )}
        <BasicHeader title='구매 완료' />
      </div>
    )
  }
})

export default OrderEndScreen
