import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'
import BottomOrderBar from './BottomOrderBar'
import BottomJoinTeamOrderBar from './BottomJoinTeamOrderBar'
import OrderStore from '../../stores/OrderStore'
import TeamDealFailedModal from '../modal/TeamDealFailedModal'
import { set } from 'mobx'
import BottomOrderBarForTenThoushandsDeal from './BottomOrderBarForTenThoushandsDeal'

const BottomOrderBarWrapper = observer(
  ({
    itemId,
    isEventDeal = false,
    teamDealId,
    invitorId,
    isFromFeed,
    needsPrivacyProtection,
    soldOut,
    // isLinkFromApp,
    // setIsLinkRewardTarget,
    invitedOrderId,
    linkInvitorId,
    isInitialTeamDealLinkInvited,
    isLinkCheckDone,
    notify,
    onClickFunc,
  }) => {
    // console.log(`team deal id: ${teamDealId}`)
    const [teamDealLoaded, setTeamDealLoaded] = useState(false)
    const [teamDealInfo, setTeamDealInfo] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [showSoldOutModal, setShowSoldOutModal] = useState(false)
    // const [isInitialTeamDealLinkInvited, setIsInitialTeamDealLinkInvited] =
    //   useState(false)
    // const [invitedOrderId, setInvitedOrderId] = useState('')
    const notifyEndedTeamDeal = () => {
      // if (UserStore.testGroup?.endedTeamDealNotify === 'A') {
      //   setShowModal(true)
      // } else if (UserStore.testGroup?.endedTeamDealNotify === 'B') {
      console.log(`toast notify`)
      notify(
        <div
          style={{
            color: '#999999',
            marginRight: 16,
            marginLeft: 16,
            // marginBottom: 32,
            textAlign: 'center',
          }}
        >
          들어오신 링크의 팀구매는 <br />
          이미 성사되었거나 만료되었어요 <br />새 팀구매를 열어보세요.
        </div>,
        5000,
      )
      // }
    }
    const loadTeamDealInfo = async () => {
      if (!teamDealId) {
        setTeamDealLoaded(true)
      } else {
        console.log(`invitor id: ${invitorId}, ${UserStore.userInfo._id}`)
        if (invitorId === UserStore.userInfo._id && UserStore.userInfo._id) {
          setTeamDealLoaded(true)
          return
        }
        // if (
        //   isLinkFromApp &&
        //   OrderStore.loadedUserOrdersList.filter(
        //     (order) => order.status !== 'pre-pay',
        //   ).length === 0
        // ) {
        //   setIsInitialTeamDealLinkInvited(true)
        //   setIsLinkRewardTarget(true)
        //   console.log('!!!!!Set Is Link Reward Target is Done')
        // }
        const result = await backendApis.getTeamDealMembersInfo(teamDealId)
        if (result?.status === 200 && result.data) {
          // setInvitedOrderId(result.data.orderId)
          // if (
          //   result.data.orderId &&
          //   teamDealId &&
          //   invitorId &&
          //   OrderStore.loadedUserOrdersList.filter(
          //     (order) => order.status !== 'pre-pay',
          //   ).length === 0
          // ) {
          //   setIsInitialTeamDealLinkInvited(true)
          //   setIsLinkRewardTarget(true)
          // }
          if (
            new Date(result.data.restartedAt || result.data.createdAt) <
              new Date(new Date().getTime() - 1000 * 60 * 60 * 24) ||
            result.data.teamCompleted ||
            result.data.deletedAt
          ) {
            if (isFromFeed) {
              setTeamDealLoaded(true)
              return
            }
            if (result.data.teamCompleted || result.data.deletedAt) {
              notifyEndedTeamDeal()
              // setShowModal(true)
              // alert(
              //   '해당 팀구매는 이미 성사되었거나 취소되었어요. 새 팀구매를 시작해보세요!',
              // )
            } else {
              notifyEndedTeamDeal()
              // setShowModal(true)
              // alert(
              //   '해당 팀구매는 24시간이 만료되어 일시적으로 참여할 수 없습니다. 새 팀구매를 시작해보세요!',
              // )
            }
            UserStore.setIsLateComer(true)
            UserStore.setTestGroup({
              ...UserStore.testGroup,
              isLateComer: 'true',
            })
            setTeamDealLoaded(true)
            return
          }
          if (
            ['612e60c4ce136a1f4454c8d7', '6199ff28d7e71638ec75dba9'].includes(
              itemId,
            )
          ) {
            OrderStore.setInvitedTeamDealInfo(result.data)
          }

          setTeamDealInfo(result.data)
          setTeamDealLoaded(true)
        } else {
          alert(
            '해당 팀구매 정보를 불러오는데에 실패했어요! 잠시 후 다시 시도해주세요.',
          )
          setTeamDealLoaded(true)
          // setIsLinkRewardTarget(false)
        }
      }
    }
    useEffect(() => {
      console.log(`team deal load useeffect activated`)
      setTeamDealLoaded(false)
      // setIsInitialTeamDealLinkInvited(false)
      // setIsLinkRewardTarget(false)
      loadTeamDealInfo()
    }, [UserStore.userInfo._id])

    // useEffect(() => {
    //   if (
    //     OrderStore.finishedLoadingUserOrders &&
    //     OrderStore.loadedUserOrdersList.filter(
    //       (order) => order.status !== 'pre-pay',
    //     ).length > 0
    //   ) {
    //     console.log(`canceled initial team deal link invited!`)
    //     setIsInitialTeamDealLinkInvited(false)
    //     setIsLinkRewardTarget(false)
    //   }
    // }, [OrderStore.finishedLoadingUserOrders])

    if (isEventDeal) {
      return (
        <>
          <TeamDealFailedModal
            showModal={showModal}
            setShowModal={setShowModal}
            soldOut={soldOut}
            invitorId={invitorId}
            isInitialTeamDealLinkInvited={isInitialTeamDealLinkInvited}
            invitedOrderId={invitedOrderId}
            showSoldOutModal={showSoldOutModal}
            setShowSoldOutModal={setShowSoldOutModal}
          />
          <BottomOrderBarForTenThoushandsDeal
            isEventDeal={isEventDeal}
            item={ItemStore.targetItemInfo}
            soldOut={soldOut}
            invitorId={invitorId}
            isInitialTeamDealLinkInvited={isInitialTeamDealLinkInvited}
            invitedOrderId={invitedOrderId}
            showSoldOutModal={showSoldOutModal}
            setShowSoldOutModal={setShowSoldOutModal}
            // onPress={() => {
            //   OrderStore.setItemInfo({
            //     itemId,
            //     itemName: ItemStore.targetItemInfo.itemTitle,
            //     optionsInfo: ItemStore.targetItemInfo.optionsInfo,
            //   })
            // }}
          />
        </>
      )
    }
    if (!teamDealLoaded || !ItemStore.targetItemInfo.detailImageUris) {
      return <></>
    }
    if (!teamDealInfo) {
      return (
        <>
          <TeamDealFailedModal
            showModal={showModal}
            setShowModal={setShowModal}
            soldOut={soldOut}
            invitorId={invitorId}
            isInitialTeamDealLinkInvited={isInitialTeamDealLinkInvited}
            invitedOrderId={invitedOrderId}
            showSoldOutModal={showSoldOutModal}
            setShowSoldOutModal={setShowSoldOutModal}
          />
          <BottomOrderBar
            item={ItemStore.targetItemInfo}
            soldOut={soldOut}
            invitorId={invitorId}
            isInitialTeamDealLinkInvited={isInitialTeamDealLinkInvited}
            invitedOrderId={invitedOrderId}
            showSoldOutModal={showSoldOutModal}
            setShowSoldOutModal={setShowSoldOutModal}
            onClickFunc={onClickFunc}
            // onPress={() => {
            //   OrderStore.setItemInfo({
            //     itemId,
            //     itemName: ItemStore.targetItemInfo.itemTitle,
            //     optionsInfo: ItemStore.targetItemInfo.optionsInfo,
            //   })
            // }}
          />
        </>
      )
    }
    return (
      <BottomJoinTeamOrderBar
        teamDealId={teamDealId}
        setTeamDealInfo={setTeamDealInfo}
        invitorId={invitorId}
        teamMembersInfo={teamDealInfo.membersInfo}
        teamDealTimeStamp={
          new Date(teamDealInfo.restartedAt || teamDealInfo.createdAt)
        }
        needsPrivacyProtection={needsPrivacyProtection}
        teamDealThreshold={teamDealInfo.teamDealThreshold || 2}
        soldOut={soldOut}
        isInitialTeamDealLinkInvited={isInitialTeamDealLinkInvited}
        invitedOrderId={invitedOrderId}
      />
    )
  },
)

export default BottomOrderBarWrapper
