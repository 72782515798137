const Layout = ({ children }) => (
  <section
    style={{
      position: 'relative',
      zIndex: 1,
      width: '100vw',
      height: '100vh',
      display: '',
      flexDirection: 'column',
      //   justifyContent: "space-between",
      overflow: 'hidden',
      //   opacity: isLoading ? 0 : 1,
      transition: 'opacity 0.3s ease',
      //   backgroundColor:'red'
    }}
  >
    {children}
  </section>
)

export default Layout
