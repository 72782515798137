import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'

const TitleBodyWithDivider = ({ title, body }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 16,
        marginRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
        border: 'solid',
        borderColor: '#E0E0E0',
        borderWidth: 0,
        borderBlockColor: '#E0E0E0',
        borderTopWidth: 0.5,
        fontSize: 14,
      }}
    >
      <div style={{ width: 96, marginRight: 12 }}>{title}</div>
      <div style={{ flex: 1, color: '#9E9E9E', whiteSpace: 'pre-line' }}>
        {body}
      </div>
    </div>
  )
}

export default TitleBodyWithDivider
