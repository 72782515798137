import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'
import UserStore from '../../stores/UserStore'
import ImageManager from '../../images/ImageManager'
import AppDownloadMessage from '../common/AppDownloadMessage'

// ItemScreenHeader로 부터 가져옴

const ChallengeScreenHeader = observer(() => {
  const style = {
    container: {
      // position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      // height: 72,
      backgroundColor: '#FFF',
    },
  }
  return (
    <div style={{ backgroundColor: '#FFFFFF' }}>
      <div style={style.container}>
        <div
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <button
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              backgroundColor: '#FFF',
              padding: 0,
              fontSize: 16,
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            type='button'
            onClick={async () => {
              const { itemId, teamDealId, invitorId, linkType } =
                UserStore.dynamicLinkParams
              UserStore.setIsLoading(true)
              const link = await dynamicLinkUtils.createAppDownloadLink(
                itemId || '',
                teamDealId || '',
                invitorId || '',
                linkType || '',
                '',
                '',
                '',
                'ItemScreenWeb',
                UserStore.dynamicLinkParams.isLinkFromApp,
                UserStore.dynamicLinkParams.bottomSheetTimeOut,
                UserStore.isLateComer,
                UserStore.testGroup?.endedTeamDealNotify,
                UserStore.testGroup?.ableToPurchaseViralItem,
                UserStore.testGroup?.reviewPosition,
                UserStore.testGroup?.teamMemberShowing,
                UserStore.testGroup?.appDownloadFullScreen,
              )
              UserStore.setIsLoading(false)
              if (link) {
                window.location.href = link
                // await copyToClipboard(link)
              }
            }}
          >
            <AppDownloadMessage small />
            <div
              style={{
                border: 'solid',
                width: 100,
                height: 32,
                borderRadius: 4,
                borderWidth: 1.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: '#EA3F49',
                color: '#EA3F49',
                // fontWeight: 'normal',
                fontSize: 14,
                backgroundColor: '#FFF',
              }}
            >
              <div style={{ marginLeft: 8, marginRight: 2 }} />
              <ImageManager source='NextRed' size={14} />
            </div>
          </button>
        </div>
        <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
      </div>
    </div>
  )
})

export default ChallengeScreenHeader
