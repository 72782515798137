import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import WhiteTextTimer from '../texts/WhiteTextTimer'
import OrderStore from '../../stores/OrderStore'
import UserStore from '../../stores/UserStore'
import ImageManager from '../../images/ImageManager'
import ProfileImageWithNameFilter from '../social/ProfileImageWithNameFilter'
import SoldOutModal from '../modal/SoldOutModal'
import LinesEllipsis from 'react-lines-ellipsis'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'

const BottomJoinTeamOrderBar = observer(
  ({
    teamMembersInfo,
    teamDealId,
    setTeamDealInfo,
    teamDealTimeStamp,
    invitorId,
    teamDealThreshold,
    needsPrivacyProtection,
    soldOut,
    isInitialTeamDealLinkInvited,
    invitedOrderId,
  }) => {
    const [showSoldOutModal, setShowSoldOutModal] = useState(false)
    const [ableToPressHeart, setAbleToPressHeart] = useState(true)
    const [invitorName, setInvitorName] = useState('')
    useEffect(() => {
      if (
        UserStore.userInfo.friendIds?.includes(invitorId) &&
        invitorId &&
        !needsPrivacyProtection
      ) {
        setInvitorName(
          teamMembersInfo.find((memberInfo) => memberInfo.userId === invitorId)
            .userName,
        )
      } else {
        let userName = teamMembersInfo.find(
          (memberInfo) => memberInfo.userId === invitorId,
        ).userName
        userName =
          userName.slice(0, Math.floor((2 * userName.length) / 3)) +
          userName
            .slice(Math.floor((2 * userName.length) / 3))
            .replace(/./g, '*')
        setInvitorName(userName)
      }
    }, [UserStore.userInfo.friendIds, needsPrivacyProtection])

    return (
      <div
        style={{
          height: 64,
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
        }}
      >
        <SoldOutModal
          showModal={showSoldOutModal}
          setShowModal={setShowSoldOutModal}
        />
        <button
          type='button'
          onClick={() => {
            alert('찜하기 기능은 앱 다운로드 후 이용 가능합니다.')
          }}
          style={{
            backgroundColor: '#FFF',
            borderColor: '#FFF',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#999999',
            padding: 16,
          }}
        >
          <ImageManager source='Heart' size={24} />
          찜하기
        </button>
        <button
          type='button'
          style={{
            borderColor: '#ea3f49',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'row',
            color: '#FFF',
            flex: 1,
            alignItems: 'center',
            backgroundColor: '#ea3f49',
            justifyContent: 'flex-start',
            marginRight: 16,
            marginTop: 8,
            marginBottom: 8,
            borderRadius: 4,
          }}
          onClick={async () => {
            // 스토어 링크로 타고 넘어가는 로직
            const { itemId, teamDealId, invitorId, linkType } =
              UserStore.dynamicLinkParams
            UserStore.setIsLoading(true)
            const link = await dynamicLinkUtils.createAppDownloadLink(
              itemId || '',
              teamDealId || '',
              invitorId || '',
              linkType || '',
              '',
              '',
              '',
              'ItemScreenWeb',
              UserStore.dynamicLinkParams.isLinkFromApp,
              UserStore.dynamicLinkParams.bottomSheetTimeOut,
              UserStore.isLateComer,
              UserStore.testGroup?.endedTeamDealNotify,
              UserStore.testGroup?.ableToPurchaseViralItem,
              UserStore.testGroup?.reviewPosition,
              UserStore.testGroup?.teamMemberShowing,
              UserStore.testGroup?.appDownloadFullScreen,
            )
            UserStore.setIsLoading(false)
            if (link) {
              window.location.href = link
              // await copyToClipboard(link)
            }
            // onPressJoinTeamDeal()
            // 스토어로 넘어가는 로직 끝

            // 기존 웹결제 로직
            // if (soldOut) {
            //   // alert(
            //   //   '해당 상품은 현재 품절되어서 주문이 불가능합니다. 더 많은 상품들을 보시려면 앱에서 확인해주세요!',
            //   // )
            //   setShowSoldOutModal(true)
            //   return
            // }
            // if (!ItemStore.targetItemInfo.detailImageUris) {
            //   return
            // }
            // if (UserStore.uploadingUserLinkUsage) {
            //   return
            // }
            // const isParticipatedTeamDeal = teamMembersInfo.find(
            //   (member) => member.userId === UserStore.userInfo._id,
            // )
            // if (isParticipatedTeamDeal) {
            //   // checked
            //   alert('이미 참여한 팀에는 다시 참여할 수 없어요.')
            //   return
            // }
            // // const existingOrder = OrderStore.loadedUserOrdersList
            // //   .filter((order) =>
            // //     ['team-gathering', 'pre-shipping', 'shipping'].includes(
            // //       order.status,
            // //     ),
            // //   )
            // //   .find(
            // //     (order) => order.itemInfo.itemId === ItemStore.targetItemInfo._id,
            // //   )
            // // if (existingOrder) {
            // //   // checked
            // //   alert(
            // //     '해당 상품은 동시에 여러 번 주문할 수 없어요. 배송 완료 후 주문 가능합니다.',
            // //   )
            // //   return
            // // }
            // // OrderStore.setSelectedOption({})
            // // OrderStore.setItemInfo({
            // //   itemId: ItemStore.targetItemInfo._id,
            // //   itemTitle: ItemStore.targetItemInfo.itemTitle,
            // //   optionsInfo: ItemStore.targetItemInfo.optionsInfo,
            // //   itemImageUri:
            // //     ItemStore.targetItemInfo.mainImageUris &&
            // //     ItemStore.targetItemInfo.mainImageUris[0],
            // // })
            // // OrderStore.setIsTeamDeal(true)
            // // OrderStore.setCloseOrderBottomSheet(false)
            // let sellerId = ItemStore.targetItemInfo.sellerInfo.sellerId
            // let optionsInfo = ItemStore.targetItemInfo.optionsInfo
            // if (
            //   ItemStore.targetItemInfo.sellerInfo.sharedSellerIds?.length > 0
            // ) {
            //   const randomNumber = Math.random()
            //   if (
            //     randomNumber > ItemStore.targetItemInfo.sellerInfo.orderRatio
            //   ) {
            //     const randomInt = Math.floor(
            //       Math.random() *
            //         ItemStore.targetItemInfo.sellerInfo.sharedSellerIds?.length,
            //     )
            //     sellerId =
            //       ItemStore.targetItemInfo.sellerInfo.sharedSellerIds[randomInt]
            //     optionsInfo = parseViralItemOptions(optionsInfo, randomInt)
            //   }
            // }
            // OrderStore.resetItemInfo()
            // OrderStore.setItemInfoByIndex(
            //   {
            //     itemId: ItemStore.targetItemInfo._id,
            //     itemTitle: ItemStore.targetItemInfo.itemTitle,
            //     optionsInfo,
            //     itemImageUri:
            //       ItemStore.targetItemInfo.mainImageUris &&
            //       ItemStore.targetItemInfo.mainImageUris[0],
            //     isTaxFree: !!ItemStore.targetItemInfo.isTaxFree,
            //     shippingInfo: ItemStore.targetItemInfo.shippingInfo,
            //     teamPurchasePrice: ItemStore.targetItemInfo.teamPurchasePrice,
            //     individualPurchasePrice:
            //       ItemStore.targetItemInfo.individualPurchasePrice,
            //     purchaseLimits: ItemStore.targetItemInfo.purchaseLimits,
            //     teamDealThreshold: teamDealThreshold || 2,
            //     sellerId,
            //     invitorId,
            //     invitedOrderId,
            //     dynamicLinkParams: {
            //       ...UserStore.dynamicLinkParams,
            //       ...UserStore.testGroup,
            //     },
            //     isViralItem: ItemStore.targetItemInfo.isViralItem,
            //     isUnrestartableItem:
            //       ItemStore.targetItemInfo.isUnrestartableItem,
            //     isLotteryItem: ItemStore.targetItemInfo?.isLotteryItem,
            //     isCouponExchangeItem:
            //       ItemStore.targetItemInfo?.isCouponExchangeItem,
            //     isMartItem: ItemStore.targetItemInfo?.isMartItem,
            //     characteristicItem:
            //       ItemStore.targetItemInfo?.characteristicItem,

            //     source: 'Web',
            //   },
            //   OrderStore.currentItemIndex,
            // )
            // OrderStore.setItemIsTeamDealByIndex(
            //   true,
            //   OrderStore.currentItemIndex,
            // )
            // OrderStore.setItemTeamDealIdByIndex(
            //   teamDealId,
            //   OrderStore.currentItemIndex,
            // )
            // OrderStore.setItemQuantityByIndex(
            //   OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //     ?.quantity || 1,
            //   OrderStore.currentItemIndex,
            // )
            // if (isInitialTeamDealLinkInvited) {
            //   OrderStore.setItemIsInitialTeamDealLinkInvited(
            //     isInitialTeamDealLinkInvited,
            //     OrderStore.currentItemIndex,
            //   )
            // }
            // OrderStore.setCloseOrderBottomSheet(false)
            // 기존 웹결제 로직 끝
          }}
        >
          <ProfileImageWithNameFilter
            imageUri={
              teamMembersInfo.find(
                (memberInfo) => memberInfo.userId === invitorId,
              )?.userImageUri || teamMembersInfo[0].userImageUri
            }
            size={32}
            needsPrivacyProtection={needsPrivacyProtection}
          />
          {/* <img
          alt=''
          src={
            teamMembersInfo.find(
              (memberInfo) => memberInfo.userId === invitorId,
            )?.userImageUri || teamMembersInfo[0].userImageUri
          }
          style={{
            width: 32,
            height: 32,
            borderRadius: 50,
            filter: `blur(${needsPrivacyProtection ? 8 : 0}px)`,
          }}
        /> */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginLeft: 8,
              fontSize: 14,
              fontWeight: 'bold',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <LinesEllipsis
                style={{ maxWidth: 52 }}
                text={
                  teamMembersInfo.find(
                    (memberInfo) => memberInfo.userId === invitorId,
                  )?.userName || teamMembersInfo[0].userName
                }
                maxLine='1'
                ellipsis='..'
                basedOn='letters'
              />
              님과 함께 팀구매(
              {`${teamMembersInfo.length}/${teamDealThreshold}명`})
            </div>
            <div>
              <WhiteTextTimer
                timeStamp={teamDealTimeStamp}
                onTimeEnd={() => {
                  setTeamDealInfo(null)
                }}
              />
            </div>
          </div>
        </button>
      </div>
    )
  },
)

export default BottomJoinTeamOrderBar
