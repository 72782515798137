import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'
import OrderStore from '../../stores/OrderStore'
import ItemStore from '../../stores/ItemStore'
import UserStore from '../../stores/UserStore'
import ImageManager from '../../images/ImageManager'
import AppDownloadMessage from '../common/AppDownloadMessage'
import TeamMembersComponentItemScreen from '../social/TeamMembersComponentItemScreen'

// import copyToClipboard from '../../utils/clipboardUtils'

// function copyToClipboard(textToCopy) {
//   // navigator clipboard api needs a secure context (https)
//   if (navigator.clipboard && window.isSecureContext) {
//     // navigator clipboard api method'
//     return navigator.clipboard.writeText(textToCopy)
//   }
//   // text area method
//   const textArea = document.createElement('textarea')
//   textArea.value = textToCopy
//   // make the textarea out of viewport
//   textArea.style.position = 'fixed'
//   textArea.style.left = '-999999px'
//   textArea.style.top = '-999999px'
//   document.body.appendChild(textArea)
//   textArea.focus()
//   textArea.select()
//   try {
//     const successful = document.execCommand('copy')
//     const msg = successful ? 'successful' : 'unsuccessful'
//     console.log(`msg: ${msg}`)
//   } catch (err) {
//     console.log('Was not possible to copy te text: ', err)
//   }
// }

const TeamMemberHeader = observer(() => {
  const style = {
    container: {
      // height: 72,
      backgroundColor: '#FFF',
    },
  }
  return (
    <div style={{ backgroundColor: '#FFFFFF' }}>
      <div style={style.container}>
        <div
          style={{
            paddingTop: 20,
            paddingBottom: 16,
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: '#FAFAFA',
            color: '#333333',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 18,
              fontWeight: 'bold',
            }}
          >
            <div>올웨이즈 팀구매에 초대됐어요! 🎁</div>
          </div>
          <div style={{ height: 8 }} />
          {OrderStore.invitedTeamDealInfo?.membersInfo?.length > 0 && (
            <div>
              <TeamMembersComponentItemScreen
                teamDealInfo={OrderStore?.invitedTeamDealInfo}
              />
            </div>
          )}
          <div style={{ height: 4 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 18,
              // fontWeight: 'bold',
            }}
          >
            <div>친구와 함께 팀구매하여</div>
            <div style={{ height: 2 }} />
            <div>할인 혜택을 받아보세요.</div>
          </div>
        </div>
        <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
      </div>
    </div>
  )
})

export default TeamMemberHeader
