import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import DetailImage from './DetailImage'
// import { useHistory, useLocation, useParams } from 'react-router-dom'

const DetailImageList = observer(() => {
  //   const history = useHistory()
  //   const { itemId } = useParams()
  //   useEffect(() => {
  //     // if (itemId) {
  //     //   ItemStore.loadTargetItemInfo(itemId)
  //     // }
  //   }, [])
  return (
    <div>
      {ItemStore.targetItemInfo.detailImageUris?.map((detailImageUri) => (
        // <div key={detailImageUri}>
        //   <img
        //     alt={detailImageUri}
        //     src={detailImageUri}
        //     //   style={{ objectFit: 'cover' }}
        //     width='100%'
        //     //   height='200'
        //   />
        // </div>
        <DetailImage key={detailImageUri} detailImageUri={detailImageUri} />
      ))}
    </div>
  )
})

export default DetailImageList
