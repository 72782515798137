import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import SafeArea from 'react-safe-area-component'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import ImageManager from '../images/ImageManager'
import styles from './ChallengeZeroMarketScreen.css'
import ViralEngineItemStore from '../stores/ViralEngineItemStore'
import ChallengeScreenHeader from '../components/challenges/ChallengeScreenHeader'
import backendApis from '../utils/backendApis'
import WhiteTextTimer from '../components/texts/WhiteTextTimer'
import commaNumber from 'comma-number'
import ItemStore from '../stores/ItemStore'
import ItemList from '../components/items/ItemList'

// Query 가져오는 함수
function get_query(url) {
  const qs = url.substring(url.indexOf('?') + 1).split('&')
  for (var i = 0, result = {}; i < qs.length; i++) {
    qs[i] = qs[i].split('=')
    result[qs[i][0]] = decodeURIComponent(qs[i][1])
  }
  return result
}

const ChallengeZeroMarketScreen = observer(() => {
  const history = useHistory()
  const [minutes, setMinutes] = useState(2)
  const [seconds, setSeconds] = useState(0)
  const [challengeItemInfo, setChallengeItemInfo] = useState([])

  const [ongoingChallengeInfo, setOngoingChallengeInfo] = useState({
    challengeItemInfo: {},
    expiresAt: '',
    targetPrice: 0,
    remainedPrice: 0,
    membersInfo: [{ userName: '' }],
    masterShareLink: '',
  })
  const [ongoingItemInfo, setOngoingItemInfo] = useState({
    itemTitle: '',
    itemImage: '',
  })
  const [teamInfo, setTeamInfo] = useState([{ userName: '' }])

  const styles = {
    informationText: {
      fontSize: 12,
      fontWeight: 'normal',
      height: 18,
      color: '#999999',
      paddingBottom: 8,
    },
  }

  useEffect(() => {
    if (
      !UserStore.isLoadingChallengeScreen &&
      !ItemStore.initialLoadingDataAssetsDone &&
      UserStore.screenName === 'ZeroMarketChallengeScreen'
    ) {
      const itemId = localStorage.getItem('@alwayz@itemId')
      if (!itemId) {
        ItemStore.initialLoadDataAssets()
      } else {
        history.replace(`/items/${itemId}`)
      }
    }
  }, [UserStore.isLoadingChallengeScreen, UserStore.screenName])

  useEffect(async () => {
    const data = await backendApis.getChallengeTeamShareMasterLink(
      get_query(window.location.search).alwayzChallengeTeamId,
    )
    if (data.status === 2000) {
      setOngoingChallengeInfo(data?.data)
      setOngoingItemInfo(data?.data?.challengeItemInfo)
      setTeamInfo(data?.data?.membersInfo)
    }
    UserStore.setScreenName('ZeroMarketChallengeScreen')
    UserStore.setIsLoadingChallengeScreen(false)
  }, [])

  useEffect(async () => {
    await ViralEngineItemStore.loadChallengeItems()
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      {/* <ChallengeScreenHeader /> */}
      <ToastContainer
        style={{ color: '#333333' }}
        position='top-center'
        autoClose={1500}
        hideProgressBar
        transition={Zoom}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        closeButton={false}
      />
      <div
        style={{
          height: 48,
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '25%', marginTop: 4 }}>
          <ImageManager source='AlwayzFullLogoRed' />
        </div>
      </div>
      <div
        className='box-sizing'
        style={{
          position: 'relative',
          // height: 350,
          // box-sizing: 'border-box',
          backgroundColor: '#F1F1F1',
          // padding: 8,
          paddingTop: 8,
        }}
      >
        <div
          className='box-sizing'
          style={{
            position: 'relative',
            height: '100%',
            // borderRadius: 8,
            paddingTop: 8,
            backgroundColor: '#fff',
            // backgroundColor: '#474747',
          }}
        >
          <div
            className='box-sizing border'
            style={{
              display: 'flex',
              position: 'relative',
              height: 124,
              // width: '100%',
              marginLeft: 8,
              marginRight: 8,
              // backgroundColor: '#fff',
              backgroundColor: '#FEF8EC',
              borderRadius: 6,
              padding: 8,
              borderWidth: 0.5,
              borderColor: '#000',
            }}
          >
            <div
              className='box-sizing item-image'
              style={{
                height: '100%',
                textAlign: 'center',
              }}
            >
              <img src={ongoingItemInfo?.itemImage} alt='' height='100%' />
            </div>
            <div
              className='box-sizing'
              style={{
                position: 'relative',
                width: '100%',
                marginRight: 8,
              }}
            >
              <div style={{ fontSize: 18, marginTop: 18, color: 'black' }}>
                {ongoingItemInfo?.itemTitle.length > 10
                  ? `${ongoingItemInfo?.itemTitle.slice(0, 10)}..`
                  : `${ongoingItemInfo?.itemTitle}`}
              </div>
              <div
                className='progress-bar'
                style={{
                  width: '100%',
                  height: 12,
                  backgroundColor: '#E7E7E7',
                  borderRadius: 12,
                  marginBottom: 12,
                  marginTop: 8,
                }}
              >
                <div
                  className='progress-bar'
                  style={{
                    width: `${
                      100 *
                      (1 -
                        ongoingChallengeInfo?.remainedPrice /
                          ongoingChallengeInfo?.targetPrice)
                    }%`,
                    height: 12,
                    backgroundColor: '#F9DB53',
                    borderRadius: 12,
                  }}
                />
              </div>
              {ongoingChallengeInfo?.expiresAt && (
                <div
                  style={{
                    fontSize: 14,
                    color: 'black',
                  }}
                >
                  <WhiteTextTimer
                    onTimeEnd={() => {}}
                    timeStamp={new Date(ongoingChallengeInfo?.expiresAt)}
                    timerMinutes={0}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className='box-sizing'
            style={{
              paddingBottom: 8,
              position: 'relative',
              backgroundColor: '#f1f1f1',
            }}
          >
            <div
              style={{
                // marginRight: 8,
                // marginLeft: 8,
                paddingTop: 12,
                backgroundColor: '#fff',
                paddingBottom: 12,
                paddingRight: 12,
                paddingLeft: 12,
                // borderBottomRightRadius: 12,
                // borderBottomLeftRadius: 12,
                borderTopColor: '#000',
                borderTopWidth: 5,
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: 8,
                  fontSize: 16,
                  // backgroundColor: '#fff',
                }}
              >
                {`${teamInfo[0]?.userName}님이 지금까지 `}
                {commaNumber(
                  parseInt(
                    ongoingChallengeInfo?.targetPrice -
                      ongoingChallengeInfo?.remainedPrice,
                  ),
                )}
                원 할인 받았어요!
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: 18,
                }}
              >
                버튼을 눌러 {teamInfo[0].userName}님의 상품 가격을
              </div>
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: 16,
                  fontSize: 18,
                }}
              >
                <b>최대 5만원</b> 깎아줄 수 있어요.
              </div>
              <button
                className='box-sizing sale-button'
                style={{
                  width: '100%',
                  height: 54,
                  backgroundColor: '#ea3F49',
                  borderRadius: 12,
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: '#fff',
                }}
                onClick={() => {
                  window.location.href = `https://alwayzshop.ilevit.com/app`
                }}
              >
                친구 가격 할인 도와주기
              </button>
            </div>
          </div>
          <div />
        </div>
      </div>
      <ItemList
        categoryType='large'
        categoryId='total'
        enteringComponent='ZeroMarketChallengeScreen'
      />
      {/* <div
        style={{
          backgroundColor: '#FEF8EC',
          flex: 1,
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        <ImageManager source='ChallengeInviteBannerImage' size={24} />
      </div> */}
    </div>
  )
})

export default ChallengeZeroMarketScreen
