import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ItemStore from '../../stores/ItemStore'
import UserStore from '../../stores/UserStore'
import OrderStore from '../../stores/OrderStore'
import ItemScreenHeader from '../items/ItemScreenHeader'
import heic2any from 'heic2any'
import ImageManager from '../../images/ImageManager'
import EmptyProfile from '../../images/EmptyProfile.png'

const DoubleProfileImageFilter = ({
  imageUri,
  needsPrivacyProtection = false,
}) => {
  const [filteredImageUri, setFilteredImageUri] = useState(imageUri)
  useEffect(() => {
    if (imageUri.toLowerCase().slice(-4) === 'heic') {
      setFilteredImageUri(EmptyProfile)
      fetch(imageUri)
        .then((res) => res.blob())
        .then((blob) =>
          heic2any({
            blob,
            // toType: 'image/jpeg',
            // quality: 0.01,
          }),
        )
        .then((conversionResult) => {
          const url = URL.createObjectURL(conversionResult)
          // console.log(url)
          setFilteredImageUri(url)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [])
  return (
    <img
      alt={filteredImageUri}
      src={filteredImageUri}
      // src={imageUrl}
      style={{
        objectFit: 'cover',
        height: 32,
        width: 32,
        borderRadius: 32,
        marginRight: -12,
        border: 'solid',
        borderWidth: 2,
        borderColor: '#FFF',
        filter: `blur(${needsPrivacyProtection ? 8 : 0}px)`,
      }}
    />
  )
}

export default DoubleProfileImageFilter
