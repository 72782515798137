import React, { useState } from 'react'
import Modal from 'react-modal'
import { observer } from 'mobx-react-lite'
import UserStore from '../../stores/UserStore'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'
import { isMobile } from 'react-device-detect'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '80%',
    borderRadius: 4,
    border: 'solid',
    borderColor: '#BDBDBD',
    borderWidth: 0.5,
    padding: 0,
    height: 280,
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // paddingLeft: 20,
    // paddingRight: 20,
    transform: 'translate(-50%, -50%)',
  },
}

const SoldOutModal = observer(
  ({ showModal = false, setShowModal, isSoldOut = false }) => {
    // const [modalIsOpen, setIsOpen] = useState(true)

    // function openModal() {
    //   setIsOpen(true)
    // }

    const closeModal = () => {
      setShowModal(false)
    }
    return (
      <Modal
        isOpen={showModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='App Download Modal'
      >
        <div
          style={{
            marginLeft: 16,
            marginRight: 16,
            marginTop: 32,
            marginBottom: 12,
            // backgroundColor: 'red',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 18,
              fontWeight: 'bold',
              marginBottom: 32,
            }}
          >
            앱 다운로드 안내
          </div>
          <div
            style={{
              color: '#BDBDBD',
              marginRight: 16,
              marginLeft: 16,
              marginBottom: 32,
            }}
          >
            해당 상품은 품절되었어요. 앱으로 더 많은 상품과 혜택을 즐겨보세요!
          </div>
          <button
            style={{
              width: '100%',
              marginBottom: 0,
              height: 36,
              backgroundColor: '#EA3f49',
              border: 'none',
              borderRadius: 4,
              fontSize: 16,
              color: '#FFF',
              fontWeight: 'bold',
            }}
            type='button'
            onClick={async () => {
              const { itemId, teamDealId, invitorId, linkType } =
                UserStore.dynamicLinkParams
              UserStore.setIsLoading(true)
              const link = await dynamicLinkUtils.createAppDownloadLink(
                itemId || '',
                teamDealId || '',
                invitorId || '',
                linkType || '',
                '',
                '',
                '',
                'SoldOutModalWeb',
                UserStore.dynamicLinkParams.isLinkFromApp,
                UserStore.dynamicLinkParams.bottomSheetTimeOut,
                UserStore.isLateComer,
                UserStore.testGroup?.endedTeamDealNotify,
                UserStore.testGroup?.ableToPurchaseViralItem,
                UserStore.testGroup?.reviewPosition,
                UserStore.testGroup?.teamMemberShowing,
                UserStore.testGroup?.appDownloadFullScreen,
              )
              UserStore.setIsLoading(false)
              if (link) {
                console.log(`link: ${link}`)
                window.location.href = link
              }
            }}
          >
            앱으로 할인 쿠폰 받기
          </button>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            style={{
              width: '100%',
              marginTop: 8,
              height: 36,
              backgroundColor: '#FFF',
              border: 'solid',
              borderColor: '#E0E0E0',
              borderWidth: 1,
              borderRadius: 4,
              fontSize: 16,
              color: '#333333',
            }}
            type='button'
          >
            {isMobile ? '모바일 ' : ''}웹으로 돌아가기
          </button>
        </div>
      </Modal>
    )
  },
)

export default SoldOutModal
