import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import OrderStore from '../../stores/OrderStore'
// import { useHistory, useLocation, useParams } from 'react-router-dom'

const SelectOptionButton = observer(
  ({ index, item, checked, optionLargeIndex, correspondingOptionsLength }) => {
    const [soldOutOption, setSoldOutOption] = useState(false)
    useEffect(() => {
      const optionsInfo =
        OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]?.itemInfo
          .optionsInfo
      if (optionsInfo) {
        const optionIndices =
          OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            ?.optionIndices
        const tempOptionIndices = optionIndices ? { ...optionIndices } : {}
        tempOptionIndices[optionLargeIndex] = index
        // console.log(
        //   `${JSON.stringify([
        //     ...Object.keys(tempOptionIndices).filter(
        //       (largeIndex) => largeIndex !== optionLargeIndex.toString(),
        //     ),
        //     optionLargeIndex,
        //   ])}`,
        // )
        if (
          Object.keys(tempOptionIndices).length ===
          optionsInfo.optionNames.length
        ) {
          let selectedPriceOption = optionsInfo.optionPrices
          Object.values(tempOptionIndices).forEach((optionIndex) => {
            selectedPriceOption = selectedPriceOption[optionIndex]
          })
          if (selectedPriceOption.sold_out) {
            setSoldOutOption(true)
          } else {
            setSoldOutOption(false)
          }
        }
      }
    }, [
      OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
        ?.optionIndices,
    ])

    useEffect(() => {
      if (correspondingOptionsLength === 1) {
        const optionIndices =
          OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            ?.optionIndices
        console.log(`setting option indices`)
        const tempOptionIndices = optionIndices ? { ...optionIndices } : {}
        tempOptionIndices[optionLargeIndex] = index
        // console.log(`${ItemStore.targetItemInfo.optionsInfo}`)
        // setOptionIndices(tempOptionIndices)
        OrderStore.setItemOptionIndicesByIndex(
          tempOptionIndices,
          OrderStore.currentItemIndex,
        )
      }
    }, [])
    if (checked) {
      return (
        <button
          type='button'
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor: '#EA3F49',
            borderRadius: 4,
            marginLeft: 4,
            marginRight: 4,
            marginTop: 4,
            marginBottom: 4,
            fontSize: 14,
            color: '#FFF',
            border: 'none',
          }}
          onClick={() => {
            const optionIndices =
              OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                ?.optionIndices
            console.log(`setting option indices`)
            const tempOptionIndices = optionIndices ? { ...optionIndices } : {}
            tempOptionIndices[optionLargeIndex] = index
            // console.log(`${ItemStore.targetItemInfo.optionsInfo}`)
            // setOptionIndices(tempOptionIndices)
            OrderStore.setItemOptionIndicesByIndex(
              tempOptionIndices,
              OrderStore.currentItemIndex,
            )
          }}
        >
          {item.name}
        </button>
      )
    }
    return (
      <button
        type='button'
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: soldOutOption ? '#F8F8F8' : '#F5F5F5',
          borderRadius: 4,
          marginLeft: 4,
          marginRight: 4,
          marginTop: 4,
          marginBottom: 4,
          fontSize: 14,
          color: soldOutOption ? '#BDBDBD' : '#333333',
          border: 'none',
        }}
        onClick={() => {
          if (soldOutOption) {
            return
          }
          const optionIndices =
            OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
              ?.optionIndices
          console.log(`setting option indices`)
          const tempOptionIndices = optionIndices ? { ...optionIndices } : {}
          tempOptionIndices[optionLargeIndex] = index
          // console.log(`${ItemStore.targetItemInfo.optionsInfo}`)
          // setOptionIndices(tempOptionIndices)
          OrderStore.setItemOptionIndicesByIndex(
            tempOptionIndices,
            OrderStore.currentItemIndex,
          )
        }}
      >
        {item.name}
      </button>
    )
  },
)

export default SelectOptionButton
