import React from 'react'
import UserStore from '../stores/UserStore'
import './LivingExpensesDealScreen.css'
import { logEvent, getAnalytics } from 'firebase/analytics'

const LivingExpensesDealScreen = () => {
  const analytics = getAnalytics()
  return (
    <div
      style={{
        maxWidth: '480px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#ff6c69',
      }}
    >
      <img
        src='https://assets.ilevit.com/75aa81a4-9162-4888-a291-77953fa29461.png'
        width='100%'
        style={{ display: 'block', margin: 0, padding: 0, border: 'none' }}
      />
      <iframe
        width='100%'
        height='240'
        src='https://www.youtube.com/embed/n1zUMzdViNQ?si=0mEW9yLCDgaQrvtt&autoplay=1&mute=1'
        title='Alwayz brand campaign'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowfullscreen
      />
      <img
        src='https://assets.ilevit.com/d50bfc41-52ae-44bb-8a25-29b1e13bd0d9.png'
        width='100%'
        style={{
          display: 'block',
          margin: 0,
          padding: 0,
          border: 'none',
        }}
      />

      <div
        className='special'
        style={{
          maxWidth: '480px',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
          backgroundColor: 'transparent',
        }}
      >
        <button
          className='button color-4'
          type='button'
          onClick={() => {
            if (UserStore.isLoading) return
            UserStore.setIsLoading(true)
            window.location.href = 'https://alwayzshop.page.link/djCh'
            logEvent(analytics, 'click_app_install_in_deal_event')
            UserStore.setIsLoading(false)
          }}
        >
          특가상품 앱에서 더 보기 ▶︎
        </button>
      </div>
    </div>
  )
}
export default LivingExpensesDealScreen
