import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import Timer from './Timer'
// import { useHistory, useLocation, useParams } from 'react-router-dom'

const BlackTextTimer = observer(({ timeStamp, onTimeEnd }) => {
  return <div>{Timer({ timeStamp, onTimeEnd }).formattedTime}</div>
})

export default BlackTextTimer
