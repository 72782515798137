import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ItemStore from '../../stores/ItemStore'
import UserStore from '../../stores/UserStore'
import OrderStore from '../../stores/OrderStore'
import DoubleProfileImage from '../social/DoubleProfileImage'

const PastTeamOrderComponent = ({ team }) => {
  return (
    <div
      style={{
        height: 48,
        paddingLeft: 16,
        paddingRight: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <DoubleProfileImage names membersInfo={team.membersInfo} />
      <div style={{ color: '#999999', fontSize: 14 }}>팀 모집 완료</div>
    </div>
  )
}

export default PastTeamOrderComponent
