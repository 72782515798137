import React, { useState, useRef, useEffect } from 'react'
import ItemStore from '../../stores/ItemStore'
import ItemListComponent from './ItemListComponent'
import { observer } from 'mobx-react-lite'
import ItemScreenTitleText from '../texts/ItemScreenTitleText'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const ItemList = observer(
  ({
    ListHeaderComponent,
    ScrollButton = false,
    categoryType,
    categoryId,
    higherCategoryId,
    enteringComponent,
  }) => {
    const width = window.innerWidth
    const [itemsInfo, setItemsInfo] = useState([])
    const [ableToLoad, setAbleToLoad] = useState(true)
    const [initialLoadDone, setInitialLoadDone] = useState(false)
    const [waitingQueToLoad, setWaitingQueToLoad] = useState(false)
    // const { width } = UseWindowDimension()
    const loadItemsInfo = async () => {
      setAbleToLoad(false)
      const result = await ItemStore.loadTopkRemainingItem(
        categoryType,
        categoryId,
        itemsInfo.length,
      )
      if (!initialLoadDone) {
        setInitialLoadDone(true)
      }
      const existingItemIds = itemsInfo.map((itemInfoTemp) => itemInfoTemp._id)
      const itemsInfoTemp = JSON.parse(
        JSON.stringify([
          ...itemsInfo,
          ...result.filter(
            (item) => !item.soldOut && !existingItemIds.includes(item._id),
          ),
        ]),
      )
      setItemsInfo(
        itemsInfoTemp.slice(0, Math.floor(itemsInfoTemp.length / 2) * 2),
      )

      await sleep(50)
      setAbleToLoad(true)
    }

    useEffect(() => {
      if (ItemStore.initialLoadingDataAssetsDone) {
        ItemStore.initializeItemOrdering(
          categoryType,
          categoryId,
          higherCategoryId,
        )

        loadItemsInfo()
      }
    }, [ItemStore.initialLoadingDataAssetsDone])

    useEffect(() => {
      if (ableToLoad === true && waitingQueToLoad === true) {
        setWaitingQueToLoad(false)
        loadItemsInfo()
      }
    }, [ableToLoad])

    return (
      <div>
        <ItemScreenTitleText text='올웨이즈 추천 상품' />
        <div
          style={{
            // display: 'flex',
            // flex: 1,
            // flexDirection: 'column',
            columnCount: 2,
            columnGap: 12,
            paddingLeft: 12,
            paddingRight: 12,
            // columnWidth: Math.floor((width - 36) / 2),
          }}
        >
          {itemsInfo.map((item) => (
            <ItemListComponent
              key={item._id}
              itemInfo={item}
              enteringComponent={enteringComponent}
            />
          ))}
        </div>
      </div>
    )
  },
)

export default ItemList
