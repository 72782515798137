import React, { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import commaNumber from 'comma-number'

function AlfarmCoffeeGiftScreen() {
  return (
    <>
      <Layout>
        {/* <Notice onClick={() => {}} /> */}
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#F2EADA',
            marginBottom: 10,
            fontFamily: 'Maplestory',
            display: 'flex',
            // justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            // justifyContent: 'space-between',
          }}
        >
          <div style={{ fontSize: 32, marginTop: 68, color: '#704410' }}>
            5초 미션을 수행하면
          </div>
          <div
            style={{
              fontSize: 32,
              marginBottom: 32,
              fontWeight: 'bold',
              color: '#704410',
            }}
          >
            공짜 커피를 받을 수 있어요
          </div>
          <div
            style={{
              backgroundImage:
                "url('https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/35573ca2-e04e-446d-b751-1cc3e521b3d7.png')",

              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '60%',
              height: 128,
              marginTop: 8,
              //   backgroundColor: 'green',
            }}
          />
          <button
            type='button'
            style={{
              width: '100%',
              border: 'none',
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 16,
              //   backgroundColor: 'red',
            }}
            onClick={async () => {
              window.location.href = `https://alwayzshop.ilevit.com/app`
            }}
          >
            <div
              style={{
                backgroundImage:
                  "url('https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/3170a945-50fc-4835-a75b-adbe39128ef8.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: 200,
              }}
            />
          </button>
          <div style={{ marginTop: 16, color: '#98896C' }}>
            *올웨이즈 신규 고객만 공짜 커피를 받을 수 있어요
          </div>
          <button
            type='button'
            style={{
              width: '100%',
              border: 'none',
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 12,
              //   backgroundColor: 'blue',
            }}
            onClick={async () => {
              window.location.href = `https://alwayzshop.ilevit.com/app`
            }}
          >
            <div
              style={{
                backgroundImage:
                  "url('https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/a42ae4c2-10a9-4ea5-8d41-df6275e192a5.png')",

                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '90%',
                height: 120,
              }}
            />
          </button>
        </div>
      </Layout>
    </>
  )
}
export default AlfarmCoffeeGiftScreen
