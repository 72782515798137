import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import OrderStore from '../../stores/OrderStore'
import UserStore from '../../stores/UserStore'
import commaNumber from 'comma-number'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import '../../screens/ItemScreen.css'
import configs from '../../utils/configs'
import uuid from 'uuid-random'
import backendApis from '../../utils/backendApis'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'

const BottomPlaceOrderBar = observer(({ notify }) => {
  const IMP = window.IMP
  IMP.init(configs.impCode)
  const history = useHistory()
  useEffect(() => {
    if (UserStore.userInfo._id && OrderStore.finishedLoadingUserOrders) {
      OrderStore.registerOrders()
    }
  }, [UserStore.userInfo._id, OrderStore.finishedLoadingUserOrders])

  useEffect(() => {
    if (
      OrderStore.kakaoPaySubscriptionRequirementParams.kakaoSinglePayRedirectUri
    ) {
      // window.location.href =
      //   OrderStore.kakaoPaySubscriptionRequirementParams.kakaoSinglePayRedirectUri
      window.location.replace(
        OrderStore.kakaoPaySubscriptionRequirementParams
          .kakaoSinglePayRedirectUri,
      )
      OrderStore.setKakaoSinglePayRedirectUri('')
    }
  }, [
    OrderStore.kakaoPaySubscriptionRequirementParams.kakaoSinglePayRedirectUri,
  ])
  return (
    <div
      style={{
        paddingRight: 16,
        paddingLeft: 16,
      }}
      className='special-gray'
    >
      <button
        style={{
          width: '100%',
          marginBottom: 16,
          marginTop: 8,
          height: 48,
          backgroundColor: '#EA3f49',
          border: 'none',
          borderRadius: 4,
          fontSize: 18,
          color: '#FFF',
        }}
        type='button'
        onClick={async () => {
          if (UserStore.isLoading) {
            return
          }
          if (!UserStore.userInfo._id) {
            alert('주문자 확인을 위해서 먼저 카카오 본인인증을 해주세요!')
            return
          }
          if (!UserStore.userInfo.defaultAddressInfo.addressId) {
            if (UserStore.userInfo.addressesInfo?.length > 0) {
              // checked
              alert(
                '선택한 배송지가 없습니다. 상품을 받으실 배송지를 선택해주세요.',
              )
              history.push('/address')
              return
            }
            // checked
            alert(
              '등록한 배송지가 없습니다. 상품을 받으실 배송지를 등록해주세요.',
            )
            history.push('/address')
            return
          }
          if (
            !OrderStore.finishedLoadingUserOrders ||
            !OrderStore.finishedRegisteringOrders
          ) {
            return
          }
          // if (
          //   OrderStore.currentUserOrdersList.find((orderTemp) => {
          //     return (
          //       orderTemp.itemInfo.isViralItem &&
          //       !orderTemp.teamDealId &&
          //       new Date() > new Date('Sat, 18 Dec 2021 06:00:00 GMT')
          //     )
          //   })
          // ) {
          //   alert('해당 상품은 현재 구매 시작이 불가합니다.')
          //   return
          // }
          UserStore.setIsLoading(true)
          const confirmPrePayedOrdersResult =
            await backendApis.confirmPayableOrders(
              OrderStore.currentUserOrdersList.map((order) => order._id),
            )
          if (
            confirmPrePayedOrdersResult?.status === 200 &&
            confirmPrePayedOrdersResult.data
          ) {
            alert(
              '주문 내역이 정확하지 않습니다. 잠시 후 다시 시도해주세요.',
              // [
              //   {
              //     text: '확인',
              //     onPress: () => {
              //       setIsPaying(false)
              //       OrderStore.loadUserOrdersList()
              //       navigation.pop()
              //     },
              //   },
              // ],
            )
            UserStore.setIsLoading(false)
            if (OrderStore.currentUserOrdersList[0]) {
              history.replace(
                `/items/${OrderStore.currentUserOrdersList[0]?.itemInfo.itemId}`,
              )
            } else {
              history.replace('/')
            }

            return
          }
          const participatedTeamDeal = OrderStore.currentUserOrdersList.filter(
            (orderTemp) => {
              return (
                orderTemp.teamDealId &&
                OrderStore.loadedUserOrdersList
                  .filter(
                    (orderTemp2) =>
                      orderTemp2.status !== 'pre-pay' &&
                      orderTemp2.status !== 'canceled-team-gathering',
                  )
                  .map((orderTemp2) => orderTemp2.teamDealId)
                  .includes(orderTemp.teamDealId)
              )
            },
          )
          if (participatedTeamDeal.length > 0) {
            alert(
              '이미 참여한 팀에는 다시 참여할 수 없어요. 새롭게 팀구매를 열어보세요!',
            )
            OrderStore.setCurrentUserOrdersList(
              OrderStore.currentUserOrdersList.map((order) => {
                if (
                  participatedTeamDeal
                    .map((orderTemp) => orderTemp._id)
                    .includes(order._id)
                ) {
                  return { ...order, teamDealId: undefined }
                }
                return order
              }),
            )
            UserStore.setIsLoading(false)
            return
          }
          // const existingOrder = OrderStore.loadedUserOrdersList
          //   .filter((order) =>
          //     ['team-gathering', 'pre-shipping', 'shipping'].includes(
          //       order.status,
          //     ),
          //   )
          //   .find(
          //     (order) =>
          //       order.itemInfo.itemId ===
          //       OrderStore.currentUserOrdersList[0]?.itemInfo.itemId,
          //   )
          // if (existingOrder) {
          //   // checked
          //   alert(
          //     '해당 상품은 동시에 여러 번 주문할 수 없어요. 배송 완료 후 주문 가능합니다.',
          //   )
          //   return
          // }

          const teamDealCheckResult = await OrderStore.checkTeamDeals()
          if (teamDealCheckResult.data.length === 1) {
            if (teamDealCheckResult.data[0].teamCompleted) {
              alert('해당 팀구매는 마감되었어요. 새롭게 팀구매를 열어보세요!')
              OrderStore.setCurrentUserOrdersList(
                OrderStore.currentUserOrdersList.map((order) => {
                  if (order._id === teamDealCheckResult.data[0].orderId) {
                    return { ...order, teamDealId: undefined }
                  }
                  return order
                }),
              )
              UserStore.setIsLoading(false)
              return
            }
            if (teamDealCheckResult.data[0].deletedAt) {
              alert('해당 팀구매는 취소되었어요. 새롭게 팀구매를 열어보세요!')
              OrderStore.setCurrentUserOrdersList(
                OrderStore.currentUserOrdersList.map((order) => {
                  if (order._id === teamDealCheckResult.data[0].orderId) {
                    return { ...order, teamDealId: undefined }
                  }
                  return order
                }),
              )
              UserStore.setIsLoading(false)
              return
            }
          }
          const isViralOption = OrderStore.currentUserOrdersList.some(
            (orderTemp, index) => {
              if (
                orderTemp.itemInfo?.isViralItem &&
                orderTemp.selectedOption?.isViralOption
                //  &&
                // OrderStore.loadedUserOrdersList.find((order) => {
                //   if (
                //     order.status !== 'pre-pay' &&
                //     order.itemInfo?.itemId === orderTemp.itemInfo?.itemId &&
                //     order.selectedOption?.isViralOption
                //   ) {
                //     return true
                //   }
                //   return false
                // })
              ) {
                return true
              }
              return false
            },
          )
          if (isViralOption) {
            if (
              // !localStorage.getItem('@alwayz@purchaseAvailable') ||
              OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                .teamDealId
            ) {
              notify(
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginBottom: 32,
                    }}
                  >
                    해당 옵션은 앱에서 구매가 가능해요.
                  </div>

                  <button
                    style={{
                      width: '100%',
                      marginBottom: 0,
                      height: 40,
                      backgroundColor: '#EA3f49',
                      border: 'none',
                      borderRadius: 4,
                      fontSize: 14,
                      color: '#FFF',
                      fontWeight: 'bold',
                    }}
                    type='button'
                    onClick={async () => {
                      const { itemId, teamDealId, invitorId, linkType } =
                        UserStore.dynamicLinkParams
                      UserStore.setIsLoading(true)
                      const link = await dynamicLinkUtils.createAppDownloadLink(
                        itemId || '',
                        teamDealId || '',
                        invitorId || '',
                        linkType || '',
                        '',
                        '',
                        '',
                        'OrderMainViralOptionAppDownloadWeb',
                        UserStore.dynamicLinkParams.isLinkFromApp,
                        UserStore.dynamicLinkParams.bottomSheetTimeOut,
                        UserStore.isLateComer,
                        UserStore.testGroup?.endedTeamDealNotify,
                        UserStore.testGroup?.ableToPurchaseViralItem,
                        UserStore.testGroup?.reviewPosition,
                        UserStore.testGroup?.teamMemberShowing,
                        UserStore.testGroup?.appDownloadFullScreen,
                      )
                      UserStore.setIsLoading(false)
                      if (link) {
                        console.log(`link: ${link}`)
                        window.location.href = link
                      }
                    }}
                  >
                    앱 다운로드 받기
                  </button>
                </div>,
                4000,
                // <div>
                //   더 많은 분에게 혜택을 드리기 위해서
                //   <br />
                //   해당 옵션은 현재 중복 구매가 어려워요
                // </div>,
              )
              UserStore.setIsLoading(false)
              // history.replace(
              //   `/items/${OrderStore.currentUserOrdersList[0].itemInfo.itemId}`,
              // )
              return
            }
            // const isDuplicateViralOption =
            //   OrderStore.currentUserOrdersList.some((orderTemp, index) => {
            //     if (
            //       orderTemp.teamDealId &&
            //       orderTemp.itemInfo?.isViralItem &&
            //       orderTemp.selectedOption?.isViralOption &&
            //       OrderStore.loadedUserOrdersList.find((order) => {
            //         if (
            //           [
            //             'team-gathering',
            //             'pre-shipping',
            //             'canceling-pre-shipping',
            //             'shipping',
            //             'canceling-shipping',
            //             'arrived',
            //             'reviewed',
            //             'refunding',
            //           ].includes(order.status) &&
            //           order.itemInfo?.itemId === orderTemp.itemInfo?.itemId &&
            //           order.selectedOption?.isViralOption
            //         ) {
            //           return true
            //         }
            //         return false
            //       })
            //     ) {
            //       return true
            //     }
            //     return false
            //   })
            // if (isDuplicateViralOption) {
            //   notify(
            //     <div>
            //       <div
            //         style={{
            //           display: 'flex',
            //           justifyContent: 'center',
            //           fontSize: 16,
            //           fontWeight: 'bold',
            //           marginBottom: 32,
            //         }}
            //       >
            //         해당 옵션은 앱에서 구매가 가능해요.
            //       </div>

            //       <button
            //         style={{
            //           width: '100%',
            //           marginBottom: 0,
            //           height: 40,
            //           backgroundColor: '#EA3f49',
            //           border: 'none',
            //           borderRadius: 4,
            //           fontSize: 14,
            //           color: '#FFF',
            //           fontWeight: 'bold',
            //         }}
            //         type='button'
            //         onClick={async () => {
            //           const { itemId, teamDealId, invitorId, linkType } =
            //             UserStore.dynamicLinkParams
            //           UserStore.setIsLoading(true)
            //           const link = await dynamicLinkUtils.createAppDownloadLink(
            //             itemId || '',
            //             teamDealId || '',
            //             invitorId || '',
            //             linkType || '',
            //             '',
            //             '',
            //             '',
            //             'OrderMainViralOptionAppDownloadWeb',
            //             UserStore.dynamicLinkParams.isLinkFromApp,
            //             UserStore.dynamicLinkParams.bottomSheetTimeOut,
            //             UserStore.isLateComer,
            //             UserStore.testGroup?.endedTeamDealNotify,
            //             UserStore.testGroup?.ableToPurchaseViralItem,
            //             UserStore.testGroup?.reviewPosition,
            //             UserStore.testGroup?.teamMemberShowing,
            //             UserStore.testGroup?.appDownloadFullScreen,
            //           )
            //           UserStore.setIsLoading(false)
            //           if (link) {
            //             console.log(`link: ${link}`)
            //             window.location.href = link
            //           }
            //         }}
            //       >
            //         앱 다운로드 받기
            //       </button>
            //     </div>,
            //     4000,
            //     // <div>
            //     //   더 많은 분에게 혜택을 드리기 위해서
            //     //   <br />
            //     //   해당 옵션은 현재 중복 구매가 어려워요
            //     // </div>,
            //   )
            //   UserStore.setIsLoading(false)
            //   // history.replace(
            //   //   `/items/${OrderStore.currentUserOrdersList[0].itemInfo.itemId}`,
            //   // )
            //   return
            // }
          }
          const isLotteryItemTeamJoin = OrderStore.currentUserOrdersList.some(
            (orderTemp, index) => {
              if (
                orderTemp.itemInfo?.isLotteryItem &&
                orderTemp.teamDealId
                //  &&
                // OrderStore.loadedUserOrdersList.find((order) => {
                //   if (
                //     order.status !== 'pre-pay' &&
                //     order.itemInfo?.itemId === orderTemp.itemInfo?.itemId &&
                //     order.selectedOption?.isViralOption
                //   ) {
                //     return true
                //   }
                //   return false
                // })
              ) {
                return true
              }
              return false
            },
          )
          if (isLotteryItemTeamJoin) {
            notify(
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 16,
                    fontWeight: 'bold',
                    marginBottom: 32,
                  }}
                >
                  해당 상품은 앱에서 구매가 가능해요.
                </div>

                <button
                  style={{
                    width: '100%',
                    marginBottom: 0,
                    height: 40,
                    backgroundColor: '#EA3f49',
                    border: 'none',
                    borderRadius: 4,
                    fontSize: 14,
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                  type='button'
                  onClick={async () => {
                    const { itemId, teamDealId, invitorId, linkType } =
                      UserStore.dynamicLinkParams
                    UserStore.setIsLoading(true)
                    const link = await dynamicLinkUtils.createAppDownloadLink(
                      itemId || '',
                      teamDealId || '',
                      invitorId || '',
                      linkType || '',
                      '',
                      '',
                      '',
                      'OrderMainLotteryItemAppDownloadWeb',
                      UserStore.dynamicLinkParams.isLinkFromApp,
                      UserStore.dynamicLinkParams.bottomSheetTimeOut,
                      UserStore.isLateComer,
                      UserStore.testGroup?.endedTeamDealNotify,
                      UserStore.testGroup?.ableToPurchaseViralItem,
                      UserStore.testGroup?.reviewPosition,
                      UserStore.testGroup?.teamMemberShowing,
                      UserStore.testGroup?.appDownloadFullScreen,
                    )
                    UserStore.setIsLoading(false)
                    if (link) {
                      console.log(`link: ${link}`)
                      window.location.href = link
                    }
                  }}
                >
                  앱 다운로드 받기
                </button>
              </div>,
              4000,
              // <div>
              //   더 많은 분에게 혜택을 드리기 위해서
              //   <br />
              //   해당 옵션은 현재 중복 구매가 어려워요
              // </div>,
            )
            UserStore.setIsLoading(false)
            // history.replace(
            //   `/items/${OrderStore.currentUserOrdersList[0].itemInfo.itemId}`,
            // )
            return
          }

          // iamport 이전 kakaopay 로직 시작
          // await OrderStore.prepareKakaoSinglePay()
          // iamport 이전 kakaopay 로직 끝

          // iamport 로직
          if (OrderStore.currentUserOrdersList.length > 0) {
            const orderIndicator = uuid()
            const itemNames = OrderStore.currentUserOrdersList
              .map((order) => order.itemInfo.itemTitle)
              .join(' + ')
            await OrderStore.uploadUpdatedOrdersInfo(orderIndicator)
            const amount = OrderStore.currentUserOrdersList
              .map(
                (order) =>
                  order.totalPrice +
                  order.shippingFee -
                  (order.initialLinkShareDiscountAmount || 0),
              )
              .reduce((a, b) => a + b, 0)
            if (
              amount === 0 &&
              OrderStore.currentUserOrdersList.length === 1 &&
              OrderStore.currentUserOrdersList[0].itemInfo?.isLotteryItem
            ) {
              const result = await OrderStore.recordKakaoSinglePayResultFromImp(
                orderIndicator,
                {},
              )
              UserStore.setIsLoading(false)
              if (result?.status === 200 && result.data?.ordersListProcessed) {
                const teamGatheringOrders =
                  result.data.ordersListProcessed.filter(
                    (order) => order.status === 'team-gathering',
                  )
                const isTeamJoined = result.data.ordersListProcessed.filter(
                  (order) =>
                    order.isTeamDeal && order.status === 'pre-shipping',
                )
                if (teamGatheringOrders.length > 0) {
                  history.replace(
                    `/items/${teamGatheringOrders[0].itemInfo.itemId}`,
                  )
                  history.push('/order-end', { teamGatheringOrders })
                } else if (
                  isTeamJoined.length > 0 &&
                  result.data.ordersListProcessed.length > 0
                ) {
                  OrderStore.setSharingOrdersInfo(
                    result.data.ordersListProcessed,
                  )
                  OrderStore.setOrderIdsToShareToAlwayz(
                    result.data.ordersListProcessed.map((order) => order._id),
                  )
                  OrderStore.setShowTeamJoinedPurchaseModal(true)
                  history.replace(`/items/${isTeamJoined[0].itemInfo.itemId}`)
                  history.push('/order-end', {
                    teamJoinedOrders: isTeamJoined,
                  })
                } else if (
                  teamGatheringOrders.length === 0 &&
                  result.data.ordersListProcessed.length > 0
                ) {
                  OrderStore.setSharingOrdersInfo(
                    result.data.ordersListProcessed,
                  )
                  OrderStore.setOrderIdsToShareToAlwayz(
                    result.data.ordersListProcessed.map((order) => order._id),
                  )
                  OrderStore.setShowIndividualPurchaseModal(true)
                  history.replace(
                    `/items/${result.data.ordersListProcessed[0].itemInfo.itemId}`,
                  )
                  history.push('/order-end', {
                    individualOrders: result.data.ordersListProcessed,
                  })
                }

                OrderStore.resetItemInfo()
                UserStore.setIsLoadingEmptyScreen(false)
              } else {
                const currentUserOrdersList =
                  OrderStore.loadedUserOrdersList.find(
                    (order) => order.orderIndicator === orderIndicator,
                  )
                if (currentUserOrdersList) {
                  history.replace(
                    `/items/${currentUserOrdersList.itemInfo.itemId}`,
                  )
                } else {
                  history.replace('/')
                }
              }
              return
            }
            const options = {
              pg: 'kakaopay',
              // pay_method: 'card', // 생략 가능
              merchant_uid: orderIndicator,
              // OrderStore.currentUserOrdersList
              //   .map((order) => order._id)
              //   .join('+')
              //   .slice(0, 36), // 상점에서 관리하는 주문 번호
              name:
                itemNames.length > 100
                  ? `${itemNames.slice(0, 95)}...`
                  : itemNames,
              amount,
              // buyer_email: 'iamport@siot.do',
              buyer_name: UserStore.userInfo.userName,
              buyer_tel: UserStore.userInfo.phoneNumber,
              custom_data: UserStore.userInfo._id,
              // buyer_addr: '서울특별시 강남구 삼성동',
              // buyer_postcode: '123-456',
              // m_redirect_url: `${configs.webUrl}`,
            }
            if (UserStore.isMobile) {
              options.m_redirect_url = `${configs.webUrl}/?orderIndicator=${orderIndicator}&amount=${amount}`
            }
            IMP.request_pay(options, async (rsp) => {
              // console.log(`kakaopay request result: ${JSON.stringify(rsp)}`)
              if (rsp.success) {
                const {
                  imp_uid: impUid,
                  imp_success: impSuccess,
                  merchant_uid: merchantUid,
                } = rsp
                const kakaoPayResult = {
                  impUid,
                  impSuccess,
                  merchantUid,
                  amount,
                }
                const result =
                  await OrderStore.recordKakaoSinglePayResultFromImp(
                    orderIndicator,
                    kakaoPayResult,
                  )
                UserStore.setIsLoading(false)
                if (
                  result?.status === 200 &&
                  result.data?.ordersListProcessed
                ) {
                  const teamGatheringOrders =
                    result.data.ordersListProcessed.filter(
                      (order) => order.status === 'team-gathering',
                    )
                  const isTeamJoined = result.data.ordersListProcessed.filter(
                    (order) =>
                      order.isTeamDeal && order.status === 'pre-shipping',
                  )
                  if (teamGatheringOrders.length > 0) {
                    history.replace(
                      `/items/${teamGatheringOrders[0].itemInfo.itemId}`,
                    )
                    history.push('/order-end', { teamGatheringOrders })
                  } else if (
                    isTeamJoined.length > 0 &&
                    result.data.ordersListProcessed.length > 0
                  ) {
                    OrderStore.setSharingOrdersInfo(
                      result.data.ordersListProcessed,
                    )
                    OrderStore.setOrderIdsToShareToAlwayz(
                      result.data.ordersListProcessed.map((order) => order._id),
                    )
                    OrderStore.setShowTeamJoinedPurchaseModal(true)
                    history.replace(`/items/${isTeamJoined[0].itemInfo.itemId}`)
                    history.push('/order-end', {
                      teamJoinedOrders: isTeamJoined,
                    })
                  } else if (
                    teamGatheringOrders.length === 0 &&
                    result.data.ordersListProcessed.length > 0
                  ) {
                    OrderStore.setSharingOrdersInfo(
                      result.data.ordersListProcessed,
                    )
                    OrderStore.setOrderIdsToShareToAlwayz(
                      result.data.ordersListProcessed.map((order) => order._id),
                    )
                    OrderStore.setShowIndividualPurchaseModal(true)
                    history.replace(
                      `/items/${result.data.ordersListProcessed[0].itemInfo.itemId}`,
                    )
                    history.push('/order-end', {
                      individualOrders: result.data.ordersListProcessed,
                    })
                  }

                  OrderStore.resetItemInfo()
                  UserStore.setIsLoadingEmptyScreen(false)
                } else {
                  const currentUserOrdersList =
                    OrderStore.loadedUserOrdersList.find(
                      (order) => order.orderIndicator === orderIndicator,
                    )
                  if (currentUserOrdersList) {
                    history.replace(
                      `/items/${currentUserOrdersList.itemInfo.itemId}`,
                    )
                  } else {
                    history.replace('/')
                  }
                }
              } else {
                console.log(`failed kakao pay`)
                UserStore.setIsLoading(false)
              }
            })
          } else {
            UserStore.setIsLoading(false)
          }
        }}
      >
        {commaNumber(
          OrderStore.currentUserOrdersList
            .map(
              (order) =>
                order.totalPrice +
                order.shippingFee -
                (order.initialLinkShareDiscountAmount || 0),
            )
            .reduce((a, b) => a + b, 0),
        )}
        원 결제하기
      </button>
    </div>
  )
})

export default BottomPlaceOrderBar
