const parseViralItemOptions = (optionsInfo, sellerInt) => {
  console.log(`parse viral item options activated!`)
  if (optionsInfo.optionNames.length === 1) {
    return {
      ...optionsInfo,
      optionPrices: optionsInfo.optionPrices.map((firstOptionTemp) => {
        if (firstOptionTemp.sharedPlatformSupportPrice?.length > sellerInt) {
          return {
            ...firstOptionTemp,
            platformSupportPrice:
              firstOptionTemp.sharedPlatformSupportPrice[sellerInt],
          }
        }
        return firstOptionTemp
      }),
    }
  }
  if (optionsInfo.optionNames.length === 2) {
    return {
      ...optionsInfo,
      optionPrices: optionsInfo.optionPrices.map((firstOptionTemp) => {
        return firstOptionTemp.map((secondOptionTemp) => {
          if (secondOptionTemp.sharedPlatformSupportPrice?.length > sellerInt) {
            return {
              ...secondOptionTemp,
              platformSupportPrice:
                secondOptionTemp.sharedPlatformSupportPrice[sellerInt],
            }
          }
          return secondOptionTemp
        })
      }),
    }
  }
}

export default parseViralItemOptions
