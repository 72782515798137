const Footer = ({ title, clickable = false, backgroundColor, onClick }) => (
  <section
    style={{
      backgroundColor: `${backgroundColor}`,
      position: 'absolute',
      bottom: 0,
      zIndex: 1,
      width: '100vw',
      height: '76px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        float: 'bottom',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        width: '100vw',
        height: '2px',
        boxSizing: 'border-box',
        padding: '10px',
      }}
    >
      <button
        style={{
          backgroundColor: 'transparent',

          borderWidth: 0,
          color: 'white',
          fontFamily: 'GmarketSans',
          fontWeight: 900,
          fontSize: 24,
        }}
        onClick={() => {
          onClick()
        }}
        // onClick={() => {
        //   window.location.href = '#goBack'
        // }}
      >
        {title}
      </button>
    </div>
  </section>
)

export default Footer
