import logo from './logo.svg'
import './App.css'
import React, { useState, useEffect } from 'react'
import configs from './utils/configs'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useHistory,
  useNavigate,
} from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import backendApis from './utils/backendApis'
import ItemScreen from './screens/ItemScreen'
import OrderMainScreen from './screens/OrderMainScreen'
import AddShippingAddressScreen from './screens/AddShippingAddressScreen'
import ShippingAddressScreen from './screens/ShippingAddressScreen'
import OrderEndScreen from './screens/OrderEndScreen'
import OrdersScreen from './screens/OrdersScreen'
import KakaoTestScreen from './screens/KakaoTestScreen'
import { isMobile } from 'react-device-detect'
import UserStore from './stores/UserStore'
import socket from './utils/socket'
import AuthStore from './stores/AuthStore'
import ItemQuestionScreen from './screens/ItemQuestionScreen'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import OrderStore from './stores/OrderStore'
import ItemOrderBottomSheet from './components/items/ItemOrderBottomSheet'
import AddShippingAddressSecondScreen from './screens/AddShippingAddressSecondScreen'
import dynamicLinkUtils from './utils/dynamicLinkUtils'
import ShippingMethodDetailScreen from './screens/ShippingMethodDetailScreen'
import EmptyScreenComponent from './components/EmptyScreenComponent'
// // Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import LoadingIndicator from './components/LoadingIndicator'
import AppDownloadModal from './components/modal/AppDownloadModal'
import AppDownloadItemModal from './components/modal/AppDownloadItemModal'
import ReviewScreen from './screens/ReviewScreen'
import ChallengeZeroMarketScreen from './screens/ChallengeZeroMarketScreen'
import SendGiftScreen from './screens/SendGiftScreen'
import moment from 'moment'
import ChallengeMelonaScreen from './screens/ChallengeMelonaScreen'
import SendMoneyScreen from './screens/SendMoneyScreen'
import PocketMoneyScreen from './screens/PocketMoneyScreen'
import MyGiftCardScreen from './screens/MyGiftCardScreen'
import CheckInInviteScreen from './screens/CheckInInviteScreen'
import LuckyBoxEventScreen from './screens/LuckyBoxEventScreen'
import SupportFundEventScreen from './screens/SupportFundEventScreen'
import AlfarmCoffeeGiftScreen from './screens/AlfarmCoffeeGiftScreen'
import AlfarmChocoGiftScreen from './screens/AlfarmChocoGiftScreen'
import OnePlusOneScreen from './screens/OnePlusOneScreen'
import NewYearsMoneyScreen from './screens/NewYearsMoneyScreen'
import GganbuChickenScreen from './screens/GganbuChickenScreen'
import AlfarmCoffeeEventWithDongWookLeeScreen from './screens/AlfarmCoffeeEventWithDongWookLeeScreen'
import LivingExpensesDealScreen from './screens/LivingExpensesDealScreen'

require('moment-timezone')

moment.tz.setDefault('Asia/Seoul')
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const firebaseConfig = {
  apiKey: configs.firebaseApiKey,
  authDomain: 'alwayzproject.firebaseapp.com',
  projectId: 'alwayzproject',
  storageBucket: 'alwayzproject.appspot.com',
  messagingSenderId: '245182288581',
  appId: '1:245182288581:web:481559188813d8cd0416ac',
  measurementId: 'G-0RCXC9VYTB',
}

// // Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)

const App = observer(() => {
  const kakao = window.Kakao
  const history = useHistory()
  const [openBottomSheet, setOpenBottomSheet] = useState(false)

  useEffect(() => {
    socket.connect()
    UserStore.setIsMobile(isMobile)
    checkInitialKakaoState()
    UserStore.loadLinkBenefitsInfo()
  }, [])

  useEffect(() => {
    localStorage.setItem('@alwayz@purchaseAvailable', 'true')
    UserStore.setTestGroup({
      ...UserStore.testGroup,
      ableToPurchaseViralItem: true,
    })
    // const setAbleToPurchaseTestGroup = localStorage.getItem(
    //   '@alwayz@setPurchaseAvailable',
    // )
    // if (!setAbleToPurchaseTestGroup) {
    //   localStorage.setItem('@alwayz@setPurchaseAvailable', 'true')
    //   const randomNumber = Math.random()
    //   if (randomNumber > 0.5) {
    //     localStorage.setItem('@alwayz@purchaseAvailable', 'true')
    //     // UserStore.setAbleToPurchaseViralItem(true)
    //     UserStore.setTestGroup({
    //       ...UserStore.testGroup,
    //       ableToPurchaseViralItem: true,
    //     })
    //   }
    // } else {
    //   const ableToPurchaseViralItem = localStorage.getItem(
    //     '@alwayz@purchaseAvailable',
    //   )
    //   if (ableToPurchaseViralItem) {
    //     UserStore.setTestGroup({
    //       ...UserStore.testGroup,
    //       ableToPurchaseViralItem: true,
    //     })
    //     // UserStore.setAbleToPurchaseViralItem(true)
    //   }
    // }
    // const endedTeamDealNotifyMethod = localStorage.getItem(
    //   '@alwayz@endedTeamDealNotify',
    // )
    // if (!endedTeamDealNotifyMethod) {
    //   const randomNumber = Math.random()
    //   if (randomNumber > 0.5) {
    //     localStorage.setItem('@alwayz@endedTeamDealNotify', 'A')
    //     UserStore.setTestGroup({
    //       ...UserStore.testGroup,
    //       endedTeamDealNotify: 'A',
    //     })
    //   } else {
    //     localStorage.setItem('@alwayz@endedTeamDealNotify', 'B')
    //     UserStore.setTestGroup({
    //       ...UserStore.testGroup,
    //       endedTeamDealNotify: 'B',
    //     })
    //   }
    // } else {
    //   UserStore.setTestGroup({
    //     ...UserStore.testGroup,
    //     endedTeamDealNotify: endedTeamDealNotifyMethod,
    //   })
    // }

    const reviewPosition = localStorage.getItem('@alwayz@reviewPosition')

    if (!reviewPosition) {
      const randomNumber = Math.random()
      if (randomNumber > 0.5) {
        localStorage.setItem('@alwayz@reviewPosition', 'A')
        UserStore.setTestGroup({
          ...UserStore.testGroup,
          reviewPosition: 'A',
        })
      } else {
        localStorage.setItem('@alwayz@reviewPosition', 'null')
        UserStore.setTestGroup({
          ...UserStore.testGroup,
          reviewPosition: 'null',
        })
      }
    } else {
      UserStore.setTestGroup({
        ...UserStore.testGroup,
        reviewPosition,
      })
    }

    const teamMemberShowing = localStorage.getItem('@alwayz@teamMemberShowing')
    if (!teamMemberShowing) {
      const randomNumber = Math.random()
      if (randomNumber > 0.8) {
        localStorage.setItem('@alwayz@teamMemberShowing', 'A')
        UserStore.setTestGroup({
          ...UserStore.testGroup,
          teamMemberShowing: 'A',
        })
      } else {
        localStorage.setItem('@alwayz@teamMemberShowing', 'null')
        UserStore.setTestGroup({
          ...UserStore.testGroup,
          teamMemberShowing: 'null',
        })
      }
    } else {
      UserStore.setTestGroup({
        ...UserStore.testGroup,
        teamMemberShowing,
      })
    }

    const appDownloadFullScreen = localStorage.getItem(
      '@alwayz@appDownloadFullScreen',
    )
    if (!appDownloadFullScreen) {
      const randomNumber = Math.random()
      if (randomNumber > 2 / 3) {
        localStorage.setItem('@alwayz@appDownloadFullScreen', 'A')
        UserStore.setTestGroup({
          ...UserStore.testGroup,
          appDownloadFullScreen: 'A',
        })
      } else if (randomNumber > 1 / 3) {
        localStorage.setItem('@alwayz@appDownloadFullScreen', 'B')
        UserStore.setTestGroup({
          ...UserStore.testGroup,
          appDownloadFullScreen: 'B',
        })
      } else {
        localStorage.setItem('@alwayz@appDownloadFullScreen', 'null')
        UserStore.setTestGroup({
          ...UserStore.testGroup,
          appDownloadFullScreen: 'null',
        })
      }
    } else {
      UserStore.setTestGroup({
        ...UserStore.testGroup,
        appDownloadFullScreen,
      })
    }
  }, [])

  useEffect(() => {
    // backendApis.registerLogs({ location: window.location.href })
    const params = dynamicLinkUtils.parseLink(window.location.href)
    backendApis.registerLogs(params)
  }, [window.location.href])

  useEffect(() => {
    if (UserStore.screenName) {
      logEvent(analytics, 'screen_view', {
        firebase_screen: `${UserStore.screenName}_${configs.nodeEnv}`,
        firebase_screen_class: `${UserStore.screenName}_${configs.nodeEnv}`,
      })
    }
  }, [UserStore.screenName])

  // useEffect(() => {
  //   console.log(
  //     `dynamic link params in app: ${JSON.stringify(
  //       UserStore.dynamicLinkParams,
  //     )}`,
  //   )
  //   if (
  //     UserStore.userInfo._id &&
  //     (UserStore.dynamicLinkParams.itemId ||
  //       UserStore.dynamicLinkParams.alwayzItemId)
  //   ) {
  //     UserStore.uploadUserLinkUsage(
  //       window.location.href,
  //       UserStore.dynamicLinkParams,
  //       'received',
  //       UserStore.screenName,
  //     )
  //   }
  // }, [UserStore.userInfo._id, UserStore.dynamicLinkParams])

  useEffect(() => {
    if (AuthStore.needToConnectSocket && UserStore.userInfo._id) {
      UserStore.initializeSocket()
      AuthStore.setNeedToConnectSocket(false)
    }
  }, [AuthStore.needToConnectSocket, UserStore.userInfo._id])

  const checkInitialKakaoState = async () => {
    if (kakao) {
      //   const kakao = window.Kakao
      if (!kakao.isInitialized()) {
        await kakao.init(configs.kakaoJsKey)
      }

      const params = dynamicLinkUtils.parseLink(window.location.href)

      // return

      if (window.location.href.split(`${configs.webUrl}`).length > 1) {
        const rightPath = window.location.href.split(`${configs.webUrl}`)[1]

        const rightPathRaw = rightPath.split('?')[0]
        console.log('rightPathRaw', rightPathRaw)
        if (rightPathRaw === '/1000000melona') {
          return
        } else if (rightPathRaw === '/alfarm-coffee-event2401') {
          return
        } else if (rightPathRaw === '/living-expenses-deal') {
          return
        } else if (rightPathRaw.includes('/items/')) {
          return
        }
      }

      // challenge Share일때 params를 활용하여 다른 페이지로 렌더링
      // if (params.shareType === 'challengeShare') {
      //   console.log('challenge Share Work!!')
      //   // 팀 id 데이터 활용
      //   window.location.href = `${configs.webUrl}/challenge/${params.alwayzChallengeTeamId}`
      // }

      if (!params.code && !params.error) {
        // if (!params.itemId && !params.alwayzItemId) {
        //   const itemId =
        //     window.location.href.match(/\/order-main\/([a-zA-Z\d]*)[?]*/) ||
        //     window.location.href.match(/\/items\/([a-zA-Z\d]*)[?]*/)
        //   if (itemId && itemId[0]) {
        //     params.alwayzItemId = itemId[1]
        //   }
        // }

        // const dynamicLinkParams = { ...params }
        // dynamicLinkParams.itemId = params.alwayzItemId
        // dynamicLinkParams.teamDealId = params.alwayzTeamDealId
        // dynamicLinkParams.invitorId = params.alwayzInvitorId
        // delete dynamicLinkParams.alwayzItemId
        // delete dynamicLinkParams.alwayzTeamDealId
        // delete dynamicLinkParams.alwayzInvitorId
        // delete dynamicLinkParams.code
        // UserStore.setDynamicLinkParams(dynamicLinkParams)

        // if (params.code) {
        //   //   history.push(`/items/${params.itemId}`)
        //   const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
        //   if (
        //     kakaoAccessTokenResult.status === 200 &&
        //     kakaoAccessTokenResult.data.access_token
        //   ) {
        //     await getUserInfoAndRegister(dynamicLinkParams)
        //   }
        // } else if (params.error) {
        //   //   history.push(`/items/${params.itemId}`)
        // } else {

        // window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=25f474ae1c52a3059473823b0a65aaec&redirect_uri=${
        //   configs.backendUrl
        // }/users/login/kakao/auth-code/auto&response_type=code&prompt=none&state=${JSON.stringify(
        //   { ...params, webUrl: configs.webUrl },
        // )}`

        // 2022.04.08 영환 Check 수정 kakao

        window.location.replace(
          `https://kauth.kakao.com/oauth/authorize?client_id=25f474ae1c52a3059473823b0a65aaec&redirect_uri=${
            configs.backendUrl
          }/users/login/kakao/auth-code/auto&response_type=code&prompt=none&state=${JSON.stringify(
            { ...params, webUrl: configs.webUrl },
          )}`,
        )

        // }
      }
      //  else if (params.orderIndicator) {
      //   const dynamicLinkParams = { ...params }
      //   delete dynamicLinkParams.code
      //   if (params.code) {
      //     const kakaoAccessTokenResult = await getKakaoAccessToken(params.code)
      //     if (
      //       kakaoAccessTokenResult.status === 200 &&
      //       kakaoAccessTokenResult.data.access_token
      //     ) {
      //       await getUserInfoAndRegister(dynamicLinkParams)
      //       console.log(`params: ${JSON.stringify(params)}`)
      //       const {
      //         orderIndicator: orderIndicatorTemp,
      //         pg_token: kakaoPayPgTokenTemp,
      //       } = params
      //       setOrderIndicator(orderIndicatorTemp)
      //       setKakaoPayPgToken(kakaoPayPgTokenTemp)
      //       //   await OrderStore.setKakaoPayPgToken(kakaoPayPgToken, orderIndicator)
      //     }
      //   } else {
      //     console.log(`params before login: ${JSON.stringify(params)}`)
      //     window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=25f474ae1c52a3059473823b0a65aaec&redirect_uri=http://192.168.45.230:3000/users/login/kakao/auth-code/auto&response_type=code&prompt=none&state=${JSON.stringify(
      //       params,
      //     )}`
      //   }
      // }

      // checkKakaoLogin()
    }
  }
  // useEffect(() => {
  //   checkInitialKakaoState()
  // }, [])

  // useEffect(() => {
  //   if (OrderStore.closeOrderBottomSheet)
  // }, [OrderStore.closeOrderBottomSheet])

  return (
    <div style={{ backgroundColor: '#e2e2e8' }}>
      <Router>
        <Switch>
          <Route path='/items/questions'>
            <ItemQuestionScreen />
          </Route>
          <Route path='/items/review'>
            <ReviewScreen />
          </Route>
          <Route path='/items/shipping-details'>
            <ShippingMethodDetailScreen />
          </Route>
          <Route path='/items/:itemId'>
            <ItemScreen />
          </Route>
          <Route path='/alfarm-coffee-event2401'>
            <AlfarmCoffeeEventWithDongWookLeeScreen />
          </Route>
          <Route path='/living-expenses-deal'>
            <LivingExpensesDealScreen />
          </Route>
          <Route path='/order-main/:itemId'>
            <OrderMainScreen />
          </Route>
          {/* <Route path='/payment'>
          <div>Item</div>
            </Route> */}
          <Route path='/address/add/second'>
            {/* <div>add address</div> */}
            <AddShippingAddressSecondScreen />
          </Route>
          <Route path='/address/add'>
            {/* <div>add address</div> */}
            <AddShippingAddressScreen />
          </Route>
          <Route path='/address'>
            <ShippingAddressScreen />
          </Route>
          <Route path='/order-end'>
            <OrderEndScreen />
          </Route>
          {/* <Route path='/orders'>
            <OrdersScreen />
          </Route> */}
          <Route path='/send-gift/:giftId'>
            <SendGiftScreen />
          </Route>
          <Route path='/challenge/:challengeId'>
            <ChallengeZeroMarketScreen />
          </Route>
          <Route path='/luckyBox/:documentId'>
            <LuckyBoxEventScreen />
          </Route>
          <Route path='/support-fund-event'>
            <SupportFundEventScreen />
          </Route>
          <Route path='/alfarm-coffee-gift'>
            <AlfarmCoffeeGiftScreen />
          </Route>
          <Route path='/alfarm-choco-gift'>
            <AlfarmChocoGiftScreen />
          </Route>
          <Route path='/one-plus-one'>
            <OnePlusOneScreen />
          </Route>
          <Route path='/gganbu-chicken-event'>
            <GganbuChickenScreen />
          </Route>
          <Route path='/new-years-money'>
            <NewYearsMoneyScreen />
          </Route>
          <Route path='/1000000melona'>
            <ChallengeMelonaScreen />
          </Route>
          <Route path='/sendMoney/:sendMoneyId'>
            <SendMoneyScreen />
          </Route>
          <Route path='/pocketMoney'>
            <PocketMoneyScreen />
          </Route>
          <Route path='/checkInInvite'>
            <CheckInInviteScreen />
          </Route>
          <Route path='/giftcard/:invitorId'>
            <MyGiftCardScreen />
          </Route>
          <Route path='/'>
            <KakaoTestScreen />
          </Route>
        </Switch>
        <AppDownloadModal showModal={UserStore.showAppDownloadModal} />
        <AppDownloadItemModal showModal={UserStore.showAppDownloadItemModal} />
        <BottomSheet
          open={!OrderStore.closeOrderBottomSheet}
          onDismiss={async () => {
            OrderStore.setCloseOrderBottomSheet(true)
            OrderStore.setItemTeamDealIdByIndex(
              null,
              OrderStore.currentItemIndex,
            )
            // await sleep(50)
            OrderStore.setItemIsInitialTeamDealLinkInvited(
              false,
              OrderStore.currentItemIndex,
            )
          }}
        >
          <ItemOrderBottomSheet />
        </BottomSheet>
        <LoadingIndicator />
      </Router>
    </div>
  )
})

export default App
