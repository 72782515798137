import React, { useEffect, useState } from 'react'
import Footer from '../components/footer'
import Layout from '../components/layout'
import ImageManager from '../images/ImageManager'
import backendApis from '../utils/backendApis'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'

const LuckyBoxEventScreen = () => {
  const [data, setData] = useState()
  const params = dynamicLinkUtils.parseLink(window.location.href)

  useEffect(async () => {
    if (params?.documentId) {
      const result = await backendApis.getReceivedLuckyBoxInfo(
        params?.documentId,
      )
      if (result?.status === 200) {
        console.log(result?.data)
        setData(result?.data)
      }
    }
  }, [params?.documentId])

  console.log(params)

  const ItemComponent = ({ item }) => {
    if (!item) return null
    const { itemTitle, mainImageUri, tier } = item

    return (
      <>
        <div
          style={{
            width: '32%',
            height: '56%',
          }}
        >
          <button
            type='button'
            style={{ background: 'none', border: 'none' }}
            onClick={() => {}}
          >
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                height: '70%',
                marginTop: 8,
              }}
            >
              <img
                src={mainImageUri}
                alt=''
                style={{ height: 84, width: 84 }}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                fontFamily: 'GmarketSans',
                fontWeight: 300,
                fontSize: 12,
                marginTop: 8,
                color: 'black',
              }}
            >
              {itemTitle}
            </div>
          </button>
        </div>
      </>
    )
  }

  const LuckyBoxComponent = ({ luckyBoxItemList }) => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          width: '80vw',
          height: '50vh',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          margin: 8,
          marginTop: 56,
          padding: 2,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            borderWidth: 1.5,
            fontFamily: 'GmarketSans',
            fontWeight: 700,
            marginTop: 8,
          }}
        >
          <div style={{ zIndex: 2, marginTop: -48, marginBottom: 8 }}>
            <ImageManager source='LuckyBox' alt='' size={72} />
          </div>
          친구가 보낸
          <span style={{ color: '#E63423', paddingRight: 4 }}> 행운상자 </span>
        </div>
        <div
          style={{
            borderBottom: '#ebebeb solid',
            borderBottomWidth: 1.5,
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 4,
            marginBottom: 12,
            boxSizing: 'border-box',
            padding: 8,
          }}
        />
        <div
          style={{
            width: '100%',
            height: '64%',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {luckyBoxItemList?.map((each, index) => (
            <ItemComponent key={each?.tier} item={each} index={index} />
          ))}
        </div>
      </div>
    </>
  )

  return (
    <Layout>
      <div style={{ height: '93%' }}>
        <div
          style={{
            display: 'flex',
            height: '150vh',
            width: '100vw',
            background:
              'linear-gradient(180deg, #f67815 0%, #f98611 3.65%, #E63323 94.79%, #e83b21 100%)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ height: 120, marginTop: 28 }}>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 32,
                color: 'white',
                textAlign: 'center',
              }}
            >
              친구가 보낸
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 32,
                color: 'white',
                textAlign: 'center',
              }}
            >
              <span style={{ fontWeight: 900 }}>행운상자</span>가 왔어요
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
                paddingTop: 4,
                paddingBottom: 2,
                marginTop: 8,
              }}
            >
              친구가 행운 상자를 선물했어요.
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
              }}
            >
              아래의 상품 중 1개를{' '}
              <span
                style={{
                  fontWeight: 900,
                  marginLeft: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: '#ED531C',
                }}
              >
                100% 확률
              </span>
              로 드려요
            </div>
          </div>
          <div />
          <LuckyBoxComponent luckyBoxItemList={data?.itemList} />
        </div>
      </div>

      <Footer
        title='행운 상자 열기'
        // clickable
        backgroundColor='#FFA13D'
        onClick={async () => {
          window.location.href = `https://alwayzshop.ilevit.com/app`
        }}
      />
    </Layout>
  )
}

export default LuckyBoxEventScreen
