import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import UserStore from '../stores/UserStore'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import ImageManager from '../images/ImageManager'
import ViralEngineItemStore from '../stores/ViralEngineItemStore'
import backendApis from '../utils/backendApis'
import WhiteTextTimer from '../components/texts/WhiteTextTimer'
import { CopyToClipboard } from 'react-copy-to-clipboard'

// 사진
import AlwayzMelona from '../images/AlwayzMelona.png'
import MelonaCopy from '../images/MelonaCopy.png'
import EventDone from '../images/EventDone.png'
import MelonaImage from '../images/MelonaImage.png'
import EventProduct from '../images/EventProduct.png'
import Prize1 from '../images/Prize1.png'
import Prize2 from '../images/Prize2.png'
import Prize3 from '../images/Prize3.png'
import FirstPrize from '../images/FirstPrize.png'
import SecondPrize from '../images/SecondPrize.png'
import ThirdPrize from '../images/ThirdPrize.png'

const ChallengeMelonaScreen = observer(() => {
  const history = useHistory()

  const [totalJoinNumber, setTotalJoinNumber] = useState(0)

  useEffect(() => {}, [
    UserStore.isLoadingChallengeScreen,
    UserStore.screenName,
  ])

  useEffect(async () => {
    const data = await backendApis.getTotalJoinedNumber()
    if (data.status === 200) {
      setTotalJoinNumber(
        data?.data?.find((x) => x.hourInterval === 'total')?.TotalNumber,
      )
    }
    UserStore.setScreenName('ChallengeMelonaScreen')
    UserStore.setIsLoadingChallengeScreen(false)
  }, [])

  useEffect(async () => {
    await ViralEngineItemStore.loadPublicItems()
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: 1,
      }}
    >
      <div
        style={{
          height: 48,
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '25%', marginTop: 4 }}>
          <ImageManager source='AlwayzFullLogoRed' />
        </div>
        <img src={MelonaImage} alt='' width={40} />
      </div>
      <div style={{ position: 'relative' }}>
        <div
          className='go-back'
          style={{
            position: 'absolute',
            backgroundColor: '#F2FFE4',
            width: window.innerWidth,
            height: window.innerWidth + 48,
          }}
        >
          <div
            className='go-front'
            style={{
              // width: '100%',
              justifyContent: 'center',
              // backgroundColor: '#000',
              display: 'flex',
              marginTop: 24,
              marginRight: 16,
              marginLeft: 16,
            }}
          >
            <img src={MelonaCopy} alt='' width='100%' />
          </div>
          <div
            style={{
              // width: '100%',
              justifyContent: 'center',
              // backgroundColor: '#000',
              display: 'flex',
              marginTop: 24,
              marginRight: 20,
              marginLeft: 20,
            }}
          >
            <img src={AlwayzMelona} alt='' width='100%' />
          </div>
          <div style={{ marginBottom: 40 }}>
            <div
              className='box-sizing'
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 18,
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  color: '#FF3E3E',
                }}
              >
                남은 시간{' '}
                <WhiteTextTimer
                  onTimeEnd={() => {}}
                  timeStamp={new Date('2022-05-03T11:00:00.000Z')}
                  timerMinutes={0}
                />
              </div>
            </div>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                width: '100%',
                display: 'flex',
              }}
            >
              <button
                className='box-sizing sale-button'
                style={{
                  height: 51,
                  backgroundColor: '#FF3E3E',
                  borderRadius: 25.5,
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: '#fff',
                  width: window.innerWidth - 96,
                  borderWidth: 1,
                  borderColor: '#E9E6E6',
                }}
                onClick={() => {
                  window.location.href = `https://alwayzshop.ilevit.com/app`
                }}
              >
                나도 공짜 메로나 받기
              </button>
            </div>
          </div>
          <div
            className='box-sizing'
            style={{
              width: '100%',
            }}
          >
            <div
              className='box-sizing'
              style={{
                marginRight: 20,
                marginLeft: 20,
                backgroundColor: '#fafafa',
                borderRadius: 16,
                paddingTop: 12,
                paddingBottom: 20,
                boxShadow: ' 0px 10px 20px rgba(72, 104, 74, 0.12)',
              }}
            >
              <div
                className='box-sizing'
                style={{
                  marginTop: 8,
                  marginBottom: 12,
                }}
              >
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      lineHeight: '32px',
                    }}
                  >
                    지금까지
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      lineHeight: '32px',
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {totalJoinNumber}명
                    </div>
                    이 모였어요
                  </div>
                </div>
                <div
                  className='box-sizing'
                  style={{
                    paddingLeft: 20,
                    paddingRight: 32,
                  }}
                >
                  <div
                    className='box-sizing'
                    style={{
                      position: 'relative',
                      height: 24,
                      width: '100%',
                      backgroundColor: '#E0F4BF',
                      borderRadius: 12,
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div
                      className='box-sizing'
                      style={{
                        height: 24,
                        width: `${(100 * totalJoinNumber) / 1000000}%`,
                        backgroundColor: '#A7E26B',
                        borderRadius: 12,
                      }}
                    />
                    <img
                      style={{
                        position: 'absolute',
                        top: -8,
                        right: -16,
                      }}
                      src={EventDone}
                      alt=''
                      width={44}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='box-sizing'
            style={{
              width: '100%',
              marginTop: 48,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  fontSize: '24px',
                  lineHeight: '32px',
                  fontWeight: 800,
                }}
              >
                모인 사람 수 맞추면
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: 8,
              }}
            >
              <div
                style={{
                  fontSize: '24px',
                  lineHeight: '32px',
                  fontWeight: 800,
                }}
              >
                신세계 상품권 백만원!
              </div>
            </div>
          </div>
          <div
            className='box-sizing'
            style={{
              width: '100%',
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontWeight: 500,
                }}
              >
                가장 근접한 수치를 맞춘 분께 상품을 드려요
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: 8,
              }}
            >
              <div
                style={{
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontWeight: 500,
                }}
              >
                마감 10분 전까지만 입력할 수 있어요
              </div>
            </div>
          </div>
          <div
            style={{
              // width: '100%',
              justifyContent: 'center',
              // backgroundColor: '#000',
              display: 'flex',
              marginTop: 24,
              marginRight: 44,
              marginLeft: 44,
            }}
          >
            <img src={EventProduct} alt='' width='70%' />
          </div>
          <div
            className='box-sizing'
            style={{
              width: '100%',
            }}
          >
            <div
              className='box-sizing'
              style={{
                marginRight: 20,
                marginLeft: 20,
                backgroundColor: '#fafafa',
                borderRadius: 16,
                paddingTop: 12,
                paddingBottom: 20,
                boxShadow: ' 0px 10px 20px rgba(72, 104, 74, 0.12)',
              }}
            >
              <div
                className='box-sizing'
                style={{
                  marginTop: 8,
                  marginBottom: 12,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      lineHeight: '32px',
                      fontWeight: 700,
                      color: '#FF3E3E',
                    }}
                  >
                    24시간동안&nbsp;
                  </div>
                  <div
                    style={{
                      fontSize: '24px',
                      lineHeight: '32px',
                      fontWeight: 700,
                    }}
                  >
                    모일 사람 수를
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      lineHeight: '32px',
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      예측해주세요!
                    </div>
                  </div>
                </div>
                <div
                  className='box-sizing'
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 500,
                    }}
                  >
                    가장 가깝게 맞춘&nbsp;
                  </div>
                </div>
                <div
                  className='box-sizing'
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 700,
                    }}
                  >
                    1등
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 500,
                    }}
                  >
                    에게&nbsp;
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 700,
                    }}
                  >
                    신세계 상품권 100만원
                  </div>
                </div>
                <div
                  className='box-sizing'
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 700,
                    }}
                  >
                    2등부터 50등
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 500,
                    }}
                  >
                    에게&nbsp;
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 700,
                    }}
                  >
                    신세계 상품권 10만원
                  </div>
                </div>
                <div
                  className='box-sizing'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 40,
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        width: 135,
                        marginRight: 8,
                        borderBottom: '2px solid rgb(0,0,0)',
                        fontSize: '24px',
                        lineHeight: '32px',
                        fontWeight: 700,
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      ???
                    </div>
                    <div
                      style={{
                        fontSize: '24px',
                        lineHeight: '32px',
                        fontWeight: 700,
                      }}
                    >
                      명
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  width: '100%',
                  display: 'flex',
                  marginTop: 32,
                }}
              >
                <button
                  className='box-sizing sale-button'
                  style={{
                    height: 51,
                    backgroundColor: '#FF3E3E',
                    borderRadius: 25.5,
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#fff',
                    flex: 1,
                    borderWidth: 1,
                    borderColor: '#E9E6E6',
                  }}
                  onClick={() => {
                    window.location.href = `https://alwayzshop.ilevit.com/app`
                  }}
                >
                  예측하고 상품권 받기
                </button>
              </div>
            </div>
            <div
              className='box-sizing'
              style={{
                marginRight: 20,
                marginLeft: 20,
                backgroundColor: '#fafafa',
                borderRadius: 16,
                paddingTop: 12,
                paddingBottom: 20,
                marginTop: 12,
                boxShadow: ' 0px 10px 20px rgba(72, 104, 74, 0.12)',
              }}
            >
              <div
                className='box-sizing'
                style={{
                  marginTop: 8,
                  marginBottom: 12,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      lineHeight: '32px',
                      fontWeight: 700,
                      color: '#FF3E3E',
                    }}
                  >
                    두 시간동안&nbsp;
                  </div>
                  <div
                    style={{
                      fontSize: '24px',
                      lineHeight: '32px',
                      fontWeight: 700,
                    }}
                  >
                    모일 사람 수를
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      lineHeight: '32px',
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      예측해주세요!
                    </div>
                  </div>
                </div>
                <div
                  className='box-sizing'
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 500,
                    }}
                  >
                    두 시간마다 가장 가깝게 맞춘 분들에게&nbsp;
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 700,
                    }}
                  >
                    푸짐한 상품
                  </div>
                  <div
                    style={{
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 500,
                    }}
                  >
                    을 제공해요&nbsp;
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    display: 'flex',
                    backgroundColor: '#f4f4f4',
                    marginTop: 24,
                  }}
                />
                <div
                  className='box-sizing'
                  style={{
                    display: 'flex',
                    marginTop: 24,
                    marginBottom: 24,
                  }}
                >
                  <div style={{ display: 'flex' }} />
                </div>
                <div
                  className='box-sizing'
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                    alignItems: 'center',
                    marginBottom: 4,
                  }}
                >
                  <img
                    src={Prize1}
                    alt=''
                    width={48}
                    height={72}
                    style={{ marginRight: 14 }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      verticalAlign: 'center',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        height: 74,
                        alignItems: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginLeft: 13,
                          marginTop: 4,

                          borderRadius: 24,
                          width: 68,
                          height: 68,
                          marginBottom: 4,
                          marginRight: 8,
                          overflow: 'hidden',
                        }}
                      >
                        <img src={FirstPrize} alt='' width={68} height={68} />
                      </div>
                      <div
                        style={{
                          fontSize: '18px',
                          lineHeight: '22px',
                          fontWeight: 'bold',
                        }}
                      >
                        X 1
                      </div>
                    </div>
                    <div
                      className='border-box'
                      style={{
                        fontSize: '16px',
                        lineHeight: '22px',
                        fontWeight: 500,
                        verticalAlign: 'center',
                        marginTop: 26,
                      }}
                    >
                      신세계 상품권 30만원
                    </div>
                  </div>
                </div>
                <div
                  className='box-sizing'
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                    alignItems: 'center',
                    marginBottom: 4,
                  }}
                >
                  <img
                    src={Prize2}
                    alt=''
                    width={48}
                    height={72}
                    style={{ marginRight: 14 }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      verticalAlign: 'center',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        height: 74,
                        alignItems: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginLeft: 13,
                          marginTop: 4,
                          borderRadius: 24,
                          width: 68,
                          height: 68,
                          marginBottom: 4,
                          marginRight: 8,
                          overflow: 'hidden',
                        }}
                      >
                        <img src={SecondPrize} alt='' width={68} height={68} />
                      </div>
                      <div
                        style={{
                          fontSize: '18px',
                          lineHeight: '22px',
                          fontWeight: 'bold',
                        }}
                      >
                        X 5
                      </div>
                    </div>
                    <div
                      className='border-box'
                      style={{
                        fontSize: '16px',
                        lineHeight: '22px',
                        fontWeight: 500,
                        verticalAlign: 'center',
                        marginTop: 26,
                      }}
                    >
                      BBQ 황금 올리브
                    </div>
                  </div>
                </div>
                <div
                  className='box-sizing'
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={Prize3}
                    alt=''
                    width={48}
                    height={72}
                    style={{ marginRight: 14 }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      verticalAlign: 'center',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        height: 74,
                        alignItems: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginLeft: 13,
                          marginTop: 4,
                          borderRadius: 24,
                          width: 68,
                          height: 68,
                          marginBottom: 4,
                          marginRight: 8,
                          overflow: 'hidden',
                        }}
                      >
                        <img src={ThirdPrize} alt='' width={68} height={68} />
                      </div>
                      <div
                        style={{
                          fontSize: '18px',
                          lineHeight: '22px',
                          fontWeight: 'bold',
                        }}
                      >
                        X 10
                      </div>
                    </div>

                    <div
                      className='border-box'
                      style={{
                        fontSize: '16px',
                        lineHeight: '22px',
                        fontWeight: 500,
                        verticalAlign: 'center',

                        marginTop: 26,
                      }}
                    >
                      메로나 10개
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  width: '100%',
                  display: 'flex',
                  marginTop: 32,
                }}
              >
                <button
                  className='box-sizing sale-button'
                  style={{
                    height: 51,
                    backgroundColor: '#FF3E3E',
                    borderRadius: 25.5,
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#fff',
                    flex: 1,
                    borderWidth: 1,
                    borderColor: '#E9E6E6',
                  }}
                  onClick={() => {
                    window.location.href = `https://alwayzshop.ilevit.com/app`
                  }}
                >
                  예측하고 상품권 받기
                </button>
              </div>
            </div>
            <div
              className='box-sizing'
              style={{
                width: '100%',
                marginTop: 48,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    fontSize: '24px',
                    lineHeight: '32px',
                    fontWeight: 800,
                  }}
                >
                  백만명이 모일 수 있게
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginBottom: 8,
                }}
              >
                <div
                  style={{
                    fontSize: '24px',
                    lineHeight: '32px',
                    fontWeight: 800,
                  }}
                >
                  친구에게 알려보세요
                </div>
              </div>
            </div>
            <div
              className='box-sizing'
              style={{
                width: '100%',
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 500,
                  }}
                >
                  24시간 안에 백만명이 모이지 못하면
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginBottom: 8,
                }}
              >
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 500,
                  }}
                >
                  이벤트가 무산돼요
                </div>
              </div>
            </div>
            <div
              className='border-box'
              style={{
                display: 'flex',
                marginLeft: 48,
                marginRight: 48,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  marginTop: 32,
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <CopyToClipboard text='https://alwayzshop.page.link/1000000melona'>
                  <button
                    className='box-sizing sale-button'
                    style={{
                      width: '70%',
                      height: 51,
                      backgroundColor: '#FF3E3E',
                      borderRadius: 25.5,
                      fontSize: 18,
                      fontWeight: 'bold',
                      color: '#fff',
                      flex: 1,
                      borderWidth: 1,
                      borderColor: '#E9E6E6',
                    }}
                    onClick={() => {
                      alert('링크가 복사되었어요. 링크를 공유해 소문내 주세요!')
                    }}
                  >
                    친구에게 알리기
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div style={{ height: 120 }} />
        </div>
      </div>
    </div>
  )
})

export default ChallengeMelonaScreen
