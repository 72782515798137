import React from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import ImageManager from '../../images/ImageManager'

const BasicHeader = observer(({ title, orderMain = false }) => {
  const history = useHistory()

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          // top: orderMain ? 72 : 0,
          maxWidth: '480px',
          marginLeft: 'auto',
          marginRight: 'auto',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          backgroundColor: '#FFF',
          display: 'flex',
          height: 56,
          border: 'solid',
          borderWidth: 0,
          borderBottomWidth: 0.5,
          borderColor: '#E0E0E0',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 16,
        }}
      >
        <button
          style={{
            position: 'absolute',
            left: 0,
            top: 12,
            border: 'none',
            backgroundColor: '#FFF',
          }}
          type='button'
          onClick={() => {
            history.goBack()
          }}
        >
          <ImageManager source='Back' size={24} />
        </button>
        {title}
      </div>
      {/* <div style={{ height: orderMain ? 72 + 56 : 56 }} /> */}
      <div style={{ height: 56 }} />
    </div>
  )
})

export default BasicHeader
