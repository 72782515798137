import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
// import OrderStore from '../stores/OrderStore'
import ItemStore from '../stores/ItemStore'
import UserStore from '../stores/UserStore'
import Loader from 'react-loader-spinner'

const LoadingIndicator = observer(() => {
  return (
    UserStore.isLoading && (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0',
          bottom: '0',
        }}
      >
        <Loader type='Oval' color='#EA3F49' height='40' width='40' />
      </div>
    )
  )
})

export default LoadingIndicator
