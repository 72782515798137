import React, { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'

function NewYearsMoneyScreen() {
  const params = dynamicLinkUtils.parseLink(window.location.href)

  return (
    <>
      <Layout>
        <div
          style={{
            width: '100%',
            height: '100%',
            marginBottom: 10,
            fontFamily: 'GmarketSans',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
            position: 'relative',
          }}
        >
          <img
            src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/0bf84746-1459-4253-aeeb-f7c4a4c3e22f.png'
            alt=''
            style={{
              width: '100vw',
              position: 'absolute',
            }}
          />
          <div
            style={{
              backgroundColor: '#292D36',
              height: 680,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                marginTop: 100,
                fontSize: 24,
                color: '#fff',
              }}
            >
              친구 초대하고
            </div>
            <div
              style={{
                marginTop: 4,
                fontSize: 24,
                color: '#fff',
                fontWeight: 'bold',
              }}
            >
              <span
                style={{
                  color: '#FFF744',
                  borderBottom: '2px #fff744 solid',
                }}
              >
                세뱃돈 11만원
              </span>{' '}
              받아가세요!
            </div>
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/ecf7c5d1-61aa-4399-8b88-1e23af3a3eb1.png'
              alt=''
              style={{
                marginTop: 36,
                height: 200,
                marginBottom: 16,
              }}
            />
            <button
              type='button'
              style={{
                border: 'none',
                background: 'none',
                width: '80vw',
              }}
              onClick={() => {
                window.location.href = `https://alwayzshop.ilevit.com/app`
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'GmarketSans',
                  color: '#292D36',
                  fontSize: 20,
                  fontWeight: 'bold',
                  borderRadius: 20,
                }}
              >
                <img
                  style={{
                    width: '88%',
                  }}
                  src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/b850c891-7d0d-4dcf-ad51-98d13252b58b.png'
                  alt=''
                />
              </div>
            </button>
          </div>
          <div
            style={{
              marginTop: 8,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              width: '100vw',
            }}
          />
        </div>
      </Layout>
    </>
  )
}
export default NewYearsMoneyScreen
