import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import Timer from './Timer'
import ImageManager from '../../images/ImageManager'

const EventTextBox = ({
  isViralItem = false,
  isLotteryItem = false,
  isUnrestartableItem = false,
  isNewUserOnlyItem = false,
  isCouponExchangeItem = false,
  isEventDeal = false,
  itemId,
  itemText,
  numberOfTeamText,
  deadlineText,
  announceText,
  isPushNotified,
  teamPurchasePrice,
}) => {
  const NoticeComponent = ({
    title = '',
    subText1 = '',
    subText2 = '',
    subText3 = '',
  }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 20 }}>
        <div style={{ width: 90, fontSize: 16, fontWeight: 'bold' }}>
          {title}
          {/* <BlackText text={title} fontSize={16} fontWeight='bold' /> */}
        </div>
        <div
          style={{ flex: 1, marginLeft: 16, fontSize: 16, color: '#9E9E9E' }}
        >
          {/* <GrayText text={subText1} fontSize={16} dark /> */}
          {subText1}
          <div style={{ height: 4, fontSize: 16, color: '#9E9E9E' }} />
          {/* <GrayText text={subText2} fontSize={16} dark /> */}
          {subText2}
          {subText3 !== '' && (
            <>
              <div style={{ height: 4, fontSize: 16, color: '#9E9E9E' }} />
              {/* <GrayText text={subText2} fontSize={16} dark /> */}
              {subText3}
            </>
          )}
        </div>
      </div>
    )
  }

  const LotteryBannerC = ({
    itemText = '',
    numberOfTeam = '',
    deadlineText = '',
  }) => {
    if (isEventDeal) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#FEE9ED',
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <div style={{ height: 32, backgroundColor: '#FEE9ED' }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                borderRadius: 8,
                backgroundColor: '#FFFFFF',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    height: 32,
                    marginTop: -16,
                    width: 150,
                    borderRadius: 24,
                    backgroundColor: '#EA3F49',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#FFFFFF',
                  }}
                >
                  만인딜 상품 안내
                </div>
              </div>
              <div style={{ height: 16 }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <NoticeComponent
                  title='구매 방식'
                  subText1='혼자서도 구매가 가능합니다.'
                  subText2='구매 횟수는 1회로 한정됩니다.'
                />
                <NoticeComponent
                  title='이벤트 기간'
                  subText1='아이템별 이벤트 기간은'
                  subText2='약 7일입니다.'
                />
                <NoticeComponent
                  title='배송 안내'
                  subText1='일정 구매 인원이 충족되거나,'
                  subText2='이벤트 기간이 종료될 경우'
                  subText3='3일 이내로 배송이 시작됩니다.'
                />
              </div>
            </div>
            <div style={{ height: 20 }} />
          </div>
        </div>
      )
    }
    if (!isEventDeal) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#FEE9ED',
              // backgroundColor: 'red',
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <div
              style={{
                display: 'flex',
                paddingTop: 8,
                paddingBottom: 8,
                // paddingVertical: 8,
                width: '100%',
                backgroundColor: '#FFFFFF',
                // marginLeft: 16,
                marginTop: 16,
                borderRadius: 8,
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div>
                  <ImageManager source='LotteryWinner' size={120} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    // paddingHorizontal: 12,
                    paddingRight: 12,
                    paddingLeft: 12,
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ fontSize: 24, fontWeight: 'bold' }}>
                    <div style={{ height: 4 }} />
                    {/* <BlackText text='추첨상품' fontSize={24} fontWeight='bold' /> */}
                    추첨상품
                  </div>
                  <div style={{ fontSize: 16 }}>
                    <div style={{ height: 6 }} />
                    {itemText}
                    {/* <BlackText text={itemText} fontSize={14} /> */}
                    <div style={{ height: 12 }} />팀 단위 추첨
                    {/* <GrayText text='팀 단위 추첨' fontSize={14} /> */}
                    <div style={{ height: 4 }} />
                    {numberOfTeam}
                    {/* <GrayText text={`${numberOfTeam}`} fontSize={14} /> */}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: 40, backgroundColor: '#FEE9ED' }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'flex-start',
                // paddingHorizontal: 16,
                borderRadius: 8,
                backgroundColor: '#FFFFFF',
                // backgroundColor: 'red',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    height: 32,
                    marginTop: -16,
                    width: 127,
                    borderRadius: 24,
                    backgroundColor: '#EA3F49',
                    justifyContent: 'center',

                    alignItems: 'center',
                    // marginTop: 'center',
                    // flexDirection: 'row',
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#FFFFFF',
                  }}
                >
                  추첨 안내
                  {/* <WhiteText text='추첨 안내' m fontSize={18} fontWeight='bold' /> */}
                </div>
              </div>
              <div style={{ height: 16 }} />
              <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                <NoticeComponent
                  title='팀구매'
                  subText1='새 팀구매 열기는 제한 없음'
                  subText2={
                    isNewUserOnlyItem
                      ? '열린 팀 참여는 신규 고객님만 가능'
                      : '링크를 받은 팀구매 참여 1회 가능'
                  }
                  subText3={
                    isCouponExchangeItem ? '팀 성사만 해도 즉시 쿠폰 지급' : ''
                  }
                />
                <NoticeComponent
                  title='마감 및 발표'
                  subText1={`${deadlineText} 마감`}
                  subText2={announceText || '오후 8시 발표'}
                />
                <NoticeComponent
                  title='발표 방식'
                  subText1={
                    isPushNotified
                      ? '푸시 알림 발송'
                      : '팀 성사자 전원에게 문자 발송'
                  }
                  subText2='내 정보 > 내 주문 에서도 확인 가능'
                />
                <NoticeComponent
                  title='확률 높이는팁'
                  subText1='팀 구매 여러 번 성사시키기'
                  subText2={
                    isNewUserOnlyItem ? '' : '팀원 중 신규 가입자 비율 높이기'
                  }
                />
              </div>
            </div>

            <div style={{ height: 12 }} />
          </div>
          <div style={{ backgroundColor: '#FEE9ED', paddingBottom: 16 }}>
            <div
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                fontSize: 14,
                color: '#9E9E9E',
              }}
            >
              • 당첨된 고객님은 전부 사진 구매 후기 작성을 부탁드려요.
              <div style={{ height: 8 }} />
              • 후기 작성을 안하신 고객님께는 별도의 연락을 드릴 수 있어요.
              <div style={{ height: 8 }} />
              • 해당 이벤트는 예고 없이 조기 마감 될 수 있어요.
              <div style={{ height: 8 }} />
            </div>
            {teamPurchasePrice > 0 && (
              <div
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  fontSize: 14,
                  color: '#9E9E9E',
                }}
              >
                {/* <GrayText
              text='• 팀구매 시간 만료 시 환불 가능해요.'
              fontSize={14}
              dark
            /> */}
                • 팀구매 시간 만료 시 환불 가능해요.
                <div style={{ height: 8 }} />
                {/* <GrayText
              text='• 팀원 없이 1인일 때는 언제나 환불 가능해요.'
              fontSize={14}
              dark
            /> */}
                • 팀원 없이 1인일 때는 언제나 환불 가능해요.
                <div style={{ height: 8 }} />
                {/* <GrayText
              text='• 상품 당첨 안 될 시 전액 환불돼요.'
              fontSize={14}
              dark
            /> */}
                • 상품 당첨 안 될 시 전액 환불돼요.
                <div style={{ height: 8 }} />
                {/* <GrayText
              text='• 환불 신청하지 않아도 발표 후 24시간 이내 자동 환불 돼요.'
              fontSize={14}
              dark
            /> */}
                • 환불 신청하지 않아도 발표 후 24시간 이내 자동 환불 돼요.
                <div style={{ height: 8 }} />
                {/* <GrayText
              text='• 앱 내 환불 이후 카드사 승인까지 3~7일 소요돼요.'
              fontSize={14}
              dark
            /> */}
                • 앱 내 환불 이후 카드사 승인까지 3~7일 소요돼요.
              </div>
            )}
          </div>
        </div>
      )
    }
  }
  if (isEventDeal) {
    return (
      <>
        {' '}
        <LotteryBannerC
          itemText={itemText}
          numberOfTeam={numberOfTeamText}
          deadlineText={deadlineText}
        />
      </>
    )
  }
  if (isNewUserOnlyItem && !isLotteryItem) {
    return (
      <div
        style={{
          backgroundColor: '#FFF',

          paddingBottom: 8,
        }}
      >
        <div
          style={{
            paddingTop: 24,
            paddingBottom: 24,
            paddingLeft: 16,
            backgroundColor: '#F9F9F9',
          }}
        >
          <div style={{ color: '#EA3F49', fontWeight: 'bold', fontSize: 18 }}>
            100% 드리는 초특가 상품
          </div>
          <div style={{ height: 12 }} />
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>
            횟수 제한 없이 새 팀구매를 열 수 있어요.
          </div>
          <div style={{ height: 4 }} />
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>
            열린 팀 참여는 올웨이즈 신규 고객님만 가능해요.
          </div>
          <div style={{ height: 16 }} />
          <div style={{ color: '#BDBDBD', fontSize: 14 }}>추가 안내</div>
          <div style={{ height: 4 }} />
          <div style={{ color: '#BDBDBD', fontSize: 14 }}>
            • 팀구매 모집 시간 만료 후 환불 가능해요.
          </div>
          <div style={{ height: 4 }} />
          <div style={{ color: '#BDBDBD', fontSize: 14 }}>
            • 팀원 없이 1명만 있을 때는 항상 환불 가능해요.
          </div>
          <div style={{ height: 4 }} />
          <div style={{ color: '#BDBDBD', fontSize: 14 }}>
            • 준비 수량 소진 시 별도의 안내 없이 품절될 수 있어요.
          </div>
          <div style={{ height: 4 }} />
          <div style={{ color: '#BDBDBD', fontSize: 14 }}>
            • 품절 이후에는 더 이상 팀구매 인원을 모을 수 없어요.
          </div>
        </div>

        <div style={{ height: 16 }} />
      </div>
    )
  }
  if (isUnrestartableItem) {
    return (
      <div
        style={{
          backgroundColor: '#FFF',
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: 8,
        }}
      >
        <div
          style={{
            paddingLeft: 12,
            paddingTop: 12,
            paddingBottom: 12,
            backgroundColor: '#FAFAFA',
            borderRadius: 8,
          }}
        >
          <div style={{ color: '#EA3F49', fontWeight: 'bold', fontSize: 16 }}>
            초특가 상품
          </div>
          <div style={{ height: 12 }} />
          <div style={{ fontWeight: 'bold', fontSize: 14 }}>
            참여하기 : 팀구매 링크로만 1회 참여 가능해요.
          </div>
          <div style={{ height: 4 }} />
          <div style={{ fontWeight: 'bold', fontSize: 14 }}>
            시작하기 : 누구나 횟수 제한 없이 시작할 수 있어요!
          </div>
          <div style={{ height: 4 }} />
          <div style={{ color: '#999999', fontSize: 14 }}>
            팀구매 실패 시 재도전은 불가해요.
          </div>
        </div>
      </div>
    )
  }
  if (isLotteryItem) {
    // 로터리 아이템 블루베리
    if (
      itemId === '61b825c95f65496a6b021fda' ||
      itemId === '61c55af2ac0d2522f26e56ce'
    ) {
      return (
        <div>
          <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
          <ImageManager source='LotteryBanner1' />
        </div>
      )
    }
    if (
      itemId === '61b88c082a0621d7b2effc12' ||
      itemId === '61c7e7414110175e882ec688'
    ) {
      return (
        <div>
          <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
          <ImageManager source='LotteryBanner2' />
        </div>
      )
    }
    if (
      // itemId === '61b8a1bcb1ad790b5e9355ef' ||
      itemId === '61c822ebf4e1c162f5493009'
    ) {
      return (
        <div>
          <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
          <ImageManager source='LotteryBanner3' />
        </div>
      )
    }
    if (itemId === '61cd31265892a336ce6f3501') {
      // 고구마
      return (
        <div>
          <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
          <ImageManager source='LotteryBanner5' />
        </div>
      )
    }
    if (
      itemId === '61cd678447f5303f7cfa920e' ||
      itemId === '612e60c4ce136a1f4454c8c8'
    ) {
      // 고구마
      return (
        <div>
          <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
          <ImageManager source='LotteryBanner6' />
        </div>
      )
    }
    if (
      // itemId === '61b8a1bcb1ad790b5e9355ef' ||
      itemId === '61d1175a656be94762fc9038'
    ) {
      // 테팔 후라이팬 3차
      return (
        <div>
          <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
          <ImageManager source='LotteryBanner7' />
        </div>
      )
    }
    if (
      itemId === '61b8a1bcb1ad790b5e9355ef' ||
      itemId === '61d11d8afd1296490a7a8d95'
    ) {
      // JMW 2차
      return (
        <div>
          <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
          <ImageManager source='LotteryBanner8' />
        </div>
      )
    }
    return (
      <LotteryBannerC
        itemText={itemText}
        numberOfTeam={numberOfTeamText}
        deadlineText={deadlineText}
      />
      // <div>
      //   <div
      //     style={{
      //       display: 'flex',
      //       flexDirection: 'column',
      //       alignItems: 'center',
      //       backgroundColor: '#FEE9ED',
      //       // backgroundColor: 'red',
      //     }}
      //   >
      //     <div
      //       style={{
      //         display: 'flex',
      //         paddingVertical: 8,
      //         width: '100%',
      //         backgroundColor: '#FFFFFF',
      //         // marginLeft: 16,
      //         marginTop: 16,
      //         borderRadius: 8,
      //         justifyContent: 'center',
      //       }}
      //     >
      //       <div
      //         style={{
      //           display: 'flex',
      //           flexDirection: 'row',
      //           alignItems: 'center',
      //         }}
      //       >
      //         <div>
      //           <ImageManager source='LotteryWinner' size={120} />
      //         </div>
      //         <div
      //           style={{
      //             display: 'flex',
      //             flexDirection: 'column',
      //             paddingHorizontal: 12,
      //             justifyContent: 'center',
      //           }}
      //         >
      //           <div style={{ fontSize: 24, fontWeight: 'bold' }}>
      //             <div style={{ height: 4 }} />
      //             {/* <BlackText text='추첨상품' fontSize={24} fontWeight='bold' /> */}
      //             추첨상품
      //           </div>
      //           <div style={{ fontSize: 14 }}>
      //             <div style={{ height: 6 }} />
      //             JMW 에어비 드라이기
      //             {/* <BlackText text={itemText} fontSize={14} /> */}
      //             <div style={{ height: 12 }} />팀 단위 추첨
      //             {/* <GrayText text='팀 단위 추첨' fontSize={14} /> */}
      //             <div style={{ height: 4 }} />
      //             80팀(640명)
      //             {/* <GrayText text={`${numberOfTeam}`} fontSize={14} /> */}
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //     <div style={{ height: 40, backgroundColor: '#FEE9ED' }} />
      //     <div
      //       style={{
      //         display: 'flex',
      //         flexDirection: 'column',
      //         width: '100%',
      //         alignItems: 'center',
      //         paddingHorizontal: 16,
      //         borderRadius: 8,
      //         backgroundColor: '#FFFFFF',
      //       }}
      //     >
      //       <div
      //         style={{
      //           display: 'flex',
      //           height: 32,
      //           marginTop: -16,
      //           width: 127,
      //           borderRadius: 24,
      //           backgroundColor: '#EA3F49',
      //           justifyContent: 'center',

      //           alignItems: 'center',
      //           // marginTop: 'center',
      //           // flexDirection: 'row',
      //           fontSize: 18,
      //           fontWeight: 'bold',
      //         }}
      //       >
      //         추첨 안내
      //         {/* <WhiteText text='추첨 안내' m fontSize={18} fontWeight='bold' /> */}
      //       </div>
      //       <div style={{ height: 16 }} />
      //       <NoticeComponent
      //         title='팀구매'
      //         subText1='새 팀구매 열기는 제한 없음'
      //         subText2='링크를 받은 팀구매 참여 1회 가능'
      //       />
      //       <NoticeComponent
      //         title='마감 및 발표'
      //         subText1='토요일 오후 2시 마감'
      //         subText2='오후 8시 발표'
      //       />
      //       <NoticeComponent
      //         title='발표 방식'
      //         subText1='팀 성사자 전원에게 문자 발송'
      //         subText2='내 정보 > 내 주문 에서도 확인 가능'
      //       />
      //       <NoticeComponent
      //         title='확률 높이는팁'
      //         subText1='팀 구매 여러 번 성사시키기'
      //         subText2='팀원 중 신규 가입자 비율 높이기'
      //       />
      //     </div>

      //     <div style={{ height: 12 }} />
      //   </div>
      // </div>
    )
  }
  if (isViralItem) {
    return (
      <div
        style={{
          backgroundColor: '#FFF',
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: 8,
        }}
      >
        <div
          style={{
            paddingLeft: 12,
            paddingTop: 12,
            paddingBottom: 12,
            backgroundColor: '#FAFAFA',
            borderRadius: 8,
          }}
        >
          <div style={{ color: '#EA3F49', fontWeight: 'bold', fontSize: 16 }}>
            초특가 상품
          </div>
          <div style={{ height: 12 }} />
          <div style={{ fontWeight: 'bold', fontSize: 14 }}>
            참여하기 : 팀구매 링크로만 1회 참여 가능해요.
          </div>
          <div style={{ height: 4 }} />
          <div style={{ fontWeight: 'bold', fontSize: 14 }}>
            시작하기 : 누구나 횟수 제한 없이 시작할 수 있어요!
          </div>
        </div>
      </div>
    )
  }
  return <></>
}

export default EventTextBox
