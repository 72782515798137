import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ImageManager from '../images/ImageManager'
import Modal from 'react-modal'

// 사진
import PocketMoneyEnvelope from '../images/PocketMoneyEnvelope.png'
import duoduoStyleEnvelope from '../images/duoduoStyleEnvelope.png'
import Flower from '../images/Flower.png'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'
import commaNumber from 'comma-number'
import ItemList from '../components/items/ItemList'
import ItemStore from '../stores/ItemStore'
import backendApis from '../utils/backendApis'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '88%',
    borderRadius: 25.5,
    borderColor: '#BDBDBD',
    padding: 0,
    height: 240,
    paddingTop: 18,
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // paddingLeft: 20,
    // paddingRight: 20,
    transform: 'translate(-50%, -50%)',
  },
}

const SendGiftScreen = observer(() => {
  const history = useHistory()

  const [currentGiftEventInfo, setCurrentGiftEventInfo] = useState()

  useEffect(async () => {
    const params = dynamicLinkUtils.parseLink(window.location.href)

    if (params?.alwayzSendGiftId) {
      const result = await backendApis.getGiftEventInfoById(
        params?.alwayzSendGiftId,
      )
      if (result?.status === 200) {
        setCurrentGiftEventInfo(result?.data)
      }
    }
  }, [])

  useEffect(() => {
    if (!ItemStore.initialLoadingDataAssetsDone) {
      ItemStore.initialLoadDataAssets()
    }
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: 1,
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: window.innerWidth * 0.72,
          flex: 1,
          backgroundColor: '#FFF3E1',
        }}
      >
        <div
          style={{
            height: 48,
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <div style={{ width: '25%', marginTop: 4 }}>
            <ImageManager source='AlwayzFullLogoRed' />
          </div>
        </div>
        <img
          src={Flower}
          alt=''
          width={window.innerWidth}
          height={window.innerWidth * (320 / 704)}
          style={{
            alignItems: 'center',
          }}
        />
        <div
          className='box-sizing'
          style={{
            width: '100%',
            paddingLeft: 20,
            paddingRight: 20,
            alignItems: 'center',
            marginBottom: 20,
            position: 'absolute',
            top: (window.innerWidth * (320 / 704)) / 2 - 42,
          }}
        >
          <div style={{ height: 40 }} />
          <div
            className='box-sizing'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <div
              style={{
                width: 84,
                height: 84,
                backgroundColor: '#000',
                borderRadius: 412,
                overflow: 'hidden',
              }}
            >
              <img
                src={currentGiftEventInfo?.senderImageUri}
                alt=''
                width={84}
                height={84}
                style={{
                  alignItems: 'center',
                }}
              />
            </div>
          </div>

          <div style={{ height: 30 }} />
          <div
            className='box-sizing'
            style={{ fontSize: 24, textAlign: 'center', fontWeight: 'bold' }}
          >
            {currentGiftEventInfo?.senderName} 님이
          </div>
          <div
            className='box-sizing'
            style={{ fontSize: 24, textAlign: 'center', fontWeight: 'bold' }}
          >
            {currentGiftEventInfo?.giftItemTitle} 선물을 보냈어요.
          </div>
        </div>
        <div style={{ height: 20 }} />
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            width: '100%',
            display: 'flex',
            marginBottom: 40,
          }}
        />
      </div>
      <div>
        <div
          className='box-sizing'
          style={{
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className='box-sizing'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              paddingTop: 36,
            }}
          >
            <div
              style={{
                width: window.innerWidth - 64,
                height: window.innerWidth - 64,
                backgroundColor: '#000',
                borderRadius: 25.5,
                overflow: 'hidden',
                boxShadow: ' 0px 5px 13px rgba(0, 0, 0, 0.25)',
              }}
            >
              <img
                src={currentGiftEventInfo?.giftItemImageUri}
                alt=''
                width={window.innerWidth - 64}
                height={window.innerWidth - 64}
                style={{
                  alignItems: 'center',
                }}
              />
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              marginTop: 18,
            }}
          >
            올웨이즈
          </div>
          <div
            style={{
              fontSize: 24,
              textAlign: 'center',
              fontWeight: 'bold',
              marginTop: 12,
              marginBottom: 24,
            }}
          >
            {currentGiftEventInfo?.giftItemTitle}
          </div>
          <div
            className='box-sizing'
            style={{
              marginTop: 18,
              fontSize: 18,
              marginBottom: 48,
              textAlign: 'center',
            }}
          >
            앱 설치만 하면 선물을 받을 수 있어요!
          </div>
          <div
            className='box-sizing'
            style={{
              marginTop: 18,
              fontSize: 18,
              height: 24,
            }}
          />
        </div>
      </div>
      {ItemStore.initialLoadingDataAssetsDone && (
        <div style={{ backgroundColor: 'white' }}>
          <ItemList
            categoryType='large'
            categoryId='total'
            enteringComponent='OrderEndScreenAlwayzSuggestionComponent'
          />
        </div>
      )}
      <button
        className='box-sizing'
        style={{
          position: 'fixed',
          bottom: 0,
          height: window.innerWidth * 0.2,
          backgroundColor: '#ff7a00',
          fontSize: 24,
          fontWeight: 'bold',
          color: '#fff',
          width: window.innerWidth,
          borderWidth: 0,
        }}
        onClick={() => {
          window.location.href = `https://alwayzshop.ilevit.com/app`
        }}
      >
        선물 받기
      </button>
    </div>
  )
})

export default SendGiftScreen
