import { makeObservable, observable, action } from 'mobx'
import backendApis from '../utils/backendApis'
import UserStore from './UserStore'

class ViralEngineItemStore {
  challengeItems = []
  isLoadingChallengeItemScreen = false
  ongoingChallengeInfo = {}
  sharingChallengeTeamInfo = []
  challengeTeamIdsToShareToAlwayz = []
  challengejoinedHistory = []
  publicItem = {}

  constructor() {
    makeObservable(this, {
      challengeItems: observable.ref,
      ongoingChallengeInfo: observable.ref,
      sharingChallengeTeamInfo: observable.ref,
      challengeTeamIdsToShareToAlwayz: observable.ref,
      challengejoinedHistory: observable,
      publicItem: observable.ref,

      setOngoingChallengeInfo: action,
      setChallengeItems: action,
      setSharingChallengeTeamInfo: action,
      setChallengeTeamIdsToShareToAlwayz: action,
      setChallengejoinedHistory: action,
      setPublicItem: action,
    })
  }

  setOngoingChallengeInfo(ongoingChallengeInfo) {
    this.ongoingChallengeInfo = ongoingChallengeInfo
  }

  setChallengeItems(challengeItems) {
    this.challengeItems = challengeItems
  }

  setPublicItem(publicItem) {
    this.publicItem = publicItem
  }

  setIsLoadingChallengeItemScreen(isLoadingChallengeItemScreen) {
    this.isLoadingChallengeItemScreen = isLoadingChallengeItemScreen
  }

  setSharingChallengeTeamInfo(sharingChallengeTeamInfo) {
    this.sharingChallengeTeamInfo = sharingChallengeTeamInfo
  }

  setOrderIdsToShareToAlwayz(orderIdsToShareToAlwayz) {
    this.orderIdsToShareToAlwayz = orderIdsToShareToAlwayz
  }

  setChallengeTeamIdsToShareToAlwayz(challengeTeamIdsToShareToAlwayz) {
    this.challengeTeamIdsToShareToAlwayz = challengeTeamIdsToShareToAlwayz
  }

  setChallengejoinedHistory(challengejoinedHistory) {
    this.challengejoinedHistory = challengejoinedHistory
  }

  async loadChallengeItems() {
    const result = await backendApis.getChallengeItems()
    if (result?.status === 200) {
      this.setChallengeItems(result.data)
      return result
    }
  }

  async loadPublicItems() {
    const result = await backendApis.getThePublicItem()
    if (result?.status === 200) {
      this.setPublicItem(result.data)
      return result
    }
  }

  async loadTargetChallengeItemInfo(itemId, itemInfo) {
    if (itemInfo) {
      this.setTargetChallengeItemInfo({
        ...itemInfo,
        _id: itemId,
        totalDetailImagesHeight: 0,
      })
    }
    const result = await backendApis.getChallengeItemInfo(itemId)
    if (result) {
      this.setTargetChallengeItemInfo(result)
      this.setIsLoadingChallengeItemScreen(false)
      return result
    }
  }

  async loadOngoingChallengeInfo() {
    const result = await backendApis.getOngoingChallengeInfo()
    console.log('진행 중인 챌린지를 로드', result)
    if (result?.status === 200) {
      this.setOngoingChallengeInfo(result?.data)
      console.log('진행 중인 챌린지 정보 셋', result?.data)
    }
  }

  async setChallengeHistory(userId, originalUserId) {
    console.log(
      `current orders user id setting: ${userId}, ${this.challengejoinedHistory.length}`,
    )
    const setChallengeUserId = async () => {
      if (this.challengejoinedHistory.length > 0) {
        this.setChallengejoinedHistory(
          this.challengejoinedHistory.map((challengeJoin) => ({
            ...challengeJoin,
            userId,
          })),
        )
        await backendApis.setChallengeUserId(
          originalUserId,
          this.challengejoinedHistory.map((challengeJoin) => challengeJoin._id),
        )
      }
    }

    await Promise.all([await setChallengeUserId()])
  }
}
export default new ViralEngineItemStore()
