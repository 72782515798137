// // Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app'
// import { getAnalytics } from 'firebase/analytics'
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

import backendApis from './backendApis'
import configs from './configs'
import UserStore from '../stores/UserStore'

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: 'AIzaSyA1-SCtqb_b1A3CNN10ueKJ96-zXt7y9kA',
//   authDomain: 'alwayzproject.firebaseapp.com',
//   projectId: 'alwayzproject',
//   storageBucket: 'alwayzproject.appspot.com',
//   messagingSenderId: '245182288581',
//   appId: '1:245182288581:web:481559188813d8cd0416ac',
//   measurementId: 'G-0RCXC9VYTB',
// }

// // // Initialize Firebase
// const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)

class DynamicLinkUtils {
  async createAppDownloadLink(
    itemId,
    teamDealId,
    invitorId,
    linkType = 'dynamicLink',
    title = '',
    descriptionText = '',
    imageUrl = '',
    screen = '',
    isLinkFromApp = '',
    bottomSheetTimeOut,
    isLateComer,
    endedTeamDealNotify,
    ableToPurchaseViralItem,
    reviewPosition,
    teamMemberShowing,
    appDownloadFullScreen,
  ) {
    // const result = await backendApis.getAppDownloadLink(
    //   itemId,
    //   teamDealId,
    //   invitorId,
    //   linkType,
    //   title,
    //   descriptionText,
    //   imageUrl,
    //   screen,
    //   isLinkFromApp,
    //   bottomSheetTimeOut,
    //   isLateComer,
    //   endedTeamDealNotify,
    //   ableToPurchaseViralItem,
    //   reviewPosition,
    //   teamMemberShowing,
    //   appDownloadFullScreen,
    // )
    // if (result?.status === 200 && result.data?.shortLink) {
    //   return result.data.shortLink
    // }
    return `https://alwayzshop.ilevit.com/app`
  }

  async createAppDownloadDynamicLink({
    shareType,
    itemId,
    screen,
    alwayzInvitorId,
    linkType,
    title, // 제목 title
    description, // 설명 descriptionriptionText
    imageUrl,
    // ...res
  }) {
    const { utm_source = undefined, utm_campaign = undefined, utm_campaign_id = undefined, utm_adset_id = undefined, utm_ad_id = undefined } =
    UserStore?.dynamicLinkParams
    let motherLink = `https://alwayzshop.ilevit.com/app?shareType=${shareType}`

    if (screen) {
      motherLink += `&screen=${screen}`
    }
    if (alwayzInvitorId) {
      motherLink += `&alwayzInvitorId=${alwayzInvitorId}`
    }
    if (itemId) {
      motherLink += `&alwayzItemId=${itemId}`
    }

    if(utm_source){
      motherLink += `&utm_source=${utm_source}`
    }
    if(utm_campaign){
      motherLink += `&utm_campaign=${utm_campaign}`
    }
    if(utm_campaign_id){
      motherLink += `&utm_campaign_id=${utm_campaign_id}`
    }
    if(utm_adset_id){
      motherLink += `&utm_adset_id=${utm_adset_id}`
    }
    if(utm_ad_id){
      motherLink += `&utm_ad_id=${utm_ad_id}`
    }

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        dynamicLinkInfo: {
          link: motherLink,
          domainUriPrefix: 'https://alwayzshop.page.link',
          androidInfo: {
            androidPackageName: 'com.ilevit.alwayz.android',
          },
          iosInfo: {
            iosBundleId: 'com.ilevit.alwayz.ios',
            iosAppStoreId: '1576633335',
          },
          socialMetaTagInfo: {
            socialTitle: title,
            socialDescription: description,
            socialImageLink: imageUrl,
          },
        },
      }),
    }
    const result = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyA1-SCtqb_b1A3CNN10ueKJ96-zXt7y9kA`,
      options,
    )
    const data = await result.json()

    return data?.shortLink
  }

  async createDynamicItemLink(
    itemId,
    teamDealId,
    linkType = 'dynamicLink',
    title = '',
    descriptionText = '',
    imageUrl = '',
  ) {
    const result = await backendApis.getItemShareLink(
      itemId,
      teamDealId,
      linkType,
      title,
      descriptionText,
      imageUrl,
      `${configs.webUrl}/`,
    )

    // console.log(
    //   `itemtitle: ${title}, image url: ${imageUrl}, teamdealId: ${teamDealId}`,
    // )
    // let motherLink = `${configs.webUrl}?shareType=itemShare&alwayzItemId=${itemId}&alwayzInvitorId=${userId}&linkType=${linkType}`
    // if (teamDealId) {
    //   motherLink += `&alwayzTeamDealId=${teamDealId}`
    // }
    // const options = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     dynamicLinkInfo: {
    //       link: motherLink,
    //       domainUriPrefix: 'https://alwayzshop.page.link',
    //       androidInfo: {
    //         androidPackageName: 'com.ilevit.alwayz.android',
    //         androidFallbackLink: motherLink,
    //         // .replace(
    //         //   'https://alwayzshop.ilevit.com/app',
    //         //   configs.webUrl,
    //         // ),
    //       },
    //       iosInfo: {
    //         iosBundleId: 'com.ilevit.alwayz.ios',
    //         // appStoreId: AuthStore.appStoreId,
    //         iosAppStoreId: '1576633335',
    //         iosFallbackLink: motherLink,
    //         // .replace(
    //         //   'https://alwayzshop.ilevit.com/app',
    //         //   configs.webUrl,
    //         // ),
    //       },
    //       socialMetaTagInfo: {
    //         socialTitle: title,
    //         socialDescription: descriptionText,
    //         socialImageLink: imageUrl,
    //       },
    //     },
    //   }),
    // }
    // const res = await fetch(
    //   'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyA1-SCtqb_b1A3CNN10ueKJ96-zXt7y9kA',
    //   options,
    // )
    // const data = await res.json()
    // console.log(`dynamic link data: ${JSON.stringify(data)}`)
    if (result?.status === 200 && result.data?.shortLink) {
      return result.data.shortLink
    }

    // const link = await dynamicLinks().buildShortLink({
    //   // link: `https://alwayzshop.ilevit.com/app?shareType=itemShare&alwayzItemId=${itemId}&alwayzTeamDealId=${teamDealId}&alwayzInvitorId=${userId}&linkType=${linkType}`,
    //   link: motherLink,
    //   domainUriPrefix: 'https://alwayzshop.page.link',
    //   android: {
    //     packageName: 'com.ilevit.alwayz.android',
    //   },
    //   ios: {
    //     bundleId: 'com.ilevit.alwayz.ios',
    //     // appStoreId: AuthStore.appStoreId,
    //     appStoreId: '1576633335',
    //   },
    //   social: {
    //     title,
    //     descriptionText,
    //     imageUrl,
    //   },
    // })
  }

  parseLink(url) {
    const regex = /[?&]([^=#]+)=([^&#]*)/g
    const params = {}
    let match
    while ((match = regex.exec(url)) !== null) {
      params[match[1]] = match[2]
      // console.log(match[1], match[2])
    }
    if (params.cohort && params.limit) {
      params.limit = parseInt(params.limit, 10)
    }
    return params
  }
}

export default new DynamicLinkUtils()
