import fetchWrapper from './fetchWrapper'
import configs from './configs'

class BackendApis {
  async getKakaoAccessToken(kakaoAuthCode) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ kakaoAuthCode }),
      }
      const result = await fetch(
        `${configs.backendUrl}/users/login/kakao/token-request`,
        options,
      )
      const data = await result.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerKakaoUser(
    kakaoAccountId,
    userName,
    thumbnailUserImageUri,
    userImageUri,
    birthMonthAndDay,
    birthYear,
    birthdayType,
    countryCode,
    phoneNumber,
    gender,
    dynamicLinkParams,
  ) {
    try {
      if (dynamicLinkParams.itemId) {
        dynamicLinkParams.alwayzItemId = dynamicLinkParams.itemId
        delete dynamicLinkParams.itemId
      }
      if (dynamicLinkParams.invitorId) {
        dynamicLinkParams.alwayzInvitorId = dynamicLinkParams.invitorId
        delete dynamicLinkParams.invitorId
      }
      if (dynamicLinkParams.teamDealId) {
        dynamicLinkParams.alwayzTeamDealId = dynamicLinkParams.teamDealId
        delete dynamicLinkParams.teamDealId
      }

      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          kakaoAccountId,
          userName,
          thumbnailUserImageUri,
          userImageUri,
          birthMonthAndDay,
          birthYear,
          birthdayType,
          countryCode,
          phoneNumber,
          gender,
          dynamicLinkParams,
        }),
      }
      const result = await fetch(
        `${configs.backendUrl}/users/kakao-accounts`,
        options,
      )
      const data = await result.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAppDownloadLink(
    itemId,
    teamDealId,
    invitorId,
    linkType,
    title,
    descriptionText,
    imageUrl,
    screen,
    isLinkFromApp,
    bottomSheetTimeOut,
    isLateComer,
    endedTeamDealNotify,
    ableToPurchaseViralItem,
    reviewPosition,
    teamMemberShowing,
    appDownloadFullScreen,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemId,
          teamDealId,
          invitorId,
          linkType,
          title,
          descriptionText,
          imageUrl,
          screen,
          isLinkFromApp,
          bottomSheetTimeOut,
          isLateComer,
          endedTeamDealNotify,
          ableToPurchaseViralItem,
          reviewPosition,
          teamMemberShowing,
          appDownloadFullScreen,
        }),
      }
      const res = await fetch(
        `${configs.backendUrl}/users/dynamic-links/info-requests/download`,
        options,
      )
      const data = await res.json()
      // console.log(`update threshold: ${JSON.stringify(data.data)}`)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemShareLink(
    itemId,
    teamDealId,
    linkType,
    title,
    descriptionText,
    imageUrl,
    webUrl,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemId,
          teamDealId,
          linkType,
          title,
          descriptionText,
          imageUrl,
          webUrl,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/dynamic-links/info-requests/item-share`,
        options,
      )
      const data = await res.json()
      // console.log(`update threshold: ${JSON.stringify(data.data)}`)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteUserAddress(addressId) {
    try {
      const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          addressId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/addresses`,
        options,
      )
      const data = await res.json()
      console.log(`address upload result: ${JSON.stringify(data)}`)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerUserAddress(addressInfo) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          addressInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/addresses`,
        options,
      )
      const data = await res.json()
      // console.log(`address upload result: ${JSON.stringify(data)}`)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadUserLinkUsage(link, paramsInfo, screen, action) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          link,
          paramsInfo,
          screen,
          action,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/link-usages`,
        options,
      )
      const data = await res.json()
      // console.log(
      //   `register birthday and gender result: ${JSON.stringify(data)}`,
      // )
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadUserInfo() {
    try {
      const options = { method: 'GET', cache: 'no-cache' }
      // await this.registerLogs(`load user info log: ${configs.backendUrl}/users`)
      const res = await fetchWrapper(`${configs.backendUrl}/users`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async prepareKakaoSinglePay(
    orderId,
    itemName,
    quantity,
    totalAmount,
    taxFreeAmount,
    orderIndicator = '1234',
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          orderId,
          itemName,
          quantity,
          totalAmount,
          taxFreeAmount,
          orderIndicator,
          approvalUrl: `${configs.webUrl}/?orderIndicator=${orderIndicator}`,
          cancelUrl: `${configs.webUrl}/?orderIndicator=${orderIndicator}`,
          failUrl: `${configs.webUrl}/?orderIndicator=${orderIndicator}`,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/pay-methods/kakao/preparation`,
        options,
      )
      const data = await res.json()
      // console.log(`kakao prepare singlepay result: ${JSON.stringify(data)}`)
      return data.data
    } catch (err) {
      console.log(err)
    }
  }

  async getLinkReceivedInfo(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/link-received-info?itemId=${itemId || ''}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getOrderByUserIdAndTeamDealId(invitorId, teamDealId) {
    try {
      const options = { method: 'GET' }
      // await this.registerLogs(`load user info log: ${configs.backendUrl}/users`)
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/inviting-info?userId=${invitorId}&teamDealId=${teamDealId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkOrdersBeforLeavingService(itemId, phoneNumber) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/viral-items?itemId=${itemId}&phoneNumber=${phoneNumber}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async confirmPayableOrders(orderIds) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          orderIds,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/pre-payed-orders/confirmations/new`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkLotteryOrdersBeforLeavingService(itemId, phoneNumber) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/lottery-items?itemId=${itemId}&phoneNumber=${phoneNumber}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async confirmPrePayedOrders(orderIds) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          orderIds,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/pre-payed-orders/confirmations`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordKakaoSinglePayResultFromImp(updatedOrdersList, kakaoPayResult) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          updatedOrdersList,
          kakaoPayResult,
          webUrl: configs.webUrl,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/imp/kakao`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async approveKakaoSinglePay(
    // tid,
    // orderId,
    // itemId,
    // pgToken,
    // isTeamDeal,
    // teamDealId,
    // quantity,
    tid,
    kakaoOrderId,
    pgToken,
    ordersList,
    webUrl,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tid,
          // orderId,
          // itemId,
          kakaoOrderId,
          pgToken,
          ordersList,
          webUrl,
          // isTeamDeal,
          // teamDealId,
          // quantity,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/pay-methods/kakao/approval`,
        options,
      )
      const data = await res.json()
      // console.log(`kakao approve single result: ${JSON.stringify(data)}`)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteUsedUserCoupon(couponId) {
    try {
      const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          couponId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/coupons`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getLinkBenefitsInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetch(
        `${configs.backendUrl}/users/link-benefits-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerLogs(log) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          log,
        }),
      }
      const res = await fetch(`${configs.backendUrl}/users/logs`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTeamDealMembersInfo(teamDealId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/unauth/team-deals/members?teamDealId=${teamDealId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerUserDefaultAddress(addressInfo) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          addressInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/addresses/default`,
        options,
      )
      const data = await res.json()
      // console.log(`address upload result: ${JSON.stringify(data)}`)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkItemSoldOut(itemId) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/sold-out?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTotalCategoryTree() {
    try {
      const options = { method: 'GET' }
      const res = await fetch(
        `${configs.backendUrl}/items/categories/trees`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemIdsByMediumCategories() {
    try {
      const options = { method: 'GET' }
      const res = await fetch(
        `${configs.backendUrl}/items/ids-by-medium-cat`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemCategoryMappingsByMediuimCategories() {
    try {
      const options = { method: 'GET' }
      const res = await fetch(
        `${configs.backendUrl}/items/cat-mappings-by-medium-cat`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemVectorsByMediumCategories() {
    try {
      const options = { method: 'GET' }
      const res = await fetch(
        `${configs.backendUrl}/items/vectors-by-medium-cat`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getInitialVectorsAndWeightsByMediumCategories() {
    try {
      const options = { method: 'GET' }
      const res = await fetch(
        `${configs.backendUrl}/users/initial-params-new`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getUserVectorsAndWeights() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/params`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadUserVectorsAndWeights(
    vectorsPerMediumCategories,
    weightsPerMediumCategories,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          vectorsPerMediumCategories,
          weightsPerMediumCategories,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/params`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkAndRefundTeamDealLinkInvitor(
    invitorId,
    invitorOrderId,
    inviteeOrderId,
    itemId,
    itemTitle,
    itemImageUri,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          invitorId,
          invitorOrderId,
          inviteeOrderId,
          itemId,
          itemTitle,
          itemImageUri,
          // isTeamDeal,
          // teamDealId,
          // quantity,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/team-deals/link-discounts`,
        options,
      )
      const data = await res.json()
      // console.log(`kakao approve single result: ${JSON.stringify(data)}`)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemsInfo(itemIds) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemIds,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/unauth/info-requests`,
        options,
      )
      const data = await res.json()
      return data.data
    } catch (err) {
      console.log(err)
    }
  }

  async getSpecificItems(start, end) {
    try {
      const options = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({
        //   itemIds,
        // }),
      }
      const res = await fetch(
        `${configs.backendUrl}/items/unauth/specific-items?start=${start}&end=${end}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getReviewsForItem(itemId, loadedReviewsNum) {
    try {
      const options = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({
        //   itemIds,
        // }),
      }
      const res = await fetch(
        `${configs.backendUrl}/items/unauth/reviews?itemId=${itemId}&loadedReviewsNum=${loadedReviewsNum}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async restartEndedTeamDeal(teamDealId, orderId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          teamDealId,
          orderId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/team-deals/start-times`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemInfo(itemId) {
    try {
      const options = { method: 'GET', cache: 'no-cache' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemInfoWithoutLogin(itemId) {
    try {
      const options = {
        method: 'GET',
        cache: 'no-cache',
      }
      const result = await fetch(
        `${configs.backendUrl}/items/unauth?itemId=${itemId}`,
        options,
      )
      const data = await result.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getUserOrders() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(`${configs.backendUrl}/orders`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerOrders(newOrdersList) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          newOrdersList,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/multiple`,
        options,
      )
      const data = await res.json()
      return data.data
    } catch (err) {
      console.log(err)
    }
  }

  async checkTeamDeals(teamDealsInfo) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          teamDealsInfo,
          // isTeamDeal,
          // teamDealId,
          // quantity,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/team-deals/info-request`,
        options,
      )
      const data = await res.json()
      // console.log(`kakao approve single result: ${JSON.stringify(data)}`)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelOrder(orderId, cancelInfo) {
    try {
      const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          orderId,
          cancelInfo,
        }),
      }
      const res = await fetchWrapper(`${configs.backendUrl}/orders`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateOrdersOptionsInfo(updatedOrdersList) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          updatedOrdersList,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/options-info/multiple`,
        options,
      )
      const data = await res.json()
      return data.data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerKakaoChannel(sellerId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetch(
        `${configs.backendUrl}/sellers/kakao-channel?sellerId=${sellerId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // Challenge 관련 API
  async getOngoingChallengeInfo() {
    try {
      const options = { method: 'GET' }
      console.log(`${configs.backendUrl}/viralEngines/ongoingChallengeInfoWeb`)
      const res = await fetchWrapper(
        `${configs.backendUrl}/viralEngines/ongoingChallengeInfo`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getChallengeTeamShareMasterLink(challengeTeamId) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/viralEngines/challengeTeam/master-links-web?challengeTeamId=${challengeTeamId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getChallengeItems() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/viralEngines/challengeItems-web`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getGiftEventInfoById(giftEventId) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/viralEngines/gift-event/info-by-id-web?giftEventId=${giftEventId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // 메로나 API
  async getThePublicItem() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/viralEngines/thePublicItem-web`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTotalJoinedNumber() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/viralEngines/total-joined-number-web`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getReceivedLuckyBoxInfo(docId) {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/viralEngines/web/lucky-box/get-received-lucky-box-info?documentId=${docId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getOnePlusOneReceivedItemInfo(itemId) {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/viralEngines/web/one-plus-one/get-received-item-info?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getGganbuChickenReceivedItemInfo(itemId) {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/viralEngines/web/gganbu-chicken/get-received-item-info?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }
}

export default new BackendApis()
