import { makeObservable, observable, action, computed, runInAction } from 'mobx'
import backendApis from '../utils/backendApis'
import UserStore from './UserStore'
import uuid from 'uuid-random'
import socket from '../utils/socket'
import AuthStore from './AuthStore'
import configs from '../utils/configs'

class OrderStore {
  paymentMethodType = 'nice-pay'
  closeOrderBottomSheet = true
  tid = ''
  kakaoPaySubscriptionRequirementParams = {
    kakaoPayPgToken: '',
    kakaoSinglePayAppScheme: '',
    kakaoSinglePayRedirectUri: '',
  }

  selectedCouponInfo = {}
  currentItemIndex = 0
  currentUserOrdersList = []
  loadedUserOrdersList = []
  finishedLoadingUserOrders = false
  sharingOrdersInfo = []
  orderIdsToShareToAlwayz = []
  showIndividualPurchaseModal = false
  showTeamJoinedPurchaseModal = false
  recentlyPurchasedItemIdsAndQuantities = []
  createdTeamDealIdsToShare = []
  finishedRegisteringOrders = false
  invitedTeamDealInfo = {}

  constructor() {
    makeObservable(this, {
      paymentMethodType: observable.ref,
      closeOrderBottomSheet: observable.ref,
      tid: observable.ref,

      // orderId: observable.ref,
      // itemInfo: observable.ref,
      // selectedOption: observable,
      selectedCouponInfo: observable,
      // couponsInfo: observable,
      // quantity: observable,
      // taxFreeAmount: observable,
      kakaoPaySubscriptionRequirementParams: observable,
      // isTeamDeal: observable,
      // teamDealId: observable,

      currentItemIndex: observable.ref,
      currentUserOrdersList: observable,
      loadedUserOrdersList: observable,
      finishedLoadingUserOrders: observable.ref,

      sharingOrdersInfo: observable.ref,
      orderIdsToShareToAlwayz: observable.ref,
      // isOrdering: observable.ref,
      showIndividualPurchaseModal: observable.ref,
      showTeamJoinedPurchaseModal: observable.ref,

      recentlyPurchasedItemIdsAndQuantities: observable.ref,
      createdTeamDealIdsToShare: observable.ref,
      finishedRegisteringOrders: observable.ref,
      invitedTeamDealInfo: observable.ref,

      // totalPrice: computed,
      currentItemTotalPrice: computed,
      currentItemShippingFee: computed,

      setPaymentMethodType: action,
      setCloseOrderBottomSheet: action,
      setTid: action,
      setKakaoPayPgToken: action,
      setKakaoPaySubscriptionRequirementParams: action,
      setSelectedCouponInfo: action,

      setItemOrderIdByIndex: action,
      setItemInfoByIndex: action,
      setItemSelectedOptionByIndex: action,
      setItemQuantityByIndex: action,
      setItemOptionIndicesByIndex: action,
      setItemIsInitialTeamDealLinkInvited: action,
      // setItemTaxFreeAmountByIndex: action,
      setItemIsTeamDealByIndex: action,
      setItemTeamDealIdByIndex: action,
      setItemTotalPriceByIndex: action,
      setItemShippingFeeByIndex: action,

      setCurrentItemIndex: action,
      setCurrentUserOrdersList: action,
      setLoadedUserOrdersList: action,
      setFinishedLoadingUserOrders: action,

      setSharingOrdersInfo: action,
      setOrderIdsToShareToAlwayz: action,
      // setIsOrdering: action,
      setShowIndividualPurchaseModal: action,
      setShowTeamJoinedPurchaseModal: action,

      setRecentlyPurchasedItemIdsAndQuantities: action,
      setCreatedTeamDealIdsToShare: action,
      setFinishedRegisteringOrders: action,
      setInvitedTeamDealInfo: action,
    })
  }

  get currentItemTotalPrice() {
    // console.log(
    //   `coupon discount: ${this.currentUserOrdersList[this.currentItemIndex]}`,
    // )
    const couponDiscount =
      this.currentUserOrdersList[this.currentItemIndex]?.selectedCouponInfo
        ?.price || 0

    const selectedOptionPrice = this.currentUserOrdersList[
      this.currentItemIndex
    ]?.isTeamDeal
      ? this.currentUserOrdersList[this.currentItemIndex]?.selectedOption
          ?.teamPurchasePrice
      : this.currentUserOrdersList[this.currentItemIndex]?.selectedOption
          ?.individualPurchasePrice
    return (
      (this.currentUserOrdersList[this.currentItemIndex]?.quantity || 0) *
      Math.max(selectedOptionPrice || 0 - couponDiscount, 0)
    )
  }

  get currentItemShippingFee() {
    const postcode = UserStore.userInfo.defaultAddressInfo?.postcode
    if (postcode) {
      const shippingFeeInfo = this.currentUserOrdersList[
        this.currentItemIndex
      ]?.itemInfo.shippingInfo.ruralAreaShippingFeeInfo?.find(
        (shippingFeeInfo) =>
          parseInt(postcode, 10) >=
            parseInt(shippingFeeInfo.startPostNumber, 10) &&
          parseInt(postcode, 10) <= parseInt(shippingFeeInfo.endPostNumber, 10),
      )
      if (shippingFeeInfo) {
        return shippingFeeInfo.shippingFee
      }
    }
    return 0
  }

  // itemTotalPriceByIndex = computedFn((index) => {
  //   const couponDiscount =
  //     this.currentUserOrdersList[index].selectedCouponInfo.price || 0
  //   const selectedOptionPrice = this.currentUserOrdersList[index].isTeamDeal
  //     ? this.currentUserOrdersList[index].selectedOption.teamPurchasePrice
  //     : this.currentUserOrdersList[index].selectedOption.individualPurchasePrice
  //   return (
  //     this.currentUserOrdersList[index].quantity *
  //     Math.max(selectedOptionPrice || 0 - couponDiscount, 0)
  //   )
  // })

  setInvitedTeamDealInfo(invitedTeamDealInfo) {
    this.invitedTeamDealInfo = invitedTeamDealInfo
  }

  setFinishedRegisteringOrders(finishedRegisteringOrders) {
    this.finishedRegisteringOrders = finishedRegisteringOrders
  }

  setFinishedLoadingUserOrders(finishedLoadingUserOrders) {
    this.finishedLoadingUserOrders = finishedLoadingUserOrders
  }

  setSelectedCouponInfo(selectedCouponInfo) {
    this.selectedCouponInfo = selectedCouponInfo
  }

  setCreatedTeamDealIdsToShare(createdTeamDealIdsToShare) {
    this.createdTeamDealIdsToShare = createdTeamDealIdsToShare
  }

  setRecentlyPurchasedItemIdsAndQuantities(
    recentlyPurchasedItemIdsAndQuantities,
  ) {
    this.recentlyPurchasedItemIdsAndQuantities =
      recentlyPurchasedItemIdsAndQuantities
  }

  setShowIndividualPurchaseModal(showIndividualPurchaseModal) {
    this.showIndividualPurchaseModal = showIndividualPurchaseModal
  }

  setShowTeamJoinedPurchaseModal(showTeamJoinedPurchaseModal) {
    this.showTeamJoinedPurchaseModal = showTeamJoinedPurchaseModal
  }
  // setIsOrdering(isOrdering) {
  //   this.isOrdering = isOrdering
  // }

  itemTotalPriceByIndex(index) {
    // const couponDiscount =
    //   this.currentUserOrdersList[index]?.selectedCouponInfo?.price || 0
    const selectedOptionPrice = this.currentUserOrdersList[index]?.isTeamDeal
      ? this.currentUserOrdersList[index]?.selectedOption?.teamPurchasePrice
      : this.currentUserOrdersList[index]?.selectedOption
          ?.individualPurchasePrice
    let couponDiscount = 0
    console.log(
      `coupon info: ${JSON.stringify(this.selectedCouponInfo)}, ${index}`,
    )
    const shippingFee = this.itemShippingFeeByIndex(index) || 0
    const minimumLeftOver = Math.max(100 - shippingFee, 0)
    if (this.selectedCouponInfo.orderIndex === index) {
      console.log(`index match to ${index}`)
      if (this.selectedCouponInfo.couponInfo.discountType === 'amount') {
        console.log(`amount discount`)
        couponDiscount = Math.min(
          Math.max(
            selectedOptionPrice *
              (this.currentUserOrdersList[index]?.quantity || 1) -
              minimumLeftOver,
            0,
          ),
          this.selectedCouponInfo.couponInfo.value,
        )
        console.log(
          `coupon discount price: ${couponDiscount}, selectedOption price: ${selectedOptionPrice}`,
        )
        this.setSelectedCouponInfo({
          ...this.selectedCouponInfo,
          finalDiscountAmount: couponDiscount,
        })
        return Math.max(
          (this.currentUserOrdersList[index]?.quantity || 0) *
            (selectedOptionPrice || 0) -
            couponDiscount,
          0,
        )
      }
      if (this.selectedCouponInfo.couponInfo.discountType === 'percentage') {
        couponDiscount = Math.min(
          Math.ceil(
            (selectedOptionPrice * this.selectedCouponInfo.couponInfo.value) /
              10,
          ) *
            10 *
            this.currentUserOrdersList[index]?.quantity,
          selectedOptionPrice * this.currentUserOrdersList[index]?.quantity -
            minimumLeftOver,
        )
        console.log(
          `coupon discount price: ${couponDiscount}, selectedOption price: ${selectedOptionPrice}`,
        )
        this.setSelectedCouponInfo({
          ...this.selectedCouponInfo,
          finalDiscountAmount: couponDiscount,
          // couponDiscount * this.currentUserOrdersList[index]?.quantity,
        })
        // return (
        //   (this.currentUserOrdersList[index]?.quantity || 0) *
        //   Math.max((selectedOptionPrice || 0) - couponDiscount, 0)
        // )
        return (
          selectedOptionPrice * this.currentUserOrdersList[index]?.quantity -
          couponDiscount
        )
      }
    }

    return (
      (this.currentUserOrdersList[index]?.quantity || 0) *
      Math.max((selectedOptionPrice || 0) - couponDiscount, 0)
    )
  }

  itemShippingFeeByIndex(index) {
    const postcode = UserStore.userInfo.defaultAddressInfo?.postcode
    // console.log(
    //   `item shipping fee by index ${index}: ${JSON.stringify(
    //     this.currentUserOrdersList[index]?.itemInfo,
    //   )}`,
    // )
    if (postcode) {
      const shippingFeeInfo = this.currentUserOrdersList[
        index
      ]?.itemInfo.shippingInfo.ruralAreaShippingFeeInfo?.find(
        (shippingFeeInfoTemp) =>
          parseInt(postcode, 10) >=
            parseInt(shippingFeeInfoTemp.startPostNumber, 10) &&
          parseInt(postcode, 10) <=
            parseInt(shippingFeeInfoTemp.endPostNumber, 10),
      )
      if (shippingFeeInfo) {
        return parseInt(shippingFeeInfo.shippingFee, 10)
      }
    }
    return 0
  }

  setOrderIdsToShareToAlwayz(orderIdsToShareToAlwayz) {
    this.orderIdsToShareToAlwayz = orderIdsToShareToAlwayz
  }

  setSharingOrdersInfo(sharingOrdersInfo) {
    this.sharingOrdersInfo = sharingOrdersInfo
  }

  setCurrentItemIndex(index) {
    this.currentItemIndex = index
  }

  setCurrentUserOrdersList(currentUserOrdersList) {
    console.log(
      `set current user orders list: ${JSON.stringify(
        currentUserOrdersList.map((order) => order._id),
      )}`,
    )
    this.currentUserOrdersList = currentUserOrdersList
  }

  setLoadedUserOrdersList(loadedUserOrdersList) {
    // console.log(
    //   `setting loaded user orders list: ${JSON.stringify(
    //     loadedUserOrdersList,
    //   )}`,
    // )
    this.loadedUserOrdersList = loadedUserOrdersList
  }

  setPaymentMethodType(paymentMethodType) {
    this.paymentMethodType = paymentMethodType
  }

  setCloseOrderBottomSheet(closeOrderBottomSheet) {
    this.closeOrderBottomSheet = closeOrderBottomSheet
  }

  // setOrderId(orderId) {
  //   this.orderId = orderId
  // }

  // setItemInfo(itemInfo) {
  //   this.itemInfo = itemInfo
  // }

  setItemOrderIdByIndex(orderId, index) {
    this.currentUserOrdersList[index] = {
      ...this.currentUserOrdersList[index],
      _id: orderId,
    }
  }

  setItemIsInitialTeamDealLinkInvited(isInitialTeamDealLinkInvited, index) {
    this.currentUserOrdersList[index] = {
      ...this.currentUserOrdersList[index],
      isInitialTeamDealLinkInvited,
    }
  }

  setItemQuantityByIndex(quantity, index) {
    console.log(`set item quantity ${quantity} by index ${index} activated`)
    this.currentUserOrdersList[index] = {
      ...this.currentUserOrdersList[index],
      quantity,
    }
    // this.setItemTotalPriceByIndex(this.itemTotalPriceByIndex(index), index)
  }

  setItemInfoByIndex(itemInfo, index) {
    this.currentUserOrdersList[index] = {
      ...this.currentUserOrdersList[index],
      itemInfo,
    }
  }

  setItemSelectedOptionByIndex(selectedOption, index) {
    this.currentUserOrdersList[index] = {
      ...this.currentUserOrdersList[index],
      selectedOption,
    }
  }

  setItemOptionIndicesByIndex(optionIndices, index) {
    this.currentUserOrdersList[index] = {
      ...this.currentUserOrdersList[index],
      optionIndices,
    }
  }

  // setItemTaxFreeAmountByIndex(taxFreeAmount, index) {
  //   this.currentUserOrdersList[index] = {
  //     ...this.currentUserOrdersList[index],
  //     taxFreeAmount,
  //   }
  // }

  setItemIsTeamDealByIndex(isTeamDeal, index) {
    this.currentUserOrdersList[index] = {
      ...this.currentUserOrdersList[index],
      isTeamDeal,
    }
  }

  setItemTeamDealIdByIndex(teamDealId, index) {
    this.currentUserOrdersList[index] = {
      ...this.currentUserOrdersList[index],
      teamDealId,
    }
  }

  setItemTotalPriceByIndex(totalPrice, index) {
    console.log(`setting total price to: ${totalPrice}`)
    this.currentUserOrdersList[index] = {
      ...this.currentUserOrdersList[index],
      totalPrice,
      taxFreeAmount: this.currentUserOrdersList[index].itemInfo.isTaxFree
        ? totalPrice
        : 0,
    }
    if (
      this.currentUserOrdersList[index].isInitialTeamDealLinkInvited &&
      this.currentUserOrdersList[index].itemInfo.invitorId &&
      this.currentUserOrdersList[index].itemInfo.invitedOrderId
    ) {
      let couponDiscountAmount = 0
      if (
        this.selectedCouponInfo?.orderId ===
          this.currentUserOrdersList[index]._id &&
        this.currentUserOrdersList[index]._id
      ) {
        couponDiscountAmount = this.selectedCouponInfo.finalDiscountAmount
      }
      console.log(`setting link share discount logs`)
      backendApis.registerLogs({ msg: 'setting link share discount logs' })
      this.currentUserOrdersList[index] = {
        ...this.currentUserOrdersList[index],
        initialLinkShareDiscountAmount: Math.ceil(
          (totalPrice + couponDiscountAmount) *
            (UserStore.linkBenefitsInfo.linkReceiverFirstPurchaseDiscountRate ||
              0),
        ),
        linkShareDiscountLogs: [
          {
            position: 'invitee',
            invitorId: this.currentUserOrdersList[index].itemInfo.invitorId,
            orderId: this.currentUserOrdersList[index].itemInfo.invitedOrderId,
            createdAt: new Date(),
            discountAmount: Math.ceil(
              (totalPrice + couponDiscountAmount) *
                (UserStore.linkBenefitsInfo
                  .linkReceiverFirstPurchaseDiscountRate || 0),
            ),
          },
        ],
      }
      // console.log(
      //   `initial link share discount amount: ${Math.ceil(
      //     (totalPrice + couponDiscountAmount) * 0.05,
      //   )}, ${totalPrice}, ${couponDiscountAmount}, ${JSON.stringify(
      //     this.selectedCouponInfo,
      //   )}`,
      // )
    } else {
      this.currentUserOrdersList[index] = {
        ...this.currentUserOrdersList[index],
        initialLinkShareDiscountAmount: 0,
        linkShareDiscountLogs: [],
      }
    }
  }

  setItem

  setItemShippingFeeByIndex(shippingFee, index) {
    this.currentUserOrdersList[index] = {
      ...this.currentUserOrdersList[index],
      shippingFee,
    }
  }

  resetItemInfo() {
    // this.setOrderId('')
    // this.setTid('')
    // this.setItemInfo({ itemId: '', itemTitle: '' })
    // this.setSelectedOption({})
    // this.setIsTeamDeal(true)
    // this.setTeamDealId('')
    // this.setQuantity(1)
    // this.setTaxFreeAmount(0)
    this.setCurrentItemIndex(0)
    this.setCurrentUserOrdersList([])
  }

  setTid(tid) {
    this.tid = tid
  }

  async setKakaoPayPgToken(kakaoPayPgToken, orderIndicator) {
    if (
      this.kakaoPaySubscriptionRequirementParams.kakaoPayPgToken !==
      kakaoPayPgToken
    ) {
      runInAction(() => {
        this.kakaoPaySubscriptionRequirementParams.kakaoPayPgToken =
          kakaoPayPgToken
      })
      const result = await this.approveKakaoSinglePay(orderIndicator)

      if (result?.status === 200) {
        // this.resetItemInfo()
        this.setKakaoPaySubscriptionRequirementParams({
          kakaoPayPgToken: '',
          kakaoSinglePayAppScheme: '',
          kakaoSinglePayRedirectUri: '',
        })
      }
      return result
    }
    return {}
  }

  // setSelectedOption(selectedOption) {
  //   this.selectedOption = selectedOption
  // }

  // setQuantity(quantity) {
  //   this.quantity = quantity
  // }

  // setTaxFreeAmount(taxFreeAmount) {
  //   this.taxFreeAmount = taxFreeAmount
  // }

  setKakaoSinglePayAppScheme(kakaoSinglePayAppScheme) {
    console.log(`single pay app scheme activated ${kakaoSinglePayAppScheme}`)
    this.setKakaoPaySubscriptionRequirementParams({
      ...this.kakaoPaySubscriptionRequirementParams,
      kakaoSinglePayAppScheme,
    })
  }

  setKakaoSinglePayRedirectUri(kakaoSinglePayRedirectUri) {
    this.setKakaoPaySubscriptionRequirementParams({
      ...this.kakaoPaySubscriptionRequirementParams,
      kakaoSinglePayRedirectUri,
    })
  }

  setKakaoPaySubscriptionRequirementParams(
    kakaoPaySubscriptionRequirementParams,
  ) {
    this.kakaoPaySubscriptionRequirementParams =
      kakaoPaySubscriptionRequirementParams
  }

  // setIsTeamDeal(isTeamDeal) {
  //   this.isTeamDeal = isTeamDeal
  // }

  // setTeamDealId(teamDealId) {
  //   this.teamDealId = teamDealId
  // }

  // async registerOrder() {
  //   const orderId = uuid()
  //   this.setOrderId(orderId)
  //   const result = await backendApis.registerOrder(
  //     orderId,
  //     this.itemInfo,
  //     this.selectedOption,
  //     this.isTeamDeal,
  //     this.teamDealId,
  //     this.selectedCouponInfo,
  //     this.quantity,
  //     this.totalPrice,
  //     this.taxFreeAmount,
  //   )
  //   // console.log(`register order result: ${JSON.stringify(result)}`)
  // }

  async registerOrders() {
    this.setFinishedRegisteringOrders(false)
    const ordersListWithOrderIds = this.currentUserOrdersList
      .filter((order) => order._id)
      .map((order) => ({ ...order, status: 'pre-pay' }))
    const ordersListWithoutOrderIds = this.currentUserOrdersList
      .filter((order) => !order._id)
      .map((order) => ({ ...order, _id: uuid(), status: 'pre-pay' }))

    console.log(
      `orders list without order ids: ${ordersListWithoutOrderIds.length}, with order ids: ${ordersListWithOrderIds.length}`,
    )
    const orderingItemIds = this.currentUserOrdersList.map(
      (order) => order.itemInfo.itemId,
    )
    const prePayOrderIdsWithSameItems = this.loadedUserOrdersList
      .filter(
        (order) =>
          !ordersListWithOrderIds
            .map((orderTemp) => orderTemp._id)
            .includes(order._id) &&
          order.status === 'pre-pay' &&
          orderingItemIds.includes(order.itemInfo.itemId),
      )
      .map((order) => order._id)
    const existingOrderIds = ordersListWithOrderIds.map((order) => order._id)
    // const ordersListWithOrderIds = this.currentUserOrdersList.map((order) => {
    // const orderId = uuid()

    //   return {
    //     ...order,
    //     _id: order._id || uuid(),
    //     status: 'pre-pay',
    //     // taxFreeAmount: order.itemInfo.isTaxFree ? order.totalPrice : 0,
    //   }
    // })
    // console.log(
    //   `orders list with order ids: ${JSON.stringify(
    //     ordersListWithoutOrderIds,
    //   )}`,
    // )
    console.log(`ordering `)
    this.setCurrentUserOrdersList([
      ...ordersListWithoutOrderIds,
      ...ordersListWithOrderIds,
    ])
    this.setLoadedUserOrdersList([
      ...ordersListWithoutOrderIds,
      ...ordersListWithOrderIds,
      ...this.loadedUserOrdersList.filter(
        (order) => !existingOrderIds.includes(order._id),
      ),
    ])
    if (ordersListWithoutOrderIds.length > 0) {
      await backendApis.registerOrders(ordersListWithoutOrderIds)
    }
    if (prePayOrderIdsWithSameItems.length > 0) {
      const cancelInfo = {
        cancelReason: 'purchasing same item, deleting the old one from prepay',
        canceledAt: new Date(),
      }
      await Promise.all(
        prePayOrderIdsWithSameItems.map(async (orderId) => {
          await backendApis.cancelOrder(orderId, cancelInfo)
        }),
      )
      this.setLoadedUserOrdersList(
        this.loadedUserOrdersList.filter(
          (order) => !prePayOrderIdsWithSameItems.includes(order._id),
        ),
      )
    }
    this.setFinishedRegisteringOrders(true)
  }

  // async prepareKAkaoSinglePayForImp() {
  //   const orderIndicator = uuid()
  //   if (this.currentUserOrdersList.length > 0) {
  //     await this.uploadUpdatedOrdersInfo(orderIndicator)
  //   }
  // }

  async prepareKakaoSinglePay() {
    // if (this.itemInfo.itemId === '') {
    //   this.setIsTeamDeal(false)
    //   this.setItemInfo({ itemTitle: '올웨이즈 카카오페이 간편결제' })
    // }
    // const result = await backendApis.prepareKakaoSinglePay(
    //   this.orderId,
    //   this.itemInfo.itemTitle,
    //   this.quantity,
    //   this.totalPrice,
    //   this.taxFreeAmount,
    // )
    console.log(`setting background for necessary utils to true!!`)
    // UserStore.setIsBackgroundForNecessaryUtils(true)
    let result
    const orderIndicator = uuid()
    if (this.currentUserOrdersList.length === 0) {
      result = await backendApis.prepareKakaoSinglePay(
        'registerKakaoSubscriptionPay',
        '올웨이즈 카카오페이 간편결제',
        1,
        100,
        0,
        orderIndicator,
      )
    } else {
      //   const orderIndicator = '1234'
      await this.uploadUpdatedOrdersInfo(orderIndicator)
      const itemNames = this.currentUserOrdersList
        .map((order) => order.itemInfo.itemTitle)
        .join(' + ')
      result = await backendApis.prepareKakaoSinglePay(
        this.currentUserOrdersList
          .map((order) => order._id.slice(0, 3))
          .join('+'),
        itemNames.length > 100 ? `${itemNames.slice(0, 95)}...` : itemNames,
        1,
        this.currentUserOrdersList
          .map(
            (order) =>
              order.totalPrice +
              order.shippingFee -
              (order.initialLinkShareDiscountAmount || 0),
          )
          .reduce((a, b) => a + b, 0),
        this.currentUserOrdersList
          .map((order) => order.taxFreeAmount)
          .reduce((a, b) => a + b, 0),
        orderIndicator,
      )
    }
    if (result?.tid) {
      //   this.setKakaoSinglePayAppScheme(
      //     Platform.OS === 'ios'
      //       ? result.ios_app_scheme
      //       : result.android_app_scheme,
      //   )
      //   this.setKakaoSinglePayRedirectUri(result.next_redirect_mobile_url)
      this.setKakaoSinglePayRedirectUri(
        UserStore.isMobile
          ? result.next_redirect_mobile_url
          : result.next_redirect_pc_url,
      )
      this.setTid(result.tid)
    }
  }

  async checkTeamDeals() {
    const joiningTeamDeals = this.currentUserOrdersList
      .filter((order) => order.teamDealId)
      .map((order) => ({ orderId: order._id, teamDealId: order.teamDealId }))
    if (joiningTeamDeals.length > 0) {
      const result = await backendApis.checkTeamDeals(joiningTeamDeals)
      return result
    }
    return { status: 2001, data: [] }
  }

  async recordKakaoSinglePayResultFromImp(orderIndicator, kakaoPayResult) {
    const [user, loadedUserOrdersList] = await Promise.all([
      await UserStore.loadUserInfo(),
      await this.loadUserOrdersList(),
    ])
    const currentUserOrdersList = loadedUserOrdersList.filter(
      (order) => order.orderIndicator === orderIndicator,
    )
    // this.setCurrentUserOrdersList(
    //   this.loadedUserOrdersList.filter(
    //     (order) => order.orderIndicator === orderIndicator,
    //   ),
    // )
    const joiningTeamDealIds = currentUserOrdersList.map(
      (order) => order.teamDealId,
    )
    const result = await backendApis.recordKakaoSinglePayResultFromImp(
      currentUserOrdersList,
      kakaoPayResult,
    )
    if (result?.status === 200 && result.data.ordersListProcessed) {
      this.setLoadedUserOrdersList(
        this.loadedUserOrdersList.map((order) => {
          const updatedOrder = result.data.ordersListProcessed.find(
            (processedOrder) => processedOrder._id === order._id,
          )
          if (updatedOrder) {
            return updatedOrder
          }
          return order
        }),
      )
      if (joiningTeamDealIds.length > 0) {
        // UserStore.setFriendsTeamDeals(
        //   UserStore.friendsTeamDeals.filter(
        //     (teamDeal) => !joiningTeamDealIds.includes(teamDeal._id),
        //   ),
        // )
      }

      this.setCreatedTeamDealIdsToShare(
        result.data.ordersListProcessed
          .map((order) => order.teamDealId)
          .filter((teamDealId) => !joiningTeamDealIds.includes(teamDealId)),
      )
      const completedTeamDeals = result.data.ordersListProcessed
        .filter((order) => order.status === 'pre-shipping' && order.teamDealId)
        .map((order) => order.teamDealId.toString())
      socket.emit('completedTeamDeals', completedTeamDeals)
      socket.emit(
        // 'completedTeamDeals',
        // result.data.ordersListProcessed
        //   .filter(
        //     (order) => order.status === 'pre-shipping' && order.teamDealId,
        //   )
        //   .map((order) => order.teamDealId),
        'joinedTeamDeals',
        joiningTeamDealIds.filter(
          (teamDealId) =>
            teamDealId && !completedTeamDeals.includes(teamDealId),
        ),
        UserStore.userInfo._id,
      )
      const updateStoreStates = async () => {
        socket.emit(
          'updatedTeamDeals',
          joiningTeamDealIds.filter((teamDealId) => teamDealId),
        )
        if (result.data.sid) {
          const kakaoPayInfo = {
            paymentMethodId: result.data.paymentMethodId,
            sid: result.data.sid,
            kakayPayMethodType: result.data.payment_method_type,
          }
          if (result.data.payment_method_type === 'CARD') {
            kakaoPayInfo.cardInfo = result.data.card_info
          }
          UserStore.setUserKakaoPayInfo(kakaoPayInfo)
        }
        console.log(
          `ordersListProcessed: ${JSON.stringify(
            result.data.ordersListProcessed,
          )}`,
        )
        result.data.ordersListProcessed.map(async (orderTemp) => {
          if (
            orderTemp.linkShareDiscountLogs?.length > 0 &&
            orderTemp.linkShareDiscountLogs[0]?.invitorId &&
            orderTemp.linkShareDiscountLogs[0]?.orderId
          ) {
            const result = await backendApis.checkAndRefundTeamDealLinkInvitor(
              orderTemp.linkShareDiscountLogs[0].invitorId,
              orderTemp.linkShareDiscountLogs[0].orderId,
              orderTemp._id,
              orderTemp.itemInfo.itemId,
              orderTemp.itemInfo.itemTitle,
              orderTemp.itemInfo.itemImageUri,
            )
            if (result?.status === 2000) {
              console.log(`refunded team deal link invitor socket activating!`)
              socket.emit(
                'refundedTeamDealLinkInvitor',
                orderTemp.linkShareDiscountLogs[0].invitorId,
                orderTemp.linkShareDiscountLogs[0].orderId,
                {
                  inviteeId: UserStore.userInfo._id,
                  userName: UserStore.userInfo.userName,
                  userImageUri: UserStore.userInfo.userImageUri,
                },
                result.data.discountAmount,
                orderTemp._id,
                {
                  itemId: orderTemp.itemInfo.itemId,
                  itemTitle: orderTemp.itemInfo.itemTitle,
                  itemImageUri: orderTemp.itemInfo.itemImageUri,
                },
                result.data.createdAt,
              )
            }
          }
        })

        // UserStore.setUserWishList(
        //   UserStore.userWishList.filter(
        //     (wish) => !UserStore.orderingWishIds.includes(wish._id),
        //   ),
        // )
        // await backendApis.deleteUserItemWishes(UserStore.orderingWishIds)
        // if (this.selectedCouponInfo._id) {
        //   await backendApis.deleteUsedUserCoupon(this.selectedCouponInfo._id)
        // }
        // UserStore.setCouponsInfo(
        //   UserStore.couponsInfo.filter(
        //     (coupon) => coupon._id !== this.selectedCouponInfo._id,
        //   ),
        // )
        // this.setSelectedCouponInfo({})
        // UserStore.setOrderingWishIds([])
        this.setRecentlyPurchasedItemIdsAndQuantities(
          result.data.ordersListProcessed.map((order) => ({
            itemId: order.itemInfo.itemId,
            quantity: order.quantity,
          })),
        )
      }
      updateStoreStates()
    }

    return result
  }

  async approveKakaoSinglePay(orderIndicator) {
    const [user, loadedUserOrdersList] = await Promise.all([
      await UserStore.loadUserInfo(),
      await this.loadUserOrdersList(),
    ])
    const currentUserOrdersList = loadedUserOrdersList.filter(
      (order) => order.orderIndicator === orderIndicator,
    )
    // this.setCurrentUserOrdersList(
    //   this.loadedUserOrdersList.filter(
    //     (order) => order.orderIndicator === orderIndicator,
    //   ),
    // )
    const joiningTeamDealIds = currentUserOrdersList.map(
      (order) => order.teamDealId,
    )
    const result = await backendApis.approveKakaoSinglePay(
      currentUserOrdersList[0].tid,
      currentUserOrdersList.map((order) => order._id.slice(0, 3)).join('+'),
      // this.orderId,
      // this.itemInfo.itemId,
      this.kakaoPaySubscriptionRequirementParams.kakaoPayPgToken,
      // this.isTeamDeal,
      // this.teamDealId,
      // this.quantity,
      currentUserOrdersList,
      `${configs.webUrl}/`,
      //   .map((order) => ({
      //     ...order,
      //     addressInfo: UserStore.userInfo.defaultAddressInfo,
      //     couponsInfo: UserStore.couponsInfo,
      //     selectedCouponInfo:
      //       this.selectedCouponInfo.orderId === order._id
      //         ? this.selectedCouponInfo
      //         : {},
      //   })),
    )
    // console.log(`approve kakao single pay result: ${JSON.stringify(result)}`)
    if (result?.status === 200 && result.data.ordersListProcessed) {
      this.setLoadedUserOrdersList(
        this.loadedUserOrdersList.map((order) => {
          const updatedOrder = result.data.ordersListProcessed.find(
            (processedOrder) => processedOrder._id === order._id,
          )
          if (updatedOrder) {
            return updatedOrder
          }
          return order
        }),
      )
      if (joiningTeamDealIds.length > 0) {
        // UserStore.setFriendsTeamDeals(
        //   UserStore.friendsTeamDeals.filter(
        //     (teamDeal) => !joiningTeamDealIds.includes(teamDeal._id),
        //   ),
        // )
      }

      this.setCreatedTeamDealIdsToShare(
        result.data.ordersListProcessed
          .map((order) => order.teamDealId)
          .filter((teamDealId) => !joiningTeamDealIds.includes(teamDealId)),
      )
      const completedTeamDeals = result.data.ordersListProcessed
        .filter((order) => order.status === 'pre-shipping' && order.teamDealId)
        .map((order) => order.teamDealId.toString())
      socket.emit('completedTeamDeals', completedTeamDeals)
      socket.emit(
        // 'completedTeamDeals',
        // result.data.ordersListProcessed
        //   .filter(
        //     (order) => order.status === 'pre-shipping' && order.teamDealId,
        //   )
        //   .map((order) => order.teamDealId),
        'joinedTeamDeals',
        joiningTeamDealIds.filter(
          (teamDealId) =>
            teamDealId && !completedTeamDeals.includes(teamDealId),
        ),
        UserStore.userInfo._id,
      )
      const updateStoreStates = async () => {
        socket.emit(
          'updatedTeamDeals',
          joiningTeamDealIds.filter((teamDealId) => teamDealId),
        )
        if (result.data.sid) {
          const kakaoPayInfo = {
            paymentMethodId: result.data.paymentMethodId,
            sid: result.data.sid,
            kakayPayMethodType: result.data.payment_method_type,
          }
          if (result.data.payment_method_type === 'CARD') {
            kakaoPayInfo.cardInfo = result.data.card_info
          }
          UserStore.setUserKakaoPayInfo(kakaoPayInfo)
        }
        console.log(
          `ordersListProcessed: ${JSON.stringify(
            result.data.ordersListProcessed,
          )}`,
        )
        result.data.ordersListProcessed.map(async (orderTemp) => {
          if (
            orderTemp.linkShareDiscountLogs?.length > 0 &&
            orderTemp.linkShareDiscountLogs[0]?.invitorId &&
            orderTemp.linkShareDiscountLogs[0]?.orderId
          ) {
            const result = await backendApis.checkAndRefundTeamDealLinkInvitor(
              orderTemp.linkShareDiscountLogs[0].invitorId,
              orderTemp.linkShareDiscountLogs[0].orderId,
              orderTemp._id,
              orderTemp.itemInfo.itemId,
              orderTemp.itemInfo.itemTitle,
              orderTemp.itemInfo.itemImageUri,
            )
            if (result?.status === 2000) {
              // console.log(`refunded team deal link invitor socket activating!`)
              socket.emit(
                'refundedTeamDealLinkInvitor',
                orderTemp.linkShareDiscountLogs[0].invitorId,
                orderTemp.linkShareDiscountLogs[0].orderId,
                {
                  inviteeId: UserStore.userInfo._id,
                  userName: UserStore.userInfo.userName,
                  userImageUri: UserStore.userInfo.userImageUri,
                },
                result.data.discountAmount,
                orderTemp._id,
                {
                  itemId: orderTemp.itemInfo.itemId,
                  itemTitle: orderTemp.itemInfo.itemTitle,
                  itemImageUri: orderTemp.itemInfo.itemImageUri,
                },
                result.data.createdAt,
              )
            }
          }
        })

        // UserStore.setUserWishList(
        //   UserStore.userWishList.filter(
        //     (wish) => !UserStore.orderingWishIds.includes(wish._id),
        //   ),
        // )
        // await backendApis.deleteUserItemWishes(UserStore.orderingWishIds)
        // if (this.selectedCouponInfo._id) {
        //   await backendApis.deleteUsedUserCoupon(this.selectedCouponInfo._id)
        // }
        // UserStore.setCouponsInfo(
        //   UserStore.couponsInfo.filter(
        //     (coupon) => coupon._id !== this.selectedCouponInfo._id,
        //   ),
        // )
        // this.setSelectedCouponInfo({})
        // UserStore.setOrderingWishIds([])
        this.setRecentlyPurchasedItemIdsAndQuantities(
          result.data.ordersListProcessed.map((order) => ({
            itemId: order.itemInfo.itemId,
            quantity: order.quantity,
          })),
        )
      }
      updateStoreStates()
    }

    return result
  }

  async uploadUpdatedOrdersInfo(orderIndicator) {
    await backendApis.updateOrdersOptionsInfo(
      this.currentUserOrdersList.map((order) => ({
        ...order,
        addressInfo: UserStore.userInfo.defaultAddressInfo,
        couponsInfo: UserStore.couponsInfo,
        selectedCouponInfo:
          this.selectedCouponInfo.orderId === order._id
            ? this.selectedCouponInfo
            : {},
        orderIndicator,
        // taxFreeAmount: order.itemInfo.isTaxFree ? order.totalPrice : 0,
      })),
    )
    this.setCurrentUserOrdersList(
      this.currentUserOrdersList.map((order) => ({
        ...order,
        addressInfo: UserStore.userInfo.defaultAddressInfo,
        couponsInfo: UserStore.couponsInfo,
        selectedCouponInfo:
          this.selectedCouponInfo.orderId === order._id
            ? this.selectedCouponInfo
            : {},
        orderIndicator,
        // taxFreeAmount: order.itemInfo.isTaxFree ? order.totalPrice : 0,
      })),
    )
  }

  //   async requestKakaoSubscriptionPay() {
  //     const joiningTeamDealIds = this.currentUserOrdersList.map(
  //       (order) => order.teamDealId,
  //     )
  //     const result = await backendApis.requestKakaoSubscriptionPay(
  //       UserStore.userInfo.kakaoPayInfo.paymentMethodId,
  //       UserStore.userInfo.kakaoPayInfo.sid,
  //       this.currentUserOrdersList.map((order) => ({
  //         ...order,
  //         addressInfo: UserStore.userInfo.defaultAddressInfo,
  //         couponsInfo: UserStore.couponsInfo,
  //         selectedCouponInfo:
  //           this.selectedCouponInfo.orderId === order._id
  //             ? this.selectedCouponInfo
  //             : {},
  //       })),
  //     )
  //     if (result.data.ordersListProcessed) {
  //       this.setLoadedUserOrdersList(
  //         this.loadedUserOrdersList.map((order) => {
  //           const updatedOrder = result.data.ordersListProcessed.find(
  //             (processedOrder) => processedOrder._id === order._id,
  //           )
  //           // console.log(`updated order: ${JSON.stringify(updatedOrder)}`)
  //           if (updatedOrder) {
  //             return updatedOrder
  //           }
  //           return order
  //         }),
  //       )
  //       if (joiningTeamDealIds.length > 0) {
  //         // UserStore.setFriendsTeamDeals(
  //         //   UserStore.friendsTeamDeals.filter(
  //         //     (teamDeal) => !joiningTeamDealIds.includes(teamDeal._id),
  //         //   ),
  //         // )
  //       }

  //       this.setCreatedTeamDealIdsToShare(
  //         result.data.ordersListProcessed
  //           .map((order) => order.teamDealId)
  //           .filter((teamDealId) => !joiningTeamDealIds.includes(teamDealId)),
  //       )
  //       // socket.emit(
  //       //   'completedTeamDeals',
  //       //   result.data.ordersListProcessed
  //       //     .filter(
  //       //       (order) => order.status === 'pre-shipping' && order.teamDealId,
  //       //     )
  //       //     .map((order) => order.teamDealId),
  //       // )
  //       const completedTeamDeals = result.data.ordersListProcessed
  //         .filter((order) => order.status === 'pre-shipping' && order.teamDealId)
  //         .map((order) => order.teamDealId.toString())
  //       socket.emit('completedTeamDeals', completedTeamDeals)
  //       socket.emit(
  //         'joinedTeamDeals',
  //         joiningTeamDealIds.filter(
  //           (teamDealId) =>
  //             teamDealId && !completedTeamDeals.includes(teamDealId),
  //         ),
  //         UserStore.userInfo._id,
  //       )
  //       const updateStoreStates = async () => {
  //         socket.emit(
  //           'updatedTeamDeals',
  //           joiningTeamDealIds.filter((teamDealId) => teamDealId),
  //         )
  //         // UserStore.setUserWishList(
  //         //   UserStore.userWishList.filter(
  //         //     (wish) => !UserStore.orderingWishIds.includes(wish._id),
  //         //   ),
  //         // )
  //         // console.log(
  //         //   `ordering wish ids: ${JSON.stringify(UserStore.orderingWishIds)}`,
  //         // )
  //         // await backendApis.deleteUserItemWishes(UserStore.orderingWishIds)
  //         if (this.selectedCouponInfo._id) {
  //           await backendApis.deleteUsedUserCoupon(this.selectedCouponInfo._id)
  //         }
  //         UserStore.setCouponsInfo(
  //           UserStore.couponsInfo.filter(
  //             (coupon) => coupon._id !== this.selectedCouponInfo._id,
  //           ),
  //         )
  //         this.setSelectedCouponInfo({})
  //         this.setRecentlyPurchasedItemIdsAndQuantities(
  //           result.data.ordersListProcessed.map((order) => ({
  //             itemId: order.itemInfo.itemId,
  //             quantity: order.quantity,
  //           })),
  //         )
  //         // UserStore.setOrderingWishIds([])
  //       }
  //       updateStoreStates()
  //     }
  //     // console.log(`kakao subscription pay result: ${JSON.stringify(result)}`)
  //     return result
  //   }

  //   async requestNicepayBillingPay() {
  //     const joiningTeamDealIds = this.currentUserOrdersList.map(
  //       (order) => order.teamDealId,
  //     )
  //     const result = await backendApis.requestNicepayBillingPay(
  //       UserStore.userInfo.representativeCardInfo.paymentMethodId,
  //       UserStore.userInfo.representativeCardInfo.bid,
  //       this.currentUserOrdersList.map((order) => ({
  //         ...order,
  //         addressInfo: UserStore.userInfo.defaultAddressInfo,
  //         couponsInfo: UserStore.couponsInfo,
  //         selectedCouponInfo:
  //           this.selectedCouponInfo.orderId === order._id
  //             ? this.selectedCouponInfo
  //             : {},
  //       })),
  //       // this.orderId,
  //       // this.itemInfo.itemId,
  //       // this.totalPrice,
  //       // this.taxFreeAmount,
  //       // this.isTeamDeal,
  //       // this.teamDealId,
  //       // this.quantity,
  //     )
  //     // console.log(`nice pay result: ${JSON.stringify(result)}`)
  //     if (result.status === 200 && result.data.data?.ResultCode === '3001') {
  //       this.setLoadedUserOrdersList(
  //         this.loadedUserOrdersList.map((order) => {
  //           const updatedOrder = result.data.ordersListProcessed.find(
  //             (processedOrder) => processedOrder._id === order._id,
  //           )
  //           // console.log(`updated order: ${JSON.stringify(updatedOrder)}`)
  //           if (updatedOrder) {
  //             return updatedOrder
  //           }
  //           return order
  //         }),
  //       )
  //       if (joiningTeamDealIds.length > 0) {
  //         // UserStore.setFriendsTeamDeals(
  //         //   UserStore.friendsTeamDeals.filter(
  //         //     (teamDeal) => !joiningTeamDealIds.includes(teamDeal._id),
  //         //   ),
  //         // )
  //       }

  //       this.setCreatedTeamDealIdsToShare(
  //         result.data.ordersListProcessed
  //           .map((order) => order.teamDealId)
  //           .filter((teamDealId) => !joiningTeamDealIds.includes(teamDealId)),
  //       )
  //       // socket.emit(
  //       //   'completedTeamDeals',
  //       //   result.data.ordersListProcessed
  //       //     .filter(
  //       //       (order) => order.status === 'pre-shipping' && order.teamDealId,
  //       //     )
  //       //     .map((order) => order.teamDealId),
  //       // )
  //       const completedTeamDeals = result.data.ordersListProcessed
  //         .filter((order) => order.status === 'pre-shipping' && order.teamDealId)
  //         .map((order) => order.teamDealId.toString())
  //       socket.emit('completedTeamDeals', completedTeamDeals)
  //       socket.emit(
  //         // 'completedTeamDeals',
  //         // result.data.ordersListProcessed
  //         //   .filter(
  //         //     (order) => order.status === 'pre-shipping' && order.teamDealId,
  //         //   )
  //         //   .map((order) => order.teamDealId),
  //         'joinedTeamDeals',
  //         joiningTeamDealIds.filter(
  //           (teamDealId) =>
  //             teamDealId && !completedTeamDeals.includes(teamDealId),
  //         ),
  //         UserStore.userInfo._id,
  //       )
  //       const updateStoreStates = async () => {
  //         socket.emit(
  //           'updatedTeamDeals',
  //           joiningTeamDealIds.filter((teamDealId) => teamDealId),
  //         )
  //         // UserStore.setUserWishList(
  //         //   UserStore.userWishList.filter(
  //         //     (wish) => !UserStore.orderingWishIds.includes(wish._id),
  //         //   ),
  //         // )
  //         // await backendApis.deleteUserItemWishes(UserStore.orderingWishIds)
  //         if (this.selectedCouponInfo._id) {
  //           await backendApis.deleteUsedUserCoupon(this.selectedCouponInfo._id)
  //         }
  //         UserStore.setCouponsInfo(
  //           UserStore.couponsInfo.filter(
  //             (coupon) => coupon._id !== this.selectedCouponInfo._id,
  //           ),
  //         )
  //         this.setSelectedCouponInfo({})
  //         // UserStore.setOrderingWishIds([])
  //         this.setRecentlyPurchasedItemIdsAndQuantities(
  //           result.data.ordersListProcessed.map((order) => ({
  //             itemId: order.itemInfo.itemId,
  //             quantity: order.quantity,
  //           })),
  //         )
  //       }
  //       updateStoreStates()
  //     }

  //     return result
  //   }

  //   async requestPaypleBillingPay() {
  //     const joiningTeamDealIds = this.currentUserOrdersList.map(
  //       (order) => order.teamDealId,
  //     )
  //     const result = await backendApis.requestPaypleBillingPay(
  //       UserStore.userInfo.representativeCardInfo.paymentMethodId,
  //       UserStore.userInfo.representativeCardInfo.bid,
  //       this.currentUserOrdersList.map((order) => ({
  //         ...order,
  //         addressInfo: UserStore.userInfo.defaultAddressInfo,
  //         couponsInfo: UserStore.couponsInfo,
  //         selectedCouponInfo:
  //           this.selectedCouponInfo.orderId === order._id
  //             ? this.selectedCouponInfo
  //             : {},
  //       })),
  //     )
  //     // console.log(`nice pay result: ${JSON.stringify(result)}`)
  //     if (result.status === 200 && result.data.PCD_PAY_RST === 'success') {
  //       this.setLoadedUserOrdersList(
  //         this.loadedUserOrdersList.map((order) => {
  //           const updatedOrder = result.data.ordersListProcessed.find(
  //             (processedOrder) => processedOrder._id === order._id,
  //           )
  //           // console.log(`updated order: ${JSON.stringify(updatedOrder)}`)
  //           if (updatedOrder) {
  //             return updatedOrder
  //           }
  //           return order
  //         }),
  //       )
  //       if (joiningTeamDealIds.length > 0) {
  //         // UserStore.setFriendsTeamDeals(
  //         //   UserStore.friendsTeamDeals.filter(
  //         //     (teamDeal) => !joiningTeamDealIds.includes(teamDeal._id),
  //         //   ),
  //         // )
  //       }

  //       this.setCreatedTeamDealIdsToShare(
  //         result.data.ordersListProcessed
  //           .map((order) => order.teamDealId)
  //           .filter((teamDealId) => !joiningTeamDealIds.includes(teamDealId)),
  //       )
  //       // socket.emit(
  //       //   'completedTeamDeals',
  //       //   result.data.ordersListProcessed
  //       //     .filter(
  //       //       (order) => order.status === 'pre-shipping' && order.teamDealId,
  //       //     )
  //       //     .map((order) => order.teamDealId),
  //       // )
  //       const completedTeamDeals = result.data.ordersListProcessed
  //         .filter((order) => order.status === 'pre-shipping' && order.teamDealId)
  //         .map((order) => order.teamDealId.toString())
  //       socket.emit('completedTeamDeals', completedTeamDeals)
  //       socket.emit(
  //         // 'completedTeamDeals',
  //         // result.data.ordersListProcessed
  //         //   .filter(
  //         //     (order) => order.status === 'pre-shipping' && order.teamDealId,
  //         //   )
  //         //   .map((order) => order.teamDealId),
  //         'joinedTeamDeals',
  //         joiningTeamDealIds.filter(
  //           (teamDealId) =>
  //             teamDealId && !completedTeamDeals.includes(teamDealId),
  //         ),
  //         UserStore.userInfo._id,
  //       )
  //       const updateStoreStates = async () => {
  //         socket.emit(
  //           'updatedTeamDeals',
  //           joiningTeamDealIds.filter((teamDealId) => teamDealId),
  //         )
  //         // UserStore.setUserWishList(
  //         //   UserStore.userWishList.filter(
  //         //     (wish) => !UserStore.orderingWishIds.includes(wish._id),
  //         //   ),
  //         // )
  //         // await backendApis.deleteUserItemWishes(UserStore.orderingWishIds)
  //         if (this.selectedCouponInfo._id) {
  //           await backendApis.deleteUsedUserCoupon(this.selectedCouponInfo._id)
  //         }
  //         UserStore.setCouponsInfo(
  //           UserStore.couponsInfo.filter(
  //             (coupon) => coupon._id !== this.selectedCouponInfo._id,
  //           ),
  //         )
  //         this.setSelectedCouponInfo({})
  //         // UserStore.setOrderingWishIds([])
  //         this.setRecentlyPurchasedItemIdsAndQuantities(
  //           result.data.ordersListProcessed.map((order) => ({
  //             itemId: order.itemInfo.itemId,
  //             quantity: order.quantity,
  //           })),
  //         )
  //       }
  //       updateStoreStates()
  //     }

  //     return result
  //   }

  async loadUserOrdersList() {
    // console.log(`load user orders list`)
    // await backendApis.registerLogs('load user orders list')
    const result = await backendApis.getUserOrders()
    if (result?.status === 200) {
      // console.log(`orders list: ${JSON.stringify(result.data)}`)
      this.setLoadedUserOrdersList(
        result.data.filter((order) => !!order.itemInfo),
      )
      this.setFinishedLoadingUserOrders(true)
      return result.data.filter((order) => !!order.itemInfo)
    }
  }
}

const container = {}
container.instance = new OrderStore()

export default container.instance
