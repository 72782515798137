import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ItemStore from '../../stores/ItemStore'
import UserStore from '../../stores/UserStore'
import OrderStore from '../../stores/OrderStore'
import Timer from './Timer'

const WhiteTextTimer = ({
  timeStamp,
  onTimeEnd,
  interval = 100,
  timerMinutes = 60 * 24,
}) => {
  return (
    <span>
      {Timer({ timeStamp, onTimeEnd, interval, timerMinutes }).formattedTime}
    </span>
  )
}

export default WhiteTextTimer
