import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import UserStore from '../../stores/UserStore'
import ImageManager from '../../images/ImageManager'
import ProfileImageWithNameFilter from './ProfileImageWithNameFilter'

// import { useHistory, useLocation, useParams } from 'react-router-dom'

const TeamMembersComponentItemScreen = observer(({ teamDealInfo }) => {
  if (!teamDealInfo) {
    console.log(`corresponding order: ${JSON.stringify()}`)
  }
  useEffect(() => {}, [])
  const teamLeader = teamDealInfo.membersInfo?.find(
    (memberInfo) => memberInfo.userId === teamDealInfo.creatorId,
  ) ||
    teamDealInfo.membersInfo[0] || {
      userId: UserStore.userInfo._id,
      userName: UserStore.userInfo.userName,
      userImageUri: UserStore.userInfo.userImageUri,
    }
  const restOfTheMembers =
    teamDealInfo.membersInfo?.filter(
      (memberInfo) => memberInfo.userId !== teamLeader.userId,
    ) || []

  const ArrayOfEmptyMembers = Array(
    teamDealInfo.teamDealThreshold - teamDealInfo.membersInfo.length || 0,
  ).fill({})
  const totalArray = [teamLeader].concat(restOfTheMembers, ArrayOfEmptyMembers)

  const RenderItem = ({ item, index }) => {
    // if (index === 0) {
    //   return (
    //     <div
    //       style={{
    //         position: 'relative',
    //       }}
    //     >
    //       <div
    //         style={{
    //           border: 'solid',
    //           width: 48,
    //           height: 48,
    //           borderRadius: 50,
    //           marginLeft: 6,
    //           marginRight: 6,
    //           marginTop: 4,
    //           marginBottom: 4,
    //           borderColor: '#F8C454',
    //           borderWidth: 3,
    //         }}
    //       >
    //         <ProfileImageWithNameFilter
    //           imageUri={item.userImageUri}
    //           size={48}
    //         />
    //       </div>
    //       {/* <img
    //         alt=''
    //         src={item.userImageUri}
    //         style={{
    //           border: 'solid',
    //           width: 48,
    //           height: 48,
    //           borderRadius: 50,
    //           marginLeft: 6,
    //           marginRight: 6,
    //           marginTop: 4,
    //           marginBottom: 4,
    //           borderColor: '#F8C454',
    //           borderWidth: 3,
    //         }}
    //       /> */}
    //       <div
    //         style={{
    //           position: 'absolute',
    //           top: -4,
    //           left: -4,
    //           width: 36,
    //           height: 20,
    //           borderRadius: 10,
    //           fontSize: 12,
    //           color: '#FFF',
    //           backgroundColor: '#F8C454',
    //           display: 'flex',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //         }}
    //       >
    //         팀장
    //       </div>
    //     </div>
    //   )
    // }
    if (Object.keys(item).length !== 0) {
      return (
        <div
          style={{
            width: 50,
            height: 50,
            borderRadius: 50,
            marginLeft: 6,
            marginRight: 6,
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <ProfileImageWithNameFilter imageUri={item.userImageUri} size={50} />
        </div>
      )
    }
    return (
      <div style={{}}>
        <div
          style={{
            display: 'flex',
            width: 48,
            border: 'dashed',
            height: 48,
            borderRadius: 50,
            marginLeft: 6,
            marginRight: 6,
            marginTop: 4,
            marginBottom: 4,
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: '#BDBDBD',
            borderWidth: 2,
          }}
        >
          <ImageManager source='Question' size={28} />
        </div>
      </div>
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        paddingTop: 4,
        marginBottom: 12,
      }}
    >
      {totalArray.map((value, index) => (
        <RenderItem
          key={value.userId + index.toString()}
          item={value}
          index={index}
        />
      ))}
    </div>
  )
})

export default TeamMembersComponentItemScreen
