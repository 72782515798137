import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import backendApis from '../utils/backendApis'
import Postcode from '@actbase/react-daum-postcode'
import BasicHeader from '../components/header/BasicHeader'
import UserStore from '../stores/UserStore'
import RoundCheckbox from '../components/checkbox/RoundCheckbox'
import AppDownloadModal from '../components/modal/AppDownloadModal'

const AddShippingAddressScreen = observer(({ route }) => {
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    console.log('history', history)
    console.log('route', route)
    UserStore.setScreenName('AddShippingAddressScreen_Web')
  }, [])
  const [recipient, onChangeRecipient] = useState(UserStore.userInfo.userName)
  const [phoneNumber, onChangePhoneNumber] = useState(
    UserStore.userInfo.phoneNumber,
  )
  const [postcode, setPostcode] = useState('')
  const [postcodeAddress, setPostCodeAddress] = useState('')
  const [detailAddress, onChangeDetailAddress] = useState('')
  const [open, setOpen] = useState(false)
  const [existFrontDoorPassword, setExistFrontDoorPassword] = useState(false)
  const [frontDoorPassword, onChangefrontDoorPassword] = useState('')
  const [receivingMethod, setReceivingMethod] = useState('door')
  const [detailForReceivingMethod, onChangeDetailForReceivingMethod] =
    useState('')
  const [showGiftSentModal, setShowGiftSentModal] = useState(false)

  const refRBSheet = useRef()
  const refDetailAddress = useRef()
  const refFrontDoorPassword = useRef()
  const refDetailForShippingMethod = useRef()

  const [inputLevel, setInputLevel] = useState(1)

  const onChangeTextRecipient = (text) => {
    onChangeRecipient(text)
  }
  const onChangeTextPhoneNumber = (text) => {
    onChangePhoneNumber(text)
    if (text.length > 0 && inputLevel === 0) {
      setInputLevel(1)
    }
  }
  const onChangeTextDetailAddress = (text) => {
    onChangeDetailAddress(text)
    if (text.length > 0 && inputLevel === 2) {
      setInputLevel(3)
    }
  }

  const onChangeTextfrontDoorPassword = (text) => {
    setExistFrontDoorPassword(true)
    onChangefrontDoorPassword(text)
  }
  const onChangeTextDetailForReceivingMethod = (text) => {
    setReceivingMethod('etc')
    onChangeDetailForReceivingMethod(text)
  }
  const styles = {
    textInput: {
      height: 40,
      backgroundColor: '#F5F5F5',
      fontSize: 14,
      // alignSelf: 'center',
      // textAlign: 'center',
      borderRadius: 4,
      padding: 0,
      border: 'none',
      outline: 'none',
      width: '100%',
      paddingLeft: 8,
    },
    button: {
      height: 40,
      backgroundColor: '#EA3F49',
      fontSize: 14,
      // alignSelf: 'center',
      // textAlign: 'center',
      borderRadius: 4,
      padding: 0,
      border: 'none',
      outline: 'none',
      width: '100%',
      paddingLeft: 8,
      color: '#FFFFFF',
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: 'bold',
      height: 32,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: 72,
      marginBottom: 8,
      outline: 'none',
    },
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'row',
      outline: 'none',
      height: 40,
      alignItems: 'center',
    },
    subText: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    checkboxTextInput: {
      height: 40,
      backgroundColor: '#F5F5F5',
      fontSize: 14,
      // alignSelf: 'center',
      // textAlign: 'center',
      borderRadius: 4,
      padding: 0,
      border: 'none',
      outline: 'none',
      paddingLeft: 8,
      flexGrow: 1,
    },
  }
  return (
    <div>
      <AppDownloadModal showModal={showGiftSentModal} />
      <BasicHeader title='새 배송지 추가하기' />
      <div style={styles.container}>
        <div style={{ flex: 1, marginLeft: 16 }}>
          <div style={styles.text}>받는 사람</div>
          <div>
            <input
              type='text'
              style={styles.textInput}
              placeholder='홍길동'
              value={recipient}
              onChange={(e) => {
                onChangeTextRecipient(e.target.value)
              }}
            />
          </div>
        </div>
        <div style={{ flex: 2, marginLeft: 24, marginRight: 24 }}>
          <div style={styles.text}>휴대폰 번호</div>
          <div>
            <input
              // type='number'
              inputMode='tel'
              placeholder='01012345678'
              style={styles.textInput}
              value={phoneNumber}
              onChange={(e) => {
                onChangeTextPhoneNumber(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      {inputLevel > 0 && (
        <div style={styles.container}>
          <div style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
            <div style={styles.text}>우편 번호</div>
            <div>
              <button
                type='button'
                style={styles.button}
                onClick={() => {
                  setOpen(true)
                }}
              >
                {postcode === ''
                  ? '우편 번호 검색하기'
                  : `${postcode}  ${postcodeAddress}`}
              </button>
            </div>
          </div>
        </div>
      )}
      {inputLevel > 1 && (
        <div style={styles.container}>
          <div style={{ flex: 1, marginLeft: 16, marginRight: 24 }}>
            <div style={styles.text}>상세 주소</div>
            <div>
              <input
                type='text'
                placeholder='상세 주소 입력'
                style={styles.textInput}
                value={detailAddress}
                onChange={(e) => {
                  onChangeTextDetailAddress(e.target.value)
                }}
              />
            </div>
          </div>
        </div>
      )}
      {inputLevel > 2 && (
        // recipient.length > 0 &&
        // postcode.length > 0 &&
        // postcodeAddress.length > 0 &&
        // detailAddress.length > 0 &&
        <div>
          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <div style={styles.text}>공동현관 비밀번호</div>
            <div
              style={styles.checkboxContainer}
              role='button'
              tabIndex='0'
              onClick={() => {
                setExistFrontDoorPassword(false)
                onChangefrontDoorPassword('')
              }}
              onKeyDown={() => {
                setExistFrontDoorPassword(false)
                onChangefrontDoorPassword('')
              }}
            >
              <RoundCheckbox checked={!existFrontDoorPassword} />
              <div style={styles.subText}>없음</div>
            </div>
            <div
              role='button'
              tabIndex='0'
              style={styles.checkboxContainer}
              onClick={() => {
                setExistFrontDoorPassword(true)
              }}
              onKeyDown={() => {
                setExistFrontDoorPassword(true)
              }}
            >
              <RoundCheckbox checked={existFrontDoorPassword} />
              <div style={styles.subText}>비밀번호</div>
              <input
                type='text'
                style={styles.checkboxTextInput}
                placeholder='#0000'
                onChange={(e) => {
                  onChangeTextfrontDoorPassword(e.target.value)
                }}
                value={frontDoorPassword}
                ref={refFrontDoorPassword}
              />
            </div>
          </div>
          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <div style={styles.text}>배송 수령 방식</div>
            <div
              style={styles.checkboxContainer}
              role='button'
              tabIndex='0'
              onClick={() => {
                onChangeDetailForReceivingMethod('')
                setReceivingMethod('door')
              }}
              onKeyDown={() => {
                onChangeDetailForReceivingMethod('')
                setReceivingMethod('door')
              }}
            >
              <RoundCheckbox checked={receivingMethod === 'door'} />
              <div style={styles.subText}>문 앞</div>
            </div>
            <div
              style={styles.checkboxContainer}
              role='button'
              tabIndex='0'
              onClick={() => {
                setReceivingMethod('etc')
              }}
              onKeyDown={() => {
                setReceivingMethod('etc')
              }}
            >
              <RoundCheckbox checked={receivingMethod === 'etc'} />
              <div style={styles.subText}>직접 입력</div>
              <input
                style={styles.checkboxTextInput}
                type='text'
                placeholder='직접 입력하기'
                onChange={(e) => {
                  onChangeTextDetailForReceivingMethod(e.target.value)
                }}
                value={detailForReceivingMethod}
                ref={refDetailForShippingMethod}
              />
            </div>
          </div>
          <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}>
            <button
              style={{
                border: 'none',
                outline: 'none',
                fontSize: 16,
                color: '#FFF',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 4,
                width: '100%',
                backgroundColor: '#EA3F49',
                height: 40,
              }}
              type='button'
              onClick={async () => {
                // history.push('/address/add/second', {
                //   addressInfo: {
                //     recipient,
                //     postcode,
                //     postcodeAddress,
                //     detailAddress,
                //     recipientPhoneNumber: phoneNumber,
                //   },
                // })

                if (location?.state?.origin === 'sendGift') {
                  setShowGiftSentModal(true)
                  console.log('showGiftSentModal', showGiftSentModal)
                  return
                }

                if (
                  recipient.length > 0 &&
                  postcode.length > 0 &&
                  postcodeAddress.length > 0 &&
                  detailAddress.length > 0
                ) {
                  if (existFrontDoorPassword && frontDoorPassword === '') {
                    alert('공동현관 비밀번호를 기입해주세요.')
                    return
                  }
                  if (
                    receivingMethod === 'etc' &&
                    detailForReceivingMethod === ''
                  ) {
                    alert('배송 수령 방식을 기입해주세요.')
                    return
                  }
                  UserStore.setIsLoading(true)
                  const result = await UserStore.registerAddress(
                    recipient,
                    postcode,
                    postcodeAddress,
                    detailAddress,
                    phoneNumber,
                    frontDoorPassword,
                    receivingMethod,
                    detailForReceivingMethod,
                  )
                  UserStore.setIsLoading(false)
                  if (result?.status === 200) {
                    console.log(`going back 2 steps!`)
                    history.go(-1)
                    // history.push('/address')
                    // navigation.navigate('ShippingAddress')
                  }
                }
              }}
            >
              배송지 추가하기
            </button>
          </div>
        </div>
      )}
      <BottomSheet
        open={open}
        onDismiss={() => {
          setOpen(false)
        }}
      >
        <Postcode
          style={{ display: 'flex', flex: 1, backgroundColor: '#FFF' }}
          jsOptions={{ animated: true, submitMode: false }}
          onSelected={(data) => {
            if (inputLevel === 1) {
              setInputLevel(2)
            }
            setPostcode(data.zonecode)
            setPostCodeAddress(data.address)
            setOpen(false)
            // refRBSheet.current?.close()
            // alert(JSON.stringify(data))
          }}
        />
      </BottomSheet>
    </div>
  )
})

export default AddShippingAddressScreen
