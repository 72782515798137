import React, { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import commaNumber from 'comma-number'

function SupportFundEvent() {
  return (
    <>
      <Layout>
        <div style={{ height: '100%', overflowY: 'scroll' }}>
          <div
            style={{
              display: 'flex',
              height: '100vh',
              width: '100vw',
              background: '#F6F4E5',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: 100,
            }}
          >
            <div
              style={{
                backgroundColor: '#4D4B3C',
                color: '#FFFFFF',
                fontFamily: 'GmarketSans',
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                borderRadius: 16,
                marginTop: 24,
                marginBottom: 16,
              }}
            >
              최대 1억원 지원금 혜택!
            </div>
            <div
              style={{
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'GmarketSans',
                fontSize: 20,
                color: '#4D4B3C',
                fontWeight: 900,
                marginBottom: 24,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F2C660',
                    height: 10,
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    marginRight: 4,
                  }}
                >
                  연말지원금
                </div>
                <div>
                  <span style={{ color: '#EB4F47', marginRight: 4 }}>
                    5만원
                  </span>
                  받아가세요!
                </div>
              </div>
            </div>
            <div
              style={{
                width: 264,
                height: 188,
                backgroundSize: 'cover',
                backgroundImage:
                  "url('https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/5dc4753e-fb69-47b6-bc20-0cee1363a21e.png')",
                marginBottom: 8,
                position: 'relative',
              }}
            >
              <div
                style={{
                  color: '#A5770B',
                  fontFamily: 'GmarketSans',
                  fontWeight: 900,
                  position: 'absolute',
                  top: 20,
                  left: 80,
                  fontSize: 12,
                }}
              >
                현재금액
              </div>
              <button
                type='button'
                style={{ background: 'none', border: 'none' }}
                onClick={async () => {
                  window.location.href = `https://alwayzshop.ilevit.com/app`
                }}
              >
                <div
                  style={{
                    color: 'white',
                    fontFamily: 'GmarketSans',
                    fontWeight: 700,
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    fontSize: 12,
                  }}
                >
                  출금 >
                </div>
              </button>
              <>
                <div
                  style={{
                    color: '#422E02',
                    fontFamily: 'GmarketSans',
                    fontWeight: 700,
                    position: 'absolute',
                    top: 44,
                    left: 80,
                    fontSize: 28,
                  }}
                >
                  49,500 원
                </div>
                <div
                  style={{
                    color: '#422E02',
                    fontFamily: 'GmarketSans',
                    position: 'absolute',
                    top: 140,
                    left: 76,
                    fontSize: 12,
                    fontWeight: 700,
                  }}
                >
                  남은시간 <span style={{ color: '#EB4F47' }}>23:39:12</span>
                </div>
              </>
            </div>
            <div
              style={{
                backgroundColor: '#EB4F47',
                padding: 8,
                paddingTop: 12,
                paddingBottom: 12,
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 8,
                margin: 8,
                marginBottom: 8,
                width: '64%',
              }}
            >
              <button
                type='button'
                style={{
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'GmarketSans',
                  fontWeight: 900,
                  fontSize: 16,
                }}
                onClick={async () => {
                  window.location.href = `https://alwayzshop.ilevit.com/app`
                }}
              >
                친구와 함께 연말지원금 받기
              </button>
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontSize: 12,
                color: '#79765F',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  width: '80vw',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F2C660',
                    width: '8%',
                    height: 6,
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    fontWeight: 900,
                    marginRight: 4,
                  }}
                >
                  TIP
                </div>
                새친구와 같이하면 더 빨리 모을 수 있어요
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '80vw',
                justifyContent: 'space-around',
                marginTop: 20,
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  width: '36vw',
                  borderRadius: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontFamily: 'GmarketSans',
                  color: '#666666',
                  fontSize: 12,
                }}
              >
                <>
                  <button
                    type='button'
                    style={{
                      background: 'none',
                      border: 'none',
                      color: 'white',
                      fontSize: 16,
                      fontFamily: 'GmarketSans',
                      paddingTop: 12,
                    }}
                    onClick={async () => {
                      window.location.href = `https://alwayzshop.ilevit.com/app`
                    }}
                  >
                    <img
                      alt=''
                      style={{ width: 48, height: 48, marginBottom: 8 }}
                      src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/6d234cd5-3414-4e8a-890b-2bf50f935838.png'
                    />
                    <div style={{ color: '#666666', fontSize: 12 }}>
                      한시간마다 작물에
                    </div>
                    <div style={{ color: '#666666', fontSize: 12 }}>
                      물을 주고 돈을 받아요
                    </div>
                    <div
                      style={{
                        backgroundColor: '#4D4B3C',
                        width: '36vw',
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                        marginTop: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }}
                    >
                      물주러 가기
                    </div>
                  </button>
                </>
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  width: '36vw',
                  borderRadius: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontFamily: 'GmarketSans',
                  color: '#666666',
                  fontSize: 12,
                }}
              >
                <button
                  type='button'
                  style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                    fontSize: 16,
                    fontFamily: 'GmarketSans',
                    paddingTop: 12,
                  }}
                  onClick={async () => {
                    window.location.href = `https://alwayzshop.ilevit.com/app`
                  }}
                >
                  <img
                    alt=''
                    style={{ width: 48, height: 48, marginBottom: 8 }}
                    src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/f239ca93-3372-4b17-8d95-860e5593020f.png'
                  />
                  <div style={{ color: '#666666', fontSize: 12 }}>
                    친구 3명에게
                  </div>
                  <div style={{ color: '#666666', fontSize: 12 }}>
                    돈을 뿌릴 수 있어요
                  </div>

                  <>
                    <div
                      style={{
                        backgroundColor: '#4D4B3C',
                        width: '36vw',
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                        marginTop: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }}
                    >
                      돈 받기
                    </div>
                  </>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* {showSupportFundInviteOnboardingModal && (
          <SupportFundInviteOnboardingModal
            setShowSupportFundInviteOnboardingModal={
              setShowSupportFundInviteOnboardingModal
            }
            supportFundUserInvitedInfos={supportFundUserInvitedInfos}
            token={token}
            invitorId={alwayzInvitorId}
            userInfo={userInfo}
          />
        )}
        {showSupportFundThrowOnboardingModal && (
          <SupportFundThrowOnboardingModal
            setShowSupportFundThrowOnboardingModal={
              setShowSupportFundThrowOnboardingModal
            }
            token={token}
            invitorId={alwayzInvitorId}
            setShowSupportFundGetMoneyModal={setShowSupportFundGetMoneyModal}
            setDiscountPrice={setDiscountPrice}
          />
        )} */}
      </Layout>
    </>
  )
}
export default SupportFundEvent
