import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import UserStore from '../../stores/UserStore'
import ImageManager from '../../images/ImageManager'
import ItemSummaryComponent from '../items/ItemSummaryComponent'
import backendApis from '../../utils/backendApis'
import TeamMembersComponent from './TeamMembersComponent'
import OrderStore from '../../stores/OrderStore'
import TeamGatherComponent from './TeamGatherComponent'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'
import AppDownloadButton from '../buttons/AppDownloadButton'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const TeamPurchaseComponent = observer(({ item, notify }) => {
  const [itemShareMessage, setItemShareMessage] = useState('')
  const createItemShareLink = async () => {
    const { itemInfo, teamDealId } = item
    const { itemId, itemTitle, itemImageUri, teamPurchasePrice } = itemInfo
    UserStore.setIsLoading(true)
    const link = await dynamicLinkUtils.createDynamicItemLink(
      itemId,
      teamDealId,
      'dynamicLink',
      `[올웨이즈] [무료배송] ${itemTitle} 할인특가 ${teamPurchasePrice}원!`,
      `${UserStore.userInfo.userName}님과 제한시간 내에 함께 구매하면 할인특가! 링크를 눌러 바로 참여해보세요!`,
      itemImageUri,
    )
    UserStore.setIsLoading(false)
    const message = `[올웨이즈] [무료배송] ${UserStore.userInfo.userName}님과 함께 구매하면 ${itemTitle} ${teamPurchasePrice}원! 
${link}`
    setItemShareMessage(message)
  }
  useEffect(() => {
    createItemShareLink()
  }, [])
  const styles = {
    buttonContainer: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
    },
    redButton: {
      width: '100%',
      height: 40,
      border: 'solid',
      borderRadius: 4,
      borderWidth: 1,
      backgroundColor: '#EA3F49',
      color: '#FFF',
      borderColor: '#EA3F49',
      fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    whiteButton: {
      width: '100%',
      height: 40,
      border: 'solid',
      borderRadius: 4,
      borderWidth: 1,
      backgroundColor: '#FFF',
      color: '#EA3F49',
      borderColor: '#EA3F49',
      fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    totalContainer: {
      width: '100wh',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
  }
  if (
    item.teamDealInfo.teamDealThreshold === item.teamDealInfo.membersInfo.length
  ) {
    return (
      <div>
        <ItemSummaryComponent
          imageUri={item.itemInfo.itemImageUri}
          mainText={item.itemInfo.itemTitle}
          text1={item.itemInfo.optionsInfo.optionNames
            .map((value, index) => {
              const optionDetail =
                item.itemInfo.optionsInfo.totalOptions[index][
                  item.selectedOption[index]
                ].name
              return `${value} : ${optionDetail}`
            })
            .join(', ')}
          text2={`주문 수량 : ${item.quantity}개`}
          Price={item.totalPrice + item.shippingFee}
          itemId={item.itemInfo.itemId}
          itemInfo={{
            ...item.itemInfo,
            _id: item.itemInfo.itemId,
            mainImageUris: [item.itemInfo.itemImageUri],
          }}
        />
        <div
          style={{
            height: 0.5,
            backgroundColor: '#E0E0E0',
            marginLeft: 16,
            marginRight: 16,
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              paddingBottom: 12,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <ImageManager source='WelcomeOne' size={32} />
            <div style={{ marginLeft: 24, marginRight: 24 }}>팀구매 성공!</div>
            <ImageManager source='WelcomeTwo' size={32} />
          </div>
          <TeamMembersComponent correspondingOrder={item} />
          <div style={{ color: '#333333', fontSize: 16, textAlign: 'center' }}>
            상품 배송이 시작되었어요! <br />
            배송 현황은 앱에서 확인 가능해요.
          </div>
        </div>
        <div
          style={{
            height: 0.5,
            backgroundColor: '#E0E0E0',
            marginLeft: 16,
            marginRight: 16,
          }}
        />
        <div style={styles.buttonContainer}>
          <AppDownloadButton itemId={item.itemInfo.itemId} />
        </div>
      </div>
    )
  }
  return (
    <div>
      <ItemSummaryComponent
        imageUri={item.itemInfo.itemImageUri}
        mainText={item.itemInfo.itemTitle}
        text1={item.itemInfo.optionsInfo.optionNames
          .map((value, index) => {
            const optionDetail =
              item.itemInfo.optionsInfo.totalOptions[index][
                item.selectedOption[index]
              ].name
            return `${value} : ${optionDetail}`
          })
          .join(', ')}
        text2={`주문 수량 : ${item.quantity}개`}
        Price={item.totalPrice + item.shippingFee}
        itemId={item.itemInfo.itemId}
        itemInfo={{
          ...item.itemInfo,
          _id: item.itemInfo.itemId,
          mainImageUris: [item.itemInfo.itemImageUri],
        }}
      />
      <div
        style={{
          height: 0.5,
          backgroundColor: '#E0E0E0',
          marginLeft: 16,
          marginRight: 16,
        }}
      />
      <TeamGatherComponent
        timeStamp={
          new Date(item.teamDealInfo.restartedAt || item.teamDealInfo.createdAt)
        }
        // orderId={item._id}
        correspondingOrder={item}
        isAutoMatching={item.isAutoMatching}
        isFinishedGathering={item.isFinishedGathering}
      />
      <div
        style={{
          height: 0.5,
          backgroundColor: '#E0E0E0',
          marginLeft: 16,
          marginRight: 16,
        }}
      />

      {item.isFinishedGathering ? (
        <div style={styles.buttonContainer}>
          <button
            style={styles.redButton}
            type='button'
            onClick={async () => {
              const soldOutResult = await backendApis.checkItemSoldOut(
                item.itemInfo.itemId,
              )
              if (
                soldOutResult?.status === 2000 &&
                soldOutResult.data.soldOut
              ) {
                alert(
                  '안녕하세요 고객님, 해당 상품 주문량이 많아 품절되었습니다. 결제를 취소하시거나, 재입고까지 기다리실 수 있습니다.',
                )
                return
              }
              // if (
              //   item.itemInfo?.isViralItem &&
              //   new Date().getTime() >
              //     new Date('December 18, 2021 06:00:00 GMT')
              // ) {
              //   alert(
              //     '해당 상품은 팀구매 재시작이 불가능합니다. 결제를 취소해주세요.',
              //   )
              //   return
              // }

              if (item.itemInfo.isUnrestartableItem) {
                alert(
                  '해당 상품은 팀구매 재시작이 불가능합니다. 결제를 취소해주세요.',
                )
                return
              }

              const result = await backendApis.restartEndedTeamDeal(
                item.teamDealId,
                item._id,
              )

              if (result?.status === 200) {
                OrderStore.setLoadedUserOrdersList(
                  OrderStore.loadedUserOrdersList.map((order) => {
                    if (order._id === item._id) {
                      console.log(`matched order! ${order._id}`)
                      return {
                        ...order,
                        isFinishedGathering: false,
                        teamDealInfo: {
                          ...order.teamDealInfo,
                          restartedAt: new Date(),
                        },
                        // teamGatheringStartedAt: new Date(),
                      }
                    }
                    return order
                  }),
                )
                OrderStore.setSharingOrdersInfo([item])
                OrderStore.setOrderIdsToShareToAlwayz([item._id])
                OrderStore.setCreatedTeamDealIdsToShare([item.teamDealId])
                // setShowModal(true)
              } else if (result?.status === 2001) {
                if (result.data.state === 'restarted') {
                  OrderStore.setLoadedUserOrdersList(
                    OrderStore.loadedUserOrdersList.map((order) => {
                      if (order._id === item._id) {
                        console.log(`matched order! ${order._id}`)
                        return {
                          ...order,
                          isFinishedGathering: false,
                          teamDealInfo: {
                            ...order.teamDealInfo,
                            restartedAt: new Date(result.data.restartedAt),
                          },
                          // teamGatheringStartedAt: new Date(),
                        }
                      }
                      return order
                    }),
                  )
                  OrderStore.setSharingOrdersInfo([item])
                  OrderStore.setOrderIdsToShareToAlwayz([item._id])
                  OrderStore.setCreatedTeamDealIdsToShare([item.teamDealId])
                  //   setShowModal(true)
                } else {
                  alert(
                    '팀구매가 완료되었거나 종료되었습니다. 올웨이즈 앱의 내 정보 > 내 주문현황에서 확인해주세요.',
                    // [{ text: '확인', style: 'cancel' }],
                  )
                }
              } else {
                alert(
                  '',
                  '팀구매 재시작 과정에서 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
                  //   [{ text: '확인', style: 'cancel' }],
                )
              }
            }}
          >
            팀 모집 재도전하기
          </button>
          <AppDownloadButton itemId={item.itemInfo.itemId} />
        </div>
      ) : (
        <div style={styles.buttonContainer}>
          <CopyToClipboard
            text={itemShareMessage}
            onCopy={() => {
              if (itemShareMessage) {
                const { itemInfo, teamDealId } = item
                const { itemId, itemTitle, itemImageUri, teamPurchasePrice } =
                  itemInfo
                notify({
                  shareType: 'itemShare',
                  alwayzItemId: itemId,
                  alwayzTeamDealId: teamDealId,
                  alwayzInvitorId: UserStore.userInfo._id,
                })
              }
            }}
          >
            <button
              style={styles.redButton}
              type='button'
              //             onClick={async () => {
              //               notify()
              //               OrderStore.setSharingOrdersInfo([item])
              //               //   setShowModal(true)
              //               const { itemInfo, teamDealId } = item
              //               const { itemId, itemTitle, itemImageUri, teamPurchasePrice } =
              //                 itemInfo
              //               const link = await dynamicLinkUtils.createDynamicItemLink(
              //                 itemId,
              //                 teamDealId,
              //                 'dynamicLink',
              //                 `${itemTitle} ${teamPurchasePrice}원!`,
              //                 `${UserStore.userInfo.userName}님과 팀으로 구매하면 무료배송?! 배송지도 따로, 옵션도 따로!`,
              //                 itemImageUri,
              //               )
              //               const message = `${UserStore.userInfo.userName}님과 함께 구매하면 ${itemTitle} ${teamPurchasePrice}원!
              // ${link}`
              //               copyToClipboard(message)
              //             }}
            >
              팀구매 공유 링크 복사하기
            </button>
          </CopyToClipboard>
          <div style={{ height: 12 }} />
          <AppDownloadButton itemId={item.itemInfo.itemId} />
        </div>
      )}
    </div>
  )
})

export default TeamPurchaseComponent
