import React, { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import commaNumber from 'comma-number'

function AlfarmChocoGiftScreen() {
  return (
    <>
      <Layout>
        {/* <Notice onClick={() => {}} /> */}
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#FFF8F1',
            marginBottom: 10,
            fontFamily: 'Maplestory',
            display: 'flex',
            // justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            // justifyContent: 'space-between',
          }}
        >
          <div style={{ fontSize: 32, marginTop: 68, color: '#793B10' }}>
            5초 미션을 수행하면
          </div>
          <div
            style={{
              fontSize: 32,
              marginBottom: 32,
              fontWeight: 'bold',
              color: '#793B10',
            }}
          >
            초콜릿을 받을 수 있어요
          </div>
          <div
            style={{
              backgroundImage:
                "url('https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/82b77853-4d93-4fe4-bac2-b346cf0f3afb.png')",

              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '60vw',
              height: 128,
              marginTop: 8,
              //   backgroundColor: 'green',
            }}
          />
          <button
            type='button'
            style={{
              width: '100%',
              border: 'none',
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 16,
              //   backgroundColor: 'red',
            }}
            onClick={async () => {
              window.location.href = `https://alwayzshop.ilevit.com/app`
            }}
          >
            <div
              style={{
                backgroundImage:
                  "url('https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/498c6e2a-f399-40cb-8f36-11d34a4154be.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: 200,
              }}
            />
          </button>
          <div style={{ marginTop: 16, color: '#98896C' }}>
            *올웨이즈 신규 고객만 공짜 초콜릿을 받을 수 있어요
          </div>
          <button
            type='button'
            style={{
              width: '100%',
              border: 'none',
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 12,
              //   backgroundColor: 'blue',
            }}
            onClick={async () => {
              window.location.href = `https://alwayzshop.ilevit.com/app`
            }}
          >
            <div
              style={{
                backgroundImage:
                  "url('https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/f6cf52f9-ef3a-4822-87e0-50df40645fcb.png')",

                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '90%',
                height: 120,
              }}
            />
          </button>
        </div>
      </Layout>
    </>
  )
}
export default AlfarmChocoGiftScreen
