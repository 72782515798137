import React, { useState } from 'react'
import Modal from 'react-modal'
import { observer } from 'mobx-react-lite'
import UserStore from '../../stores/UserStore'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'
import AppDownloadMessage from '../common/AppDownloadMessage'
import { isMobile } from 'react-device-detect'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '90%',
    maxWidth: '460px',
    backgroundColor:'#f1f5f9',
    opacity: 0.90,
    borderRadius: 16,
    border: 0,
    padding: 0,
    height: 200,
    zIndex:20,
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // paddingLeft: 20,
    // paddingRight: 20,
    transform: 'translate(-50%, -50%)',
  },
}

const AppDownloadModal = observer(({ showModal = false, setShowModal }) => {
  // const [modalIsOpen, setIsOpen] = useState(true)

  // function openModal() {
  //   setIsOpen(true)
  // }

  const closeModal = () => {
    UserStore.setShowAppDownloadModal(false)
  }
  return (
    <Modal
      isOpen={showModal}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='App Download Modal'
    >
      <div
        style={{
          margin: 24,
          marginRight: 24,
          marginTop: 28,
          marginBottom: 28,
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 32,
          }}
        >
          앱 설치후, 이어서 이용하실 수 있어요
        <div
          style={{
            marginTop: 6,
            fontSize: 16,
            fontWeight: 'normal',
            color:'#334155'
          }}
          className='text-slate-500'
        >
          잠시후 앱스토어로 연결돼요 :)
        </div>
        </div>
        <AppDownloadMessage small />
        {/* <div style={{ height: 24 }} /> */}
        {/* <button
          style={{
            width: '100%',
            marginBottom: 0,
            height: 40,
            backgroundColor: '#EA3f49',
            border: 'none',
            borderRadius: 4,
            fontSize: 14,
            color: '#FFF',
            fontWeight: 'bold',
          }}
          type='button'
          onClick={async () => {
            const { itemId, teamDealId, invitorId, linkType } =
              UserStore.dynamicLinkParams
            UserStore.setIsLoading(true)
            const link = await dynamicLinkUtils.createAppDownloadLink(
              itemId || '',
              teamDealId || '',
              invitorId || '',
              linkType || '',
              '',
              '',
              '',
              'AppDownloadModalWeb',
              UserStore.dynamicLinkParams.isLinkFromApp,
              UserStore.dynamicLinkParams.bottomSheetTimeOut,
              UserStore.isLateComer,
              UserStore.testGroup?.endedTeamDealNotify,
              UserStore.testGroup?.ableToPurchaseViralItem,
              UserStore.testGroup?.reviewPosition,
              UserStore.testGroup?.teamMemberShowing,
              UserStore.testGroup?.appDownloadFullScreen,
            )
            UserStore.setIsLoading(false)
            if (link) {
              console.log(`link: ${link}`)
              window.location.href = link
            }
          }}
        >
          앱으로 더 많은 상품과 혜택받기
        </button> */}
        {/* <button
          onClick={() => {
            closeModal(false)
          }}
          style={{
            width: '100%',
            marginTop: 4,
            height: 36,
            backgroundColor: '#FFF',
            border: 'none',
            borderColor: '#E0E0E0',
            borderWidth: 1,
            borderRadius: 4,
            fontSize: 14,
            color: '#333333',
          }}
          type='button'
        >
          {isMobile ? '모바일 ' : ''}웹으로 돌아가기
        </button> */}
      </div>
    </Modal>
  )
})

export default AppDownloadModal
