import React from 'react'
import ImageManager from '../../images/ImageManager'

const ItemScreenTitleText = ({
  text,
  more = false,
  moreText = '',
  moreTextTeamDeal = '',
  onClick,
  subText = false,
}) => {
  if (subText) {
    return (
      <button
        type='button'
        // activeOpacity={more ? 0.8 : 1}
        style={{
          display: 'flex',
          width: '100%',
          marginTop: -8,
          paddingBottom: 12,
          paddingLeft: 16,
          paddingRight: 16,
          marginLeft: 0,
          marginRight: 0,
          border: 'none',
          flexDirection: 'row',
          backgroundColor: '#FFF',
          justifyContent: 'flex-between',
        }}
        onClick={onClick}
      >
        <div style={{ color: '#999999', fontSize: 14 }}>{text}</div>
      </button>
    )
  }
  return (
    <button
      type='button'
      // activeOpacity={more ? 0.8 : 1}
      style={{
        display: 'flex',
        width: '100%',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        marginLeft: 0,
        marginRight: 0,
        border: 'none',
        flexDirection: 'row',
        backgroundColor: '#FFF',
        justifyContent: 'flex-between',
        alignItems: 'center',
      }}
      onClick={onClick}
    >
      <div style={{ color: '#333333', fontSize: 18, fontWeight: 'bold' }}>
        {text}
      </div>
      <div style={{ flex: 1 }} />
      {more && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ color: '#999999', fontSize: 16 }}>{moreText}</div>
          <ImageManager source='Next' size={18} style={{ marginLeft: 4 }} />
        </div>
      )}
      {moreTextTeamDeal !== '' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ color: '#999999', fontSize: 16 }}>
            {moreTextTeamDeal}
          </div>
        </div>
      )}
    </button>
  )
}

export default ItemScreenTitleText
