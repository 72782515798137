import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import OrderStore from '../../stores/OrderStore'
import UserStore from '../../stores/UserStore'
import ItemSummaryComponent from './ItemSummaryComponent'
import backendApis from '../../utils/backendApis'

const ItemOrderComponent = observer(({ item, index, couponsInfo }) => {
  const [isCouponApplied, setIsCouponApplied] = useState(false)
  useEffect(() => {
    if (
      OrderStore.selectedCouponInfo.orderId ===
      OrderStore.currentUserOrdersList[index]._id
    ) {
      setIsCouponApplied(true)
    } else {
      setIsCouponApplied(false)
    }
  }, [
    OrderStore.currentUserOrdersList[index].quantity,
    OrderStore.selectedCouponInfo.orderId,
    OrderStore.selectedCouponInfo._id,
  ])
  useEffect(() => {
    console.log(`recalculating item total price`)
    OrderStore.setItemTotalPriceByIndex(
      OrderStore.itemTotalPriceByIndex(index),
      index,
    )
  }, [
    OrderStore.currentUserOrdersList[index].quantity,
    OrderStore.selectedCouponInfo.orderId,
    OrderStore.selectedCouponInfo._id,
    UserStore.userInfo.defaultAddressInfo,
  ])

  useEffect(() => {
    OrderStore.setItemShippingFeeByIndex(
      OrderStore.itemShippingFeeByIndex(index),
      index,
    )
  }, [UserStore.userInfo.defaultAddressInfo])

  const checkIfLinkReceived = async () => {
    const linkReceivedResult = await backendApis.getLinkReceivedInfo(
      item.itemInfo.itemId,
    )
    if (linkReceivedResult?.status !== 2000) {
      console.log(`canceled initial team deal link invited!`)
      OrderStore.setItemIsInitialTeamDealLinkInvited(false, index)
      OrderStore.setItemTotalPriceByIndex(
        OrderStore.itemTotalPriceByIndex(index),
        index,
      )
    } else if (linkReceivedResult?.status === 2000) {
      const invitorId =
        linkReceivedResult.data.paramsInfo?.alwayzInvitorId ||
        linkReceivedResult.data.paramsInfo?.invitorId
      const invitedOrderId = linkReceivedResult.data.orderId
      OrderStore.setItemInfoByIndex(
        {
          ...OrderStore.currentUserOrdersList[index].itemInfo,
          invitorId,
          invitedOrderId,
        },
        index,
      )
      OrderStore.setItemIsInitialTeamDealLinkInvited(true, index)
      OrderStore.setItemTotalPriceByIndex(
        OrderStore.itemTotalPriceByIndex(index),
        index,
      )
    }
  }

  useEffect(() => {
    if (
      OrderStore.finishedLoadingUserOrders &&
      OrderStore.loadedUserOrdersList.filter(
        (order) => order.status !== 'pre-pay',
      ).length > 0
    ) {
      console.log(`canceled initial team deal link invited!`)
      OrderStore.setItemIsInitialTeamDealLinkInvited(false, index)
      OrderStore.setItemTotalPriceByIndex(
        OrderStore.itemTotalPriceByIndex(index),
        index,
      )
    } else if (OrderStore.finishedLoadingUserOrders) {
      checkIfLinkReceived()
    }
  }, [OrderStore.finishedLoadingUserOrders])
  return (
    <div>
      <ItemSummaryComponent
        isCouponSelected={isCouponApplied}
        itemId={item._id}
        imageUri={item.itemInfo.itemImageUri}
        mainText={item.itemInfo.itemTitle}
        text1={item.itemInfo.optionsInfo.optionNames
          .map((value, optionIndex) => {
            const optionDetail =
              item.itemInfo.optionsInfo.totalOptions[optionIndex][
                (item.selectedOption && item.selectedOption[optionIndex]) || 0
              ].name
            return `${value} : ${optionDetail}`
          })
          .join(', ')}
        shippingFee={
          UserStore.userInfo.defaultAddressInfo?.postcode
            ? `${OrderStore.currentUserOrdersList[index].shippingFee}`
            : '0'
        }
        // itemPrice={
        //   OrderStore.currentUserOrdersList[index].totalPrice + isCouponApplied
        //     ? OrderStore.selectedCouponInfo.finalDiscountAmount || 0
        //     : 0
        // }
        itemPrice={
          isCouponApplied
            ? OrderStore.currentUserOrdersList[index].totalPrice +
              (OrderStore.selectedCouponInfo.finalDiscountAmount || 0)
            : OrderStore.currentUserOrdersList[index].totalPrice
        }
        discountPrice={OrderStore.selectedCouponInfo.finalDiscountAmount}
        totalPrice={
          UserStore.userInfo.defaultAddressInfo.postcode
            ? `${
                OrderStore.currentUserOrdersList[index].shippingFee +
                OrderStore.currentUserOrdersList[index].totalPrice
              }`
            : OrderStore.currentUserOrdersList[index].totalPrice
        }
        linkShareDiscountAmount={
          OrderStore.currentUserOrdersList[index].initialLinkShareDiscountAmount
        }
        couponsInfo={couponsInfo}
        index={index}
        disabled
      />
    </div>
  )
})

export default ItemOrderComponent
