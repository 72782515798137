import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import OrderStore from '../../stores/OrderStore'
import BlackTextTimer from '../texts/BlackTextTimer'
import TeamMembersComponent from './TeamMembersComponent'
import UserStore from '../../stores/UserStore'
// import { useHistory, useLocation, useParams } from 'react-router-dom'

const TeamGatherComponent = observer(
  ({ timeStamp, correspondingOrder, isAutoMatching, isFinishedGathering }) => {
    // const correspondingOrder = OrderStore.loadedUserOrdersList.find(
    //   (order) => order._id === orderId,
    // )
    const MessageABText = () => {
      // if (UserStore.userInfo.testGroup?.orderEndShareMessage === 'A') {
      return (
        <>
          <div style={{ color: '#333333', fontSize: 18, textAlign: 'center' }}>
            아직{' '}
            <span style={{ color: '#EA3F49' }}>
              {correspondingOrder.teamDealInfo.teamDealThreshold -
                correspondingOrder.teamDealInfo.membersInfo.length}
              명이{' '}
            </span>
            부족해요. 얼른 팀구매 링크를
            <br />
            공유해 주문을 성공해보세요! 🔥
          </div>
          <div style={{ height: 16 }} />
          <div style={{ color: '#999999', fontSize: 18, textAlign: 'center' }}>
            팀원 모두가 <span style={{ color: '#EA3F49' }}>1~2명씩만 </span>
            <br />
            데려오면 금방 성공해요!
          </div>
        </>
      )
      // }
      // return (
      //   <>
      //     <div style={{ color: '#333333', fontSize: 18, textAlign: 'center' }}>
      //       아직{' '}
      //       <span style={{ color: '#EA3F49' }}>
      //         {correspondingOrder.teamDealInfo.teamDealThreshold -
      //           correspondingOrder.teamDealInfo.membersInfo.length}
      //         명이{' '}
      //       </span>
      //       부족해요. 얼른 팀구매 링크를
      //       <br />
      //       공유해 주문을 성공해보세요! 🔥
      //     </div>
      //     <div style={{ height: 16 }} />
      //     <div style={{ color: '#999999', fontSize: 18, textAlign: 'center' }}>
      //       팀구매 링크 공유 <span style={{ color: '#EA3F49' }}>한 번당</span>
      //       <br />
      //       성공 확률이 <span style={{ color: '#EA3F49' }}>3배</span> 높아져요!
      //     </div>
      //   </>
      // )
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <div style={{ fontSize: 18, fontWeight: 'bold', paddingBottom: 12 }}>
          {correspondingOrder.teamDealInfo.teamDealThreshold}인 팀구매
        </div>
        <div
          style={{
            backgroundColor: '#F5F5F5',
            width: 160,
            height: 24,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            fontSize: 14,
            color: '#333333',
            marginBottom: 16,
          }}
        >
          {isFinishedGathering ? (
            '팀 모집 시간 만료'
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <BlackTextTimer
                timeStamp={timeStamp}
                // timeStamp={new Date('2021-07-14T02:19:00.609Z')}
                onTimeEnd={() => {
                  OrderStore.setLoadedUserOrdersList(
                    OrderStore.loadedUserOrdersList.map((order) => {
                      if (order._id === correspondingOrder._id) {
                        return { ...order, isFinishedGathering: true }
                      }
                      return order
                    }),
                    // filter(
                    //   (order) => order._id !== orderId,
                    // ),
                  )
                }}
              />
              <div style={{ paddingLeft: 4 }}>남음</div>
            </div>
          )}
        </div>
        <TeamMembersComponent correspondingOrder={correspondingOrder} />
        {isFinishedGathering ? (
          <div style={{ color: '#333333', fontSize: 16, textAlign: 'center' }}>
            {correspondingOrder.teamDealInfo.membersInfo.length === 1
              ? '재도전하여 '
              : '팀원들과 함께 재도전하여 '}
            <span style={{ color: '#EA3F49' }}>
              {correspondingOrder.teamDealInfo.teamDealThreshold -
                correspondingOrder.teamDealInfo.membersInfo.length}
              명을
            </span>
            <br />
            {correspondingOrder.teamDealInfo.membersInfo.length === 1
              ? ' 모집해보세요!'
              : ' 추가로 모집해요!'}
          </div>
        ) : (
          <MessageABText />
        )}
      </div>
    )
  },
)

export default TeamGatherComponent
