import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import ProfileImageWithNameFilter from './ProfileImageWithNameFilter'

const ProfileImageWithName = ({
  userImageUri,
  userName,
  vertical = false,
  small = false,
  maxWidth,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 12,
        paddingLeft: 16,
        paddingBottom: 12,
      }}
    >
      <ProfileImageWithNameFilter imageUri={userImageUri} />
      <div style={{ paddingLeft: 8, fontSize: 14 }}>{userName}</div>
    </div>
  )
}

export default ProfileImageWithName
