import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import OrderStore from '../stores/OrderStore'
import UserStore from '../stores/UserStore'
import DisplayOrderMainAddress from '../components/orders/DisplayOrderMainAddress'
import DisplayOrderMainPayment from '../components/orders/DisplayOrderMainPayment'
import ItemOrderComponent from '../components/items/ItemOrderComponent'
import BottomPlaceOrderBar from '../components/tab/BottomPlaceOrderBar'
import ImageManager from '../images/ImageManager'
import BasicHeader from '../components/header/BasicHeader'
import configs from '../utils/configs'
import AuthStore from '../stores/AuthStore'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ItemScreenHeader from '../components/items/ItemScreenHeader'

const OrderMainScreen = observer(() => {
  const kakao = window.Kakao
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const notify = (message) =>
    toast(message, {
      autoClose: 1500,
      closeButton: false,
    })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const unblock = history.block((location, action) => {
      console.log('#### history block', location, action)
      if (location.pathname === '/address' && action === 'POP') {
        return false
      }
      return true
      // if (isBlock && action === 'POP') {
      //   console.log('#### blocked ####');
      //   return false;
      // }
    })
    return () => {
      unblock()
    }
  }, [])
  useEffect(() => {
    UserStore.setScreenName('OrderMainScreen_Web')
    OrderStore.setPaymentMethodType('kakao-pay')
  }, [])
  const checkInitialKakaoState = async () => {
    if (kakao) {
      if (!kakao.isInitialized()) {
        await kakao.init(configs.kakaoJsKey)
      }
    }
  }
  const getUserInfoAndRegister = async (dynamicLinkParams) => {
    await kakao.API.request({
      url: '/v2/user/me',
      success: async (response) => {
        if (response?.properties?.alwayzToken) {
          console.log(`token saved in kakao properties!`)
          AuthStore.setToken(response.properties.alwayzToken)
          UserStore.setIsLoading(true)
          const [userSuccess, orders] = await Promise.all([
            await UserStore.loadUserInfo(),
            await OrderStore.loadUserOrdersList(),
            await UserStore.uploadUserLinkUsage(
              window.location.href,
              UserStore.dynamicLinkParams,
              'received',
              UserStore.screenName,
            ),
          ])
          await Promise.all(
            OrderStore.currentUserOrdersList.map(async (order, index) => {
              const linkReceivedResult = await backendApis.getLinkReceivedInfo(
                order.itemInfo.itemId,
              )
              if (linkReceivedResult?.status !== 2000) {
                console.log(`canceled initial team deal link invited!`)
                OrderStore.setItemIsInitialTeamDealLinkInvited(false, index)
                OrderStore.setItemTotalPriceByIndex(
                  OrderStore.itemTotalPriceByIndex(index),
                  index,
                )
              } else if (linkReceivedResult?.status === 2000) {
                const invitorId =
                  linkReceivedResult.data.paramsInfo?.alwayzInvitorId ||
                  linkReceivedResult.data.paramsInfo?.invitorId
                const invitedOrderId = linkReceivedResult.data.orderId
                OrderStore.setItemInfoByIndex(
                  {
                    ...OrderStore.currentUserOrdersList[index].itemInfo,
                    invitorId,
                    invitedOrderId,
                  },
                  index,
                )
                OrderStore.setItemIsInitialTeamDealLinkInvited(true, index)
                OrderStore.setItemTotalPriceByIndex(
                  OrderStore.itemTotalPriceByIndex(index),
                  index,
                )
              }
            }),
          )
          UserStore.setIsLoading(false)
          console.log(`user success: ${userSuccess}`)
          if (!userSuccess) {
            localStorage.removeItem('@alwayz@token')
            kakao.API.request({
              url: '/v1/user/unlink',
              success(response) {
                console.log(response)
              },
              fail(error) {
                console.log(error)
              },
            })
          }

          return
        }
        // await backendApis.registerLogs('token not saved in kakao')
        const kakaoAccountId = response.id
        const kakaoAccount = response.kakao_account
        if (!kakaoAccount?.has_phone_number) {
          alert(
            '해당 계정은 전화번호 등록이 되어있지 않아서 회원가입이 불가합니다. 다른 카카오 계정을 사용해주세요.',
          )
          kakao.API.request({
            url: '/v1/user/unlink',
            success(response) {
              console.log(response)
            },
            fail(error) {
              console.log(error)
            },
          })
          return
        }
        const {
          profile,
          // phone_number: phoneNumber,
          birthday: birthMonthAndDay,
          birthday_type: birthdayType,
          birthyear: birthYear,
          gender,
        } = kakaoAccount
        if (!profile) {
          alert(
            '카카오 계정 정보가 없습니다. 잠시 후 다시 시도해주시거나 다른 카카오 계정을 사용해주세요.',
          )
          return
        }
        const {
          nickname: userName,
          thumbnail_image_url: thumbnailUserImageUri,
          profile_image_url: userImageUri,
        } = profile

        let { phone_number: phoneNumber } = kakaoAccount
        console.log(`phone number: ${phoneNumber}`)
        const countryCode = phoneNumber.split(' ')[0].replace(/\+/g, '')
        phoneNumber = `0${phoneNumber
          .split(' ')
          .slice(1)
          .join('')
          .replace(/-/g, '')}`
        console.log(
          `success response: ${JSON.stringify(response)}, ${JSON.stringify({
            kakaoAccountId,
            userName,
            thumbnailUserImageUri,
            userImageUri,
            birthMonthAndDay,
            birthYear,
            birthdayType,
            countryCode,
            phoneNumber,
            gender,
            dynamicLinkParams,
          })}`,
        )
        const kakaoRegisterResult = await backendApis.registerKakaoUser(
          kakaoAccountId,
          userName,
          thumbnailUserImageUri,
          userImageUri,
          birthMonthAndDay,
          birthYear,
          birthdayType,
          countryCode,
          phoneNumber,
          gender,
          dynamicLinkParams,
        )
        console.log(
          `register kakao user result: ${JSON.stringify(kakaoRegisterResult)}`,
        )
        // await backendApis.registerLogs(
        //   `register kakao user result: ${JSON.stringify(kakaoRegisterResult)}`,
        // )
        if ([200, 2001].includes(kakaoRegisterResult?.status)) {
          AuthStore.setToken(kakaoRegisterResult.data.token)
          UserStore.setIsLoading(true)
          const [userSuccess, orders] = await Promise.all([
            await UserStore.loadUserInfo(),
            await OrderStore.loadUserOrdersList(),
            await UserStore.uploadUserLinkUsage(
              window.location.href,
              UserStore.dynamicLinkParams,
              'received',
              UserStore.screenName,
            ),
          ])
          await Promise.all(
            OrderStore.currentUserOrdersList.map(async (order, index) => {
              const linkReceivedResult = await backendApis.getLinkReceivedInfo(
                order.itemInfo.itemId,
              )
              if (linkReceivedResult?.status !== 2000) {
                console.log(`canceled initial team deal link invited!`)
                OrderStore.setItemIsInitialTeamDealLinkInvited(false, index)
                OrderStore.setItemTotalPriceByIndex(
                  OrderStore.itemTotalPriceByIndex(index),
                  index,
                )
              } else if (linkReceivedResult?.status === 2000) {
                const invitorId =
                  linkReceivedResult.data.paramsInfo?.alwayzInvitorId ||
                  linkReceivedResult.data.paramsInfo?.invitorId
                const invitedOrderId = linkReceivedResult.data.orderId
                OrderStore.setItemInfoByIndex(
                  {
                    ...OrderStore.currentUserOrdersList[index].itemInfo,
                    invitorId,
                    invitedOrderId,
                  },
                  index,
                )
                OrderStore.setItemIsInitialTeamDealLinkInvited(true, index)
                OrderStore.setItemTotalPriceByIndex(
                  OrderStore.itemTotalPriceByIndex(index),
                  index,
                )
              }
            }),
          )
          UserStore.setIsLoading(false)
          kakao.API.request({
            url: '/v1/user/update_profile',
            data: {
              properties: {
                alwayzUserId: kakaoRegisterResult.data.userId,
                alwayzToken: kakaoRegisterResult.data.token,
              },
            },
            success: (updateResponse) => {
              console.log(
                `user properties update response: ${JSON.stringify(
                  updateResponse,
                )}`,
              )
            },
            fail: (updateError) => {
              console.log(
                `user properties update error: ${JSON.stringify(updateError)}`,
              )
            },
          })
        }
      },
      fail: async (response) => {
        console.log(`fail response: ${JSON.stringify(response)}`)
      },
    })
  }
  useEffect(() => {
    checkInitialKakaoState()
  }, [])
  const styles = {
    title: {
      fontSize: 16,
      paddingLeft: 16,
      paddingTop: 8,
      paddingBottom: 8,
    },
  }
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <BasicHeader title='주문/결제' orderMain />
      {/* <ItemScreenHeader /> */}
      <ToastContainer
        style={{ color: '#333333' }}
        position='top-center'
        autoClose={1500}
        hideProgressBar
        transition={Zoom}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        closeButton={false}
      />
      <div>
        {!UserStore.userInfo._id ? (
          <div>
            <div style={styles.title}>카카오 본인인증</div>
            <div
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 12,
              }}
            >
              <button
                style={{
                  border: 'solid',
                  fontSize: 16,
                  color: '#FFFFFF',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 4,
                  width: '100%',
                  borderColor: '#EA3F49',
                  borderWidth: 1,
                  backgroundColor: '#EA3F49',
                  height: 40,
                }}
                type='button'
                onClick={async () => {
                  kakao.Auth.login({
                    // redirectUri: 'http://localhost:4002',
                    // scope: 'profile,phone_number,gender,birthday',
                    async success(authObjTemp) {
                      // await backendApis.registerLogs('kakao login success')
                      // alert(JSON.stringify(authObj))
                      // setAuthObj(authObjTemp)
                      await kakao.Auth.setAccessToken(authObjTemp.access_token)
                      await getUserInfoAndRegister(UserStore.dynamicLinkParams)
                      //   await checkKakaoLogin()
                    },
                    async fail(err) {
                      // setError(err)
                      // alert(JSON.stringify(err))
                      // await backendApis.registerLogs(
                      //   `kakao login failed, ${JSON.stringify(err)}`,
                      // )
                    },
                  })
                }}
              >
                <div style={{ marginRight: 2 }}>
                  카카오로 3초만에 본인인증하기
                </div>
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div style={styles.title}>카카오 본인인증</div>
            <div
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 4,
                paddingBottom: 12,
                fontSize: 14,
                color: '#BDBDBD',
              }}
            >
              <div>인증 완료</div>
            </div>
          </div>
        )}
        <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
        {UserStore.userInfo._id && (
          <>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div style={styles.title}>배송지</div>
                {UserStore.userInfo.addressesInfo?.length > 0 && (
                  <button
                    style={{
                      border: 'none',
                      fontSize: 14,
                      color: '#EA3F49',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingLeft: 16,
                      paddingRight: 12,
                      backgroundColor: '#FFF',
                    }}
                    type='button'
                    onClick={() => {
                      history.push('/address')
                    }}
                  >
                    <div style={{ marginRight: 2 }}>변경하기</div>
                    <ImageManager source='NextRed' size={16} />
                  </button>
                )}
              </div>
              {UserStore.userInfo.addressesInfo?.length === 0 && (
                <div
                  style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 8,
                    paddingBottom: 12,
                  }}
                >
                  <button
                    style={{
                      border: 'solid',
                      outline: 'none',
                      fontSize: 16,
                      color: '#FFFFFF',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 4,
                      width: '100%',
                      borderColor: '#EA3F49',
                      borderWidth: 1,
                      backgroundColor: '#EA3F49',
                      height: 40,
                    }}
                    type='button'
                    onClick={() => {
                      if (UserStore.userInfo._id) {
                        history.push('/address/add')
                      } else {
                        alert(
                          '주문자 확인을 위해서 먼저 카카오 본인인증을 해주세요!',
                        )
                      }
                    }}
                  >
                    <div style={{ marginRight: 2 }}>새 배송지 추가하기</div>
                  </button>
                </div>
              )}
              <DisplayOrderMainAddress
                item={UserStore.userInfo.defaultAddressInfo}
              />
            </div>
            <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div style={styles.title}>결제 수단</div>
                <button
                  style={{
                    border: 'none',
                    fontSize: 14,
                    color: '#EA3F49',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingLeft: 16,
                    paddingRight: 12,
                    backgroundColor: '#FFF',
                  }}
                  type='button'
                  onClick={() => {
                    UserStore.setShowAppDownloadModal(true)
                  }}
                >
                  <div style={{ marginRight: 2 }}>변경하기</div>
                  <ImageManager source='NextRed' size={16} />
                </button>
              </div>
              <DisplayOrderMainPayment />
            </div>
            <div style={{ height: 8, backgroundColor: '#F5F5F5' }} />
          </>
        )}
        <div>
          <div style={styles.title}>주문 상품</div>
          {OrderStore.currentUserOrdersList.map((item, index) => (
            <div key={item._id + index.toString()}>
              <ItemOrderComponent
                item={item}
                index={index}
                couponsInfo={UserStore.couponsInfo.filter(
                  (coupon) => new Date() < new Date(coupon.expiresAt),
                )}
              />
              <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
              <div
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingLeft: 16,
                  paddingRight: 8,
                  alignItems: 'center',
                }}
              >
                <div>주문 수량</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <button
                    style={{
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 4,
                      paddingBottom: 4,
                      backgroundColor: '#F5F5F5',
                      borderRadius: 4,
                      marginLeft: 4,
                      marginRight: 4,
                      border: 'none',
                    }}
                    type='button'
                    onClick={() => {
                      if (
                        OrderStore.currentUserOrdersList[index].quantity > 1
                      ) {
                        OrderStore.setItemQuantityByIndex(
                          OrderStore.currentUserOrdersList[index].quantity - 1,
                          index,
                        )
                      }
                    }}
                  >
                    <ImageManager source='MinusSmall' size={16} />
                  </button>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: 32,
                      alignItems: 'center',
                    }}
                  >
                    {OrderStore.currentUserOrdersList[index]?.quantity}
                  </div>
                  <button
                    style={{
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 4,
                      paddingBottom: 4,
                      backgroundColor: '#F5F5F5',
                      borderRadius: 4,
                      marginLeft: 4,
                      marginRight: 4,
                      border: 'none',
                    }}
                    type='button'
                    onClick={() => {
                      if (
                        OrderStore.currentUserOrdersList[index].quantity <
                        Math.min(
                          OrderStore.currentUserOrdersList[index].itemInfo
                            .purchaseLimits || 10,
                          OrderStore.currentUserOrdersList[index].selectedOption
                            ?.stockNumber ||
                            OrderStore.currentUserOrdersList[index].itemInfo
                              .purchaseLimits ||
                            10,
                        )
                      ) {
                        OrderStore.setItemQuantityByIndex(
                          OrderStore.currentUserOrdersList[index].quantity + 1,
                          index,
                        )
                      } else if (
                        OrderStore.currentUserOrdersList[index].quantity >=
                        (OrderStore.currentUserOrdersList[index].itemInfo
                          .purchaseLimits || 10)
                      ) {
                        console.log(`Maximum purchase limit!!`)
                        notify('1회 주문 가능한 최대 수량입니다.')
                        // toastRef.current.show(`1회 주문 가능한 최대 수량입니다.`, 1000)
                      } else if (
                        OrderStore.currentUserOrdersList[index].quantity >=
                        OrderStore.currentUserOrdersList[index].selectedOption
                          ?.stockNumber
                      ) {
                        console.log(`Maximum purchase limit!!`)
                        notify('현재 재고로 주문 가능한 최대 수량입니다.')
                        // toastRef.current.show(
                        //   `현재 재고로 주문 가능한 최대 수량입니다.`,
                        //   1000,
                        // )
                      }
                    }}
                  >
                    <ImageManager source='PlusSmall' size={16} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <BottomPlaceOrderBar notify={notify} />
      </div>
      <div style={{ backgroundColor: '#F5F5F5', flexGrow: 1, minHeight: 150 }}>
        {OrderStore.currentUserOrdersList[0]?.isTeamDeal && (
          <div
            style={{
              paddingLeft: 16,
              paddingTop: 8,
              color: '#999999',
              fontSize: 14,
            }}
          >
            * 결제 후 팀구매 링크를 공유할 수 있어요.
          </div>
        )}
      </div>
    </div>
  )
})

export default OrderMainScreen
