import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import BasicHeader from '../components/header/BasicHeader'
import UserStore from '../stores/UserStore'
import ReviewListComponent from '../components/items/ReviewListComponent'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from 'react-loader-spinner'

const ReviewScreen = observer(() => {
  const location = useLocation()
  const itemId = location.state?.itemId
  const numberOfReview = location.state?.numberOfReview
  const [reviewsState, setReviewsState] = useState([])
  const [ableToLoad, setAbleToLoad] = useState(true)
  const [waitingQueToLoad, setWaitingQueToLoad] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  useEffect(() => {
    UserStore.setScreenName('ReviewScreen_Web')
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const loadAdditionalReviews = async () => {
    setAbleToLoad(false)
    const result = await backendApis.getReviewsForItem(itemId, 0)
    if (result?.status === 200) {
      const addedReviews = result.data.filter(
        (review) =>
          !reviewsState
            .map((reviewTemp) => reviewTemp._id)
            .includes(review._id),
      )
      if (addedReviews.length > 0) {
        setReviewsState([...reviewsState, ...addedReviews])
      } else {
        setHasMore(false)
      }
    } else {
      setHasMore(false)
    }
    setAbleToLoad(true)
  }

  useEffect(() => {
    if (ableToLoad === true && waitingQueToLoad === true) {
      setWaitingQueToLoad(false)
      loadAdditionalReviews()
    }
  }, [ableToLoad])
  return (
    <div style={{ backgroundColor: '#e2e2e8' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '480px',
          marginLeft: 'auto',
          marginRight: 'auto',
          backgroundColor: '#ffffff',
        }}
      >
        <BasicHeader title={`상품 후기 ${numberOfReview}건`} />
        <InfiniteScroll
          dataLength={reviewsState.length}
          next={async () => {
            if (ableToLoad) {
              // setAbleToLoad(false)
              await loadAdditionalReviews()
              // setAbleToLoad(true)
            } else {
              setWaitingQueToLoad(true)
            }
          }}
          hasMore={hasMore}
          loader={
            <div
              style={{
                width: '100%',
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '0',
                bottom: '0',
              }}
            >
              <Loader type='Oval' color='#EA3F49' height='40' width='40' />
            </div>
          }
        >
          {reviewsState.map((review) => (
            <ReviewListComponent
              key={review._id}
              review={review}
              source='ReviewScreen'
            />
          ))}
          <BasicHeader title={`상품 후기 ${numberOfReview}건`} />
        </InfiniteScroll>
        <div style={{ backgroundColor: '#F5F5F5', flexGrow: 1 }} />
      </div>
    </div>
  )
})

export default ReviewScreen
