import React from 'react'
import UserStore from '../stores/UserStore'
import { logEvent, getAnalytics } from 'firebase/analytics'

const AlfarmCoffeeEventWithDongWookLeeScreen = () => {
  const analytics = getAnalytics()
  return (
    <div
      style={{
        maxWidth: '480px',
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: '100vh',
        backgroundColor: '#fadebb',
      }}
    >
      <img
        src='https://assets.ilevit.com/767deb7b-0e48-4b58-8e85-3743ddcea300.png'
        width='100%'
        style={{ display: 'block', margin: 0, padding: 0, border: 'none' }}
      />
      <iframe
        width='100%'
        height='240'
        src='https://www.youtube.com/embed/pqGVItC0fWs?si=4AQHpJKTAaWT4Abb&autoplay=1&mute=1'
        title='Alwayz brand campaign'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowfullscreen
      />
      <img
        src='https://assets.ilevit.com/92d72d99-6cdc-4055-a3e7-248222d20ea2.png'
        width='100%'
        style={{ display: 'block', margin: 0, padding: 0, border: 'none' }}
      />

      <div
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          maxWidth: '480px',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
          backgroundColor: 'transparent',
          justifyContent: 'center',
        }}
      >
        <button
          type='button'
          style={{
            backgroundImage:
              'url("https://assets.ilevit.com/7a2a5667-a9d9-448f-a257-1917b4790312.png")',
            backgroundSize: 'cover',
            minWidth: 340,
            minHeight: 80,
            backgroundColor: 'transparent',
            color: '#994822',
            fontSize: 20,
            fontWeight: 'bold',
            border: 0,
            borderRadius: 8,
            margin: 12,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (UserStore.isLoading) return
            UserStore.setIsLoading(true)
            window.location.href = 'https://alwayzshop.page.link/KVSo'
            logEvent(analytics, 'click_app_install_in_alfarm_event')
            UserStore.setIsLoading(false)
          }}
        >
          올웨이즈에서 올팜 시작하기 ▶︎
        </button>
      </div>
    </div>
  )
}
export default AlfarmCoffeeEventWithDongWookLeeScreen
