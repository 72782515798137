import React from 'react'
import StarFull from './StarFull.png'
import AppIcon from './AppIcon.png'
import StarHalf from './StarHalf.png'
import StarNone from './StarNone.png'
import Heart from './Heart.png'
import TeamPurchase2 from './TeamPurchase2.png'
import FreeShipping from './FreeShipping.png'
import Delete from './Delete.png'
import EmptyProfile from './EmptyProfile.png'
import PlusSmall from './PlusSmall.png'
import MinusSmall from './MinusSmall.png'
import Next from './Next.png'
import NextRed from './NextRed.png'
import Back from './Back.png'
import CheckSmall from './CheckSmall.png'
import NextWhite from './NextWhite.png'
import Question from './Question.png'
import WelcomeOne from './WelcomeOne.png'
import WelcomeTwo from './WelcomeTwo.png'
import Kakao from './Kakao.png'
import KakaoPay from './KakaoPay.png'
import IsCheapestImage from './IsCheapestImage.png'
import AppScreen from './AppScreen.png'
import LotteryBanner1 from './LotteryBanner1.png'
import LotteryBanner2 from './LotteryBanner2.png'
import LotteryBanner3 from './LotteryBanner3.png'
import LotteryBanner5 from './LotteryBanner5.png'
import LotteryBanner6 from './LotteryBanner6.png'
import LotteryBanner7 from './LotteryBanner7.png'
import LotteryBanner8 from './LotteryBanner8.png'
import LotteryWinner from './LotteryWinner.png'
import ChallengeInviteBannerImage from './ChallengeInviteBannerImage.png'
import AlwayzFullLogoRed from './AlwayzFullLogoRed.png'
import duoduoStyleEnvelope from './duoduoStyleEnvelope.png'
import Welcome from './Welcome.png'
import LuckyBox from './LuckyBox.png'

const ImageManager = ({ source, size = 24, style }) => {
  let IMAGE_URI
  if (source === 'AppScreen') {
    return (
      <img src={AppScreen} alt='' width={size} height={(size / 980) * 1382} />
    )
  }
  if (source === 'AppIcon') {
    return <img src={AppIcon} alt='' width={size} height={size} />
  }
  if (source === 'StarFull') {
    return <img src={StarFull} alt='' width={size} height={size} />
  }
  if (source === 'StarHalf') {
    return <img src={StarHalf} alt='' width={size} height={size} />
  }
  if (source === 'Heart') {
    return <img src={Heart} alt='' width={size} height={size} />
  }
  if (source === 'StarNone') {
    return <img src={StarNone} alt='' width={size} height={size} />
  }
  if (source === 'KakaoPay') {
    return <img src={KakaoPay} alt='' width={48} height={20} />
  }
  if (source === 'Kakao') {
    return <img src={Kakao} alt='' width={size} height={size} />
  }
  if (source === 'WelcomeTwo') {
    return <img src={WelcomeTwo} alt='' width={size} height={size} />
  }
  if (source === 'WelcomeOne') {
    return <img src={WelcomeOne} alt='' width={size} height={size} />
  }
  if (source === 'Question') {
    return <img src={Question} alt='' width={size} height={size} />
  }
  if (source === 'NextWhite') {
    return <img src={NextWhite} alt='' width={size} height={size} />
  }
  if (source === 'CheckSmall') {
    return <img src={CheckSmall} alt='' width={size} height={size} />
  }
  if (source === 'Back') {
    return <img src={Back} alt='' width={size} height={size} />
  }
  if (source === 'NextRed') {
    return <img src={NextRed} alt='' width={size} height={size} />
  }
  if (source === 'Next') {
    return <img src={Next} alt='' width={size} height={size} />
  }
  if (source === 'MinusSmall') {
    return <img src={MinusSmall} alt='' width={size} height={size} />
  }
  if (source === 'PlusSmall') {
    return <img src={PlusSmall} alt='' width={size} height={size} />
  }
  if (source === 'LotteryBanner1') {
    return <img src={LotteryBanner1} alt='' width='100%' />
  }
  if (source === 'LotteryBanner2') {
    return <img src={LotteryBanner2} alt='' width='100%' />
  }
  if (source === 'LotteryBanner3') {
    return <img src={LotteryBanner3} alt='' width='100%' />
  }
  if (source === 'LotteryBanner5') {
    return <img src={LotteryBanner5} alt='' width='100%' />
  }
  if (source === 'LotteryBanner6') {
    return <img src={LotteryBanner6} alt='' width='100%' />
  }
  if (source === 'LotteryBanner7') {
    return <img src={LotteryBanner7} alt='' width='100%' />
  }
  if (source === 'LotteryBanner8') {
    return <img src={LotteryBanner8} alt='' width='100%' />
  }
  if (source === 'LotteryWinner') {
    return <img src={LotteryWinner} alt='' width={size} height={size} />
  }
  if (source === 'ChallengeInviteBannerImage') {
    return <img src={ChallengeInviteBannerImage} alt='' width='100%' />
  }

  if (source === 'AlwayzFullLogoRed') {
    return <img src={AlwayzFullLogoRed} alt='' width='100%' />
  }

  if (source === 'duoduoStyleEnvelope') {
    return <img src={duoduoStyleEnvelope} alt='' width='100%' />
  }

  if (source === 'Welcome') {
    return <img src={Welcome} alt='' width='100%' />
  }

  if (source === 'EmptyProfile') {
    return (
      <img
        src={EmptyProfile}
        alt=''
        width={size}
        height={size}
        style={{
          borderRadius: size,
          border: 'solid',
          borderWidth: 1,
          borderColor: '#E0E0E0',
        }}
      />
    )
  }
  if (source === 'Delete') {
    return <img src={Delete} alt='' width={size} height={size} />
  }
  if (source === 'IsCheapestImage') {
    return (
      <img
        src={IsCheapestImage}
        alt=''
        width={size}
        height={size}
        style={style}
      />
    )
  }
  if (source === 'FreeShipping') {
    return <img src={FreeShipping} alt='' width={size} height={size} />
  }
  if (source === 'TeamPurchase2') {
    return <img src={TeamPurchase2} alt='' width={size} height={size} />
  }
  if (source === 'LuckyBox') {
    return <img src={LuckyBox} alt='' width={size} height={size} />
  }

  if (!IMAGE_URI) {
    IMAGE_URI = source
  }
  return <div />
}

export default ImageManager
