import React from 'react'
import { observer } from 'mobx-react-lite'
import UseWindowDimension from '../common/UseWindowDimension'
import RenderRating from '../rating/RenderRating'
import GetRatingCount from '../texts/GetRatingCount'
import LinesEllipsis from 'react-lines-ellipsis'
import commaNumber from 'comma-number'
import FreeDeliverButton from '../buttons/FreeDeliverButton'
import UserStore from '../../stores/UserStore'

const RelatedItemComponent = observer(({ itemInfo, index }) => {
  const { width } = UseWindowDimension()
  const limitedWidth = Math.min(width, 480)

  return (
    <div
      style={{
        position: 'relative',
        width: 0.4 * limitedWidth,
        paddingLeft: index === 0 ? 16 : 0,
        paddingRight: 16,
        paddingBottom: 12,
        outline: 'none',
      }}
      role='button'
      tabIndex={0}
      onClick={() => {
        UserStore.setDynamicLinkParams({
          ...UserStore.dynamicLinkParams,
          enteringComponent: 'RelatedItems',
        })
        // history.replace(`/items/${itemInfo?._id}`)
        UserStore.setShowAppDownloadItemModal(true)
      }}
      onKeyDown={() => {
        UserStore.setDynamicLinkParams({
          ...UserStore.dynamicLinkParams,
          enteringComponent: 'RelatedItems',
        })
        UserStore.setShowAppDownloadItemModal(true)
      }}
    >
      <img
        alt={itemInfo?.mainImageUris[0]}
        // key={mainImageUri}
        src={itemInfo?.mainImageUris[0]}
        style={{
          objectFit: 'cover',
          width: 0.4 * limitedWidth,
          height: 0.4 * limitedWidth,
          borderRadius: 8,
        }}
      />

      <LinesEllipsis
        style={{ fontSize: 14 }}
        text={itemInfo?.itemTitle}
        maxLine='1'
        ellipsis='..'
        basedOn='letters'
      />
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 6 }}>
        <div style={{ color: '#666666', fontSize: 14, fontWeight: 'bold' }}>
          {Math.ceil(itemInfo?.discountRate * 100)}%
        </div>
        <div
          style={{
            fontSize: 14,
            marginLeft: 2,
            color: '#999999',
            textDecorationLine: 'line-through',
          }}
        >
          {commaNumber(itemInfo?.individualPurchasePrice)}원
        </div>
      </div>
      <div
        style={{
          color: '#EA3F49',
          fontSize: 16,
          fontWeight: 'bold',
        }}
      >
        {commaNumber(itemInfo?.teamPurchasePrice)}원
      </div>
      <div
        style={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}
      >
        <RenderRating size={14} rate={GetRatingCount(itemInfo)?.getRating} />
        <div
          style={{
            fontSize: 10,
            includeFontPadding: false,
            paddingBottom: 4,
            paddingLeft: 2,
            color: '#999999',
            fontWeight: 'bold',
          }}
        >
          ({commaNumber(GetRatingCount(itemInfo)?.getRatingCount)})
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <FreeDeliverButton small />
      </div>
    </div>
  )
})

export default RelatedItemComponent
