import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ImageManager from '../../images/ImageManager'
import UserStore from '../../stores/UserStore'
import commaNumber from 'comma-number'
import ItemStore from '../../stores/ItemStore'
import SoldOutModal from '../modal/SoldOutModal'

const BottomOrderBarForTenThoushandsDeal = observer(
  ({
    item,
    isEventDeal,
    soldOut,
    isInitialTeamDealLinkInvited,
    showSoldOutModal,
    setShowSoldOutModal,
    onPress,
    enteringComponent,
    invitedOrderId,
    linkInvitorId,
    itemInfoState,
    needsPrivacyProtection = false,
    eventDealStatusHere = '',
    orderRecord = 'unpurchased',
  }) => {
    const [ableToPressHeart, setAbleToPressHeart] = useState(true)

    return (
      <div
        style={{
          height: 64,
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
        }}
      >
        <SoldOutModal
          showModal={showSoldOutModal}
          setShowModal={setShowSoldOutModal}
        />
        <button
          type='button'
          onClick={() => {
            UserStore.setShowAppDownloadModal(true)
          }}
          style={{
            backgroundColor: '#FFF',
            borderColor: '#FFF',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#999999',
            padding: 16,
          }}
        >
          <ImageManager source='Heart' size={24} />
          찜하기
        </button>
        <button
          style={{
            borderColor: '#F8C454',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'column',
            color: '#FFF',
            flex: 1,
            alignItems: 'center',
            backgroundColor: '#F8C454',
            justifyContent: 'center',
            marginTop: 8,
            marginBottom: 8,
            marginRight: 8,
            borderRadius: 4,
          }}
          type='button'
          onClick={() => {
            if (soldOut) {
              setShowSoldOutModal(true)
            } else UserStore.setShowAppDownloadModal(true)
          }}
        >
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>
            {commaNumber(
              Math.floor(
                ItemStore.targetItemInfo.teamPurchasePrice *
                  (isInitialTeamDealLinkInvited
                    ? 1 -
                      (UserStore.linkBenefitsInfo
                        .linkReceiverFirstPurchaseDiscountRate || 0)
                    : 1),
              ),
            )}
            원
          </div>
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>
            만인딜 상품 구매하러 가기
          </div>
        </button>
      </div>
    )
  },
)

export default BottomOrderBarForTenThoushandsDeal
