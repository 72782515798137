import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import ProfileImageWithName from '../social/ProfileImageWithName'
import ReviewComponent from './ReviewComponent'

const ReviewListComponent = ({ review, source }) => {
  return (
    <div>
      <ProfileImageWithName
        userImageUri={review.userImageUri}
        userName={review.userName}
        small
      />
      <ReviewComponent itemReviewInfo={review} source={source} />
    </div>
  )
}

export default ReviewListComponent
