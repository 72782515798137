import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import OrderStore from '../../stores/OrderStore'
import UserStore from '../../stores/UserStore'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'
import ImageManager from '../../images/ImageManager'

const AppDownloadButton = observer(({ itemId }) => {
  const styles = {
    buttonContainer: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
    },
    redButton: {
      width: '100%',
      height: 40,
      border: 'solid',
      borderRadius: 4,
      borderWidth: 1,
      backgroundColor: '#EA3F49',
      color: '#FFF',
      borderColor: '#EA3F49',
      fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    whiteButton: {
      width: '100%',
      height: 40,
      border: 'solid',
      borderRadius: 4,
      borderWidth: 1,
      backgroundColor: '#FFF',
      color: '#EA3F49',
      borderColor: '#EA3F49',
      fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    totalContainer: {
      width: '100wh',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
  }
  return (
    <button
      style={styles.whiteButton}
      type='button'
      onClick={async () => {
        const { teamDealId, invitorId, linkType } = UserStore.dynamicLinkParams
        UserStore.setIsLoading(true)
        const link = await dynamicLinkUtils.createAppDownloadLink(
          itemId || '',
          teamDealId || '',
          invitorId || '',
          linkType || '',
          '',
          '',
          '',
          'OrderEndScreenWeb',
          UserStore.dynamicLinkParams.isLinkFromApp,
          UserStore.dynamicLinkParams.bottomSheetTimeOut,
          UserStore.isLateComer,
          UserStore.testGroup?.endedTeamDealNotify,
          UserStore.testGroup?.ableToPurchaseViralItem,
          UserStore.testGroup?.reviewPosition,
          UserStore.testGroup?.teamMemberShowing,
          UserStore.testGroup?.appDownloadFullScreen,
        )
        UserStore.setIsLoading(false)
        if (link) {
          console.log(`link: ${link}`)
          window.location.href = link
        }
      }}
    >
      <div style={{ paddingRight: 4 }}>앱에서 더 많은 최저가 상품들 보기</div>
      <ImageManager source='NextRed' size={16} />
    </button>
  )
})

export default AppDownloadButton
